import React, { useEffect, useState } from "react";
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack } from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { AddCircleOutline, Delete, Edit, Upgrade } from "@mui/icons-material";
import "./InventoryStyles/Consumables.css";
import UpdateQuantity from "./UpdateQuantity";
import AddItem from "./AddItem";
import EditItem from "./EditItem";
import { useDispatch, useSelector } from "react-redux";
import { GetInventoryConsumables, GetInventoryNonConsumables } from "../../Redux/Slices/InventorySlices/InventorySlice.js";
import { CircularLoader, SomethingWentWrong } from "../UI Components/Basic.js";
import { useNavigate } from "react-router-dom";
import { DrawerRoutes } from "../Routes/routes.js";
import { DeleteItem } from "./Consumables.js";
import { status } from "../../Redux/Slices/userSlice.js";
import { convertGMTtoISTAndFormat } from "../ReportsComponents/BillsAndPayments/DedicatedIndustryBill.js";

export default function Nonconsumeables() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const INVENTORY_NON_CONSUMABLES = useSelector(state => state.INVENTORY_ITEMS.NonConsumables)
  const INVENTORY_Loading = useSelector(state => state.INVENTORY_ITEMS.Loading)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const [searchResults, setSearchResults] = useState(INVENTORY_NON_CONSUMABLES.items); // For saving filtered results if anyone applies a filter or search key
  let searchTimeout; // FOr debouncing purpose

  const isEmpty = INVENTORY_NON_CONSUMABLES?.items?.length <= 0

  // Fetching the inventory items 
  useEffect(() => { if (!INVENTORY_NON_CONSUMABLES?.items && INVENTORY_Loading !== status.LOADING && INVENTORY_Loading !== status.ERROR) { dispatch(GetInventoryNonConsumables({ AuthToken: UserToken, PlantID: PlantID })) } }, [])

  // this small useffect set data to search results for mapping into the table. this is important because whenever the page loads, if the data in redux is change then the change should reflex in this table also, and direct changes is not reflecting because we are not directly mapping the redux state, we saving the redux state into filtered results or search resutlts and then mapping results in to the table.
  useEffect(() => { setSearchResults(INVENTORY_NON_CONSUMABLES.items) }, [INVENTORY_NON_CONSUMABLES])

  // function for searching a item in the search bar
  const handleSearch = (searchQuery) => {
    const lowercaseQuery = searchQuery.toLowerCase();// Convert the search query to lowercase for case-insensitive search
    const filteredResults = INVENTORY_NON_CONSUMABLES.items.filter( // Filter inventory items based on name or code containing the search query
      item =>
        item.data.itemName.toLowerCase().includes(lowercaseQuery) ||
        item.data.itemCode.toLowerCase().includes(lowercaseQuery)
    );
    setSearchResults(filteredResults); // Update the state with the search results
  };

  // implementing debouncing in the search bar
  const debouncedSearch = (query) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      handleSearch(query);
    }, 1000);
  };


  // A use state for handling opening and closing of All modals like add, update, edit and delete. A number will be assigned to every modal and if the modal number matched with state value the corresponding modal will appear on the screen. The values for modals are as follows - ( 0 - None, 1 - Add Item, 2 - Update Item, 3 - Edit Item, 4 - Delete Item). And target id for the element for which we have targeting.
  const [ActiveModal, setActiveModal] = useState({
    ActiveModalIndex: 0, // This state opens corresponding modal
    TargetItem: { // and this, passes data into that modal 
      itemId: '',
      itemName: '',
      itemCode: '',
      itemQuantityAvailable: '',
      itemUnit: ''
    }
  }) // Initialised with 0 so no modal will apear on screen


  const handleCheckUsage = () => {
    navigate(DrawerRoutes.Reports.Inventory_Reports)
  };
  return (
    <>
      {/* Dynamically rendering the modals as Active Modal changes the corresponding modal will appear on screen */}
      {ActiveModal.ActiveModalIndex === 0 ? ''
        : ActiveModal.ActiveModalIndex === 1 ? <AddItem setActiveModal={setActiveModal} />
          : ActiveModal.ActiveModalIndex === 2 ? <UpdateQuantity setActiveModal={setActiveModal} item={ActiveModal.TargetItem} />
            : ActiveModal.ActiveModalIndex === 3 ? <EditItem setActiveModal={setActiveModal} item={ActiveModal.TargetItem} />
              : ActiveModal.ActiveModalIndex === 4 ? <DeleteItem setActiveModal={setActiveModal} item={ActiveModal.TargetItem} /> : ''}
      {/* <button onClick={() => { dispatch(GetInventoryNonConsumables(UserToken)) }}>fetch items</button> */}

      {/* this is a universal page header and will be included in every page. */}
      <div className="PAGE_HEADER">
        {/* Name of the page, will show in lwft of the header */}
        <h3>Inventory / Non Consumables</h3>

        {/* create any buttons or other things here. it will show on right side of the page header */}
        <ToggleButtonGroup
          size="small"
          color="PrimaryBlue"
          value={"2"}
        >
          <ToggleButton onClick={() => { navigate(DrawerRoutes.Inventory.consumables) }} value="1">Consumable</ToggleButton>
          <ToggleButton value="2">Non-Consumable</ToggleButton>
        </ToggleButtonGroup>

      </div>

      {/* this div will contain all the content of the page */}
      <div className="Industries_Body_Container">
        <div className="table_Heading_Container">
          <div className="search_form">
            <TextField
              id="outlined-basic"
              label="Search Item..."
              variant="outlined"
              size="small"
              onChange={(e) => debouncedSearch(e.target.value)}
            />
          </div>
          <div className="table_toolbar">
            <Button disableElevation onClick={handleCheckUsage}>Check Usage</Button>
            <Button onClick={() => {
              setActiveModal({
                ActiveModalIndex: 1,
                TargetItemId: null  // Null because we dont need any item to pass for adding new items
              })
            }} variant="contained" color="PrimaryBlue" startIcon={<AddCircleOutline />} disableElevation>
              Add Item
            </Button>
          </div>
        </div>

        {
          INVENTORY_Loading === status.IDLE ?
            <div>
              {
                !(isEmpty) ? <TableContainer disableElevation>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          width="10%"
                          sx={{
                            paddingLeft: "30px",
                          }}
                        >
                          S.No.
                        </TableCell>
                        <TableCell >Name</TableCell>
                        <TableCell >Item Code</TableCell>
                        <TableCell >Quantity</TableCell>
                        <TableCell sx={{ width: '120px' }}>Created</TableCell>
                        <TableCell >Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {searchResults?.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell sx={{ paddingLeft: "30px" }}>{searchResults.indexOf(item) + 1}</TableCell>
                          <TableCell>{item.data.itemName}</TableCell>
                          <TableCell>{item.data.itemCode}</TableCell>
                          <TableCell><b>{item.data.itemQuantityAvailable}</b></TableCell>
                          <TableCell>{convertGMTtoISTAndFormat(item.data.dateCreated)}</TableCell>

                          {/* Actions for inventory items */}
                          <TableCell id="action_toolbar">
                            <Stack direction="row" spacing={1}>
                              <Button color="PrimaryBlue" variant="contained" startIcon={<Upgrade sx={{ width: '16px' }} />} sx={{ height: '32px' }} onClick={() => {
                                setActiveModal({
                                  ActiveModalIndex: 2,
                                  TargetItem: {
                                    itemId: item.id,
                                    itemName: item.data.itemName,
                                    itemCode: item.data.itemCode,
                                    itemQuantityAvailable: item.data.itemQuantityAvailable,
                                    itemUnit: item.data.itemUnit
                                  }
                                })
                              }}>Update Quantity</Button>
                              <Button color="warning" variant="contained" startIcon={<Edit sx={{ width: '16px' }} />} sx={{ height: '32px' }} onClick={() => {
                                setActiveModal({
                                  ActiveModalIndex: 3,
                                  TargetItem: {
                                    itemId: item.id,
                                    itemName: item.data.itemName,
                                    itemCode: item.data.itemCode,
                                    itemQuantityAvailable: item.data.itemQuantityAvailable,
                                    itemUnit: item.data.itemUnit
                                  }
                                })
                              }}>Edit</Button>
                              <Button color="error" variant="contained" startIcon={<Delete sx={{ width: '16px' }} />} sx={{ height: '32px' }} onClick={() => {
                                setActiveModal({
                                  ActiveModalIndex: 4,
                                  TargetItem: {
                                    itemId: item.id,
                                    itemName: item.data.itemName,
                                    itemType: item.data.itemType
                                  }
                                })
                              }} >Delete</Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> : <p className="absCenter simpleParagraph">No items found</p>
              }
            </div> : INVENTORY_Loading === status.LOADING ? < CircularLoader />
              : INVENTORY_Loading === status.ERROR ? <SomethingWentWrong /> : ''}
      </div>
    </>
  );
}
