import { useEffect } from 'react';
import './AuthStyles/AuthUIComponents.css';
import { Alert, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { removeError, showTicker } from '../Redux/Slices/TickerSlice';

export function ProceedButton(props) {
    return <button disabled={props.loading === true ? true : false} className={props.loading === true ? "ProceedButton disabledButton" : "ProceedButton"} onClick={props.targetFunction}>
        {props.loading === true ? <Loader /> : props.title}
    </button>
}

export function Loader() {
    return <div className="loader"></div>
}

// Currently not used 
export function Success() {
    return (
        <div className="wrapper">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            <p className="extrasmallHeading">User Verified</p>
        </div>
    )
}

// showing error in the application globally
export function ErrorTicker() {
    const TickerData = useSelector(state => state.TICKER_DATA)
    const dispatch = useDispatch()
    const errorQueue = useSelector(state => state.TICKER_DATA.errorQueue);
    
    useEffect(() => {
        // Remove the first error from the queue after a fixed time interval
        console.log(errorQueue.length);
        if (errorQueue.length > 0) {
            const timer = setTimeout(() => {
                dispatch(removeError());
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [TickerData]);

    return <div id="ErrorTicker">
        <Stack direction="column" spacing={1}>
            {
                errorQueue.map((error, index) => {
                    return <Alert key={index} variant='filled' severity={error.type}>
                        {error.message}
                    </Alert>
                })
            }
        </Stack>
    </div>
}