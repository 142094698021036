import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVER_ADDRESS } from "../../../Firebase/Variables";
import { showTicker } from "../TickerSlice";
import { status } from "../userSlice";

// function for getting the total amount we got between the duration through paying bills.
export const GetPaymentAmout = createAsyncThunk('GetPaymentAmout', async ({ AuthToken, PlantID, duration }, { dispatch, fulfillWithValue, rejectWithValue }) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/plant/dashboard/payments?from=${duration.from}&to=${duration.to}&plantID=${PlantID}`, requestOptions)
        const data = await response.json()

        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(data)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
    }

})


// function for getting the number of payment receipt requests.
export const GetNumberOfReceiptApprovalRequests = createAsyncThunk('GetNumberOfReceiptApprovalRequests', async ({ AuthToken, PlantID }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/plant/dashboard/industries/requests/bills?plantID=${PlantID}`, requestOptions)
        const data = await response.json();

        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(data)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
    }
})


// function for getting the total number of industries
export const GetNumberOfIndustries = createAsyncThunk('GetNumberOfIndustries', async ({ AuthToken, PlantID }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/plant/dashboard/industries?plantID=${PlantID}`, requestOptions)
        const data = await response.json();

        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(data)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
    }

})



export const GetNoOfIndustryRequests = createAsyncThunk('GetNoOfIndustryRequests', async ({ AuthToken, PlantID }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/plant/dashboard/industries/requests?plantID=${PlantID}`, requestOptions)
        const data = await response.json();

        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(data)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
    }
})


// declaring the slice
const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        PaymentData: {
            totalPaymentRecieved: {
                loading: status.IDLE,
                data: []
            },
            receiptRequests: {
                loading: status.IDLE,
                data: []
            }
        },
        IndustryData: {
            numberOfIndustries: {
                loading: status.IDLE,
                data: []
            },
            numberOfRequests: {
                loading: status.IDLE,
                data: []
            }
        },
    },
    extraReducers: {

        // handling the function for fetching the amount of the bills
        [GetPaymentAmout.pending]: (state) => {
            state.PaymentData.totalPaymentRecieved.loading = status.LOADING
        },
        [GetPaymentAmout.fulfilled]: (state, action) => {
            state.PaymentData.totalPaymentRecieved.loading = status.IDLE
            state.PaymentData.totalPaymentRecieved.data = action.payload
        },
        [GetPaymentAmout.rejected]: (state) => {
            state.PaymentData.totalPaymentRecieved.loading = status.ERROR
        },


        // handling the function for the payment receipt requests
        [GetNumberOfReceiptApprovalRequests.pending]: (state, action) => {
            state.PaymentData.receiptRequests.loading = status.LOADING
        },
        [GetNumberOfReceiptApprovalRequests.fulfilled]: (state, action) => {
            state.PaymentData.receiptRequests.loading = status.IDLE
            state.PaymentData.receiptRequests.totalPaymentRecieved.data = action.payload
        },
        [GetNumberOfReceiptApprovalRequests.rejected]: (state, action) => {
            state.PaymentData.receiptRequests.loading = status.ERROR
        },


        //handling the function for the number of industries
        [GetNumberOfIndustries.pending]: (state) => {
            state.IndustryData.numberOfIndustries.loading = status.LOADING
        },
        [GetNumberOfIndustries.fulfilled]: (state, action) => {
            state.IndustryData.numberOfIndustries.loading = status.IDLE
            state.IndustryData.numberOfIndustries.data = action.payload
        },
        [GetNumberOfIndustries.rejected]: (state) => {
            state.IndustryData.numberOfIndustries.loading = status.ERROR
        },


        //handling the function for gettting the number of industry requests.
        [GetNoOfIndustryRequests.pending]: (state, action) => {
            state.IndustryData.numberOfRequests.loading = status.LOADING
        },
        [GetNoOfIndustryRequests.fulfilled]: (state, action) => {
            state.IndustryData.numberOfRequests.loading = status.IDLE
            state.IndustryData.numberOfRequests.data = action.payload
        },
        [GetNoOfIndustryRequests.rejected]: (state, action) => {
            state.IndustryData.numberOfRequests.loading = status.ERROR
        },
    }
})


export default DashboardSlice.reducer;