import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_ADDRESS } from "../../../Firebase/Variables";
import { showTicker } from "../TickerSlice";
import { deleteIndustryRedux } from "./GetIndustriesSlice";
import { DrawerRoutes } from "../../../Components/Routes/routes";

export const DeleteIndustry = createAsyncThunk('DeleteIndustry', async ({ AuthToken, IndustryID, PlantID, setLoading, setisDelete, navigate }, { dispatch }) => {

    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/delete/${IndustryID}?plantID=${PlantID}`, requestOptions)
        const data = await response.json();
        if (response.ok) {
            dispatch(showTicker({
                message: data.message,
                type: 'success'
            }))
            dispatch(deleteIndustryRedux(IndustryID)) // deleting the industry from redux store also 
            setisDelete(false) // closing the deleting popup after deleting
            navigate(DrawerRoutes.Industry.Industries) // navigating to industries page after deleting the industries
            setLoading(false) // loading false
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            setLoading(false)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        setLoading(false)
    }
})