import { React, useState, useEffect } from 'react'
import { Button, Stack, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ArrowBack, AddCircleOutline } from "@mui/icons-material";
import { status } from "../../../Redux/Slices/userSlice";
import { CircularLoader, SomethingWentWrong } from "../../UI Components/Basic";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import "../Navigation.css";
import InletOutlet from './InletOutlet';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AddChamber from './AddChamber';
import Edit from './Edit';
import Remove from './Remove';
import { showTicker } from "../../../Redux/Slices/TickerSlice";
import { GetChambers, UpdateChamberParams, setModalState } from "../../../Redux/Slices/ProcessManagementSlices/ChamberManagementSlice"

const EditChamber = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedChamber = useSelector(state => state.CHAMBER_MANAGEMENT.SelectedChamber)
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const CHAMBER_LOADING = useSelector(state => state.CHAMBER_MANAGEMENT.GetChamberLoading)
  const ADD_CHAMBER_LOADING = useSelector(state => state.CHAMBER_MANAGEMENT.AddLoading)
  const { chamberid } = useParams()
  const [ActiveModal, setActiveModal] = useState({
    ActiveModalIndex: 0,
    chamberid: "",
    type: "",
    data: {}
  })
  const [editChamberName, setEditChamberName] = useState({ edit: false, name: selectedChamber?.data?.chamberName })
  const Navigation = [
    'Chamber Parameters',
    'Inlet Parameters',
    'Outlet Parameters'
  ]

  const [selectedButton, setSelectedButton] = useState(Navigation[0]);
  const [drawer, setDrawer] = useState({
    open: false,
    name: "",
    params: {},
    type: "",
    chamberid: "",
    inletIndex: -1,
    outletIndex: -1
  });

  const handleAddIO = (type) => {
    setActiveModal(prevState => ({
      ...prevState,
      ActiveModalIndex: 3,
      chamberid: selectedChamber.id,
      type: type,
    }));
  }

  const handleEdit = (type, data) => {
    setActiveModal({
      ActiveModalIndex: data.operation === "add" || data.operation === "update" ? 1 : 2,
      chamberid: selectedChamber.id,
      type: type,
      data: data
    })
    dispatch(setModalState(1))
  }

  useEffect(() => {
    if (CHAMBER_LOADING === status.LOADING) {
      setEditChamberName({ edit: false, name: selectedChamber?.data?.chamberName })
    }
  }, [CHAMBER_LOADING, selectedChamber?.data?.chamberName])

  useEffect(() => {
    dispatch(GetChambers({ chamberid: chamberid, UserToken, PlantID }))
  }, [PlantID, UserToken, chamberid, dispatch])

  return (
    <div>
      {ActiveModal.ActiveModalIndex === 1 ? <Edit setActiveModal={setActiveModal} data={ActiveModal} /> :
        ActiveModal.ActiveModalIndex === 2 ? <Remove setActiveModal={setActiveModal} data={ActiveModal} /> :
          ActiveModal.ActiveModalIndex === 3 ? <AddChamber setActiveModal={setActiveModal} data={ActiveModal} /> : ""
      }

      {/* {CHAMBER_LOADING === status.IDLE ? */}
      <div>
        <div className="PAGE_HEADER">
          {/* Name of the page, will show in left of the header */}
          <Stack direction="row" alignItems="center" spacing={1} sx={{ width: "100%" }}>
            <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /> </IconButton>
            {!editChamberName.edit ?
              CHAMBER_LOADING === status.IDLE ?
                <Stack direction='row' alignItems='center' sx={{ width: "100%" }}>
                  <h3>{selectedChamber?.data?.chamberName}</h3>
                  <IconButton onClick={() => setEditChamberName({ ...editChamberName, edit: true })}> <EditIcon fontSize='medium' /> </IconButton>
                </Stack> : "Loading..." :
              <Stack direction='row' alignItems='center' sx={{ width: "100%" }}>
                <TextField
                  autoFocus={editChamberName.edit}
                  placeholder='Chamber Name'
                  variant="standard"
                  defaultValue={selectedChamber?.data?.chamberName}
                  sx={{ width: "40%" }}
                  required
                  value={editChamberName.name}
                  onChange={(e) => setEditChamberName({ ...editChamberName, name: e.target.value })}
                  disabled={ADD_CHAMBER_LOADING === status.LOADING}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      if (editChamberName.name === "") {
                        dispatch(showTicker({
                          message: "Chamber Name can't be empty",
                          type: 'error'
                        }))
                        return
                      }
                      dispatch(UpdateChamberParams({
                        chamberid: selectedChamber.id,
                        type: 0,
                        name: editChamberName.name,
                        operation: "name",
                        UserToken,
                        PlantID
                      }))
                    }
                  }}
                />
                <IconButton
                  onClick={() =>
                    setEditChamberName({ name: selectedChamber.data.chamberName, edit: false })
                  }
                  disabled={ADD_CHAMBER_LOADING === status.LOADING}
                >
                  <CloseIcon fontSize='medium' />
                </IconButton>
              </Stack>
            }
          </Stack>
        </div>

        <div className="Industries_Body_Container">

          <div className="Navigation_Wrapper">
            <ToggleButtonGroup
              value={selectedButton}
              color="PrimaryBlue"
            >
              {
                Navigation.map((navigate) => {
                  return (
                    <ToggleButton
                      key={navigate}
                      sx={{ border: "none", borderRadius: "0" }}
                      value={navigate}
                      onClick={() => {
                        setSelectedButton(navigate)
                      }}
                    >
                      {navigate}
                    </ToggleButton>
                  )
                })
              }
            </ToggleButtonGroup>
          </div>

          {CHAMBER_LOADING === status.IDLE ?
            <div
              className="TOOLS_WRAPPER"
              style={{
                display: "flex",
                flexDirection: "Row",
                justifyContent: "space-between",
                boxShadow: "0 0 0 0"
              }}
            >
              <div className="search_form">
                <TextField
                  id="outlined-basic"
                  label="Search parameter"
                  variant="outlined"
                  size="small"
                />
              </div>

              {
                selectedButton === Navigation[0] ?
                  <Button
                    color="PrimaryBlue"
                    variant="contained"
                    size="medium"
                    startIcon={<AddCircleOutline />}
                    disableElevation
                    onClick={() => handleEdit("Chamber", { operation: "add" })}
                  >
                    Add Parameters
                  </Button> :
                  selectedButton === Navigation[1] ?
                    <Button
                      color="PrimaryBlue"
                      variant="contained"
                      size="medium"
                      startIcon={<AddCircleOutline />}
                      disableElevation
                      onClick={() => handleAddIO("Inlet")}
                    >
                      Add Inlet
                    </Button> :
                    <Button
                      color="PrimaryBlue"
                      variant="contained"
                      size="medium"
                      startIcon={<AddCircleOutline />}
                      disableElevation
                      onClick={() => handleAddIO("Outlet")}
                    >
                      Add Outlet
                    </Button>
              }
            </div> : CHAMBER_LOADING === status.LOADING ? <CircularLoader />
              : CHAMBER_LOADING === status.ERROR ? <SomethingWentWrong /> : ''
          }

          {CHAMBER_LOADING === status.IDLE ?
            <Table>
              {
                selectedButton === Navigation[0] ?
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ paddingLeft: "20px", width: "10%" }} >S.No.</TableCell>
                      <TableCell>Parameter</TableCell>
                      <TableCell>TagNo</TableCell>
                      <TableCell>Reading</TableCell>
                      <TableCell sx={{ width: "25%" }} >Actions</TableCell>
                    </TableRow>
                  </TableHead> :
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ paddingLeft: "20px", width: "10%" }} >S.No.</TableCell>
                      <TableCell> {selectedButton === Navigation[1] ? "Inlet" : "Outlet"} Name </TableCell>
                      <TableCell sx={{ width: "25%" }} >Actions</TableCell>
                    </TableRow>
                  </TableHead>

              }

              {
                selectedButton === Navigation[0] ?
                  <TableBody>
                    {selectedChamber?.data?.chamberParams ?
                      Object.entries(selectedChamber?.data?.chamberParams).map(([key, value], index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ paddingLeft: "20px", width: "10%" }}>{index + 1}</TableCell>
                          <TableCell>{key}</TableCell>
                          <TableCell>{value}</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>
                            <Stack
                              direction="row"
                              spacing={1}
                            >
                              <Button
                                variant="contained"
                                color="warning"
                                sx={{ height: "2rem", fontWeight: "700" }}
                                size="small"
                                disableElevation
                                onClick={() => handleEdit("Chamber", { index: index, key: key, value: value, operation: "update" })}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                sx={{ height: "2rem", fontWeight: "700" }}
                                size="small"
                                disableElevation
                                onClick={() => handleEdit("Chamber", { index: index, key: key, value: value, operation: "delete" })}
                              >
                                Delete
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      )) : ""
                    }
                  </TableBody> :
                  selectedButton === Navigation[1] ?
                    <TableBody>
                      {
                        selectedChamber.data?.inlet?.map((inlet, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ paddingLeft: "20px", width: "10%" }}>{index + 1}</TableCell>
                            <TableCell>{inlet.name}</TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                spacing={1}
                              >
                                <Button
                                  variant="contained"
                                  color="warning"
                                  sx={{ height: "2rem", fontWeight: "700" }}
                                  size="small"
                                  disableElevation
                                  onClick={(event) => {
                                    setDrawer({
                                      open: true,
                                      name: inlet.name,
                                      params: inlet.params,
                                      type: "Inlet",
                                      chamberid: selectedChamber.id,
                                      inletIndex: index,
                                      outletIndex: undefined
                                    })
                                    event.stopPropagation()
                                  }
                                  }
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="contained"
                                  color="error"
                                  sx={{ height: "2rem", fontWeight: "700" }}
                                  size="small"
                                  disableElevation
                                  onClick={() => handleEdit("Inlet", { name: inlet.name, inletIndex: index, action: 'delete', operation: "delete" })}
                                >
                                  Delete
                                </Button>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody> :
                    <TableBody>
                      {
                        selectedChamber.data?.outlet?.map((outlet, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ paddingLeft: "20px", width: "10%" }}>{index + 1}</TableCell>
                            <TableCell>{outlet.name}</TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                spacing={1}
                              >
                                <Button
                                  variant="contained"
                                  color="warning"
                                  sx={{ height: "2rem", fontWeight: "700" }}
                                  size="small"
                                  disableElevation
                                  onClick={() => setDrawer({
                                    open: true,
                                    name: outlet.name,
                                    params: outlet.params,
                                    type: "Outlet",
                                    chamberid: selectedChamber.id,
                                    inletIndex: undefined,
                                    outletIndex: index
                                  })}
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="contained"
                                  color="error"
                                  sx={{ height: "2rem", fontWeight: "700" }}
                                  size="small"
                                  disableElevation
                                  onClick={() => handleEdit("Outlet", { name: outlet.name, outletIndex: index, action: 'delete', operation: "delete" })}
                                >
                                  Delete
                                </Button>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>

              }

            </Table> : CHAMBER_LOADING === status.LOADING ? <CircularLoader />
              : CHAMBER_LOADING === status.ERROR ? <SomethingWentWrong /> : ''
          }

        </div>

      </div>
      {/* : CHAMBER_LOADING === status.LOADING ? <CircularLoader />
        : CHAMBER_LOADING === status.ERROR ? <SomethingWentWrong /> : ''

      } */}

      <InletOutlet
        Open={drawer.open}
        handle={() => {
          setDrawer({
            open: false,
            name: "",
            params: {},
            type: "",
            chamberid: "",
            inletIndex: -1,
            outletIndex: -1
          })
        }}
        data={drawer} />

    </div>
  )
}

export default EditChamber