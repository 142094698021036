import { Send } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Button, ToggleButtonGroup, ToggleButton, TableRow, Chip, Stack, Badge } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DrawerRoutes } from "../Routes/routes";
import { useDispatch, useSelector } from "react-redux";
import { GetNoticesPlantSide } from "../../Redux/Slices/Industry/SendNoticeSlice";
import { status } from "../../Redux/Slices/userSlice";
import { CircularLoader, SideDrawer, SomethingWentWrong } from "../UI Components/Basic";
import { DataGrid } from "@mui/x-data-grid";
import { convertGMTtoISTAndFormat } from "../ReportsComponents/BillsAndPayments/DedicatedIndustryBill";
import NoticeSideDrawerLayout from "./NoticeSideDrawerLayout";
import { NOTICE_PERMISSIONS } from "../../Roles/Roles";
import { GetBillReciptApprovalRequests } from "../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/PaymentsSlice";
import RenderNotices from "./RenderNotices";
import RenderPaymentApprovalRequests from "./RenderPaymentApprovalRequests";


export default function NotificationsNotices() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken) // logged in user auth token
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID) // logged in user auth token
  const UserID = useSelector(state => state.USER_DETAILS.UID)// logged in user user id for storing it in local storage for isread the notice

  const UserRole = useSelector(state => state.USER_DETAILS.UserRole)
  const accessLevel = useSelector(state => state.USER_DETAILS.UserAccessLevel)
  const departments = useSelector(state => state.USER_DETAILS.UserDetails?.departmentAccess)
  const NoticePermissions = NOTICE_PERMISSIONS(UserRole, accessLevel, departments)


  useEffect(() => {
    dispatch(GetNoticesPlantSide({ AuthToken: AuthToken, PlantID: PlantID }))
    // if (isUserAllowedThis) {
    //   dispatch(GetBillReciptApprovalRequests(AuthToken))
    // }
  }, [])

  // const { state } = useLocation();
  // const [ActiveSection, setActiveSection] = useState(state?.section !== null && state?.section !== undefined && typeof (state?.section) === "number" ? state?.section : 1) // For storing the current section of the page. checking user is coming from bills section then state must be some integer value, then setting it to approval section so it will toggle automatically to the payment approval section. 

  return (
    <>
      <div>
        {/* this is a universal page header and will be included in every page. */}
        <div className="PAGE_HEADER">
          {/* Name of the page, will show on the left of the header */}
          <h3>Notifications / Notices</h3>

          {/* create any buttons or other things here. it will show on the right side of the page header */}
          <Button
            variant="contained"
            color="PrimaryBlue"
            startIcon={<SendIcon fontSize="small" />}
            disableElevation
            disabled={!NoticePermissions.isAllowedToSendNotices}
            onClick={() => { navigate(DrawerRoutes.SendNotice) }}
          >
            Send a notice
          </Button>
        </div>

        {/* this div will contain all the content of the page */}
        <div className="Industries_Body_Container">

          <div className='Navigation_Wrapper' style={{ position: "sticky", top: 0, backgroundColor: 'white', zIndex: 1 }}>
            {/* <ToggleButtonGroup color="PrimaryBlue" value={ActiveSection} >
              <ToggleButton sx={{ border: 'none', borderRadius: '0' }} onClick={() => { setActiveSection(1) }} value={1}>Notices</ToggleButton>
              {isUserAllowedThis ? <ToggleButton sx={{ border: 'none', borderRadius: '0' }} onClick={() => { setActiveSection(2) }} value={2}>Payment Approval Requests {NoOfReqsts}</ToggleButton> : ''}
            </ToggleButtonGroup> */}
          </div>

          {/* rendering the notices here */}
          {/* {ActiveSection === 1 ? <RenderNotices /> : ActiveSection === 2 ? <RenderPaymentApprovalRequests /> : ''} */}
          <RenderNotices />
          <br /><br />
        </div>
      </div>
    </>
  );
}
