import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { showTicker } from '../../../../Redux/Slices/TickerSlice'
import { Add, ArrowBack } from '@mui/icons-material'
import { Box, Button, FormControlLabel, Stack, Switch, TextField, Typography } from '@mui/material'
import { addGood } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/BillGenerationSlice';
import { LoadingButton } from '@mui/lab';
import { GetFlowByDate } from '../../../../Redux/Slices/Industry/FlowDataSlice';
import { FormatTimestamp, GetFinalGood, GoodDatainit, ValidateAddingGoods, typeOfGoods } from './AddGoodFunctions';

// function for adding goods, to adding good we need some required details of good that we are taking here from the user
export default function AddGoodDetails(props) {

    const AuthToken = useSelector((state) => state.USER_DETAILS.AuthToken); // logged in user auth token
    const PlantID = useSelector((state) => state.USER_DETAILS.PlantID); // logged in user auth token
    const [GoodData, setGoodData] = useState({ //Usestate for holding the entered data of the good. 

        // feilds which is going to entered by the user and this data is going to be in api.
        qty: 0,
        isYearlyorMonthtly: "",
        starting: '',
        ending: '',
        masterCopyID: '',
        flowMonth: '',
        flowRate: 0,
        type: props.TypeOfGood, // getting from the bill body component and setting from the TypeofGood component. made in the bill body component

        // Feilds which are going to calculate automatically by the formula.
        cgstAmount: 0,
        sgstAmount: 0,

        // Feilds which are going to fetch from the master bill copy via bill id passing through props.
        cgstRate: 0,
        sgstRate: 0,
        goodPrice: 0,
        unit: '',
        HSN_SAC_code: '',
    })

    console.log(GoodData);

    const dispatch = useDispatch()
    const MasterCopies = useSelector((state) => state.BILL_MASTER_COPIES.Copies.billMasterCopies); // Getting master bill copies from the redux store for taking the master copy detaild based on the current selected master copy id.
    const goods = useSelector(state => state.BILL_GENERATION.goods) // Getting goods from the redux store.
    const industryID = useSelector(state => state.BILL_GENERATION.industryID)
    const [FlowLoading, setFlowLoading] = useState(false)


    //FUNCTION for getting flow of the user selected month. 
    const GetFlow = () => {
        if (GoodData.flowMonth !== '') {
            dispatch(GetFlowByDate({
                IndustryID: industryID,
                AuthToken: AuthToken,
                Date: GoodData.flowMonth,
                setLoading: setFlowLoading,
                PlantID: PlantID
            })).then((flow) => {
                // console.log("Flow:", flow.payload);
                if (flow?.payload !== undefined) {
                    setGoodData({ ...GoodData, flowRate: +flow?.payload?.toFixed(2) })
                }
                else {
                    setGoodData({ ...GoodData, flowRate: 0 })
                }
            })
                .catch((error) => {
                    console.error(error);
                    setGoodData({ ...GoodData, flowRate: 0 })
                })
        }
        else {
            dispatch(showTicker({
                message: "Please select a month first",
                type: 'error'
            }))
        }
    }



    // Function to add a new good to the list
    const AddGood = () => {

        // Validating the details of the good. 
        if (!ValidateAddingGoods(props.TypeOfGood, GoodData, dispatch)) { return }

        // Check if the new good is already in the list
        const isAlreadyInList = goods.some(
            (good) =>
                //conditions if type of good is consumption type
                (good.qty === GoodData.qty && good.starting === GoodData.starting && good.ending === GoodData.ending && good.type === typeOfGoods.WCG && good.masterCopyID === GoodData.masterCopyID)
                ||
                //conditions if type of good is flow type
                (good.flowMonth === GoodData.flowMonth && good.flowRate === GoodData.flowRate && good.type === typeOfGoods.WFG && good.masterCopyID === GoodData.masterCopyID)
        );

        // If not already in the list, add the new good
        if (!isAlreadyInList) {

            // Making data ready for adding into thhe redux store. 
            let readyData = GetFinalGood(MasterCopies, props.SelectedBill, GoodData) // data returning after the fetching and calculating the gst rates.

            // adding the good in the redux store, hence in our goods list.
            dispatch(addGood(readyData))

            // Reset the new good input fields (GoodDatainit is declared in the AddGoodFuntions.js), so that we can add new goods.
            setGoodData(GoodDatainit);

            // re-setting the selected bill for next good we are going to add.
            props.setSelectedBill('')

            // Closing the modal after adding good.
            props.setModalState({ isModalOpen: false, innerModalState: 1 })

        }
        else {
            // If already in the list, display a message or take appropriate action
            dispatch(showTicker({
                message: "This entry is already in the list",
                type: 'error'
            }))
        }
    };



    const [checked, setChecked] = useState(false);
    const ManualFlowAdd = () => {
        const switchHandle = (event) => {
            setChecked(event.target.checked);
            setGoodData({ ...GoodData, flowRate: 0 })
        }
        return <Box>
            <FormControlLabel control={<Switch checked={checked} onChange={switchHandle} />} label="Enter Manually" labelPlacement='start' /><br />
            {checked ? <TextField
                label='Type flow in meter cube'
                placeholder='Type flow in meter cube'
                type='number'
                value={GoodData.flowRate !== 0 ? GoodData.flowRate : ""}
                fullWidth
                onChange={(e) => { if (e.target.value.length <= 6) { setGoodData({ ...GoodData, flowRate: +e.target.value }) } }}
            /> : ''}
        </Box>
    };

    return <Box>
        <Box className="Modal_heading">
            <Stack>
                <Typography variant='h6'>Add Good's Details</Typography>
                <Typography variant='caption'>Add all the required details of the good in order to create a good </Typography>
            </Stack>
        </Box>

        {
            props.TypeOfGood === typeOfGoods.WCG ? <Box>
                <Box p={'20px'}>
                    <Stack direction="column" spacing={1}>
                        <Stack fullWidth direction='row' justifyContent='space-between' spacing={1}>
                            <TextField
                                label='Consumption in meter cube'
                                placeholder='Consumption in meter cube'
                                type='number'
                                value={GoodData.qty !== 0 ? GoodData.qty : ""}
                                fullWidth
                                onChange={(e) => { if (e.target?.value?.length <= 6) { setGoodData({ ...GoodData, qty: +e.target.value }) } }}
                            />
                        </Stack>

                        <Stack direction="row" spacing={1}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    sx={{ width: '50%' }}
                                    onChange={(value) => { setGoodData({ ...GoodData, starting: FormatTimestamp(value, "MM/dd/yyyy") }) }}
                                    disableFuture={true}
                                    // value={GoodData.starting}
                                    format='dd/MM/yyyy'
                                    label="Start date"
                                    slotProps={{ textField: { readOnly: true } }} // For disabling the keyboard date entry in the date picker
                                />
                                <DatePicker
                                    sx={{ width: '50%' }}
                                    onChange={(value) => { setGoodData({ ...GoodData, ending: FormatTimestamp(value, "MM/dd/yyyy") }) }}
                                    disableFuture={true}
                                    format='dd/MM/yyyy'
                                    label="End date"
                                    slotProps={{ textField: { readOnly: true } }} // For disabling the keyboard date entry in the date picker
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
                : props.TypeOfGood === typeOfGoods.WFG ? <Box p={"20px"}>
                    <Box>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label={'Select Flow Month'}
                                    views={['month', 'year']}
                                    disableFuture={true}
                                    onChange={(value) => { setGoodData({ ...GoodData, flowMonth: FormatTimestamp(value, "yyyy-MM") }) }}
                                    slotProps={{ textField: { readOnly: true } }} // For disabling the keyboard date entry in the date picker
                                />
                            </LocalizationProvider>
                            <LoadingButton
                                variant='outlined'
                                onClick={GetFlow}
                                disabled={checked}
                            >
                                Get Flow
                            </LoadingButton>
                            <Typography variant='body2'>{
                                FlowLoading ? "Loading..." : <> <b>{GoodData.flowRate}</b> Unit</>
                            }</Typography>
                        </Stack>

                        {ManualFlowAdd()}
                    </Box>
                </Box>
                    : ""
        }



        <Stack direction="row-reverse" spacing={1} padding="20px" position='sticky' bottom={0} backgroundColor='white' fullWidth>
            <Button
                variant='contained'
                disabled={props.TypeOfGood === typeOfGoods.WFG && GoodData.flowRate === 0}
                disableElevation
                endIcon={<Add />}
                onClick={AddGood}
            > Add Good</Button>
            <Button
                variant='contained'
                color='SecondaryBtn'
                disableElevation
                startIcon={<ArrowBack />}
                onClick={() => { props.setModalState({ isModalOpen: true, innerModalState: 1 }); }}
            > Back</Button>
        </Stack>
    </Box>
}
