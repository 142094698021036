import { Close } from '@mui/icons-material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { IndustrySideRoutes } from '../../Routes/routes';
import './success.css'
import { useSearchParams } from 'react-router-dom';


export default function PaymentCancelled() {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState({ amount: searchParams.get('amount'), orderid: searchParams.get('orderid') })
  const [seconds, setSeconds] = useState(6);
  const navigate = useNavigate()

  useEffect(() => {
    // Exit early if we reach 0
    if (seconds === 0) return;

    // Create an interval to decrement the timer every second
    const intervalId = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    // Clear the interval when the component is unmounted or when seconds reach 0
    return () => clearInterval(intervalId);
  }, [seconds]);

  // Action to be performed after 5 seconds
  useEffect(() => {
    if (seconds === 0) {
      // Perform your action here
      navigate(IndustrySideRoutes.IndustryBills)
    }
  }, [seconds]);

  return (
    <Box>
      <Success seconds={seconds} params={params} />
    </Box>
  )
}


export function Success(props) {
  return (
    <Box position="absolute" width="100vw" height="100vh" top={0} left={0}>
      <div class="wrapper">
        <svg class="checkmark_cancelled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <Close />
          <circle class="checkmark__circle_cancelled" cx="26" cy="26" r="25" fill="none" />
          {/* <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /> */}
        </svg>
        <p className="extrasmallHeading">Payment Cancelled</p>
        <p>Order ID: {props.params.orderid} </p>
        <p>Amount: ₹{props.params.amount} </p>
        <br />
        <p className="simpleParagraph">Redirecting to app in {props.seconds} seconds</p>
        <p className="simpleParagraph">Do not reload or close the page</p>
      </div>
    </Box>
  )
}