import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVER_ADDRESS } from "../../../../Firebase/Variables";
import { showTicker } from "../../TickerSlice";
import { status } from "../../userSlice";

export const GetConsumableReports = createAsyncThunk('GetConsumableReports', async ({ AuthToken, PlantID }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/inventory/report?plantID=${PlantID}&type=0`, requestOptions)
        const data = await response.json();

        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }
    }
    catch (err) {
        dispatch(showTicker({
            message: err.message,
            type: 'error'
        }))
        return rejectWithValue(err.message)
    }
})


// function for getting reports of the non consumables
export const GetNonConsumableReports = createAsyncThunk('GetNonConsumableReports', async ({ AuthToken, PlantID }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/inventory/report?plantID=${PlantID}&type=1`, requestOptions)
        const data = await response.json();

        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }
    }
    catch (err) {
        dispatch(showTicker({
            message: err.message,
            type: 'error'
        }))
        return rejectWithValue(err.message)
    }
})


const InventoryReportsSlice = createSlice({
    name: 'inventoryReportsSlice',
    initialState: {
        Loading: status.IDLE,
        ConsumablesReports: [],
        NonConsumableReports: []
    },
    extraReducers: {
        [GetConsumableReports.pending]: (state) => {
            state.Loading = status.LOADING
        },
        [GetConsumableReports.fulfilled]: (state, action) => {
            state.Loading = status.IDLE
            state.ConsumablesReports = action.payload
        },
        [GetConsumableReports.rejected]: (state) => {
            state.Loading = status.ERROR
        },

        [GetNonConsumableReports.pending]: (state) => {
            state.Loading = status.LOADING
        },
        [GetNonConsumableReports.fulfilled]: (state, action) => {
            state.Loading = status.IDLE
            state.NonConsumableReports = action.payload
        },
        [GetNonConsumableReports.rejected]: (state) => {
            state.Loading = status.ERROR
        }
    }
})

export default InventoryReportsSlice.reducer;