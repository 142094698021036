import React, { useEffect, useRef, useState } from 'react'
import './NoticeStyles/SendNotice.css'
import { Badge, Button, Checkbox, Chip, Divider, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { Add, ArrowBack, AttachFile, Send } from '@mui/icons-material'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux'
import { GetIndustries } from '../../Redux/Slices/Industry/GetIndustriesSlice'
import { status } from '../../Redux/Slices/userSlice'
import { CircularLoader, SomethingWentWrong } from '../UI Components/Basic'
import { SendNotices } from '../../Redux/Slices/Industry/SendNoticeSlice'
import { showTicker } from '../../Redux/Slices/TickerSlice'
import { LoadingButton } from '@mui/lab'
import { useLocation, useNavigate } from 'react-router-dom'

export default function SendNotice() {

    const [value, setValue] = useState('') //Holds the string value of entered mesage in the textbox.
    const [Title, setTitle] = useState('') // hold the title of the notice.
    const dispatch = useDispatch() // for dispatching actions
    const navigate = useNavigate() // for navigating thorugh pages
    const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)// logged in user auth token
    const PlantID = useSelector(state => state.USER_DETAILS.PlantID)// logged in user auth token
    const Industries = useSelector(state => state.INDUSTRIES.Industries)// Fetched industries
    const Loading = useSelector(state => state.INDUSTRIES.Loading) // Loadin gduring the industry loading
    const [SelectedRecipient, setSelectedRecipient] = useState([]) // this store the id of the recipient which gonna recieve this notice
    const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    const FileUploadLimit = 3; // maximum upload
    const [FilesToBeUploaded, setFilesToBeUploaded] = useState([]) // Holds the files/Attachments to be uploaded on server
    const [SendingLoading, setSendingLoading] = useState(false) // A button loader for showing notice is sending
    const { state } = useLocation() // if someone navigated on this page with state passed in it, then that state will recieve here.

    // if this page is called with a id passed in it then we are setting that id into selected recipients. This feature is made because if anyone from anyside of the application, clicks on a button for sending notice to specific industry, then that redirecting will contain an id on which we will send notice, so it must me in out selected recipients array.
    useEffect(() => {
        if (!!state?.noticeTo) {
            setSelectedRecipient([...SelectedRecipient, state.noticeTo])
        }
    }, [])

    useEffect(() => { if (!Industries?.industries && Loading !== status.ERROR && Loading !== status.LOADING) { dispatch(GetIndustries({ AuthToken: AuthToken, PlantID: PlantID })) } }, [])


    // this function handles the file selecion from user directory and push the valid files in to filestobeuploaded variable
    const handleFileChange = (e) => {
        if (e.target.files.length <= FileUploadLimit && e.target.files.length <= (FileUploadLimit - FilesToBeUploaded.length)) {
            setFilesToBeUploaded(prevFiles => {
                const newFiles = [...prevFiles];
                for (let i = 0; i < e.target.files.length; i++) {
                    const newImage = e.target.files[i];
                    if (allowedTypes.includes(newImage.type)) {
                        newFiles.push(newImage);
                    } else {
                        dispatch(showTicker({
                            message: `File ${newImage.name} is not supported.`,
                            type: 'warning'
                        }))
                    }
                }
                return newFiles;
            });
        }
        else {
            dispatch(showTicker({
                message: `Sorry! You can select maximum ${FileUploadLimit} files.`,
                type: 'warning'
            }))
        };
    };

    // function for deleting the attached images from the filesuploadarray 
    const handleDeleteFile = (index) => {
        setFilesToBeUploaded(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
    };

    // function for validating the data before sending notice, and after validation send it function is called.
    function ValidateNoticeData() {
        // console.log(FilesToBeUploaded.length, FilesToBeUploaded)
        // console.log(Title, value.length)
        // console.log(SelectedRecipient);
        if (SelectedRecipient.length <= 0) {
            dispatch(showTicker({
                message: 'Please select a recipient',
                type: 'error',
            }))
            return
        }
        if (Title !== undefined && Title !== '' && Title !== null) {
            if (value.length !== 0) {

                //sending notice if everything is correct. 
                dispatch(SendNotices({
                    AuthToken: AuthToken,
                    files: FilesToBeUploaded,
                    RecipientsIDs: SelectedRecipient,
                    title: Title,
                    content: value,
                    navigate: navigate,
                    setSendingLoading: setSendingLoading,
                    PlantID: PlantID
                }))
            }
            else {
                dispatch(showTicker({
                    message: 'Please enter a message',
                    type: 'error'
                }))
            }
        }
        else {
            dispatch(showTicker({
                message: 'Please enter a title',
                type: 'error'
            }))
        }
    }


    // will contain the search query for the industry
    const [searchQuery, setSearchQuery] = useState('')

    // Function to filter industries based on the search query
    const filterIndustries = () => {
        const query = searchQuery.toLowerCase().trim();
        if (!query) {
            return Industries.industries; // If search query is empty, return all industries
        }
        return Industries.industries.filter(industry => (
            industry?.data?.companyName?.toLowerCase()?.includes(query) ||
            industry?.data?.address?.toLowerCase()?.includes(query)
        ));
    };

    // Function to handle search input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };



    // usestate for storing the state for if user wants to send the notice to all industries. 
    const [isSendToAll, setisSendToAll] = useState(false)

    //useeffect to handling the reciepients array is someone clicks on send to all checkbox. 
    useEffect(() => {
        if (isSendToAll) {
            setSelectedRecipient(["All"]) // removing all the recipients and setting "All" string to send notices to all recipients
        }
        else {
            if (SelectedRecipient.includes("All")) {
                //removing the all string if someone unchecks the all selection. 
                let indexOfAll = SelectedRecipient.indexOf("All")
                if (indexOfAll > -1) {
                    let newRecipient = SelectedRecipient.splice(indexOfAll, 0)
                    setSelectedRecipient(newRecipient)
                }
            }
        }

    }, [isSendToAll])



    // Render table rows based on filtered industries, this row will be used to selet the industry recipients. 
    const renderIndustryRows = () => {
        if (Loading === status.IDLE) {
            if (Industries.industries !== undefined && Industries.industries !== null) {
                const filteredIndustries = filterIndustries();
                return filteredIndustries.map(ind => (<IndustryTile
                    key={ind.id}
                    id={ind.id}
                    name={ind.data.companyName}
                    address={ind.data.address}
                    SelectedRecipient={SelectedRecipient}
                    setSelectedRecipient={setSelectedRecipient}
                    isSendToAll={isSendToAll} // for checking if user sending notice to all industries, then just disable the industry tiles. 
                />
                ));
            }
            else {
                return <p className="simpleParagraph" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>No Recipients found</p>
            }
        }
        if (Loading === status.LOADING) {
            return <CircularLoader />
        }
        if (Loading === status.ERROR) {
            return <SomethingWentWrong />
        }
    };




    return (
        <div>
            {/* <button onClick={() => {  }}>Getindustries</button> */}
            <div className="PAGE_HEADER">
                {/* Name of the page, will show on the left of the header */}
                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /> </IconButton>
                    <h3>Send Notice</h3>
                </Stack>
                {/* create any buttons or other things here. it will show on the right side of the page header */}
            </div>

            {/* this div will contain all the content of the page */}
            <div className="Industries_Body_Container" style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'space-between' }}>
                <div className="NoticeTextAreaWrapper">
                    {/* toolsbar */}
                    <div className="TOOLS_WRAPPER" style={{ textAlign: 'right' }}>
                        <LoadingButton onClick={ValidateNoticeData} loading={SendingLoading} variant='contained' color='PrimaryBlue' endIcon={<Send />} disableElevation>Send Notice</LoadingButton>
                    </div>
                    <div className="TextEditor">
                        {/* this is our toolbar we can add our functionalities here  */}
                        <div className="OurToolBar">
                            <input type="file" multiple onChange={handleFileChange} id='FileInput' style={{ display: 'none' }} />
                            <Tooltip title="Attached Files" arrow placement='top'>
                                <IconButton color='PrimaryBlue' className='InputLabel' disabled={SendingLoading}>
                                    <label htmlFor="FileInput">
                                        <Badge color='PrimaryRed' badgeContent={+FilesToBeUploaded.length} size="small">
                                            <AttachFile sx={{ width: '20px' }} />
                                        </Badge>
                                    </label>
                                    <div className="FileHoverContainer">
                                        <Stack direction="column" spacing={1}>
                                            {
                                                FilesToBeUploaded.length !== 0 ?
                                                    FilesToBeUploaded.map((file, index) => {
                                                        return <Chip label={file.name} key={file.index} onDelete={() => { handleDeleteFile(index) }} />
                                                    }) : <p className='simpleParagraph'>No files Attached</p>
                                            }
                                        </Stack>
                                    </div>
                                </IconButton>
                            </Tooltip>
                        </div>
                        {/* text editor */}
                        <div style={{ padding: '0px 80px 0px 30px' }}><TextField value={Title} disabled={SendingLoading} onChange={(e) => { setTitle(e.target.value) }} size='small' placeholder='Title of the Notice' fullWidth /></div>
                        <ReactQuill theme="snow" value={value} onChange={setValue} placeholder={'Type here...'} />
                    </div>
                </div>


                {/* this container contains the recipients whom we are sending notices */}
                <div className="RecipientWrapper">
                    <div className="Recipients_searchbar">
                        <TextField id="outlined-basic" placeholder='Search any industry' onChange={handleSearchChange} size='small' fullWidth />
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant='body2'>Send to All</Typography>
                            <Checkbox checked={isSendToAll} size='small' onClick={() => { setisSendToAll(!isSendToAll) }} />
                        </Stack>
                        {/* <Stack direction="row" justifyContent="space-between" mt={1}>
                            <Typography variant='caption'>{SelectedRecipient?.length} Industries Selected</Typography>
                            <Typography variant='caption' color="PrimaryBlue">Select All</Typography>
                        </Stack> */}
                    </div>



                    {renderIndustryRows()}
                </div>
            </div>
        </div>
    )
}

// tile for showing industries in the selection panel
function IndustryTile(props) {

    // function for checking whether the selected item is already in the array or not, if not add it otherwise remove it.
    const AddRecipient = () => {

        if (props.isSendToAll) { return } // retrun nothing if send to all is active. 

        const newRecipientSet = new Set(props.SelectedRecipient);
        if (!newRecipientSet.has(props.id)) {
            newRecipientSet.add(props.id);
        } else {
            newRecipientSet.delete(props.id);
        }
        props.setSelectedRecipient(Array.from(newRecipientSet));
    }


    return <div
        className='Industry_Selection_tile'
        style={{ backgroundColor: props.SelectedRecipient === props.id ? 'var(--Tertiary_Blue)' : 'white' }}
        onClick={AddRecipient}>
        <div className='Industry_tile_left_container'>
            {`${props.name?.slice(0, 24)}..`}
            <p className="simpleParagraph">{`${props.address.slice(0, 26)}..`}</p>
        </div>
        <Checkbox
            fullWidth
            size='small'
            checked={props.SelectedRecipient.includes(props.id) || props.isSendToAll}
            disabled={props.isSendToAll}
        />
        <Divider fullWidth />
    </div>
}