import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showTicker } from "../TickerSlice";
import { status } from "../userSlice";
import { SERVER_ADDRESS } from "../../../Firebase/Variables";

export const GetFilterByName = createAsyncThunk('GetFilterByName', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const { filterName, UserToken, PlantID } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${SERVER_ADDRESS}/plant/instrument/filters?filterName=${filterName}&plantID=${PlantID}`, requestOptions)

        if (response.ok) {
            const data = await response.json()
            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }
    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

export const GetSensorData = createAsyncThunk('GetSensorData', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const { location, UserToken, PlantID } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    let query
    if (location) {
        Object.keys(location).forEach(key => {
            let temp

            if (location[key].length > 0) {
                temp = location[key].map(element => `${key}=${element}`).join("&");
            }

            if (query) {
                query = `${query}&${temp}`
            } else {
                query = temp
            }
        })
    }
    try {
        const response = await fetch(`${SERVER_ADDRESS}/plant/alert/?${query}&plantID=${PlantID}`, requestOptions)

        if (response.ok) {
            const data = await response.json()
            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }
    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

export const GetAlertsCount = createAsyncThunk('GetAlertsCount', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const { UserToken, PlantID } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${SERVER_ADDRESS}/plant/alert/count?plantID=${PlantID}`, requestOptions)

        if (response.ok) {
            const data = await response.json()
            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }
    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

const SensorSlice = createSlice({
    name: "sensors",
    initialState: {
        FilterData: {
            data: []
        },
        SensorData: {
            data: []
        },
        AlertsCount: 0,
        Loading: status.IDLE
    },
    reducers: {
        setSensorData: (state, action) => {
            state.SensorData = action.payload
        }
    },
    extraReducers: {
        // extra reducers for GetFiltersByName
        [GetFilterByName.pending]: (state) => {
            state.Loading = status.LOADING
        },
        [GetFilterByName.fulfilled]: (state, action) => {
            state.FilterData = action.payload
            state.Loading = status.IDLE
        },
        [GetFilterByName.rejected]: (state) => {
            state.Loading = status.ERROR
        },

        // extra reducers for GetSensorData
        [GetSensorData.pending]: (state, action) => {
            state.Loading = status.LOADING
        },
        [GetSensorData.fulfilled]: (state, action) => {
            state.SensorData = action.payload
            state.Loading = status.IDLE
        },
        [GetSensorData.rejected]: (state, action) => {
            state.Loading = status.ERROR
        },

        // extra reducers for GetAlertsCount
        [GetAlertsCount.pending]: (state, action) => {
            state.Loading = status.LOADING
        },
        [GetAlertsCount.fulfilled]: (state, action) => {
            state.AlertsCount = action.payload.count
            state.Loading = status.IDLE
        },
        [GetAlertsCount.rejected]: (state, action) => {
            state.Loading = status.ERROR
        }
    }
})

export const { setSensorData } = SensorSlice.actions
export default SensorSlice.reducer