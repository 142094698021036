import { Box, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetPlantDetails } from '../../../../Redux/Slices/PlantDetailsSlice'
import { status } from '../../../../Redux/Slices/userSlice'
import { Sync } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { DrawerRoutes } from '../../../Routes/routes'

export default function BillHeader() {
    const dispatch = useDispatch() // for dispatch actiong
    const navigate = useNavigate()

    const UserDetails = useSelector(state => state.USER_DETAILS)
    const PlantDetails = useSelector(state => state.PLANT_DETAILS.Details?.plant)
    const PlantDetailsLoading = useSelector(state => state.PLANT_DETAILS.Loading)
    const Industries = useSelector(state => state.INDUSTRIES.Industries?.industries)
    const industryID = useSelector(state=>state.BILL_GENERATION.industryID)

    // getting plant details. only when the data is not avilable in the redux store or loading is idle. 
    useEffect(() => {
        if (UserDetails.Loading === status.IDLE && !!PlantDetails === false) {
            dispatch(GetPlantDetails({ AuthToken: UserDetails.AuthToken, plantID: UserDetails.UserDetails?.plantID?.toUpperCase() }))
        }
    }, [])


    // function for providing the details to the jsx, as we can't check loading at every detail position. 
    const DataProvider = (key) => {
        if (PlantDetailsLoading === status.IDLE) {
            if (key === "plantName") { return PlantDetails?.plantName }
            else if (key === "plantAddress") { return PlantDetails?.plantAddress }
            else if (key === "cin") { return PlantDetails?.cin }
            else if (key === "pan") { return PlantDetails?.pan }
            else if (key === "telNo") { return PlantDetails?.telNo }
            else if (key === "email") { return PlantDetails?.email }
            else { return "Unable to get detail..." }
        }
        else if (PlantDetailsLoading === status.LOADING) { return "Loading..." }
        else if (PlantDetailsLoading === status.ERROR) { return "Error..." }
        else { return "Unable to get details..." }
    }


    // function for getting the industry details based on the passed id.
    const GetIndustryViaId = (id) => {
        if (!!Industries === true) {
            return Industries.find((obj) => obj.id === id);
        } else {
            // console.log("is this running? ");
            navigate(DrawerRoutes.Reports.Financial_Reports) // if user reloads the page at the current bill generation page, then the industry data will not be loaded. so we have to throw him to the Previous page in order to reselect the industry and start again. 
        }
    }

    //function for getting that industry details for which bill is going to generate.
    const GetIndustryDetails = (key) => {
        let ind = GetIndustryViaId(industryID) // Getting industry id through props from GenerateBill.js
        if (key === "name") { return ind?.data.companyName }
        else if (key === "address") { return ind?.data.address }
        else { return "Unable to get industry information" }
    }

    // getting current data. bill date wil be same as the day it created
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${day}/${month}/${year}`;

    return (
        <div>
            <Box component="section" sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', backgroundColor: 'var(--Tertiary_Blue)', position: "relative" }}>
                <Stack direction="column" spacing={0}>
                    <Typography variant='body2'>Proforma Invioce</Typography>
                    <Typography variant='h5'>{DataProvider("plantName")}</Typography>
                    <Typography variant='subtitle2'>{DataProvider("plantAddress")}</Typography>
                    <Typography variant='overline' sx={{ height: '20px', color: 'grey' }}>CIN : {DataProvider("cin")} | PAN : {DataProvider("pan")}</Typography>
                    <Typography variant='overline' sx={{ height: '20px', color: 'grey' }}>Tel. : {DataProvider("telNo")} | email : {DataProvider("email")}</Typography>
                </Stack>
                <br />
                <Divider />
                <br />
                <Stack direction="row" spacing={2}>
                    <Box sx={{ p: 2, border: '1px dashed var(--Primary_Blue)', borderRadius: '12px', width: '65%' }}>
                        <Typography variant='body2'>Party Details</Typography>
                        <Typography variant='h6'>{GetIndustryDetails("name")}</Typography>
                        <Typography variant='subtitle2'>{GetIndustryDetails("address")}</Typography>
                    </Box>

                    <Box sx={{ p: 2, border: '1px dashed var(--Primary_Blue)', borderRadius: '12px' }}>
                        <Typography variant='body2'>Bill Date</Typography>
                        <Typography variant='h6'>{currentDate}</Typography>
                    </Box>
                </Stack>

                <Tooltip title="Refresh Plant Data" sx={{ position: "absolute", top: '14px', right: '14px' }}>
                    <IconButton onClick={() => { dispatch(GetPlantDetails({ AuthToken: UserDetails.AuthToken, plantID: UserDetails.UserDetails.plantID.toUpperCase() })) }}>
                        <Sync fontSize='small' />
                    </IconButton>
                </Tooltip>
            </Box>
        </div>
    )
}
