import React from 'react'
import { useState } from 'react';
import { VysionLogo_Login } from '../assets/assets';
import './AuthStyles/IndustryRegistrationForm.css'
import { ErrorTicker } from './AuthUIComponents';
import { IconButton, Radio } from '@mui/material';
import { ArrowBack, Send } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { EmailValidation, EmptyFeildValidation, PhaseValidation, PhoneNumberValidation, PinCodeValidation } from './AuthFunctions';
import { SingleIndustryRegistration } from '../Redux/Slices/Industry/IndustryRegistrationSlice';

export default function IndustryRegistrationForm() {
    const [ChamberInstall, setChamberInstall] = useState(true) // State for saving chamber information, (boolean
    const dispatch = useDispatch()
    const [error, setError] = useState({
        message: '',
        type: ''
    }); // for shwing error messages.

    const [Loading, setLoading] = useState(false)
    const [formed, setFormed] = useState({
        companyName: '',
        address: '',
        pinCode: '',
        email: '',
        phone: '',
        plantType: '',
        icChamberInstall: ChamberInstall,
        consentValidity: '',
        domesticEffluent: '',
        hnType: '',
        phase: null,
        plantID: '',
        pNumber: '',
        totalEffluentTradeAndUtility: '',
        unitId: '',
        remark: ''
    });

    const FormValidation = () => {
        if ( // Feild validations
            EmptyFeildValidation(formed.companyName, 'Company Name', dispatch) &&
            EmptyFeildValidation(formed.address, 'Address', dispatch) &&
            EmailValidation(formed.email, dispatch) &&
            PhoneNumberValidation(formed.phone, dispatch)
        ) {
            const isFromPlant = false // creating a variable for letting that function know that this request is coming from plant side or industry self registration time. 
            SingleIndustryRegistration(formed, dispatch, setLoading, isFromPlant) // Fetching registration api 
        }
    }
    return (
        <div>
            <div className="IndustryRegFormContainer">
                <div className="HeaderDetails">
                    Enter all details of your Company
                </div>
                <div className="Frame">

                    <br />
                    <div className="Row"><p className="Message" style={{ textAlign: "left" }}>Basic Details</p></div>

                    <InputRow Label={"Company Name"} type={"text"} placeHolder={"Company Name"} action={(e) => setFormed({ ...formed, companyName: e.target.value })} />
                    <InputRow Label={"Address"} type={"text"} placeHolder={"Address"} action={(e) => setFormed({ ...formed, address: e.target.value })} />
                    <InputRow Label={"Pin Code"} type={"text"} placeHolder={"Pin Code"} action={(e) => setFormed({ ...formed, pinCode: e.target.value })} />
                    <InputRow Label={"E-mail"} type={"text"} placeHolder={"E-mail address"} action={(e) => setFormed({ ...formed, email: e.target.value })} />
                    <InputRow Label={"Phone Number"} type={"number"} placeHolder={"Phone Number"} action={(e) => setFormed({ ...formed, phone: e.target.value })} />
                    <br />
                </div>
                <br />
                <div className="Frame">
                    <div className="Row"><p className="Message" style={{ textAlign: "left" }}>Plant Details</p></div>

                    <InputRow Label={"Plant type"} type={"text"} placeHolder={"CETP/ETP/STP"} action={(e) => setFormed({ ...formed, plantType: e.target.value })} />

                    <div className="Row">
                        <div className="Label">{`IC Chamber Installed?`}</div>
                        <div style={{ display: "flex", alignItems: "center", width: "320px", justifyContent: "space-between" }}>
                            <Radio checked={ChamberInstall === true} onClick={() => { setChamberInstall(true) }} />
                            <div className="Label">{`Installed`}</div>
                            <Radio checked={ChamberInstall === false} onClick={() => { setChamberInstall(false) }} />
                            <div className="Label">{`Not Installed`}</div>
                        </div>
                    </div>

                    <InputRow Label={"Consent Validity"} type={"text"} placeHolder={"Consent Validity"} action={(e) => setFormed({ ...formed, consentValidity: e.target.value })} />
                    <InputRow Label={"Domestic Effluent"} type={"text"} placeHolder={"Domestic Effluent"} action={(e) => setFormed({ ...formed, domesticEffluent: e.target.value })} />
                    <InputRow Label={"H/N Type"} type={"text"} placeHolder={"H/N Type"} action={(e) => setFormed({ ...formed, hnType: e.target.value })} />
                    <InputRow Label={"Phase"} type={"number"} placeHolder={"Phase"} action={(e) => setFormed({ ...formed, phase: +e.target.value })} />

                    <div className="Row">
                        <div className="Label">{"Select Plant"}</div>
                        <Dropdown formed={formed} setFormed={setFormed} />
                    </div>

                    <InputRow Label={"P. No."} type={"text"} placeHolder={"P. No."} action={(e) => setFormed({ ...formed, pNumber: e.target.value })} />
                    <InputRow Label={"Total Effluent Trade and Utility"} type={"text"} placeHolder={"Total Effluent Trade and Utility"} action={(e) => setFormed({ ...formed, totalEffluentTradeAndUtility: e.target.value })} />
                    <InputRow Label={"Unit ID"} type={"text"} placeHolder={"Unit ID"} action={(e) => setFormed({ ...formed, unitId: e.target.value })} />
                    <InputRow Label={"Remark"} type={"text"} placeHolder={"Remark (Optional)"} action={(e) => setFormed({ ...formed, remark: e.target.value })} />

                    <div className="RowB">
                        <LoadingButton onClick={() => FormValidation()} loading={Loading ? true : false} color="PrimaryBlue" variant="contained" size='medium' startIcon={<Send />} disableElevation>Submit Request</LoadingButton>
                    </div>
                    <br />
                    <br />
                </div>
            </div>

            {error && <ErrorTicker message={error.message} reset={setError} type={error.type} />}
        </div>
    )
}

export function InputRow(props) {
    return <div className="Row">
        <div className="Label">{props.Label}</div>
        <input
            className="Input"
            type={props.type}
            placeholder={props.placeHolder}
            onChange={props.action}
            disabled={props.disabled}
            value={props.value}
            onWheel="return false"
        />
    </div>
}

export function Dropdown(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Select type of your plant');

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option, name) => {
        props.setFormed({ ...props.formed, plantID: option })
        setSelectedOption(name)
        toggleDropdown();
    };
    return (
        <div className="dropdown">
            <div className="dropdown-button" onClick={toggleDropdown}>
                {selectedOption}
            </div>
            {isOpen && (
                <div className="dropdown-content">

                    {/* this available at testing and development side. Commment this at production build */}
                    {/* <a onClick={() => handleOptionClick('P0', 'Test Plant')}>Test Plant</a> */}

                    {/* this will be available at production  */}
                    <a onClick={() => handleOptionClick('P1', 'Bhiwadi STP Plant')}>Bhiwadi STP Plant</a>

                </div>
            )}
        </div>
    );
}