import React, { useEffect, useState } from 'react'
import { status } from '../../Redux/Slices/userSlice';
import { DataGrid } from '@mui/x-data-grid';
import { CircularLoader, SideDrawer, SomethingWentWrong } from '../UI Components/Basic';
import { Chip, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { convertGMTtoISTAndFormat } from '../ReportsComponents/BillsAndPayments/DedicatedIndustryBill';
import NoticeSideDrawerLayout from './NoticeSideDrawerLayout';

export default function RenderNotices() {

    const Notices = useSelector(state => state.NOTICES.Notices) // getting notices from the redux store.
    const UserRole = useSelector(state => state.USER_DETAILS.UserRole) // for checking the logged in user is an admin or industry. Because if the user is an admin, then we will not show new notice tag to admin , because he sent that notices. no notice is new for admin. Notice is new for only industry.
    const [readNoticeIds, setReadNoticeIds] = useState([]);   // State to store IDs of read notices
    const UserID = useSelector(state => state.USER_DETAILS.UID)// logged in user user id for storing it in local storage for isread the notice
    const Loading = useSelector(state => state.NOTICES.Loading) // loading notices
    const storageKeyPrefix = `user_${UserID}_`;   // Key prefix for local storage


    // Effect to check local storage and update readNoticeIds
    useEffect(() => {
        // Get read notice IDs for the current user from local storage
        const storedIds = JSON.parse(localStorage.getItem(`${storageKeyPrefix}readNoticeIds`)) || [];
        setReadNoticeIds(storedIds);
    }, []);


    // function  for handling the clicking on a notice. it will set the clicked notice data into the side drawer state to make it render in the side drawer.
    const handleRowClick = (params) => {
        console.log(params);
        handleOpenNotice(params.row.creationDate.noticeID)
        setNoticeDetails({
            isSideDrawerOpen: true,
            activeNoticeData: params.row.data,
            activeNoticeID: params.row.creationDate.noticeID
        })
    };

    // Function to handle opening a notice
    const handleOpenNotice = (noticeId) => {
        // Update readNoticeIds in state and local storage
        const updatedIds = [...readNoticeIds, noticeId];
        setReadNoticeIds(updatedIds);
        localStorage.setItem(`${storageKeyPrefix}readNoticeIds`, JSON.stringify(updatedIds));
    };


    // coloums of the table of rendering notices
    const columns = [
        { field: "id", headerName: "S No.", type: "number", width: 70 },
        {
            field: "data", headerName: "Title", flex: 1, height: 100,
            renderCell: (params) => (
                <div >
                    <Typography variant="body1">{params.value.title}</Typography>
                    {/* <Typography variant="caption" color="grey" dangerouslySetInnerHTML={{ __html: params.value.description }} /> */}
                </div>
            )
        },
        {
            field: "creationDate", headerName: "Date", width: 220,
            // checking value and based on that value, rendering the chip of paid and not paid
            renderCell: (params) => (
                <Stack direction="row" spacing={1}>
                    {!(readNoticeIds.includes(params.value.noticeID)) && UserRole === 'industry'
                        ? <Chip label="New" size='small' color="success" sx={{ fontSize: '12px' }} /> : ''}
                    <Chip label={params.value.date} size='small' sx={{ fontSize: '12px' }} />
                </Stack>
            )
        }
    ];


    // function for rendering the grid of the notices
    const RenderNotices = () => {
        if (Loading === status.IDLE) {
            if (!!Notices?.notices) {
                return <div>
                    {
                        Notices?.notices !== undefined ?
                            <DataGrid
                                onRowClick={handleRowClick}
                                disableRowSelectionOnClick
                                rowHeight={65}
                                sx={{ height: 'calc(100vh - 130px)', cursor: "pointer", ".highlight": { bgcolor: "#00000009", "&:hover": { bgcolor: "#0000001b", }, }, }}
                                getRowClassName={(params2) => {
                                    return !(readNoticeIds.includes(params2.row.creationDate.noticeID)) && UserRole === "industry" ? 'highlight' : ''
                                }} // attaching a classname if notice is not readed yet
                                rows={Notices?.notices?.map((notice, i) => {
                                    return (
                                        {
                                            id: i + 1,
                                            data: notice?.data,
                                            creationDate: {
                                                date: convertGMTtoISTAndFormat(notice?.data?.creationDate),
                                                isNew: notice?.data?.isNew,
                                                noticeID: notice?.id
                                            },
                                        }
                                    )
                                })}
                                columns={columns}
                                pageSize={2}
                            />
                            : ''
                    }
                </div>
            }
            else {
                return <SomethingWentWrong />
            }
        }
        else if (Loading === status.LOADING) {
            return <CircularLoader />
        }
        else if (Loading === status.ERROR) {
            return <SomethingWentWrong />
        }
    }


    // function for showing the notice infromation in the side drawer
    const [NoticeDetails, setNoticeDetails] = useState({
        isSideDrawerOpen: false,
        activeNoticeData: [],
        activeNoticeID: ""
    })

    // function for rendering the all details of a notice in a side drawer
    function RenderNoticeDetails() {
        return <SideDrawer Open={NoticeDetails.isSideDrawerOpen} handle={() => { setNoticeDetails({ isSideDrawerOpen: false, activeNoticeData: [], activeNoticeID: '' }) }}>
            <NoticeSideDrawerLayout
                data={NoticeDetails.activeNoticeData}
                id={NoticeDetails.activeNoticeID}

                setNoticeDetails={setNoticeDetails}
            />
        </SideDrawer>
    }

    return (
        <div>
            {RenderNotices()}
            {/* side drawer for showing the complete details of the notice */}
            {RenderNoticeDetails()}
        </div>
    )
}
