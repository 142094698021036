import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  OutlinedInput,
  ListItemText,
  Stack,
  IconButton, // Import Stack from @mui/material
} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import { GetFilterByName, GetSensorData, setSensorData } from "../../Redux/Slices/ProcessManagementSlices/SensorSlice";
import { status } from "../../Redux/Slices/userSlice";
import { CircularLoader, SomethingWentWrong } from "../UI Components/Basic";
import Autocomplete from '@mui/material/Autocomplete';
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

// Reusable Select component with dynamic width
const SelectComponent = ({ label, id, value, onChange, options, width }) => {
  // Calculate dynamic width based on label length

  return (
    <FormControl>
      <InputLabel id={`${id}_label`} style={{ top: "-7px" }}>
        {label}
      </InputLabel>
      <Select
        labelId={`${id}_label`}
        id={id}
        size="small"
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(", ")}
        sx={{ width: width }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox size="small" checked={value.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default function Industries() {
  const AuthToken = useSelector((state) => state.USER_DETAILS.AuthToken); // logged in user auth token
  const PlantID = useSelector((state) => state.USER_DETAILS.PlantID); // logged in user auth token
  const dispatch = useDispatch();
  const [location, setLocation] = useState([])
  const [locationName, setLocationName] = useState([])
  const LocationFilter = useSelector((state) => state.SENSORS.FilterData);
  const SensorData = useSelector((state) => state.SENSORS.SensorData);
  const SENSOR_LOADING = useSelector((state) => state.SENSORS.Loading);
  const navigate = useNavigate()

  const handleSelectChange = (setter) => (event) => {
    const {
      target: { value },
    } = event;
    setter(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    dispatch(GetFilterByName({ filterName: "Location", UserToken: AuthToken, PlantID: PlantID }));
    dispatch(GetSensorData({ UserToken: AuthToken, PlantID: PlantID }));
  }, [dispatch, AuthToken]);

  useEffect(() => {
    if (LocationFilter.data.length > 0) {
      setLocationName(LocationFilter.data[0].filterItem);
    }
  }, [LocationFilter]);

  const isApplyButtonDisabled = () => {
    return (
      location.length === 0
    );
  };

  return (
    <div>
      {/* this is a universal page header and will be included in every page. */}
      <div className="PAGE_HEADER">
        {/* Name of the page, will show in left of the header */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /> </IconButton>
          <h3>Sensor Alerts</h3>
        </Stack>
        {/* create any buttons or other things here. it will show on right side of the page header */}
      </div>

      {/* this div will contain all the content of the page */}
      <div className="Industries_Body_Container">
        {/* this tool wrapper may be included in every page. it will contain all tools related to content of the page like searchbar, filters etc. */}
        <div
          className="TOOLS_WRAPPER"
          style={{
            display: "flex",
            flexDirection: "Row",
            justifyContent: "space-between",
          }}
        >
          {/* searchbar for searching the instruments, entered inpupt will be stored in "text" state */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Autocomplete
              freeSolo
              disableClearable
              options={SensorData.data.map((option) => option.data.TagNo)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search TagNo"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                  sx={{ width: "200px" }}
                  size="small"
                />
              )}
              onChange={(event, value) => {
                if (value) {
                  // Filter the data based on the selected TagNo
                  const filteredData = SensorData.data.filter((sensor) => sensor.data.TagNo === value);

                  // Dispatch the filtered data
                  dispatch(setSensorData({
                    data: filteredData
                  }))
                }
              }}
              onInputChange={(event, value) => {
                if (!value) {
                  dispatch(GetSensorData({
                    location: location,
                    UserToken: AuthToken,
                    PlantID: PlantID
                  }))
                }
              }}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            className="process_toolbar"
          >
            <SelectComponent
              label="Location"
              id="location"
              value={location}
              onChange={handleSelectChange(setLocation)}
              options={locationName}
              width="115px"
            />
            <Button
              disableElevation
              variant="contained"
              color="PrimaryBlue"
              disabled={isApplyButtonDisabled()}
              onClick={() => {
                dispatch(
                  GetSensorData({
                    location: { location },
                    UserToken: AuthToken,
                    PlantID: PlantID
                  })
                );
              }}
            >
              Apply
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="PrimaryBlue"
              disabled={isApplyButtonDisabled()}
              onClick={() => {
                setLocation([])
                dispatch(
                  GetSensorData({
                    location: {}, UserToken: AuthToken, PlantID: PlantID
                  })
                );
              }}
            >
              Reset
            </Button>
          </Stack>
        </div>

        <div className="INDUSTRY_TABLE_CONTAINER">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ paddingLeft: "30px" }}>Date | Time</TableCell>
                  <TableCell>Sensor</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Tag No</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Alert</TableCell>
                </TableRow>
              </TableHead>
              {SENSOR_LOADING === status.IDLE ?
                <TableBody>
                  {SensorData.data ? SensorData.data.map((sensor) => {
                    return (
                      <TableRow
                        key={sensor.id}
                      >
                        <TableCell sx={{ paddingLeft: "30px" }}>
                          {sensor.data.timestamp}
                        </TableCell>
                        <TableCell>
                          {sensor.data.category}
                        </TableCell>
                        <TableCell>
                          {sensor.data.location}
                        </TableCell>
                        <TableCell>
                          {sensor.data.TagNo}
                        </TableCell>
                        <TableCell>
                          {sensor.data.type ? sensor.data.type : "-"}
                        </TableCell>
                        <TableCell>
                          {sensor.data.description}
                        </TableCell>
                      </TableRow>
                    )
                  }) : ""
                  }
                </TableBody> : SENSOR_LOADING === status.LOADING ? < CircularLoader />
                  : SENSOR_LOADING === status.ERROR ? <SomethingWentWrong /> : ''
              }

            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
