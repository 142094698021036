export const DrawerRoutes = {
    Dashboard: "/",
    Add_Industry: "/add-industry",
    Industry: {

        //Impoertant Notice : if you are changing the routes names in industry section, keep that in the mind we are manually navigated to industry detailed wrapper in the industries section, so if you change routes here you have to chege that hoarcoded routes there also.
        Industries: "/industries",

        Industry_Details: '/industries/industry',
        Industry_requests: "/industries/industry/industry_requests",
        About_Industry: "/industries/industry/about-industry/:industryID",
        Industry_Effluent_Details: "/industries/industry/industry-effluent/:industryID",
        Industry_Bills: "/industries/industry/industry-bills/:industryID",
        Industry_Notices: "/industries/industry/industry-notice/:industryID",
        Industry_Settings: "/industries/industry/industry-settings/:industryID",
    },
    Notification: "/notifications",
    SendNotice: '/send-notice',

    Process: "/process",
    SensorAlerts: "/sensor-alerts",
    Chambers: "/chambers",
    EditChamber: "chambers/:chamberid",

    CPCB: "/board",
    Inventory: {
        consumables: "/inventory/consumables",
        nonconsumables: "/inventory/non-consumables"
    },
    Reports: {
        Industry_Effluent: "reports/effluent-reports",
        Inventory_Reports: "/reports/inventory-reports",

        // Financial management routes
        //Impoertant Notice : if you are changing the routes names in financial section (action buttons for specific industry actions), keep that in the mind we are manually navigated to industry detailed wrapper in the section, so if you change routes here you have to chege that hardcoded routes there also.
        Financial_Reports: "/reports/Bills",
        Dedicated_industry_bills: '/reports/industry-bills/:industryID', // for showing the list of bills of a specific industry 
        GenerateBill: '/reports/generate-bill/:industryID', // For generating bills of a specific industry
        MasterBills: '/reports/master-copies-bills', // Contains all the master bills at one place 
        GenerateMasterBill: '/reports/create-master-bill', // For creating a new master bill 
        PaymentReceipts: '/reports/payment-requests', // For creating a new master bill 

        Power_Reports: "/reports/power-reports",
        Sludge_Management: "/reports/sludge-management",
    },
    CCTV: "/cctv",
    Team: "/team",
    PlantProfile: "/plant-profile",

}

export const AuthenticationRoutes = {
    UserSelection: "/userselect",
    PlantLogin: "/plantlogin",
    IndustryLogin: "/industrylogin",
    OTPVerification: "/userverify",
    IndustryRegistration: "/industryregistration",
    ForgotPassword: "/passwordrecover",
    PlantSelectionSuperAdmin: '/plant-selection'
}

export const IndustrySideRoutes = {
    IndustryBills: '/bills-industry', //this route will contain the list of bills of that logged in industry.
    IndustryProfile: 'industry-profile',
    EditDetails: '/edit-details',
}

//routes for all the payments
export const PaymentRoutes = {
    PaymentSuccess: "payment-success",
    PaymentFailed: "payment-failed",
    PaymentCancelled: "payment-cancelled",
    InternalServerError: "internal-server-error",
}