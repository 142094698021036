import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetConsumableReports, GetNonConsumableReports } from '../../../Redux/Slices/ReportsSlices/InventoryReportsSlices/GetInventoryReportsSlice'
import { status } from '../../../Redux/Slices/userSlice'
import { CircularLoader, SomethingWentWrong } from '../../UI Components/Basic'
import { DataGrid } from '@mui/x-data-grid'
import { convertGMTtoISTAndFormat } from '../BillsAndPayments/DedicatedIndustryBill'
import { Box, Chip, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'

export default function NonConsumableReports() {

    const dispatch = useDispatch()
    const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken) // token important for api call
    const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
    const ConsReports = useSelector(state => state.INVENTORY_REPORTS.NonConsumableReports)
    const Loading = useSelector(state => state.INVENTORY_REPORTS.Loading)
    let searchTimeout; // For debouncing purpose

    // fetching reports data if not available. 
    useEffect(() => {
        if (!ConsReports?.data && Loading !== status.ERROR && Loading !== status.LOADING) { dispatch(GetNonConsumableReports({ AuthToken: AuthToken, PlantID: PlantID })) }
    }, []);

    // this small useffect set data to search results for mapping into the table. this is important because whenever the page loads, if the data in redux is change then the change should reflex in this table also, and direct changes is not reflecting because we are not directly mapping the redux state, we saving the redux state into filtered results or search resutlts and then mapping results in to the table.
    useEffect(() => { setSearchResults(ConsReports?.data) }, [ConsReports])


    const [searchResults, setSearchResults] = useState(ConsReports?.data); // For saving filtered results if anyone applies a filter or search key
    // this small useffect set data to search results for mapping into the table. this is important because whenever the page loads, if the data in redux is change then the change should reflex in this table also, and direct changes is not reflecting because we are not directly mapping the redux state, we saving the redux state into filtered results or search resutlts and then mapping results in to the table.
    useEffect(() => { setSearchResults(ConsReports?.data) }, [ConsReports])
    // function for searching the items with searchbar.
    const handleSearch = (searchQuery) => {
        const lowercaseQuery = searchQuery.toLowerCase();// Convert the search query to lowercase for case-insensitive search
        const filteredResults = ConsReports?.data?.filter( // Filter inventory items based on name or code containing the search query
            item =>
                item?.itemName?.toLowerCase().includes(lowercaseQuery) ||
                item?.itemCode?.toLowerCase().includes(lowercaseQuery)
        );
        setSearchResults(filteredResults); // Update the state with the search results
    };

    // implementing debouncing in the search bar
    const debouncedSearch = (query) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            handleSearch(query);
        }, 1000);
    };


    // coloums of the table of the reports
    const columns = [
        { field: "id", headerName: "S No.", type: "number", width: 70, sortable: false },
        { field: "itemName", headerName: "Name", width: 200, sortable: true },
        { field: "itemCode", headerName: "Item Code", width: 140, sortable: false },
        {
            field: "Action", headerName: "Action", width: 170, sortable: true,
            renderCell: (params) => (
                params.row.Action === "Added" ? <Chip label="Added" size='small' variant='outlined' color='success' sx={{ fontSize: '12px' }} />
                    : params.row.Action === "Removed" ? <Chip label="Removed" size='small' variant='outlined' color='PrimaryRed' sx={{ fontSize: '12px' }} />
                        : ''
            )
        },
        { field: "Qty", headerName: "Quantity", width: 100, sortable: false },
        { field: "date", headerName: "Date (dd/mm/yyyy)", width: 170, sortable: false },
    ];

    // Define a function to render bills or a message based on the loading and error state
    const renderReports = () => {
        // Check if bills for the current industry are being loaded
        if (Loading === status.LOADING) {
            return <CircularLoader />; // Show loader while loading
        } else if (Loading === status.ERROR) {
            return <SomethingWentWrong /> // Show error message if loading failed
        } else {
            // Render bills if they exist
            return (
                <div>
                    {
                        ConsReports && ConsReports?.data !== undefined && ConsReports?.data !== null && searchResults !== undefined && searchResults !== null ?
                            ConsReports?.data?.length !== 0 ?
                                <DataGrid
                                    sx={{ height: 'calc(100vh - 190px)' }}
                                    // onCellClick={handleCellClick}
                                    // rowSelection={false}
                                    rows={searchResults?.map((item, i) => {
                                        return (
                                            {
                                                id: i + 1,
                                                itemName: item?.itemName,
                                                itemCode: item?.itemCode,
                                                Action: item?.action,
                                                Qty: `${item?.qty} ${item?.unit}`,
                                                date: convertGMTtoISTAndFormat(item?.date),
                                            }
                                        )
                                    })}
                                    columns={columns}
                                    pageSize={2}
                                /> : <p className="simpleParagraph absCenter">Reports not found</p>
                            : <SomethingWentWrong />
                    }
                </div>
            );

        }
    };




    return (
        <div>
            <Box p={2}>
                <div className="search_form">
                    <TextField
                        id="outlined-basic"
                        label="Search any item"
                        variant="outlined"
                        size="small"
                        onChange={(e) => debouncedSearch(e.target.value)}
                    />
                </div>
            </Box>
            {renderReports()}
        </div>
    )
}
