import { React, useState, useEffect } from 'react'
import { TextField, Autocomplete, Stack, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { showTicker } from "../../../Redux/Slices/TickerSlice";
import { LoadingButton } from "@mui/lab";
import { status } from "../../../Redux/Slices/userSlice";
import { UpdateChamberPosition } from '../../../Redux/Slices/ProcessManagementSlices/ChamberManagementSlice';
import { Cancel } from "@mui/icons-material";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const Swap = (props) => {
  const dispatch = useDispatch();
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const CHAMBER_LOADING = useSelector(state => state.CHAMBER_MANAGEMENT.AddLoading)
  const GET_CHAMBER_LOADING = useSelector(state => state.CHAMBER_MANAGEMENT.GetChamberLoading)
  const Chambers = useSelector(state => state.CHAMBER_MANAGEMENT.Chambers)
  const [swapValue, setSwapValue] = useState({
    chamber1: "",
    chamber2: ""
  })

  const handleSetSwapValue = (key, value) => {
    setSwapValue((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleSwapPositions = () => {
    // validate swapValue chamber1
    if(swapValue.chamber1 === ""){
      dispatch(showTicker({
        message: "Chamber to swap can`t be empty",
        type: 'error'
      }))
      return
    }
    
    // validate swapValue chamber2
    if(swapValue.chamber2 === ""){
      dispatch(showTicker({
        message: "Chamber to swap with can`t be empty",
        type: 'error'
      }))
      return
    }

    // check if both chambers are same
    if(swapValue.chamber1 === swapValue.chamber2){
      dispatch(showTicker({
        message: "Both chambers can't be the same",
        type: 'error'
      }))
      return
    }

    // check if both chambers exist in the Chambers list
    const chamber1 = Chambers.chamber.filter((option) => `${option.data.position}. ${option.data.chamberName}` === swapValue.chamber1)
    if(chamber1.length === 0){
      dispatch(showTicker({
        message: `Chamber ${swapValue.chamber1} not found`,
        type: 'error'
      }))
      return
    }

    if(!Chambers.chamber.some((option) => `${option.data.position}. ${option.data.chamberName}` === swapValue.chamber2)){
      dispatch(showTicker({
        message: `Chamber ${swapValue.chamber2} not found`,
        type: 'error'
      }))
    }

    // swap position
    dispatch(UpdateChamberPosition({ 
      chamberid: chamber1[0].id,
      newPosition: parseInt(swapValue.chamber2.split(".")[0]),
      UserToken,
      PlantID
    }))
  
  }

  useEffect(() => {
    if(GET_CHAMBER_LOADING === status.LOADING){
      props.setActiveModal({ ActiveModalIndex: 0 })
    }
  }, [GET_CHAMBER_LOADING, props])

  return (
    <div className="Modal_Black_bg">
      <div className='Modal_base' style={{ border: "none" }}>
        {/* Modal Heading */}
        <div className="Modal_heading" style={{ borderRadius: "10px 10px 0 0" }} >
          Swap Chamber Positions
          <IconButton color="PrimaryBlue" onClick={() => { props.setActiveModal(0) }}>
            <Cancel />
          </IconButton>
        </div>

        {/* Modal Content */}
        <div style={{padding: "15px", animation: 'fade-up 1s cubic-bezier(0.19, 1, 0.22, 1)' }}>
          <Stack 
            direction='row' 
            spacing={1}
            alignItems='center'
            justifyContent='space-between'
          > 
            <Autocomplete
              freeSolo
              disableClearable
              sx={{ width: "47.5%" }}
              options={Chambers.chamber.map((option) => `${option.data.position}. ${option.data.chamberName}`)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Chamber to swap"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                  sx={{ width: "100%" }}
                  size="small"
                />
              )}
              inputValue={swapValue.chamber1}
              onInputChange={(event, value, reason) => {
                // Updated to handle all change reasons correctly
                handleSetSwapValue('chamber1', value);
              }}
            />
            <SwapHorizIcon sx={{ width: '5%' }} />
            <Autocomplete
              freeSolo
              disableClearable
              sx={{ width: "47.5%" }}
              options={Chambers.chamber.map((option) => `${option.data.position}. ${option.data.chamberName}`)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Chamber to swap with"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                  sx={{ width: "100%" }}
                  size="small"
                />
              )}
              inputValue={swapValue.chamber2}
              onInputChange={(event, value, reason) => {
                // Updated to handle all change reasons correctly
                handleSetSwapValue('chamber2', value);
              }}
            />
          </Stack>

          {/* Modal Footer */}
          <Stack
            direction="row" 
            justifyContent="end" 
            sx={{ padding: "15px 0 0 0" }}
          >
            <LoadingButton
              loading={CHAMBER_LOADING === status.LOADING}
              loadingPosition="start"
              variant="contained"
              size="large"
              color="PrimaryBlue"
              disableElevation
              onClick={handleSwapPositions}
              // disabled={ApiBodyArray.name.length === 0}
              sx={{ width: "170px" }}
            >
              Swap Positions
            </LoadingButton>

          </Stack>
              
        </div>

      </div>

    </div>
  )
}

export default Swap