import React, { useEffect } from "react";
import { useState } from "react";
import { Add, ArrowBack } from "@mui/icons-material";
import { TextField, Button, Autocomplete, Divider, Chip, Stack, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import "./Team.css";
import "./TeamStyles/AddUser.css";
import { useDispatch, useSelector } from "react-redux";
import { showTicker } from "../../Redux/Slices/TickerSlice";
import { LoadingButton } from "@mui/lab";
import { FetchUsers } from "../../Redux/Slices/MyTeamSlices/GetUsersSlice";
import { SERVER_ADDRESS } from "../../Firebase/Variables";

export default function DepartmentAccessComponent(props) {
    const [selectedDepartment, setSelectedDepartment] = useState(''); // For storing the selected department
    const [selectedAccess, setSelectedAccess] = useState(''); // For storing the selected access
    const [Loading, setLoading] = useState(false)
    const dispatch = useDispatch() // For dispatching actions
    const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
    const plantID = useSelector(state => state.USER_DETAILS.PlantID)

    useEffect(() => { if (props.Category === 'Admin') { props.setDepartmentAccess([]) } }, [props.Category])


    // this function is the main function here, it handels all the operations from department and access selelction. 
    const handleAddAccess = () => {
        if (selectedDepartment && selectedAccess) {
            const newAccess = `${selectedDepartment}-${selectedAccess}`;

            // if array includes the ALL the remove it for adding the other permsisions. beacuse both can not be present in that array 
            if (props.departmentAccess.includes('All')) {
                const index = props.departmentAccess.indexOf('All')
                if (index > -1) {
                    props.departmentAccess.splice(index, 1);
                }
            }

            // Check if the newAccess already exists in the array
            if (!props.departmentAccess.includes(newAccess)) {
                // If selectedAccess is 'write', remove 'read' if present and add 'write'
                if (selectedAccess === 'Write') {
                    const readPermission = `${selectedDepartment}-Read`;
                    props.setDepartmentAccess((prevAccess) =>
                        prevAccess
                            .filter((access) => !access.startsWith(`${selectedDepartment}-`))
                            .concat(newAccess)
                    );
                } else if (selectedAccess === 'Read') {
                    // If selectedAccess is 'read', remove 'write' if present and add 'read'
                    const writePermission = `${selectedDepartment}-Write`;
                    props.setDepartmentAccess((prevAccess) =>
                        prevAccess
                            .filter((access) => !access.startsWith(`${selectedDepartment}-`))
                            .concat(newAccess)
                    );
                } else {
                    props.setDepartmentAccess((prevAccess) => [...prevAccess, newAccess]);
                }
            } else {
                // Handle case when the permission already exists
                dispatch(showTicker({
                    message: 'Access already exists',
                    type: 'error'
                }))
                // You can show a message or perform other actions as needed
            }
            // Clear selected values after adding
            setSelectedDepartment('');
            setSelectedAccess('');
        }
        else if (selectedDepartment === "All") {
            props.setDepartmentAccess(['All']);
        }
        else {
            dispatch(showTicker({
                message: 'Please select Department and Access',
                type: 'error'
            }))
        }
    };


    // delete access
    const handleDeleteAccess = (index) => {
        const updatedAccess = [...props.departmentAccess];
        updatedAccess.splice(index, 1);
        props.setDepartmentAccess(updatedAccess);
    };

    // cant show read and write in the page. so this function make paragraph based on given access.
    const EnhanceParagraph = (access) => {
        const [department, accessType] = access.split('-');
        let actionDescription = '';
        if (access === 'All') {
            return `The user can access all the Departments`;
        }
        else {
            if (accessType === 'Read') {
                actionDescription = 'View';
            } else if (accessType === 'Write') {
                actionDescription = 'View and Change';
            }
            return `This User can ${actionDescription} the data in ${department} Section.`;
        }
    }

    return (
        <div className="DepartmentAccessContainer" style={{ animation: 'fade-up 1s cubic-bezier(0.19, 1, 0.22, 1)' }}>
            <div className="Item_addbtn" style={{ padding: '20px' }}>
                <Button
                    variant="contained"
                    size="large"
                    color="SecondaryBtn"
                    disableElevation
                    startIcon={<ArrowBack />}
                    onClick={() => { props.setProgressLevel(0) }}
                >Back</Button>
                &nbsp;
                {/* Main button for fetching api  */}
                <LoadingButton
                    variant="contained"
                    size="large"
                    color="PrimaryBlue"
                    disableElevation
                    startIcon={<Add />}
                    loading={Loading}
                    disabled={props.departmentAccess.length === 0 && props.Category !== 'Admin' ? true : false}
                    onClick={() => {
                        HandleAddUserAPIS(
                            props.ApiBodyArray,
                            props.Category,
                            props.departmentAccess,
                            UserToken,
                            dispatch,
                            props.setActiveModal,
                            setLoading,
                            plantID
                        )
                    }} // Passing APiBodyArray that we are getting from AddUser.js
                >Add User</LoadingButton>
            </div>

            <div className="UserAccess">
                <p className="simpleParagraph">User Permissions</p>
                <br />
                { //For showing that if admin is selected then we don't need the give any permissions to admin. Admin have all permissison to acess all departments
                    props.departmentAccess.length === 0 && props.Category !== 'Admin' ? <p className="simpleParagraph">No Permissions Given</p>
                        : props.Category === 'Admin' ? <p className="simpleParagraph"><b>Admin</b> user have permissions to <b>View</b> and <b>Change</b> data in all deparments</p> : ''
                }
                <Stack direction="column" spacing={1}>
                    {props.departmentAccess.map((access, index) => (
                        <div key={index}>
                            <Chip label={EnhanceParagraph(access)} variant="outlined" onDelete={() => handleDeleteAccess(index)} />
                        </div>
                    ))}
                </Stack>
            </div>

            <Divider />
            <div className="AddUserAccess">
                <FormControl sx={{ width: '36%' }} size="small">
                    <InputLabel id="demo-simple-select-label">Select Department</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedDepartment}
                        label="Age"
                        onChange={(e) => { setSelectedDepartment(e.target.value) }}
                        disabled={props.Category === 'Admin'} // disabled because admin dont need any permissions
                    >
                        {/* <MenuItem value={'All'}>All</MenuItem> */}
                        <MenuItem value={'Industry'}>Industry</MenuItem>
                        {/* <MenuItem value={'Notices'}>Notices</MenuItem> */}
                        <MenuItem value={'Bill'}>Bills</MenuItem>
                        {/* <MenuItem value={'Sludge'}>Sludge</MenuItem> */}
                        {/* <MenuItem value={'Process'}>Process</MenuItem> */}
                        {/* <MenuItem value={'Reports'}>Reports</MenuItem> */}
                        <MenuItem value={'Inventory'}>Inventory</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ width: '36%' }} size="small">
                    <InputLabel id="demo-simple-select-label">Select Access</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedAccess}
                        label="Age"
                        onChange={(e) => { setSelectedAccess(e.target.value) }}
                        disabled={selectedDepartment === "All" || props.Category === 'Admin'}
                    >
                        {/* <MenuItem value={'Read'}>View Only</MenuItem> */}
                        <MenuItem value={'Write'}>View and Change</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="outlined" color="PrimaryBlue" onClick={handleAddAccess} startIcon={<Add />} disabled={props.Category === 'Admin'}>
                    Add Access
                </Button>
            </div>
        </div>
    );
};





// this function decides which api is to be fetch accoording to selected Category
function HandleAddUserAPIS(ApiBodyArray, Category, departmentAccess, UserToken, dispatch, setActiveModal, setLoading, PlantID) {

    const OfficerEndpoint = `${SERVER_ADDRESS}/officer/signup?plantID=${PlantID}`
    const OperatorEndpoint = `${SERVER_ADDRESS}/operator/signup?plantID=${PlantID}`
    const AdminEndpoint = `${SERVER_ADDRESS}/admin/signup?plantID=${PlantID}`

    // console.log(ApiBodyArray, Category, departmentAccess);

    var OtherRaw = JSON.stringify({
        "name": ApiBodyArray.name,
        "email": ApiBodyArray.email,
        "phoneNo": ApiBodyArray.phoneNo,
        "postName": ApiBodyArray.postName,
        "departmentAccess": departmentAccess
    });
    var AdminRaw = JSON.stringify({
        "name": ApiBodyArray.name,
        "email": ApiBodyArray.email,
        "phoneNo": ApiBodyArray.phoneNo,
        "postName": ApiBodyArray.postName,
    });

    if (Category === "Admin") { Fetch(AdminEndpoint, AdminRaw) }
    else if (Category === 'Officer') { Fetch(OfficerEndpoint, OtherRaw) }
    else if (Category === 'Operator') { Fetch(OperatorEndpoint, OtherRaw) }
    else {
        dispatch(showTicker({
            message: 'Something went wrong, Please try again',
            type: 'error'
        }))
    }

    function Fetch(Endpoint, raw) {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        // fetching api here
        fetch(Endpoint, requestOptions)
            .then(response => {
                response.json().then((data) => {
                    if (response.ok) {
                        dispatch(showTicker({
                            message: `${Category} created successfully`,
                            type: 'success'
                        }))
                        setActiveModal(0) //Closing the modal
                        setLoading(false) // Close loading indicator
                        dispatch(FetchUsers({ AuthToken: UserToken, PlantID: PlantID }))// Refetching users for showing changes
                    }
                    else {
                        dispatch(showTicker({
                            message: data?.message,
                            type: 'error'
                        }))
                        setLoading(false)
                        // console.log(data);
                    }
                })
            })
            .catch(error => {
                dispatch(showTicker({
                    message: error.message,
                    type: 'error'
                }))
                setLoading(false)
                // console.log(error);
            });
    }
}
