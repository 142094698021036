import { Add, AddCircleOutline, ArrowBack, Delete } from '@mui/icons-material';
import { Box, Button, Chip, Divider, IconButton, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { status } from '../../../../Redux/Slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMasterBill, GetMasterBillTypes } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/MasterCopySlice';
import { EmptyArrayValidation, EmptyFeildValidation } from '../../../../UserAuthentication/AuthFunctions';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

export default function GenerateMasterBill() {

  //Initial state of the Master bill.
  const initialState = {
    HSN_SAC_code: '',
    cgstRate: '',
    sgstRate: '',
    declaration: [],
    description: '',
    price: '',
    requiredFields: ["lastDate", "interestRate"],
    termsAndCondn: [],
    type: '',
    unit: '',
  };

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [formData, setFormData] = useState(initialState);
  const [newDeclaration, setNewDeclaration] = useState('');
  const [newTerm, setNewTerm] = useState('');
  const [CreateBillLoading, setCreateBillLoading] = useState(false)
  const Types = useSelector(state => state.BILL_MASTER_COPIES.Types)
  const Loading = useSelector(state => state.BILL_MASTER_COPIES.Loading)
  const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  
  useEffect(() => { if (!Types.types && Loading !== status.LOADING && Loading !== status.ERROR) { dispatch(GetMasterBillTypes({ AuthToken: AuthToken, PlantID: PlantID })) } }, []);


  const handleAddDeclaration = () => {
    if (newDeclaration.trim() !== '') {
      setFormData({ ...formData, declaration: [...formData.declaration, newDeclaration] });
      setNewDeclaration('');
    }
  };

  const handleDeleteDeclaration = (index) => {
    const updatedDeclarations = formData.declaration.filter((_, i) => i !== index);
    setFormData({ ...formData, declaration: updatedDeclarations });
  };

  const handleAddTerm = () => {
    if (newTerm.trim() !== '') {
      setFormData({ ...formData, termsAndCondn: [...formData.termsAndCondn, newTerm] });
      setNewTerm('');
    }
  };

  const handleDeleteTerm = (index) => {
    const updatedTerms = formData.termsAndCondn.filter((_, i) => i !== index);
    setFormData({ ...formData, termsAndCondn: updatedTerms });
  };

  const handleSubmit = (e) => {
    CreateMasterBill(formData, AuthToken, dispatch, navigate, setCreateBillLoading, PlantID)
    // setFormData(initialState); // Reset form after submission
  };

  const validateFormDetails = () => {
    if (
      EmptyFeildValidation(formData.description, 'Description', dispatch) &&
      EmptyFeildValidation(formData.HSN_SAC_code, 'HSN / SAC code', dispatch) &&
      EmptyFeildValidation(formData.cgstRate, 'CGST rate', dispatch) &&
      EmptyFeildValidation(formData.sgstRate, 'SGST rate', dispatch) &&
      EmptyFeildValidation(formData.price, 'Price', dispatch) &&
      EmptyFeildValidation(formData.type, 'Type of the bill', dispatch) &&
      EmptyFeildValidation(formData.unit, 'Unit', dispatch) &&
      EmptyArrayValidation(formData.declaration, 'Declaration', dispatch) &&
      EmptyArrayValidation(formData.termsAndCondn, 'Terms and Conditions', dispatch)
    ) {
      handleSubmit()
    }
  }

  const handleUnit = (event) => {
    const inputValue = event.target.value;
    // Check if the input contains only alphabets
    const onlyAlphabets = /^[a-zA-Z\s]*$/;
    if (!onlyAlphabets.test(inputValue)) {
      // If input contains non-alphabet characters, prevent the change
      event.preventDefault();
    }
    else {
      setFormData({ ...formData, unit: event.target.value })
    }
  }

  return (
    <div>
      <div className="PAGE_HEADER">
        {/* Name of the page, will show in lwft of the header */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /> </IconButton>
          <h3>Creating a Master Bill</h3>
        </Stack>
      </div>


      {/* our navigation  */}
      <div className="Industries_Body_Container">

        <Stack direction="row" justifyContent="space-between" p={2}>
          <Typography variant='body2'>Please enter all the details required for genration of a new master bill. </Typography>
          <LoadingButton variant='contained' loading={CreateBillLoading} startIcon={<Add sx={{ width: '16px' }} />} onClick={validateFormDetails} disableElevation>Create</LoadingButton>
        </Stack>

        <Divider />

        <Box p={3}>

          <Stack spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography variant='body1'>Decription Of the Bill</Typography>
              <TextField
                size="small"
                label="Description"
                name="description"
                value={formData.description}
                sx={{ width: '500px' }}
                disabled={CreateBillLoading}
                onChange={(e) => { setFormData({ ...formData, description: e.target.value }) }}
                required
              />
            </Stack>


            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography variant='body1'>HSN / SAC Code</Typography>
              <TextField
                size="small"
                type='number'
                label="HSN/SAC Code"
                name="HSN_SAC_code"
                value={formData.HSN_SAC_code}
                disabled={CreateBillLoading}
                onChange={(e) => { setFormData({ ...formData, HSN_SAC_code: e.target.value }) }}
                sx={{ width: '500px' }}
                required
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography variant='body1'>CGST Rate (in %)</Typography>
              <TextField size="small"
                type="number"
                label="CGST Rate"
                name="cgstRate"
                value={formData.cgstRate}
                onChange={(e) => { setFormData({ ...formData, cgstRate: +e.target.value }) }}
                sx={{ width: '500px' }}
                disabled={CreateBillLoading}
                required
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography variant='body1'>SGST Rate (in %)</Typography>
              <TextField size="small"
                type="number"
                label="SGST Rate"
                name="sgstRate"
                value={formData.sgstRate}
                disabled={CreateBillLoading}
                sx={{ width: '500px' }}
                onChange={(e) => { setFormData({ ...formData, sgstRate: +e.target.value }) }}
                required
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography variant='body1'>Price of the good (Rs)</Typography>
              <TextField size="small"
                type="number"
                label="Price"
                name="price"
                value={formData.price}
                disabled={CreateBillLoading}
                sx={{ width: '500px' }}
                onChange={(e) => { setFormData({ ...formData, price: +e.target.value }) }}
                required
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography variant='body1'>Type of the Bill</Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.type}
                label="Select bill type"
                disabled={CreateBillLoading}
                sx={{ width: '500px' }}
                size='small'
                onChange={(e) => { setFormData({ ...formData, type: e.target.value }) }}
              >
                {
                  !!Types.types ? Types.types.map((type) => {
                    return <MenuItem key={type} value={type}>{type}</MenuItem>
                  }) : ''
                }

              </Select>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography variant='body1'>Unit of the Consumption</Typography>
              <TextField size="small"
                label="Unit"
                name="unit"
                value={formData.unit}
                onChange={handleUnit}
                sx={{ width: '500px' }}
                disabled={CreateBillLoading}
                required
              />
            </Stack>


            <Box sx={{ p: 2, border: '1px dashed lightgrey', borderRadius: '12px', backgroundColor: 'var(--Tertiary_Blue)' }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <TextField size="small"
                  label="Add Declaration"
                  value={newDeclaration}
                  onChange={(e) => setNewDeclaration(e.target.value)}
                  fullWidth
                  disabled={CreateBillLoading}
                />
                <Button onClick={handleAddDeclaration} variant='outlined' startIcon={<AddCircleOutline fontSize='small' />} sx={{ whiteSpace: "nowrap", padding: "13px 60px" }}>Add Declaration</Button>
              </Stack>

              {formData.declaration.length > 0 ? <Typography variant='caption'>Declarations</Typography> : ''}
              <Stack direction="column" alignItems="flex-start" p={1} spacing={1}>
                {formData.declaration.map((declaration, index) => (
                  <Chip key={index} label={declaration} onDelete={() => handleDeleteDeclaration(index)} size="large" />
                ))}
              </Stack>
            </Box>


            <Box sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', backgroundColor: 'var(--Tertiary_Blue)' }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <TextField size="small"
                  label="Add Terms & Conditions"
                  value={newTerm}
                  onChange={(e) => setNewTerm(e.target.value)}
                  disabled={CreateBillLoading}
                  fullWidth
                />
                <Button onClick={handleAddTerm} variant='outlined' startIcon={<AddCircleOutline fontSize='small' />} sx={{ whiteSpace: "nowrap", padding: "13px 60px" }}>Add Terms</Button>
              </Stack>
              <Typography variant='caption'>* Do not include the last date and Interest rate in the terms and conditions as it will automatically include while creating bills</Typography>

              {formData.termsAndCondn.length > 0 ? <Typography variant='caption'>Terms & Conditions</Typography> : ''}

              <Stack direction="column" alignItems="flex-start" p={1} spacing={1}>
                {formData.termsAndCondn.map((term, index) => (
                  <Chip key={index} label={term} onDelete={() => handleDeleteTerm(index)} size="large" />
                ))}
              </Stack>
            </Box>
          </Stack>
        </Box>
        <br />
      </div>
    </div>
  )
}
