import { ArrowBack } from '@mui/icons-material'
import { Box, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GetPlantDetails } from '../../Redux/Slices/PlantDetailsSlice'
import { status } from '../../Redux/Slices/userSlice'
import { CircularLoader, SomethingWentWrong } from '../UI Components/Basic'

export default function PlantProfile() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const PlantDetails = useSelector(state => state.PLANT_DETAILS.Details?.plant)
    const PlantDetailsLoading = useSelector(state => state.PLANT_DETAILS.Loading)

    return (
        <div>
            <div className="PAGE_HEADER">
                {/* Name of the page, will show in lwft of the header */}
                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /> </IconButton>
                    <h3>Plant Profile</h3>
                </Stack>

            </div>
            {/* table of the bills */}
            <div className="Industries_Body_Container">
                <Box p={2}>
                    {
                        PlantDetailsLoading === status.IDLE ?
                            <Box>
                                <Typography variant='h6'>{PlantDetails?.plantName}</Typography>
                                <br />
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Plant Address</TableCell>
                                                <TableCell>{PlantDetails?.plantAddress}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Pin Code</TableCell>
                                                <TableCell>{PlantDetails?.pincode}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>City</TableCell>
                                                <TableCell>{PlantDetails?.city}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>State</TableCell>
                                                <TableCell>{PlantDetails?.state}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Phone</TableCell>
                                                <TableCell>{PlantDetails?.phoneNo}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>E-mail</TableCell>
                                                <TableCell>{PlantDetails?.email}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Telephone No</TableCell>
                                                <TableCell>{PlantDetails?.telNo}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>GSTIN</TableCell>
                                                <TableCell>{PlantDetails?.gstin}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Pan No.</TableCell>
                                                <TableCell>{PlantDetails?.pan}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>CIN</TableCell>
                                                <TableCell>{PlantDetails?.cin}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>Bank Details</TableCell>
                                                <TableCell>
                                                    <Typography variant='body2'>Bank Name : {PlantDetails?.bankName}</Typography>
                                                    <Typography variant='body2'>Bank Account No. : {PlantDetails?.bankAccountNo}</Typography>
                                                    <Typography variant='body2'>Bank IFSC : {PlantDetails?.bankIFSCCode}</Typography>
                                                    <Typography variant='body2'>Bank Branch Address : {PlantDetails?.bankBranchAddress}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>Plant Capacity</TableCell>
                                                <TableCell>{PlantDetails?.plantCapacityinMLD}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Bill starting Quotation Number</TableCell>
                                                <TableCell>{PlantDetails?.startingQuotationNo}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <br /><br />
                            </Box> :
                            PlantDetailsLoading === status.LOADING ? <CircularLoader /> :
                                PlantDetailsLoading === status.ERROR ? <SomethingWentWrong />
                                    : ''
                    }
                </Box>
            </div>
        </div>
    )
}
