import React, { useEffect, useRef } from 'react'
import './AuthStyles/Login.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ErrorTicker, ProceedButton } from './AuthUIComponents';
import { VysionLogo_Login } from '../assets/assets';
import { AuthenticationRoutes, DrawerRoutes } from '../Components/Routes/routes';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "@firebase/auth";
import { FirebaseAuthVariable } from "../Firebase/firebase.js";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, Typography } from '@mui/material';
import { Dashboard, Logout } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { CheckCurrentUser } from '../Redux/Slices/userSlice.js';
import { showTicker } from '../Redux/Slices/TickerSlice.js';

export default function PlantLogin() {

    const navigate = useNavigate(); // For navigation on other routes
    const userNameRef = useRef(""); //Refrence for username feild
    const passwordRef = useRef(""); // Refrence for password feild
    const [Loading, setLoading] = useState(false) //Loading
    const UID = useSelector(state => state.USER_DETAILS.UID); //Getting uid from redux store for checking any user is logged in or not
    const dispatch = useDispatch()

    // dispatching this action because on login page we have to check if user is logged in or not, if any user is already logged in then it will show, alredy logged in user 
    useEffect(() => {
        dispatch(CheckCurrentUser())
    }, [])

    // validating  empty feilds 
    const VALIDATE_FEILDS = () => {
        if (userNameRef.current.value === '') {
            dispatch(showTicker({
                message: 'Please fill all the required fields',
                type: 'error'
            }))
            userNameRef.current.focus();
            return
        }
        if (passwordRef.current.value === '') {
            dispatch(showTicker({
                message: 'Please fill all the required fields',
                type: 'error'
            }))
            passwordRef.current.focus();
            return
        }
        else {
            if (checkUsername()) { LOGGIN_IN(userNameRef.current.value, passwordRef.current.value) }
            else {
                dispatch(showTicker({
                    message: 'Invalid username or password',
                    type: 'error'
                }))
            }
        }
    }

    // validating proper email
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const checkUsername = () => {
        if (regex.test(userNameRef.current.value)) {
            userNameRef.current.style.outline = "1px solid green"
            return true
        }
        else {
            userNameRef.current.style.outline = "1px solid red"
            return false
        }
    }

    // LOGGING IN THE USER BY TAKING USER EMAIL AND PASSWORD. 
    const LOGGIN_IN = (email, password) => {
        setLoading(true)
        signInWithEmailAndPassword(FirebaseAuthVariable, email, password).then((user) => {
            dispatch(showTicker({
                message: 'Login successful',
                type: 'success'
            }))
            navigate(DrawerRoutes.Dashboard, { replace: true })
            setLoading(false)
        }).catch((error) => {
            // console.log(error);
            if (error.code === "auth/invalid-credential") {
                dispatch(showTicker({
                    message: 'Invalid credentials',
                    type: 'error'
                }))
            }
            setLoading(false)
        })
    }
    return (
        <>
            <div className="Grid1">
                {
                    //getting uid from redux store, we are checking if any user logged in, it will suggest him to go to dashobard or logout
                    UID === null ? <div className="Login" id="login">
                        <br />
                        <Stack width="89%">
                            <Typography variant='h6'>Login</Typography>
                            <Typography variant='caption'>Enter your registered email and password</Typography>
                        </Stack>
                        {/* <img className="Logologin" src={VysionLogo_Login} />
                        <p className="Message">Welcome to the STP monitoring dashboard
                            designed and developed by Vysion Technology Pvt. Ltd.</p> */}

                        {/* Email and passwords inputs */}
                        <input className="UserName" onChange={checkUsername} ref={userNameRef} placeholder="Registered E-mail address" />
                        <input className="UserName" ref={passwordRef} placeholder="Password" type='password' />

                        <p className="Message" style={{ marginTop: "7px", textAlign: "right", textDecoration: "underline 1px solid lightgrey", cursor: "pointer" }} onClick={() => { navigate(AuthenticationRoutes.ForgotPassword) }}>Forgot Password?</p>
                        <ProceedButton targetFunction={VALIDATE_FEILDS} title={"Login"} loading={Loading} />
                    </div> : <Already_Loggedin_User />
                }
            </div>
        </>
    )
}



// A card for if a user logged in already, this card will show
export function Already_Loggedin_User() {
    const UserDetails = useSelector(state => state.USER_DETAILS.UserEmail); //Getting Userdetails from redux store for checking any user is logged in or not
    const [Loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const LOGGIN_OUT = () => {
        setLoading(true)
        signOut(FirebaseAuthVariable).then((res) => {
            setLoading(false)
            dispatch(showTicker({
                message: 'Logged Out Successfully',
                type: 'success'
            }))
            navigate(AuthenticationRoutes.UserSelection)
        }).catch((err) => {
            setLoading(false)
            dispatch(showTicker({
                message: err.message,
                type: 'error'
            }))
        })
    }
    return <div className='Login'>
        <div className="Already_LoggedIn_User">
            <p className="Message" style={{ width: "fit-content" }}>A user is Already logged in</p>
            <p className="Message" style={{ width: "fit-content" }}><b>{UserDetails}</b></p>
        </div>
        <Button onClick={() => { navigate(DrawerRoutes.Dashboard, { replace: true }) }} color="PrimaryBlue" variant="contained" size='medium' startIcon={<Dashboard />} sx={{ width: "90%" }} disableElevation>Go to Dashboard</Button>
        <div className="gap" style={{ height: "10px" }}></div>
        <LoadingButton loading={Loading ? true : false} onClick={LOGGIN_OUT} color="PrimaryRed" variant="contained" size='medium' startIcon={<Logout />} sx={{ width: "90%", borderRadius: "8px" }} disableElevation>Logout</LoadingButton>
        <div className="gap" style={{ height: "10px" }}></div>
        <div className="gap" style={{ height: "10px" }}></div>
    </div>
}