import React, { useEffect, useState } from 'react'
import { ArrowBack, ArrowDropDown, ArrowDropUp, ArrowForward, Close } from '@mui/icons-material'
import { Box, Button, Collapse, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

// this function will going to import in the bill body component. the functionality of this function will be to selecting a moster copy and forwarding it to next step which is enter goods detials 
export default function SelectMasterCopy(props) {
    const [openRow, setOpenRow] = useState("") // For Storing the row which is currently in OPENED state
    const MasterCopies = useSelector(state => state.BILL_MASTER_COPIES.Copies)

    const [FilteredMasterBills, setFilteredMasterBills] = useState([])

    // function for filtering data on type basis
    const filterByType = (data, type) => {
        return data.filter(item => item.data.type === type);
    };

    // console.log(props.TypeOfGood, FilteredMasterBills.length);

    // filtering the bills on the current selected type of the good. 
    useEffect(() => {
        if (!!MasterCopies) {
            setFilteredMasterBills([])
            setFilteredMasterBills(filterByType(MasterCopies.billMasterCopies, props.TypeOfGood))
        }
    }, [])



    return <Box>
        <Box className="Modal_heading">
            <Stack>
                <Typography variant='h6'>Please Select A Master Bill.</Typography>
                <Typography variant='caption'>For proceeding to create a good, you must have been select a bill master copy. </Typography>
            </Stack>
        </Box>
        <Stack>
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ paddingLeft: '20px' }}>Desc (Click for more details)</TableCell>
                            <TableCell>Price (rs)</TableCell>
                            <TableCell>CGST (%)</TableCell>
                            <TableCell>SGST (%)</TableCell>
                            <TableCell>HSN Code</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>

                    {/* all master copies rows */}
                    <TableBody>
                        {!!MasterCopies ?
                            FilteredMasterBills.length > 0 ?
                                FilteredMasterBills.map((copy) => {
                                    return <Row
                                        key={copy.id} // A unique id for the row. 
                                        data={copy.data} // Passing the bill data into the master copy selection component.
                                        billId={copy.id} // Passing the bill id into the master copy selection component, becuase of data and id is seperated in the response
                                        openRow={openRow} // Setting open row globally because, this is implemented because a problem was happening when we are opening a collapsible bill and if we open next bill then the first one is keep opened not closing. so we made a state for controlling the open close behavoiur of the rows from its parent. So now if one row open other will close.
                                        setOpenRow={setOpenRow} //this is passed because we created an arrow button for closing the collapsible bill and this is important for closing the bill extra details. setting open row to '' will close this bill

                                        SelectedBill={props.SelectedBill} // Basically this is pass here becuase of changing background color of the bill which is selected. so we compare the selected bill id and the passed id in the row component and if its matched the we change its color to other one.
                                        setSelectedBill={props.setSelectedBill} // This is for setting the selected bill. Simply for selecting the bill.
                                    />
                                }) : <><br /><br /><br /><p className="absCenter simpleParagraph"><br /><br />Master Bills not found for this type. Please add some and try again</p></>
                            :
                            'No Master Copies found, please refresh the page or add some master copies'}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
        {/* buttons for navigation */}
        <Stack direction="row-reverse" spacing={1} padding="20px" position='sticky' bottom={0} backgroundColor='white' fullWidth>
            <Button
                variant='contained'
                disabled={props.SelectedBill === ''}
                disableElevation
                endIcon={<ArrowForward />}
                onClick={() => { props.setModalState({ isModalOpen: true, innerModalState: 2 }); }}
            > Proceed</Button>
            <Button
                variant='contained'
                color='SecondaryBtn'
                disableElevation
                startIcon={<ArrowBack />}
                onClick={() => { props.setModalState({ isModalOpen: true, innerModalState: 0 }); }}
            > Back</Button>
        </Stack>
    </Box>
}



// function component for a single row with collapsible content, this one single row will contain very much data about the master bill, so we have to make it seprately.
//exporting this because this is going to use in the editing component also.
export function Row(props) {
    return (
        <React.Fragment>
            <TableRow sx={{ backgroundColor: props.billId === props.SelectedBill ? '#dbffd4' : 'white' }}>
                <TableCell sx={{ paddingLeft: '20px' }} onClick={() => { props.setOpenRow(props.billId) }}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {props.data.description}
                        {props.billId === props.openRow ?
                            <IconButton onClick={(event) => { props.setOpenRow(''); event.stopPropagation() }}>
                                <ArrowDropUp />
                            </IconButton>
                            : <IconButton>
                                <ArrowDropDown />
                            </IconButton>}
                    </Stack>
                </TableCell>
                <TableCell>{props.data.price}</TableCell>
                <TableCell>{props.data.cgstRate}</TableCell>
                <TableCell>{props.data.sgstRate}</TableCell>
                <TableCell>{props.data.HSN_SAC_code}</TableCell>
                <TableCell>
                    <Button
                        variant='contained'
                        sx={{ height: '32px' }}
                        color={props.billId === props.SelectedBill ? "success" : 'PrimaryBlue'}
                        onClick={() => { props.setSelectedBill(props.billId) }} // Setting the bill id into the SelectedBill State which made in grandParent componenet
                        disableElevation>{props.billId === props.SelectedBill ? "Selected" : "Select"}</Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={props.billId === props.openRow ? true : false} timeout="auto" sx={{ backgroundColor: props.billId === props.SelectedBill ? "#dbffd4" : "white" }} unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <TableContainer sx={{ paddingLeft: '20px' }}>
                                <Table aria-label="collapsible table">
                                    <Typography variant='body1' gutterBottom>Other Details</Typography>

                                    <TableBody>

                                        {/* writing these items seperately because main details are mentioned in the main table and these other details are in the collapsible content. if we map the data again then all data will map here and it will consume space.  */}

                                        <TableRow>
                                            <TableCell> Unit </TableCell>
                                            <TableCell> <Typography variant='body2'>{props.data.unit ? props.data.unit : 'Not Avialable'}</Typography> </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell> Description </TableCell>
                                            <TableCell> <Typography variant='body2'>{props.data.description ? props.data.description : 'Not Avialable'}</Typography> </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell> Declaration </TableCell>
                                            <TableCell>
                                                <Stack>
                                                    {
                                                        props.data.declaration !== undefined ?
                                                            props.data?.declaration?.map((dec, index) => {
                                                                return <Typography key={index} variant='body2'>{dec}</Typography>
                                                            })
                                                            : ''
                                                    }
                                                </Stack>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell > Terms & Conditions </TableCell>
                                            <TableCell>
                                                <Stack>
                                                    {
                                                        props.data.termsAndCondn !== undefined ?
                                                            props.data?.termsAndCondn?.map((condn, index) => {
                                                                return <Typography key={index} variant='body2'>{index + 1}&nbsp;&nbsp;{condn}</Typography>
                                                            })
                                                            : ''
                                                    }
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}
