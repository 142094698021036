import { Box, Button, Collapse, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Typography, IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DrawerRoutes } from '../../../Routes/routes'
import { ArrowBack, ArrowDropDown, ArrowDropUp, Receipt } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GetMasterBillTypes, GetMasterCopies } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/MasterCopySlice'
import { status } from '../../../../Redux/Slices/userSlice'
import { CircularLoader, SomethingWentWrong, UniversalLoader } from '../../../UI Components/Basic'

export default function MasterCopies() {

  // variables 
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const Types = useSelector(state => state.BILL_MASTER_COPIES.Types)
  const Loading = useSelector(state => state.BILL_MASTER_COPIES.Loading)
  const MasterCopies = useSelector(state => state.BILL_MASTER_COPIES.Copies)
  const [ActiveBillType, setActiveBillType] = useState('') // Hold the value of the active bill type, in the toggle button group
  const [FilteredBills, setFilteredBills] = useState([])// For storing the filtered list of bills
  const [openRow, setOpenRow] = useState("") // For Storing the row which is currently in OPENED state

  // activating the first type of the bills section. different types of the bills are filtered on basis of the bill type, so we are showing the first type of the bill in the type array. Further user can switch accordingly .
  useEffect(() => {
    if (!!Types.types && ActiveBillType === '' && Loading === status.IDLE) {
      if (Types.types[0] === null) {
        setActiveBillType(Types.types[1])
      } else {
        setActiveBillType(Types.types[0])
      }
    }
  }, [Types])


  // this useffect fetches the type of the bills we have in the database. According to the type we creating the toggle buttons for showing different type of the bills
  useEffect(() => {
    if (!Types.types && Loading !== status.LOADING && Loading !== status.ERROR) {
      dispatch(GetMasterBillTypes({ AuthToken: AuthToken, PlantID: PlantID })) // Fetching the types of the bills we have in our database.
      dispatch(GetMasterCopies({ AuthToken: AuthToken, PlantID: PlantID })) // Fetching all bill at once and the filtering out them as types. 
    }
  }, []);

  // function for filtering data on type basis
  const filterByType = (data, type) => {
    return data.filter(item => item.data.type === type);
  };

  // updating list of bills everytime when someone switch the type of the bill, through navigation
  useEffect(() => {
    if (!!Types.types && Loading === status.IDLE && ActiveBillType && MasterCopies.billMasterCopies) {
      const newBills = (filterByType(MasterCopies.billMasterCopies, ActiveBillType))
      setFilteredBills(newBills)
    }
  }, [ActiveBillType, MasterCopies])


  const [searchResults, setSearchResults] = useState(FilteredBills); // For saving filtered results if anyone applies a filter or search key
  let searchTimeout; // FOr debouncing purpose

  // this small useffect set data to search results for mapping into the table. this is important because whenever the page loads, if the data in redux is change then the change should reflex in this table also, and direct changes is not reflecting because we are not directly mapping the redux state, we saving the redux state into filtered results or search resutlts and then mapping results in to the table.
  useEffect(() => { setSearchResults(FilteredBills) }, [FilteredBills])

  // function for searching a item in the search bar
  const handleSearch = (searchQuery) => {
    const lowercaseQuery = searchQuery.toLowerCase();// Convert the search query to lowercase for case-insensitive search
    const filteredResults = FilteredBills.filter( // Filter inventory items based on name or code containing the search query
      item =>
        item.data.description.toLowerCase().includes(lowercaseQuery) ||
        item.data.HSN_SAC_code.toLowerCase().includes(lowercaseQuery)
    );
    setSearchResults(filteredResults); // Update the state with the search results
  };

  // implementing debouncing in the search bar
  const debouncedSearch = (query) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      handleSearch(query);
    }, 1000);
  };


  return (
    <div>
      {/* <button onClick={() => { dispatch(GetMasterBillTypes(AuthToken)) }}>get types</button> */}
      {/* <button onClick={() => { dispatch(GetMasterCopies(AuthToken)) }}>get bills</button> */}
      <div className="PAGE_HEADER">
        {/* Name of the page, will show in lwft of the header */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /> </IconButton>
          <h3>Master Bills</h3>
        </Stack>
        <ToggleButtonGroup
          size='small'
          value={ActiveBillType}
          color="PrimaryBlue">
          {
            !!Types.types ? Types.types.map((type) => {
              return (
                type !== null ?
                  <ToggleButton
                    key={type}
                    value={type}
                    onClick={() => { setActiveBillType(type) }}
                  >
                    {type}
                  </ToggleButton>
                  : ""
              )
            }) : ''
          }
        </ToggleButtonGroup>
      </div>



      {/* our navigation  */}
      <div className="Industries_Body_Container">

        <Stack direction="row" justifyContent="space-between" p={2}>
          <TextField placeholder='Search bills' size='small' onChange={(e) => debouncedSearch(e.target.value)} />
          <Button variant='contained' startIcon={<Receipt sx={{ width: '16px' }} />} onClick={() => { navigate(DrawerRoutes.Reports.GenerateMasterBill) }} disableElevation>Create Master Bill</Button>
        </Stack>

        {/* our master bills */}
        <Stack>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ paddingLeft: '20px' }}>Description</TableCell>
                  <TableCell >Type</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Price (rs)</TableCell>
                  <TableCell>CGST (%)</TableCell>
                  <TableCell>SGST (%)</TableCell>
                  <TableCell>HSN Code</TableCell>
                </TableRow>
              </TableHead>

              {/* all master copies rows */}
              <TableBody>
                {
                  Loading === status.IDLE ?
                    !!MasterCopies?.billMasterCopies ?
                      FilteredBills.length > 0 ?
                        searchResults.map((copy) => {
                          return <Row
                            key={copy.id} // A unique id for the row. 
                            data={copy.data} // Passing the bill data into the master copy selection component.
                            billId={copy.id} // Passing the bill id into the master copy selection component, becuase of data and id is seperated in the response
                            openRow={openRow} // Setting open row globally because, this is implemented because a problem was happening when we are opening a collapsible bill and if we open next bill then the first one is keep opened not closing. so we made a state for controlling the open close behavoiur of the rows from its parent. So now if one row open other will close.
                            setOpenRow={setOpenRow} //this is passed because we created an arrow button for closing the collapsible bill and this is important for closing the bill extra details. setting open row to '' will close this bill
                          />
                        })
                        : <p className="simpleParagraph absCenter">No bills found</p>
                      : ''
                    : Loading === status.LOADING ? <UniversalLoader />
                      : Loading === status.ERROR ? <SomethingWentWrong /> : ''
                }
              </TableBody>
            </Table>
          </TableContainer>
          <br /><br /><br /><br />
        </Stack>
      </div>
    </div>
  )
}


// function component for a single row with collapsible content, this one single row will contain very much data about the master bill, so we have to make it seprately.
//exporting this because this is going to use in the editing component also.
function Row(props) {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell sx={{ paddingLeft: '20px' }} onClick={() => { props.setOpenRow(props.billId) }}>
          <Stack direction="row" alignItems="center" spacing={2}>

            {props.data.description}


            {props.billId === props.openRow ?
              <IconButton onClick={(event) => { props.setOpenRow(''); event.stopPropagation() }}>
                <ArrowDropUp />
              </IconButton>
              : <IconButton>
                <ArrowDropDown />
              </IconButton>}
          </Stack>
        </TableCell>
        <TableCell>  {props.data.type}</TableCell>
        <TableCell>{props.data.unit ? props.data.unit : 'Not available'}</TableCell>
        <TableCell>{props.data.price}</TableCell>
        <TableCell>{props.data.cgstRate}</TableCell>
        <TableCell>{props.data.sgstRate}</TableCell>
        <TableCell>{props.data.HSN_SAC_code}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={props.billId === props.openRow ? true : false} timeout="auto" sx={{ backgroundColor: props.billId === props.SelectedBill ? "#dbffd4" : "white" }} unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <TableContainer sx={{ paddingLeft: '20px' }}>
                <Table aria-label="collapsible table">
                  <Typography variant='body1' gutterBottom>Other Details</Typography>

                  <TableBody>

                    {/* writing these items seperately because main details are mentioned in the main table and these other details are in the collapsible content. if we map the data again then all data will map here and it will consume space.  */}

                    <TableRow>
                      <TableCell> Unit </TableCell>
                      <TableCell> <Typography variant='body2'>{props.data.unit ? props.data.unit : 'Not Avialable'}</Typography> </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell> Description </TableCell>
                      <TableCell> <Typography variant='body2'>{props.data.description ? props.data.description : 'Not Avialable'}</Typography> </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell> Declaration </TableCell>
                      <TableCell>
                        <Stack>
                          {
                            props.data.declaration !== undefined ?
                              props.data?.declaration?.map((dec, index) => {
                                return <Typography key={index} variant='body2'>{dec}</Typography>
                              })
                              : ''
                          }
                        </Stack>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell > Terms & Conditions </TableCell>
                      <TableCell>
                        <Stack>
                          {
                            props.data.termsAndCondn !== undefined ?
                              props.data?.termsAndCondn?.map((condn, index) => {
                                return <Typography key={index} variant='body2'>{index + 1}&nbsp;&nbsp;{condn}</Typography>
                              })
                              : ''
                          }
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment >
  );
}
