// basic icons imports
export const BackButtonIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/back_mRiZ1EuXB.svg?updatedAt=1679391536984"
export const SearchButtonIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/search_4CSTOcNB5x.svg?updatedAt=1679391540341"
export const AddButtonIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Add-s_8qwO5k3q8.svg?updatedAt=1679392317778"
export const SortButtonIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/SortButton_FAZlp9Vxr.svg?updatedAt=1679391540539"
export const DropdownButtonIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Dropdown_KQYhspih7b.svg?updatedAt=1679391537010"

// Dashboard icons imports
export const DashboardIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Dashboard_24x24_3xYnEEV_9z.svg?updatedAt=1679391536859"
export const IndustryIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Industry_24x24_TUJgDbGrau.svg?updatedAt=1679391537038"
export const InventoryIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Inventory_4KGcQyjFP_.svg?updatedAt=1679391537010"
export const NotificationIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Notification_FOY5EgKJM.svg?updatedAt=1679391540262"
export const ProcessIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/process_tWV5oMpqw.svg?updatedAt=1679391540300"
export const CPCBIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/cpcb_qgzTXT1cdA.svg?updatedAt=1679391536901"
export const ReportsIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/reports_2uZRpKEMun.svg?updatedAt=1679391540316"
export const CCTVIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/cctv_OgRJWHkPj.svg?updatedAt=1679391536852"
export const MyTeamIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/team_HMOLVaQeO.svg?updatedAt=1679391540823"

// About industry navigation icons imports
export const AboutIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/About_ad27LEp1D.svg?updatedAt=1679391537001"
export const EffluentDetailsIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/effluent_M-wOQHflvw.svg?updatedAt=1679391537073"
export const BillsPaymentsIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/bills_4cHX6SLYk1.svg?updatedAt=1679391537002"
export const NoticesIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/notice_9T6bQ6zIL.svg?updatedAt=1679391540378"
export const SettingsIcon = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/setting_tf7_Uw0tB.svg?updatedAt=1679391540554"

// Vysion Logos For different locations
export const VysionLogo_Login = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Others/logo_hBuemc-rA.png?updatedAt=1681388921683"
export const VysionLogo_Payments = "https://ik.imagekit.io/asnegg1je/Vysion%20Logo.png?updatedAt=1714120125620"

// Login Assets
export const IndustryImg = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Others/IndustryIMG_s0VhRQ73F.png?updatedAt=1681285124726"
export const LoginDecoration = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Others/LoginDecoration_6QpTfKK9Z.png?updatedAt=1681298742784"
export const MailVerificationIllustration = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Others/OTPverification_S3HNn0bn2v.svg?updatedAt=1681544270320"
export const PendingRequestIllustration = "https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Others/PendingIllustration_OkVwy_TuvL.svg?updatedAt=1681543383106"
