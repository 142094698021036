import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showTicker } from "../../TickerSlice";
import { status } from "../../userSlice";
import { DrawerRoutes } from "../../../../Components/Routes/routes";
import { SERVER_ADDRESS } from "../../../../Firebase/Variables";


// redux thunk fucntion for getting master copies
export const GetMasterCopies = createAsyncThunk('GetMasterCopies', async ({ AuthToken, PlantID }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/bill/master?plantID=${PlantID}`, requestOptions)
        const data = await response.json()

        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            rejectWithValue(response.statusText)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
    }
})

// redux thunk function for getting master bills types
export const GetMasterBillTypes = createAsyncThunk('GetMasterBillTypes', async ({ AuthToken, PlantID }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/bill/master/types?plantID=${PlantID}`, requestOptions)
        const data = await response.json()
        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: "error"
            }))
            return rejectWithValue(response.statusText)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: "error"
        }))
        return rejectWithValue(error.message)
    }

})


export const GetMasterBillByID = createAsyncThunk('GetMasterBillByID', async ({ AuthToken, billID }, { dispatch }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(`${SERVER_ADDRESS}/industry/bill/master/?id=${billID}`, requestOptions)
        .then((response) => {
            response.json().then((data) => {
                if (response.ok) {
                    return data
                }
                else {
                    dispatch(showTicker({
                        message: `${response.statusText} | ${data.message}`,
                        type: 'error'
                    }))
                }
            })
        })
        .catch(error => {
            dispatch(showTicker({
                message: error.message,
                type: 'error'
            }))
        });
})



// function for creating a master copy
export const CreateMasterBill = (data, AuthToken, dispatch, navigate, setCreateBillLoading, PlantID) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    // console.log(data);
    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(`${SERVER_ADDRESS}/industry/bill/master/create?plantID=${PlantID}`, requestOptions)
        .then(response => {
            response.json().then((data) => {
                if (response.ok) {
                    dispatch(showTicker({
                        message: data.message,
                        type: 'success'
                    }))
                    navigate(DrawerRoutes.Reports.MasterBills) // after adding a bill, navigating to the main pge of the master bills page
                    setTimeout(() => { navigate(0) }, 1000); // refreshing the page after navigating the main pge of the master bills page
                }
                else {
                    dispatch(showTicker({
                        message: `${response.statusText} | ${data.message}`,
                        type: 'error'
                    }))
                }
                setCreateBillLoading(false)
            })
        })

        .catch(error => {
            dispatch(showTicker({
                message: error.message,
                type: 'error'
            }))
            setCreateBillLoading(false)
        });
}




const MasterCopiesSlice = createSlice({
    name: 'BillsMasterCopiesFetching',
    initialState: {
        Loading: status.IDLE,
        Copies: [],
        Types: []
    },
    extraReducers: {
        [GetMasterCopies.pending]: (state) => {
            state.Loading = status.LOADING
        },
        [GetMasterCopies.fulfilled]: (state, action) => {
            state.Loading = status.IDLE
            state.Copies = action.payload
        },
        [GetMasterCopies.rejected]: (state) => {
            state.Loading = status.ERROR
        },

        // extra reducers for getting bill types
        [GetMasterBillTypes.pending]: (state) => {
            state.Loading = status.LOADING
        },
        [GetMasterBillTypes.fulfilled]: (state, action) => {
            state.Loading = status.IDLE
            state.Types = action.payload
        },
        [GetMasterBillTypes.rejected]: (state) => {
            state.Loading = status.ERROR
        },
    }
})

export default MasterCopiesSlice.reducer;