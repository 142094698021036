import { onAuthStateChanged } from "@firebase/auth"
import { FirebaseAuthVariable } from "../../../Firebase/firebase"
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { AuthenticationRoutes } from "../routes"
import PlantSelectionSuperAdmin from "../../../UserAuthentication/PlantSelectionSuperAdmin"

// Protected route for the Dasboard Component, By OnAUthChange function we are checking the current loged in user and if there are'nt any user login it will redirect to user selection component
export default function LoginProtectedRoute({ children }) {

    const navigate = useNavigate()
    const [user, setuser] = useState()
    const [isSuperAdmin, setisSuperAdmin] = useState(false)

    const SuperAdmin = "amverma1204a@gmail.com" // setting up SuperAdmin email, if this user logged in then we have to redirect him to plant selection component.
    const storedPlantID = localStorage.getItem('superAdminSelected_PlantID'); // this is for checking that if the superadmin is already selected a plant, then it is not important to ask him for a plant selection.
    const isPlantSelected = storedPlantID !== undefined && storedPlantID !== null && storedPlantID !== "null" ? true : false;

    console.log(isPlantSelected, storedPlantID);
    // Checking if there any user logged in 
    useEffect(() => {
        // checking the current user user the firebase and storing it into the usestate. 
        const unsubscribe = onAuthStateChanged(FirebaseAuthVariable, (currentuser) => {

            if (currentuser !== null && currentuser !== undefined) {
                setuser(currentuser)
                setisSuperAdmin(currentuser?.email === SuperAdmin)
            }
            else {
                setuser(null)
                setisSuperAdmin(false)
            }

            //checking if the user logged in is a super admin or not. 
            // if (currentuser?.email === SuperAdmin) { setisSuperAdmin(true) }
            // else { setisSuperAdmin(false) }
            // setuser(currentuser)
        });
        return () => { unsubscribe() };
    }, []);


    // Checking if there any user logged in 
    if (user === undefined || user === null) {
        navigate(AuthenticationRoutes.UserSelection) // navigating the app to the userselection page if no user is logged in. 
        return null
    }

    if (isSuperAdmin && !isPlantSelected) {
        return <PlantSelectionSuperAdmin />
    }

    return children // return the children if a user is logged in. here childeren will be MainLayout.js


}
