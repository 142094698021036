import { Badge, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetIndustries } from '../../../Redux/Slices/Industry/GetIndustriesSlice';
import { FileCopy, Receipt, RequestPage, Send, Undo } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { DrawerRoutes } from '../../Routes/routes';
import { status } from '../../../Redux/Slices/userSlice';
import { CircularLoader, SomethingWentWrong } from '../../UI Components/Basic';
import { GetBillReciptApprovalRequests } from '../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/PaymentsSlice';
import { NOTICE_PERMISSIONS } from '../../../Roles/Roles';

// this component holds all the industries. from here we can generate bills for the industries and can check the previous bills of the respective industry and also can send notice to them.
export default function BillsPayments() {

    // const [ActiveSection, setActiveSection] = useState(1) // For storing the current section of the page
    const IndustryData = useSelector((state) => state.INDUSTRIES.Industries); // Getting industries from redux store
    const AuthToken = useSelector((state) => state.USER_DETAILS.AuthToken); // logged in user auth token
    const PlantID = useSelector((state) => state.USER_DETAILS.PlantID); // logged in user auth token
    const Loading = useSelector((state) => state.INDUSTRIES.Loading); // loading true when industries is loading.
    const dispatch = useDispatch(); // For dispatching actions
    const navigate = useNavigate() //for navigation
    let searchTimeout; // FOr debouncing purpose
    const UserRole = useSelector(state => state.USER_DETAILS.UserRole)
    const accessLevel = useSelector(state => state.USER_DETAILS.UserAccessLevel)
    const departments = useSelector(state => state.USER_DETAILS.UserDetails?.departmentAccess)
    const NoticePermissions = NOTICE_PERMISSIONS(UserRole, accessLevel, departments)

    useEffect(() => {
        if (!IndustryData?.industries && Loading !== status.LOADING && Loading !== status.ERROR) {
            dispatch(GetIndustries({ AuthToken: AuthToken, PlantID: PlantID }))
        }
    }, [])



    const [searchResults, setSearchResults] = useState(IndustryData?.industries); // For saving filtered results if anyone applies a filter or search key
    // this small useffect set data to search results for mapping into the table. this is important because whenever the page loads, if the data in redux is change then the change should reflex in this table also, and direct changes is not reflecting because we are not directly mapping the redux state, we saving the redux state into filtered results or search resutlts and then mapping results in to the table.
    useEffect(() => { setSearchResults(IndustryData?.industries) }, [IndustryData])
    // function for searching the indstries with searchbar.
    const handleSearch = (searchQuery) => {
        const lowercaseQuery = searchQuery.toLowerCase();// Convert the search query to lowercase for case-insensitive search
        const filteredResults = IndustryData?.industries?.filter( // Filter inventory items based on name or code containing the search query
            ind =>
                ind?.data?.companyName?.toLowerCase().includes(lowercaseQuery) ||
                ind?.data?.address?.toLowerCase().includes(lowercaseQuery) ||
                ind?.data?.email?.toLowerCase().includes(lowercaseQuery)
        );
        setSearchResults(filteredResults); // Update the state with the search results
    };

    // implementing debouncing in the search bar
    const debouncedSearch = (query) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            handleSearch(query);
        }, 1000);
    };

    // const User = useSelector(state => state.USER_DETAILS)
    const Requests = useSelector(state => state.PAYMENT_APPROVAL_REQUESTS.Requests)
    const ReqLoading = useSelector(state => state.PAYMENT_APPROVAL_REQUESTS.Loading)
    // const isUserAllowedThis = (User.UserRole === "admin" && User.UserAccessLevel === 1) || (User.UserRole === "superadmin" && User.UserAccessLevel === 0)
    const NoOfReqsts = Requests?.requests?.length !== undefined && Requests?.requests?.length !== 0 ? Requests?.requests?.length : 0

    // fetching the payment requests here becuase we want to show the badge in the button. 
    useEffect(() => {
        dispatch(GetBillReciptApprovalRequests({ AuthToken: AuthToken, PlantID: PlantID }))
    }, [])


    return <div>
        {/* <button onClick={() => { dispatch(GetIndustries(AuthToken)) }}>Getindustries</button> */}
        <div className="PAGE_HEADER">
            {/* Name of the page, will show in lwft of the header */}
            <h3>Bills & Payments</h3>
            <Stack direction="row" spacing={1}>
                <Badge badgeContent={NoOfReqsts} color='PrimaryRed' anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                    <Button variant='contained' color='SecondaryBtn' startIcon={<RequestPage sx={{ width: '16px' }} />} onClick={() => { navigate(DrawerRoutes.Reports.PaymentReceipts) }} disableElevation>Payment Approval requests</Button>
                </Badge>
                <Button variant='contained' startIcon={<FileCopy sx={{ width: '16px' }} />} onClick={() => { navigate(DrawerRoutes.Reports.MasterBills) }} disableElevation>Master Bills</Button>
            </Stack>
        </div>

        {/* this div will contain all the content of the page */}
        <div className="Industries_Body_Container">

            <Stack direction="row" justifyContent="space-between" p={2}>
                <TextField placeholder='Search Industries' size='small' onChange={(e) => debouncedSearch(e.target.value)} />
                {/* <Button variant='contained' startIcon={<Receipt sx={{ width: '16px' }} />} onClick={() => { navigate(DrawerRoutes.Reports.GenerateMasterBill) }} disableElevation>Create Master Bill</Button> */}
            </Stack>

            <div className="INDUSTRY_TABLE_CONTAINER">
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ paddingLeft: "30px", width: '90px' }}>S No.</TableCell>
                                <TableCell>Industries</TableCell>
                                <TableCell sx={{ paddingRight: "30px" }} align='right'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Loading === status.IDLE ?
                                    IndustryData.industries !== undefined
                                        ? searchResults?.map((ind, index) => {
                                            return (
                                                <TableRow key={ind.id}>
                                                    <TableCell sx={{ paddingLeft: "30px" }}>{index + 1}</TableCell>
                                                    <TableCell
                                                        // onClick={() => { navigate(`${DrawerRoutes.Reports.Dedicated_industry_bills}/${ind.id}`) }} // Passing the industry id also for fetching the bills on target page.
                                                        onClick={() => { navigate(`/reports/industry-bills/${ind.id}`) }} // Passing the industry id also for fetching the bills on target page.
                                                    >
                                                        <Stack direction="column">
                                                            {ind.data.companyName}
                                                            <p className="simpleParagraph">{`${ind.data.address.slice(0, 30)}...`}</p>
                                                        </Stack>
                                                    </TableCell>
                                                    {/* action buttons for specific industry */}
                                                    <TableCell sx={{ paddingRight: "26px" }} align='right'>
                                                        <Button variant='contained'
                                                            sx={{ height: '32px' }}
                                                            color='PrimaryBlue'
                                                            startIcon={<Receipt sx={{ width: '16px' }} />}
                                                            onClick={() => { navigate(`/reports/generate-bill/${ind.id}`) }}
                                                            disableElevation
                                                        >Generate Bill</Button> &nbsp;
                                                        <Button
                                                            variant='contained'
                                                            sx={{ height: '32px' }}
                                                            color='success'
                                                            startIcon={<Undo sx={{ width: '16px' }} />}
                                                            onClick={() => { navigate(`/reports/industry-bills/${ind.id}`) }}
                                                            disableElevation
                                                        >View Previous Bills</Button> &nbsp;
                                                        <Button
                                                            variant='contained'
                                                            sx={{ height: '32px' }}
                                                            color='warning'
                                                            disabled={!NoticePermissions.isAllowedToSendNotices}
                                                            startIcon={<Send sx={{ width: '16px' }} />}
                                                            onClick={() => { navigate(DrawerRoutes.SendNotice, { state: { noticeTo: ind.id } }) }}
                                                            disableElevation
                                                        >Send Notice</Button> &nbsp;
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        : <div className='absCenter'><p className="simpleParagraph">No Industries found</p></div>// if data is undefined
                                    : Loading === status.LOADING ? <CircularLoader /> // if data in loading state
                                        : Loading === status.ERROR ? <div className='absCenter'><SomethingWentWrong /></div> // If something went wrong in fetching data.
                                            : ''
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <br /><br /><br />
            </div>
        </div>
    </div>
}
