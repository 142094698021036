import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showTicker } from "../TickerSlice";
import { status } from "../userSlice";
import { SERVER_ADDRESS } from "../../../Firebase/Variables";

// function for getting industry data at plant side. need authtoken of the admin 
export const GetIndustries = createAsyncThunk('GetIndustries', async ({ AuthToken, PlantID }, { dispatch, rejectWithValue, fulfillWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry?plantID=${PlantID}`, requestOptions)
        const data = await response.json()
        if (!response.ok) {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(response.status)
        }
        return fulfillWithValue(data)
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})


// function for getting a single industry data at industry side, needed industry auth token for completion
export const GetIndustryByID = createAsyncThunk('GetIndustryByID', async ({ AuthToken, IndustryID }, { dispatch, rejectWithValue, fulfillWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry`, requestOptions)
        const data = await response.json()
        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(response.status)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})



// function for getting single industry data at plant side needed plant authtoken and industry id. 
export const GetIndustryByIDPlantSide = createAsyncThunk('GetIndustryByIDPl', async ({ AuthToken, IndustryID, setLoading, plantID }, { dispatch }) => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry?id=${IndustryID}&plantID=${plantID}`, requestOptions)
        const data = await response.json()
        if (response.ok) {
            setLoading(false)
            return data
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            setLoading(false)
            return response.statusText
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        setLoading(false)
        return error.message
    }

})



// function for getting name and address of the industry by industryid at plant side. endpoint used in notices, payment approval section where we have to show only the name of the industry by industry id. 
export const GetIndName = createAsyncThunk('getIndName', async ({ AuthToken, IndustryID, setLoading, PlantID }, { dispatch }) => {

    console.log(AuthToken, IndustryID, setLoading);

    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/name/${IndustryID}?plantID=${PlantID}`, requestOptions)
        const data = await response.json()
        if (response.ok) {
            console.log(data);
            setLoading(false)
            return data
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            setLoading(false)
            return response.statusText
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        setLoading(false)
        return error.message
    }
})

const IndustrySlice = createSlice({
    name: 'IndustrySlice',
    initialState: {
        Industries: [],
        Loading: status.IDLE
    },
    reducers: {
        deleteIndustryRedux(state, action) {
            const industryId = action.payload;
            state.Industries.industries = state.Industries.industries.filter(ind => ind.id !== industryId);
        }
    },
    extraReducers: {
        [GetIndustries.pending]: (state) => {
            state.Loading = status.LOADING
        },
        [GetIndustries.fulfilled]: (state, action) => {
            state.Loading = status.IDLE
            state.Industries = action.payload
        },
        [GetIndustries.rejected]: (state) => {
            state.Loading = status.ERROR

        }
    }
})

export const { deleteIndustryRedux } = IndustrySlice.actions;
export default IndustrySlice.reducer;