import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { Link_Container_Box } from './Drawer';
import { DropdownButtonIcon } from '../../assets/assets';


export default function Link_Container_Dropdown(props) {

    const DynamicHeight = `${56 * (props.selections.length + 1)}px` //Dynamically adjust height of dropdown.
    const [toggle, settoggle] = useState(false); // Storing state of the dropdown.
    const closeRef = useRef(null); //reference to close the dropdown on clicking outside.

    // for closing dropdown on click outside anywhere
    useEffect(() => {
        const handleOutsideClick = (event) => { if (toggle && closeRef.current && !closeRef.current.contains(event.target)) { settoggle(false) } }
        document.addEventListener('click', handleOutsideClick, true);
        return () => { document.removeEventListener('click', handleOutsideClick, true) }
    }, [toggle]);


    return (
        <div
            className="Link_Container_Dropdown"
            ref={closeRef}
            // onClick={() => { settoggle(true) }}
            style={{ height: toggle ? DynamicHeight : "56px" }}
        >
            {/* dropdown icon */}
            <img src={DropdownButtonIcon} alt="dropdownicon" className='Dropdown_Icon' style={{ transform: !toggle ? "rotate(0deg)" : "rotate(180deg)" }} />
            <div className="Link_Container_Box" onClick={() => { settoggle(!toggle) }} style={{ backgroundColor: toggle ? 'var(--Drawer_Active_blue)' : 'var(--Drawer_Blue)' }}>
                {props.SelectorIcon}&nbsp;&nbsp;&nbsp;&nbsp;{props.SelectorName}
            </div>

            {toggle ? <div style={{ paddingLeft: "15px", backgroundColor: toggle ? 'var(--Drawer_Active_blue)' : 'var(--Drawer_Blue)' }}>
                {
                    props.selections.map((selector) => {
                        return <Link_Container_Box key={selector.route} icon={selector.icon} route={selector.route} Name={selector.Name} action={selector.onclickAction} link={selector.link} />
                    })
                }
            </div> : ''}




        </div>
    )
}
