
// this component will contain all the bills of a specific industry in industry side login. 
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button, Chip, Stack } from '@mui/material'
import { Download, Receipt } from '@mui/icons-material'
import { DataGrid } from '@mui/x-data-grid'
import { GetIndustryBills } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/IndustryBillsSlice'
import { CircularLoader, SideDrawer, SomethingWentWrong } from '../../../UI Components/Basic'
import { downloadBill, setDownloadBillID } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/BillGenerationSlice'
import { status } from '../../../../Redux/Slices/userSlice'
import LoadingButton from '@mui/lab/LoadingButton';
import BillSideDetails from './BillSideDetails'

export default function IndustrySideBills() {
  // const industryID  =  useSelector(state=>state.USER_DETAILS.id)  // getting industry id from redux store
  const dispatch = useDispatch() // For dispatching actions
  const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)  // auth token of the user 
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)  // auth token of the user 
  const bills = useSelector(state => state.BILLS.DedicatedBills)
  const Loading = useSelector(state => state.BILLS.loading) // Loading of the bills of the industry
  const navigate = useNavigate() // for navigating
  const industryID = useSelector(state => state.USER_DETAILS.DocID);
  const DownloadBillID = useSelector(state => state.BILL_GENERATION.downloadBillID)
  const UserDetails = useSelector(state => state.USER_DETAILS.UserDetails)
  // coloums of the table

  const downloadHandler = (billid) => {
    dispatch(setDownloadBillID(billid))
    dispatch(downloadBill({ industryid: industryID, industryName: UserDetails.companyName, billid: billid, AuthToken: AuthToken }))
  }

  const updateBillDetails = (details) => {
    setBillDetails(details);
  };

  const columns = [
    { field: "id", headerName: "S No.", type: "number", width: 70, sortable: false },
    { field: "billDesc", headerName: "Bill", width: 200, sortable: false },
    { field: "date", headerName: "Date", width: 180, sortable: false },
    { field: "quotationNo", headerName: "Q. No.", width: 180, sortable: false },
    { field: "amount", headerName: "Amount (₹)", width: 120, sortable: false },
    {
      field: "status", headerName: "Status", width: 100,
      // checking value and based on that value, rendering the chip of paid and not paid
      renderCell: (params) => (
        params.value ? <Chip label="Paid" size='small' color='success' sx={{ fontSize: '12px' }} />
          : <Chip label="Not Paid" size='small' color='PrimaryRed' sx={{ fontSize: '12px' }} />
      )
    },
    {

      // important buttons in the coloums of the bills
      field: "actions", headerName: "Actions", sortable: false, width: 400,
      // rendering action buttons
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <LoadingButton
            loading={DownloadBillID === params.value.id}
            loadingPosition="start"
            variant='contained'
            sx={{ height: '32px' }}
            startIcon={<Download sx={{ width: '16px' }} />}
            disableElevation
            onClick={() => { downloadHandler(params.value.id) }}
          >Download Bill</LoadingButton>
          <Button
            variant='outlined'
            sx={{ height: '32px' }}
            startIcon={<Receipt sx={{ width: '16px' }} />}
            onClick={() => [setBillDetails({ // Setting the bills details in to the side drawer state for showing it into the sidedrawer
              isSideDrawerOpen: true,
              activeBillId: params.value.id,
              activeBillData: params.value.data
            })]}
            disableElevation
          >View Bill</Button>
        </Stack>
      )
    },
  ];


  // function for opening the bill details if someone clicks on the row of the bill, opens only on when someone clicks on the specific cells of the bill row. 
  const handleCellClick = (params) => {
    // console.log(params);
    if (params.field === "billDesc" || params.field === "date" || params.field === 'quotationNo' || params.field === "amount") {
      setBillDetails({ // Setting the bills details in to the side drawer state for showing it into the sidedrawer
        isSideDrawerOpen: true,
        activeBillId: params.row.actions.id,
        activeBillData: params.row.actions.data
      })
    }
  }

  // fetching the bills of the industry via industry id. Checking also if the bills are already fetched, then do not fetch again. 
  useEffect(() => {
    // Check if bills for the current industry are already fetched
    if (!bills?.bills && Loading !== status.LOADING && Loading !== status.ERROR) {
      // If not fetched, dispatch an action to fetch bills for this industry
      dispatch(GetIndustryBills({ AuthToken: AuthToken, PlantID: PlantID }))
    }
  }, [bills]);


  // Function to convert timestamp string to milliseconds
  function getTimestampInMilliseconds(timestamp) {
    return new Date(timestamp).getTime();
  }

  //function for sorting the bills according to date
  const getSortedBills = (bills) => {
    const newbills = bills.slice().sort((a, b) => getTimestampInMilliseconds(b.data.dateCreated) - getTimestampInMilliseconds(a.data.dateCreated));
    return newbills
  }

  // a function to render bills or a message based on the loading and error state
  const renderBills = () => {
    // Check if bills for the current industry are being loaded
    if (Loading === status.LOADING) {
      return <CircularLoader />; // Show loader while loading
    } else if (Loading === status.ERROR) {
      return <SomethingWentWrong />; // Show error message if loading failed
    } else {
      // Render bills if they exist
      return (
        <div>
          {
            !!bills?.bills ?
              <DataGrid
                onCellClick={handleCellClick}
                rowSelection={false}
                rows={getSortedBills(bills?.bills).map((bill, i) => {
                  return (
                    {
                      id: i + 1,
                      billDesc: bill.data.description,
                      date: convertGMTtoISTAndFormat(bill.data.dateCreated),
                      quotationNo: bill.data.quotationNo,
                      amount: `₹ ${bill.data.amount.toFixed(2)}`,
                      status: bill.data.isPaid,
                      actions: bill
                    }
                  )
                })}
                columns={columns}
                pageSize={2}
              />
              : <SomethingWentWrong />
          }

        </div>
      );

    }
  };



  const [ReRender, setReRender] = useState(false)

  // state for holding the current state of the side drawer
  const [BillDetails, setBillDetails] = useState({
    isSideDrawerOpen: false,
    activeBillId: '',
    activeBillData: []
  })


  // function for showing the all bill information in the side drawer.
  function BillDetailsDrawer() {
    return <SideDrawer Open={BillDetails.isSideDrawerOpen} handle={setBillDetails}>
      <BillSideDetails // creating a layout for the bill details seperately for more clean code
        billID={BillDetails.activeBillId}
        billData={BillDetails.activeBillData}
        updateBillDetails={updateBillDetails}

        ReRender={ReRender}
        setReRender={setReRender}
      />
    </SideDrawer>
  }



  // from the functional Component, returning the main jsx where all the bills of the dedicated industry will render
  return (
    <div>
      <div className="PAGE_HEADER">
        {/* Name of the page, will show in lwft of the header */}
        <h3>Your Bills</h3>
        {/* <button onClick={() => { dispatch(GetIndustryBills(AuthToken)) }}>get bills</button> */}
      </div>


      {/* table of the bills */}
      <div className="Industries_Body_Container">
        {/* rendering the bills */}
        {renderBills()}
        <br />
        <br />
      </div>

      {/* rendering the side drawer which contains the details of the bills */}
      {BillDetailsDrawer()}
    </div>
  )
}


// function for convert and format the date 
export function convertGMTtoISTAndFormat(dateString) {
  // Convert GMT to IST
  const dateGMT = new Date(dateString);
  const ISTOffset = 330; // IST offset in minutes
  const dateIST = new Date(dateGMT.getTime() + (ISTOffset * 60 * 1000));

  // Format date to "dd/mm/yyyy"
  const day = dateIST.getDate().toString().padStart(2, '0');
  const month = (dateIST.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const year = dateIST.getFullYear();

  // console.log(day, month, year);
  return `${day}/${month}/${year}`;
}
