import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showTicker } from "../TickerSlice";
import { status } from "../userSlice";
import { SERVER_ADDRESS, LOCAL_SERVER_ADDRESS } from "../../../Firebase/Variables";

export const GetChambers = createAsyncThunk('GetChambers', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const { chamberid, UserToken, PlantID } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        let query = `${SERVER_ADDRESS}/plant/chamber/?plantID=${PlantID}`
        if(chamberid) query += `&chamber=${chamberid}`

        const response = await fetch(query, requestOptions)

        if (response.ok) {
            const data = await response.json()

            if(chamberid){
                dispatch(setSelectedChamber(data.chamber[0]))
            }

            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }
    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

export const GetTagNos = createAsyncThunk('GetTagNos', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const { UserToken, PlantID } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${SERVER_ADDRESS}/plant/instrument/tagnos?plantID=${PlantID}`, requestOptions)

        if (response.ok) {
            const data = await response.json()
            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }
    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

export const AddChamberData = createAsyncThunk('AddChamber', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const { chamber, UserToken, PlantID } = data

    const chamberParams = {}

    for (let i = 0; i < chamber.params.length; i++) {
        const param = chamber.params[i];
        chamberParams[param.key] = param.value
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
        "chamberName": chamber.name,
        "chamberParams": chamberParams
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/plant/chamber/add?plantID=${PlantID}`, requestOptions)

        if (response.ok) {
            const data = await response.json()
            dispatch(showTicker({
                message: "Chamber Added Successfully",
                type: 'success'
            }))

            dispatch(GetChambers({ UserToken, PlantID }))

            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }

    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

export const AddIO = createAsyncThunk('AddIO', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const { io, chamberid, type, UserToken, PlantID } = data

    const ioParams = {}

    for (let i = 0; i < io.params.length; i++) {
        const param = io.params[i];
        ioParams[param.key] = param.value
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
        "name": io.name,
        "params": ioParams
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/plant/chamber/add/io?plantID=${PlantID}&chamberid=${chamberid}&type=${type}`, requestOptions)

        if (response.ok) {
            const data = await response.json()
            
            dispatch(showTicker({
                message: data.message,
                type: 'success'
            }))
            
            dispatch(GetChambers({ chamberid: chamberid, UserToken, PlantID }))
            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }

    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

export const UpdateChamberPosition = createAsyncThunk('UpdateChamberPosition', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const { 
        chamberid, 
        newPosition,
        UserToken,
        PlantID
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
        "chamberID": chamberid,
        "newPosition": newPosition
    });

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        let query = `${SERVER_ADDRESS}/plant/chamber/update/position?plantID=${PlantID}`

        const response = await fetch(query, requestOptions)

        if (response.ok) {
            const data = await response.json()

            dispatch(showTicker({
                message: "Chambers Swapped Successfully",
                type: 'success'
            }))

            dispatch(GetChambers({ UserToken, PlantID }))

            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }

    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

export const UpdateChamberParams = createAsyncThunk('UpdateChamberParams', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const { 
        chamberid, 
        type, 
        key, 
        value, 
        index, 
        inletIndex, 
        outletIndex, 
        operation, 
        name,
        UserToken, 
        PlantID 
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
        "params": { key: key, value: value },
        "name": name || ""
    });

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        let query = `${SERVER_ADDRESS}/plant/chamber/update/params?plantID=${PlantID}&chamberid=${chamberid}&type=${type}&operation=${operation}`

        if(String(index)) query += `&index=${index}`

        if(String(inletIndex)) query += `&inletIndex=${inletIndex}`

        if(String(outletIndex)) query += `&outletIndex=${outletIndex}`

        const response = await fetch(query, requestOptions)

        if (response.ok) {
            const data = await response.json()

            dispatch(showTicker({
                message: data.message,
                type: 'success'
            }))

            dispatch(GetChambers({ chamberid: chamberid, UserToken, PlantID }))

            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }

    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

export const DeleteChamberIO = createAsyncThunk('DeleteChamberIO', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const { 
        chamberid, 
        type, 
        inletIndex, 
        outletIndex, 
        UserToken, 
        PlantID 
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        let query = `${SERVER_ADDRESS}/plant/chamber/delete/${chamberid}?plantID=${PlantID}&type=${type}`

        if(inletIndex !== undefined) query += `&inletIndex=${inletIndex}`

        if(outletIndex !== undefined) query += `&outletIndex=${outletIndex}`

        const response = await fetch(query, requestOptions)

        if (response.ok) {
            const data = await response.json()

            dispatch(showTicker({
                message: data.message,
                type: 'success'
            }))

            if(type === 0)
                dispatch(GetChambers({ UserToken, PlantID }))
            else 
                dispatch(GetChambers({ chamberid: chamberid, UserToken, PlantID }))

            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }

    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

const chamberManagementSlice = createSlice({
    name: 'chamber_management',
    initialState: {
        TagNosList: {
            count: 0,
            data: []
        },
        Chambers: {
            chamber: []
        },
        SelectedChamber: {
            id: "",
            data: {}
        },
        Loading: status.IDLE,
        GetChamberLoading: status.IDLE,
        AddLoading: status.IDLE,
        ModalState: 0
    },
    reducers: {
        setModalState: (state, action) => {
            state.ModalState = action.payload
        },
        setSelectedChamber: (state, action) => {
            state.SelectedChamber = action.payload
        }
    },
    extraReducers: {
        // Extra reducers for handling fetching the tagnos list
        [GetTagNos.pending]: (state, action) => {
            state.Loading = status.LOADING
        },
        [GetTagNos.fulfilled]: (state, action) => {
            state.TagNosList = action.payload
            state.Loading = status.IDLE
        },
        [GetTagNos.rejected]: (state, action) => {
            state.Loading = status.ERROR
        },

        // Extra reducers for handling adding chamber
        [AddChamberData.pending]: (state, action) => {
            state.AddLoading = status.LOADING
        },
        [AddChamberData.fulfilled]: (state, action) => {
            state.ModalState = 0
            state.AddLoading = status.IDLE
        },
        [AddChamberData.rejected]: (state, action) => {
            state.AddLoading = status.ERROR
        },

        // Extra reducers for handling adding io
        [AddIO.pending]: (state, action) => {
            state.AddLoading = status.LOADING
        },
        [AddIO.fulfilled]: (state, action) => {
            state.ModalState = 0
            state.AddLoading = status.IDLE
        },
        [AddIO.rejected]: (state, action) => {
            state.AddLoading = status.ERROR
        },

        // Extra reducers for handling get chamber
        [GetChambers.pending]: (state, action) => {
            state.GetChamberLoading = status.LOADING
        },
        [GetChambers.fulfilled]: (state, action) => {
            state.Chambers = action.payload
            state.GetChamberLoading = status.IDLE
        },
        [GetChambers.rejected]: (state, action) => {
            state.GetChamberLoading = status.ERROR
        },

        // Extra reducers for handling update chamber params
        [UpdateChamberParams.pending]: (state, action) => {
            state.AddLoading = status.LOADING
        },
        [UpdateChamberParams.fulfilled]: (state, action) => {
            state.ModalState = 0
            state.AddLoading = status.IDLE
        },
        [UpdateChamberParams.rejected]: (state, action) => {
            state.AddLoading = status.ERROR
        },

        // Extra reducers for handling update chamber position
        [UpdateChamberPosition.pending]: (state, action) => {
            state.AddLoading = status.LOADING
        },
        [UpdateChamberPosition.fulfilled]: (state, action) => {
            state.ModalState = 0
            state.AddLoading = status.IDLE
        },
        [UpdateChamberPosition.rejected]: (state, action) => {
            state.AddLoading = status.ERROR
        },

        // Extra reducers for handling delete chamber io
        [DeleteChamberIO.pending]: (state, action) => {
            state.AddLoading = status.LOADING
        },
        [DeleteChamberIO.fulfilled]: (state, action) => {
            state.ModalState = 0
            state.AddLoading = status.IDLE
        },
        [DeleteChamberIO.rejected]: (state, action) => {
            state.AddLoading = status.ERROR
        }
    }
})

export const { setModalState, setSelectedChamber } = chamberManagementSlice.actions

export default chamberManagementSlice.reducer