import React from 'react'
import './AuthStyles/UserSelection.css'
import { useNavigate } from 'react-router-dom'
import { AuthenticationRoutes } from '../Components/Routes/routes'

export default function UserSelection() {

    const navigate = useNavigate()

    return (
        <div className="UserSelectionContainer">
            <div className="LeftSubContainer">
                <div className="DivTop">
                    <h1 className="Title">
                        CETP MONITORING SYSTEM
                    </h1>
                    <div className="simpleParagraph">
                        Choose "I am from plant side" for plant representatives, or "I am from industry side" for industry personnel.
                    </div>
                </div>
                <div className="DivBottom">
                    <button className='MainButton' onClick={() => { navigate(AuthenticationRoutes.PlantLogin) }}>I am from Plant side</button>
                    <button className='MainButton' onClick={() => { navigate(AuthenticationRoutes.IndustryLogin) }}>I am from Industry Side</button>
                </div>
            </div>
            <div className="RightSubContainer">
                <div className="Heading"> Online centralized plant management and optimization</div>
                <div className="Content">An online continuous effluent monitoring software that measures and analyzes water quality in real-time to ensure compliance with regulatory standards. Specially trained to suggest measures for optimized operation of STP/CETP plants.</div>
                {/* <div className="WhiteRectangle"></div> */}
            </div>
        </div>
    )
}



