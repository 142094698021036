import { Add, ArrowForward, Close, Delete, Edit } from '@mui/icons-material'
import { Box, Button, Divider, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ShowModal } from '../../../UI Components/Basic'
import SelectMasterCopy from './SelectMasterCopy'
import AddGoodDetails from './AddGoodDetails'
import { debouncedSetDescription, deleteGood } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/BillGenerationSlice'
import { useDispatch, useSelector } from 'react-redux'
import EditGoods from './EditGoods'
import { typeOfGoods } from './AddGoodFunctions'



// this component is the body of the bill you saw in the generate bill section. It consist major code for adding goods in the bill, it consist functionality for selecting master bills, for that we have to fetch master bills from the api, and after seleting master bill, we have to enter the detials of the goods. and after entering the deials of the good, validate it and add to our good list. 
export default function BillBody() {

    // this state handels the modal when user adding a new good.
    const [ModalState, setModalState] = useState({
        isModalOpen: false, // This showing the modal open or close state
        innerModalState: 0 // Inner modal state specifies the which step is currently showing.0 for selecting bill type, 1 for selecting master copies and 2 for entering details
    })

    // this state handels the modal when user is editing any existing good.
    const [EditingModalState, setEditingModalState] = useState({
        isEditingModalOpen: false, // This holds the modal open or close state
        innerModalState: 1, // Inner modal state specifies the which step is currently showing. 
        targetElement: '' // element or good which we are going to edit.
    })

    const dispatch = useDispatch() // FOr dispatching actions 
    const [SelectedBill, setSelectedBill] = useState('') // it will store the bill id of the selected bill from the list of Master Bills.
    const [TypeOfGood, setTypeOfGood] = useState('') // it will store the type of of the good we are going to add. either it is a water consumption good or a water effluent discharge good. 
    const goods = useSelector(state => state.BILL_GENERATION.goods) // taking goods from the redux store.

    // function for handling the closing of the modal, as we can't close the modal directly. we have to warn user if he'll close that modal his entered information WILL be discarded.
    const handelModalClose = () => {
        setModalState({ isModalOpen: false, innerModalState: 0 })
    }

    // function for handling the closing of the editing modal, as we can't close the modal directly. we have to warn user if he'll close that modal his entered information WILL be discarded.
    const handelEditingModalClose = () => {
        setEditingModalState({ isEditingModalOpen: false, innerModalState: 1 })
    }

    // function for formatting the timestamp 
    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to get the correct month (0-indexed)
        const year = date.getFullYear().toString().slice(-2); // Extracting the last two digits of the year
        return `${month}/${year}`;
    }


    return (
        <div>
            {/* this jsx will contain all the goods which are going to add in the list, also with delete editing functionalty to a good. */}
            <Box component="section" sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', backgroundColor: 'var(--Tertiary_Blue)' }}>
                <TextField label="Title" placeholder='Title of the bill' sx={{ marginBottom: '10px' }} color='PrimaryBlue' onChange={(e) => { debouncedSetDescription(dispatch, e.target.value) }} fullWidth />
                <Button variant='outlined' startIcon={<Add />} onClick={() => { setModalState({ isModalOpen: true, innerModalState: 0 }) }} fullWidth>Add Goods</Button>
            </Box>


            {/* Goods table, All the added goods will list here.  */}
            <Box sx={{ p: 2 }}>
                <TableContainer>
                    <Table sx={{ minWidth: 900 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ paddingLeft: '20px', fontSize: '12px' }}>Services</TableCell>
                                <TableCell sx={{ fontSize: '12px' }}>HSN/SAC</TableCell>
                                <TableCell sx={{ fontSize: '12px' }}>Cons/Flow</TableCell>
                                <TableCell sx={{ fontSize: '12px' }}>Price(₹)</TableCell>
                                <TableCell sx={{ fontSize: '12px' }}>CGST(%)</TableCell>
                                <TableCell sx={{ fontSize: '12px' }}>CGST Amt.</TableCell>
                                <TableCell sx={{ fontSize: '12px' }}>SGST(%)</TableCell>
                                <TableCell sx={{ fontSize: '12px' }}>SGST Amt.</TableCell>
                                <TableCell sx={{ fontSize: '12px' }}>Amount(₹)</TableCell>
                                <TableCell sx={{ fontSize: '12px', width: "180px" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody sx={{ position: "relative" }}>
                            {
                                goods !== undefined && goods !== null ?
                                    goods.length !== 0 ?
                                        goods.map((good, index) => {
                                            return <TableRow key={index}>
                                                <TableCell sx={{ paddingLeft: '20px', fontSize: '12px' }}>
                                                    {
                                                        good.type === typeOfGoods.WCG ? `${formatTimestamp(good.starting)}-${formatTimestamp(good.ending)}`
                                                            : good.type === typeOfGoods.WFG ? good.flowMonth
                                                                : 'Not Available'

                                                    }
                                                </TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}>{good.HSN_SAC_code}</TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}>
                                                    {
                                                        good.type === typeOfGoods.WCG ? <p>{good.qty} <span style={{ fontSize: '10px' }}>{good.unit}</span>&nbsp;&nbsp;</p>
                                                            : good.type === typeOfGoods.WFG ? <p>{good.flowRate} <span style={{ fontSize: '10px' }}>{good.unit}</span>&nbsp;&nbsp;</p>
                                                                : 'Not Available'
                                                    }
                                                </TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}>{good.goodPrice}</TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}>{good.cgstRate}</TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}>{good.cgstAmount.toFixed(2)}</TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}>{good.sgstRate}</TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}>{good.sgstAmount.toFixed(2)}</TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}>{(good.cgstAmount + good.sgstAmount + ((good.qty + good.flowRate) * good.goodPrice))?.toFixed(2)}</TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}>
                                                    <Button
                                                        size='small'
                                                        variant='contained'
                                                        startIcon={<Edit sx={{ width: '11px' }} />}
                                                        sx={{ height: '28px', fontSize: '10px' }}
                                                        color='warning'
                                                        disableElevation
                                                        onClick={() => { setEditingModalState({ isEditingModalOpen: true, innerModalState: 1, targetElement: index }) }}
                                                    >
                                                        Edit
                                                    </Button>&nbsp;
                                                    <Button
                                                        size='small'
                                                        variant='contained'
                                                        startIcon={<Delete sx={{ width: '11px' }} />}
                                                        sx={{ height: '28px', fontSize: '10px' }}
                                                        color='PrimaryRed'
                                                        disableElevation
                                                        onClick={() => { dispatch(deleteGood(index)) }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        })
                                        : <><br /><br /><br /><br /> <p className="simpleParagraph absCenter" > &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No Goods found. Please add some goods</p></>
                                    : ''
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <br />


            {/* this jsx here will contain a modal, a modal for entering the details of the good which are going to add into the main list. this modal will contain 3 pages. one for selectng the type of the good we are going to add other is for selecting the master bill and last one for entering the details of the goods. For this we made a state which holds the current state of the popup. */}
            {
                //showing modal component only if modal state is opened
                ModalState.isModalOpen ? <ShowModal open={ModalState.isModalOpen} close={handelModalClose} width={ModalState.innerModalState === 1 ? "70%" : ModalState.innerModalState === 2 ? "50%" : ModalState.innerModalState === 0 ? "30%" : '50%'}>
                    {
                        ModalState.innerModalState === 0 ? <SelectTypeOfGood
                            setTypeOfGood={setTypeOfGood} // for selecting the type of the good.
                            TypeOfGood={TypeOfGood} // type of good we selected
                            setModalState={setModalState} // for controlling the state of the modal
                        />
                            :
                            ModalState.innerModalState === 1 ? <SelectMasterCopy
                                SelectedBill={SelectedBill} //  Sending this through props because bill is going to select from the select master copy component and it is required in the add good component, so parently it is created in the bill body component and passed into the both add good and select master bill componentill it will reflect in the next good we are going to add. 
                                setSelectedBill={setSelectedBill} // passing this becuase we have to reset the selected bill after adding good into the main good array. if we don't reset the selected b
                                setModalState={setModalState} // Sending for handling the modal states.
                                TypeOfGood={
                                    TypeOfGood === "Water Consumption good" ? "Water Consumption Bill" :
                                        TypeOfGood === "Water Flow good" ? "Water Flow Bill" :
                                            ""
                                } // passing here for filtering the master bill on basis of the selected good type. channging the values of the type of goods here because in database types of bills are different and in api type of goods are diffrent. so becuase of this we can not filter the type of bill from the type of good. so if the good is of type water consumption then bill should be water consumption bill and same for the water discharge also.
                            />
                                :
                                ModalState.innerModalState === 2 ? <AddGoodDetails
                                    SelectedBill={SelectedBill} //  Sending this through props because bill is going to select from the select master copy component and it is required in the add good component, so parently it is created in the bill body component and passed into the both add good and select master bill componentill it will reflect in the next good we are going to add. 
                                    setSelectedBill={setSelectedBill} // passing this becuase we have to reset the selected bill after adding good into the main good array. if we don't reset the selected b
                                    setModalState={setModalState} // Sending for handling the modal states.
                                    setTypeOfGood={setTypeOfGood} // sending the type of good we selected
                                    TypeOfGood={TypeOfGood} // sending the type of good we selected
                                />
                                    : ''
                    }
                </ShowModal> : ''
            }


            {/* this is the modal for the editing of the good, when soemone edits any existing good, this will be the jsx for the editing */}
            {
                EditingModalState.isEditingModalOpen ? <ShowModal open={EditingModalState.isEditingModalOpen} close={handelEditingModalClose} width={EditingModalState.innerModalState === 1 ? "70%" : "50%"}>

                    <EditGoods
                        setEditingModalState={setEditingModalState} //for handling the modal states.
                        EditingModalState={EditingModalState} // FOr handling the moDAL states
                    />

                </ShowModal>
                    : ''
            }

        </div>
    )
}




function SelectTypeOfGood(props) {
    return <Box>
        <Box className="Modal_heading">
            <Stack>
                <Typography variant='h6'>Please select good type</Typography>
                <Typography variant='caption'>Select the type of the good you want to generate </Typography>
            </Stack>
        </Box>


        <Box p={2}>
            <Typography variant='body2' gutterBottom>Select good type</Typography>
            <Select
                fullWidth
                value={props.TypeOfGood}
                onChange={(e) => { props.setTypeOfGood(e.target.value) }}
            >
                <MenuItem value={"Water Consumption good"}>Water Consumption</MenuItem>
                <MenuItem value={"Water Flow good"}>Effluent Discharge</MenuItem>
            </Select>
        </Box>


        {/* buttons for navigation */}
        <Stack direction="row-reverse" spacing={1} padding="20px" position='sticky' bottom={0} backgroundColor='white' fullWidth>
            <Button
                variant='contained'
                disabled={props.TypeOfGood === ''}
                disableElevation
                endIcon={<ArrowForward />}
                onClick={() => { props.setModalState({ isModalOpen: true, innerModalState: 1 }); }}
            > Proceed</Button>
            <Button
                variant='contained'
                color='SecondaryBtn'
                disableElevation
                startIcon={<Close />}
                onClick={() => { props.setModalState({ isModalOpen: false, innerModalState: 0 }); }}
            > Cancel</Button>
        </Stack>
    </Box>
}