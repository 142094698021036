import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVER_ADDRESS } from "../../../Firebase/Variables";
import { showTicker } from "../TickerSlice";
import { status } from "../userSlice";

// Main function for send industry request to admin 
export function SingleIndustryRegistration(IndustryDetails, dispatch, setLoading, isFromPlant) {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    //stringify object for sending on the server
    var raw = JSON.stringify({
        IC_chamber_install: IndustryDetails.icChamberInstall,
        address: IndustryDetails.address,
        cetp_stp_etp_type: IndustryDetails.plantType,
        companyName: IndustryDetails.companyName,
        consentValidity: IndustryDetails.consentValidity,
        domesticEffluent: IndustryDetails.domesticEffluent,
        h_n_type: IndustryDetails.hnType,
        phase: IndustryDetails.phase,
        email: IndustryDetails.email,
        phoneNo: IndustryDetails.phone,
        pno: IndustryDetails.pNumber,
        pincode: IndustryDetails.pinCode,
        plantID: IndustryDetails.plantID,
        remark: IndustryDetails.remark,
        totalEffluentTradeAndUtility: IndustryDetails.totalEffluentTradeAndUtility,
        unitId: IndustryDetails.unitId
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(`${SERVER_ADDRESS}/public/industry/signup`, requestOptions)
        .then(response => {
            response.json().then((data => {
                if (response.ok) {
                    dispatch(showTicker({
                        message: "Request sent to admin. wait for approval",
                        type: 'success'
                    }))
                }
                else {
                    dispatch(showTicker({
                        message: `${response.statusText} | ${data.message}`,
                        type: 'error'
                    }))
                }
            }))
            setLoading(false)
        })
        .catch(error => {
            dispatch(showTicker({
                message: error.message,
                type: 'error'
            }))
            setLoading(false)
        });
}


export const AddIndustryPlantSide = createAsyncThunk('AddIndustryPlantSide', async ({ IndustryDetails, AuthToken, PlantID, setLoading }, { dispatch }) => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    //stringify object for sending on the server
    var raw = JSON.stringify({
        IC_chamber_install: IndustryDetails.icChamberInstall,
        address: IndustryDetails.address,
        cetp_stp_etp_type: IndustryDetails.plantType,
        companyName: IndustryDetails.companyName,
        consentValidity: IndustryDetails.consentValidity,
        domesticEffluent: IndustryDetails.domesticEffluent,
        h_n_type: IndustryDetails.hnType,
        phase: IndustryDetails.phase,
        email: IndustryDetails.email,
        phoneNo: IndustryDetails.phone,
        pno: IndustryDetails.pNumber,
        pincode: IndustryDetails.pinCode,
        plantID: IndustryDetails.plantID,
        remark: IndustryDetails.remark,
        totalEffluentTradeAndUtility: IndustryDetails.totalEffluentTradeAndUtility,
        unitId: IndustryDetails.unitId
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    
    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/add?plantID=${PlantID}`, requestOptions)
        const data = await response.json()

        if (response.ok) {
            dispatch(showTicker({
                message: data.message,
                type: 'success'
            }))
            setLoading(false);
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            setLoading(false);
        }
    }
    catch (err) {
        dispatch(showTicker({
            message: err.message,
            type: 'error'
        }))
        setLoading(false);
    }

})



// storing the responses of bulk adding industry api to an object, because we are checking string responses to show different types of error in the file. so instead of comparing strings we stroed the strings in the var and comapring the vars.
export const bulkIndustryAddResponses = {
    success: "Industries added successfully",
    errorEntries: "Please check the error in rows and correct them.",
    emptyFile: "Please upload a file",
    invalidFile: "Only .csv files are allowed",
    headerMissing: "Header length mismatch",
    invalidHeader: "Incorrect header value"
}

export const BulkIndustryUploadAPI = createAsyncThunk('BulkIndustryUpload', async ({ AuthToken, file, PlantID }, { dispatch, fulfillWithValue, rejectWithValue }) => {

    // console.log(AuthToken, file, PlantID);
    dispatch(resetState()); // Reset the state before starting the upload

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    const formData = new FormData();
    formData.append('industries_file', file);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS} / industry / bulkadd ? plantID = ${PlantID}`, requestOptions);
        const data = await response.json();

        // console.log(response, data);

        if (response.ok) {
            return fulfillWithValue(data)
        }

        if (!response.ok) {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }));
            return rejectWithValue(data)
            // throw new Error(data.message);
        }
    }
    catch (error) {

        // console.log(error);

        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }));
        return rejectWithValue(error.message);
    }
})

// Define initial state
const initialState = {
    loading: status.IDLE,
    error: null,
    successMessage: null,
    errorObj: []
};

// Create a slice
const industrySlice = createSlice({
    name: 'industry',
    initialState,
    reducers: {
        resetState(state) {
            state.error = null;
            state.successMessage = null;
        }
    },
    extraReducers: {
        [BulkIndustryUploadAPI.pending]: (state) => {
            state.loading = status.LOADING;
        },
        [BulkIndustryUploadAPI.fulfilled]: (state, action) => {
            state.loading = status.IDLE;
            state.successMessage = action.payload.message;
            state.errorObj = [];
        },
        [BulkIndustryUploadAPI.rejected]: (state, action) => {
            state.loading = status.ERROR;
            state.error = action.payload.message;
            state.errorObj = action.payload || [];
        }
    }
});

export const { resetState } = industrySlice.actions;
export default industrySlice.reducer;