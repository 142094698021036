import { createAsyncThunk, createSlice, isRejectedWithValue } from "@reduxjs/toolkit";
import { showTicker } from "../TickerSlice";
import { status } from "../userSlice";
import { SERVER_ADDRESS } from "../../../Firebase/Variables";


// thunk function for getting all consumables in inventory
export const GetInventoryConsumables = createAsyncThunk('GetInventoryItemsCon', async ({ AuthToken, PlantID }, { dispatch, rejectWithValue, fulfillWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${SERVER_ADDRESS}/inventory?type=0&plantID=${PlantID}`, requestOptions)
        const data = await response.json();
        if (!response.ok) {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(response.status)
        }
        return fulfillWithValue(data)
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})


// thunk function for getting all non-consumables in inventory
export const GetInventoryNonConsumables = createAsyncThunk('GetInventoryItemsNonCon', async ({ AuthToken, PlantID }, { dispatch, rejectWithValue, fulfillWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    console.log(PlantID);
    try {
        const response = await fetch(`${SERVER_ADDRESS}/inventory?type=1&plantID=${PlantID}`, requestOptions)
        const data = await response.json();
        if (!response.ok) {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(response.status)
        }
        return fulfillWithValue(data)
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})


const InventorySlice = createSlice({
    name: 'inventory',
    initialState: {
        Consumables: [],
        NonConsumables: [],
        Loading: status.IDLE
    },
    extraReducers: {
        // Extra reducers for handling fetching consumables
        [GetInventoryConsumables.pending]: (state) => {
            state.Loading = status.LOADING;
        },
        [GetInventoryConsumables.fulfilled]: (state, action) => {
            state.Consumables = getSortedItemsByDate(action.payload.items);
            state.Loading = status.IDLE;
        },
        [GetInventoryConsumables.rejected]: (state) => {
            state.Loading = status.ERROR
        },

        // Extra reducers for handling fetching non consumables
        [GetInventoryNonConsumables.pending]: (state) => {
            state.Loading = status.LOADING;
        },
        [GetInventoryNonConsumables.fulfilled]: (state, action) => {
            state.NonConsumables = getSortedItemsByDate(action.payload.items);
            state.Loading = status.IDLE;
        },
        [GetInventoryNonConsumables.rejected]: (state) => {
            state.Loading = status.ERROR;
        }
    }
})

export default InventorySlice.reducer;



// Function to convert timestamp string to milliseconds
function getTimestampInMilliseconds(timestamp) {
    return new Date(timestamp).getTime();
}

//function for sorting the notices according to date
const getSortedItemsByDate = (invItems) => {
    const items = invItems?.slice().sort((a, b) => getTimestampInMilliseconds(b.data.dateCreated) - getTimestampInMilliseconds(a.data.dateCreated));
    const newNotices = { items }
    return newNotices
}