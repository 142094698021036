import React, { useEffect, useState } from 'react'
import { Add, ArrowBack, ArrowDropDown, ArrowDropUp, ArrowForward, Close, Save } from '@mui/icons-material'
import { Box, Button, Collapse, FormControlLabel, IconButton, MenuItem, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Row } from './SelectMasterCopy'
import { format } from 'date-fns'
import { showTicker } from '../../../../Redux/Slices/TickerSlice'
import { calculateCgst, calculateSgst } from './Calculations'
import { addGood, editGood } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/BillGenerationSlice'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FormatTimestamp, GetFinalGood, ValidateAddingGoods, typeOfGoods } from './AddGoodFunctions'
import { LoadingButton } from '@mui/lab'
import { GetFlowByDate } from '../../../../Redux/Slices/Industry/FlowDataSlice'

// the component for editing the goods, jsx is same as tha adding of the goods but the functionality is different. here values is pre fetched from the targetted element. and we have to change vaalues if we want. 
export default function EditGoods(props) {

  let index = props.EditingModalState.targetElement; // simplify the index of the target element. passing from the bil body component.
  const goods = useSelector(state => state.BILL_GENERATION.goods) // Getting goods from the redux store.
  const [NewMasterBillId, setNewMasterBillId] = useState(goods[index].masterCopyID)  // new id of the master bill is going to initialise with the old id so we can show the old one selected


  // This usestate is a copy of our good's object and initiaised with all the same details of the good which we are editing. For editing this good we are going to make changes in this object and will replace this object with the previous one. one the same index. thats why we are passing index in this component.
  const [GoodData, setGoodData] = useState({
    qty: goods[index].qty,
    isYearlyorMonthtly: goods[index].isYearlyorMonthtly,
    starting: goods[index].starting,
    ending: goods[index].ending,
    masterCopyID: NewMasterBillId,
    flowMonth: goods[index].flowMonth,
    flowRate: goods[index].flowRate,
    type: goods[index].type,
    cgstAmount: 0,
    sgstAmount: 0,
    cgstRate: 0,
    sgstRate: 0,
    goodPrice: goods[index].goodPrice,
    unit: goods[index].unit,
    HSN_SAC_code: goods[index].HSN_SAC_code,
  })


  // console.log("initialissing data");
  // console.log(GoodData);

  // ensuring that gooddata update with the new master bill id when it get changes. because of the async nature of the usestate updates. 
  useEffect(() => { setGoodData((prevData) => ({ ...prevData, masterCopyID: NewMasterBillId, })) }, [NewMasterBillId]);

  return <div>
    {
      props.EditingModalState.innerModalState === 1 ? <EditMasterCopy // Component for editing the selected master copy. 
        setEditingModalState={props.setEditingModalState} // Passed here for handling the modal, going forward and backwards
        EditingModalState={props.EditingModalState}// Passed here for handling the modal, going forward and backwards
        GoodData={GoodData} // Need masterbill id in this compoent so passed the complete good obect to show which master copy is currently selected, we will highlight it.
        setGoodData={setGoodData} // for anthing we want to set in this component, basically this component is for selecting the master bill copy and storing its id inthe object.
        setNewMasterBillId={setNewMasterBillId} // Setting new bill id in this variable. 
      />
        :
        props.EditingModalState.innerModalState === 2 ? <EditGoodsDetails // Componennt for editing 4 details of the good. 
          setEditingModalState={props.setEditingModalState}// Passed here for handling the modal, going forward and backwards
          EditingModalState={props.EditingModalState}// Passed here for handling the modal, going forward and backwards
          GoodData={GoodData} // For storing the changes in our goodCopy object. 
          setGoodData={setGoodData} //For storing the changes in our goodCopy object.
          NewMasterBillId={NewMasterBillId} // Need to check bill id for getting respective bill from the mastercopies in redux
          setNewMasterBillId={setNewMasterBillId} // for resetting the bill id for other good to be edit. 
        />
          : ''
    }
  </div>
}






















































// Functional component for the form of editing the 4 important details of the good. 
export function EditGoodsDetails(props) {

  const dispatch = useDispatch() // for deispatching actions
  const MasterCopies = useSelector((state) => state.BILL_MASTER_COPIES.Copies.billMasterCopies); // Getting master bill copies from the redux store for taking the master copy detaild based on the current selected master copy id.
  const goods = useSelector(state => state.BILL_GENERATION.goods) // Getting goods from the redux store. herefor checking the details we changed is already exist in the data or not.
  const industryID = useSelector(state => state.BILL_GENERATION.industryID)
  const [FlowLoading, setFlowLoading] = useState(false)
  const AuthToken = useSelector((state) => state.USER_DETAILS.AuthToken); // logged in user auth token
  const PlantID = useSelector((state) => state.USER_DETAILS.PlantID); // logged in user auth token


  // console.log(props.GoodData);

  //setting this because whenever someone change the date, the flow should be refreshed and currently we are fetching flow manually through button, so flow rate must be set to 0 if someone chage date for preventing setting new date with previous flow.
  // useEffect(() => {
  //   props.setGoodData({ ...props.GoodData, flowRate: 0 })
  // }, [props.GoodData.flowMonth])



  // function for adding the good to list, it first checks the list is included that same entry or not. then it will add item inthe list.
  const EditCurrentGood = () => {

    // Validating the details of the good. 
    if (!ValidateAddingGoods(props.GoodData.type, props.GoodData, dispatch)) { return }

    // Check if the new good is already in the list
    const isAlreadyInList = goods.some(
      (good) =>
        //conditions if type of good is consumption type
        (good.qty === props.GoodData.qty && good.starting === props.GoodData.starting && good.ending === props.GoodData.ending && good.type === typeOfGoods.WCG && good.masterCopyID === props.GoodData.masterCopyID)
        ||
        //conditions if type of good is flow type
        (good.flowMonth === props.GoodData.flowMonth && good.flowRate === props.GoodData.flowRate && good.type === typeOfGoods.WFG && good.masterCopyID === props.GoodData.masterCopyID)
    );


    // if the edited data is not matching with any of the other data present in the goods list. 
    if (!isAlreadyInList) {
      let readyData = GetFinalGood(MasterCopies, props.GoodData.masterCopyID, props.GoodData) // data returning after the fetching and calculating the gst rates
      let index = props.EditingModalState.targetElement; // Simplifying the index off the targetting element assing from the bill bosy.js
      dispatch(editGood({ index, readyData })) // replacing the new ready data with the old one on basis of index. 
      props.setNewMasterBillId('') // re-setting the  new selected bill for next good we are going to edit.
      props.setEditingModalState({ isEditingModalOpen: false, innerModalState: 1, targetElement: '' }) // Closing the modal after editing good.
    }
    else {
      dispatch(showTicker({
        message: 'No changes made or item already present in the list',
        type: 'error'
      }))
    }

  };


  //FUNCTION for getting flow of the user selected month. 
  const GetFlow = () => {
    if (props.GoodData.flowMonth !== '') {
      dispatch(GetFlowByDate({
        IndustryID: industryID,
        AuthToken: AuthToken,
        Date: props.GoodData.flowMonth,
        setLoading: setFlowLoading,
        PlantID: PlantID
      })).then((flow) => {
        // console.log("Flow:", flow.payload);
        if (flow?.payload !== undefined) {
          props.setGoodData({ ...props.GoodData, flowRate: +flow?.payload?.toFixed(2) })
        }
        else {
          props.setGoodData({ ...props.GoodData, flowRate: 0 })
        }
      })
        .catch((error) => {
          console.error(error);
          props.setGoodData({ ...props.GoodData, flowRate: 0 })
        })
    }
    else {
      dispatch(showTicker({
        message: "Please select a month first",
        type: 'error'
      }))
    }
  }


  const [checked, setChecked] = useState(false);
  const ManualFlowAdd = () => {
    const switchHandle = (event) => {
      setChecked(event.target.checked);
      props.setGoodData({ ...props.GoodData, flowRate: 0 })
    }
    return <Box>
      <FormControlLabel control={<Switch checked={checked} onChange={switchHandle} />} label="Enter Manually" labelPlacement='start' /><br />
      {checked ? <TextField
        label='Type value of flow here'
        placeholder='FLow Rate'
        type='number'
        fullWidth
        value={props.GoodData.flowRate !== 0 ? props.GoodData.flowRate : ''}
        onChange={(e) => { if (e.target.value.length <= 6) { props.setGoodData({ ...props.GoodData, flowRate: +e.target.value }) } }}
      /> : ''}
    </Box>
  };


  const HandleDate = (value) => {
    props.setGoodData({ ...props.GoodData, flowRate: 0, flowMonth: FormatTimestamp(value, "yyyy-MM") })
  }

  return <Box>
    <Box className="Modal_heading">
      <Stack>
        <Typography variant='h6'>Add Good's Details</Typography>
        <Typography variant='caption'>Add all the required details of the good in order to create a good </Typography>
      </Stack>
    </Box>

    {/* text feild for good adding */}
    {
      props.GoodData.type === typeOfGoods.WCG ? <Box p={'20px'}>
        <Stack direction="column" spacing={1}>
          <Stack fullWidth direction='row' justifyContent='space-between' spacing={1}>
            <TextField label='Consumption'
              placeholder='Consumption'
              type='number'
              fullWidth
              value={props.GoodData.qty !== 0 ? props.GoodData.qty : ''}
              onChange={(e) => { if (e.target?.value?.length <= 6) { props.setGoodData({ ...props.GoodData, qty: +e.target.value }) } }}
            />
          </Stack>

          <Stack direction="row" spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={{ width: '50%' }}
                onChange={(value) => { props.setGoodData({ ...props.GoodData, starting: FormatTimestamp(value, "MM/dd/yyyy") }) }}
                disableFuture={true}
                format='dd/MM/yyyy'
                label={props.GoodData.starting}
              />
              <DatePicker
                sx={{ width: '50%' }}
                onChange={(value) => { props.setGoodData({ ...props.GoodData, ending: FormatTimestamp(value, "MM/dd/yyyy") }) }}
                disableFuture={true}
                format='dd/MM/yyyy'
                label={props.GoodData.ending} />
            </LocalizationProvider>
          </Stack>
        </Stack>
      </Box>
        : props.GoodData.type === typeOfGoods.WFG ? <Box p={"20px"}>
          <Stack direction="row" spacing={1} alignItems="center">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={props.GoodData.flowMonth}
                views={['year', 'month']}
                disableFuture={true}
                onChange={(value) => { HandleDate(value) }}
              // slotProps={{ textField: { readOnly: true } }} // For disabling the keyboard date entry in the date picker
              />
            </LocalizationProvider>
            <LoadingButton
              variant='outlined'
              onClick={GetFlow}
            >
              Get Flow
            </LoadingButton>
            <Typography variant='body2'>{
              FlowLoading ? "Loading..." : <> <b>{props.GoodData.flowRate}</b> Unit</>
            }</Typography>
          </Stack>
          {ManualFlowAdd()}
        </Box>
          : ""
    }

    <Stack direction="row-reverse" spacing={1} padding="20px" position='sticky' bottom={0} backgroundColor='white' fullWidth>
      <Button
        variant='contained'
        disabled={props.GoodData.type === typeOfGoods.WFG && props.GoodData.flowRate === 0}
        disableElevation
        endIcon={<Save fontSize='small' />}
        onClick={EditCurrentGood}
      > Save Good</Button>
      <Button
        variant='contained'
        color='SecondaryBtn'
        disableElevation
        startIcon={<ArrowBack />}
        onClick={() => { props.setEditingModalState({ ...props.EditingModalState, isEditingModalOpen: true, innerModalState: 1 }); }}
      > Back</Button>
    </Stack>
  </Box>
}





















































export function EditMasterCopy(props) {
  const [openRow, setOpenRow] = useState("") // For Storing the row which is currently in OPENED state
  const MasterCopies = useSelector(state => state.BILL_MASTER_COPIES.Copies)

  const [FilteredMasterBills, setFilteredMasterBills] = useState([])

  // function for filtering data on type basis
  const filterByType = (data, type) => {
    return data.filter(item => item.data.type === type);
  };

  // console.log(props.GoodData.type, FilteredMasterBills.length);

  // filtering the bills on the current selected type of the good. 
  useEffect(() => {
    if (!!MasterCopies) {
      let billCompatibleTypeOfGood = props.GoodData.type === "Water Consumption good" ? "Water Consumption Bill" : props.GoodData.type === "Water Flow good" ? "Water Flow Bill" : 'none'
      setFilteredMasterBills([])
      setFilteredMasterBills(filterByType(MasterCopies.billMasterCopies, billCompatibleTypeOfGood))
    }
  }, [])


  return <Box>
    <Box className="Modal_heading">
      <Stack>
        <Typography variant='h6'>Please Select A Master Bill. {props.GoodData.qty}</Typography>
        <Typography variant='caption'>For proceeding to create a good, you must have been select a bill master copy. </Typography>
      </Stack>
    </Box>
    <Stack>

      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingLeft: '20px' }}>Type (Click for more details)</TableCell>
              <TableCell>Price (rs)</TableCell>
              <TableCell>CGST (%)</TableCell>
              <TableCell>SGST (%)</TableCell>
              <TableCell>HSN Code</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              !!MasterCopies && FilteredMasterBills.length > 0 ?
                FilteredMasterBills.map((copy) => {
                  return <Row
                    key={copy.id} // A unique id for the row. 
                    data={copy.data} // Passing the bill data into the master copy selection component.
                    billId={copy.id} // Passing the bill id into the master copy selection component, becuase of data and id is seperated in the response
                    openRow={openRow} // Setting open row globally because, this is implemented because a problem was happening when we are opening a collapsible bill and if we open next bill then the first one is keep opened not closing. so we made a state for controlling the open close behavoiur of the rows from its parent. So now if one row open other will close.
                    setOpenRow={setOpenRow} //this is passed because we created an arrow button for closing the collapsible bill and this is important for closing the bill extra details. setting open row to '' will close this bill

                    SelectedBill={props.GoodData.masterCopyID} // Basically this is pass here becuase of changing background color of the bill which is selected. so we compare the selected bill id and the passed id in the row component and if its matched the we change its color to other one.
                    setSelectedBill={props.setNewMasterBillId} // This is for setting the selected bill. Simply for selecting the bill.
                  />
                }) : 'No Master Copies found, please refresh the page or add some master copies'}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
    {/* buttons for navigation */}
    <Stack direction="row-reverse" spacing={1} padding="20px" position='sticky' bottom={0} backgroundColor='white' fullWidth>
      <Button
        variant='contained'
        disabled={props.SelectedBill === ''}
        disableElevation
        endIcon={<ArrowForward />}
        onClick={() => { props.setEditingModalState({ ...props.EditingModalState, isEditingModalOpen: true, innerModalState: 2 }); }}
      > Proceed</Button>
      <Button
        variant='contained'
        color='SecondaryBtn'
        disableElevation
        startIcon={<Close />}
        onClick={() => { props.setEditingModalState({ ...props.EditingModalState, isEditingModalOpen: false, innerModalState: 1 }); }}
      > Cancel</Button>
    </Stack>
  </Box>
}
