import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './App.css';
import { Provider } from 'react-redux';
import { store } from './Redux/store.js'
import { ThemeProvider } from '@mui/material';
import { theme } from './Theme.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
    <App />
    </ThemeProvider>
  </Provider>
  // <React.StrictMode>
  //   <Provider store={store}>
  //     <ThemeProvider theme={theme}>
  //     <App />
  //     </ThemeProvider>
  //   </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
