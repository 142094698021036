import { React, useState, useEffect } from 'react'
import { ConfirmationPopup } from '../../UI Components/Basic'
import { UpdateChamberParams, DeleteChamberIO } from '../../../Redux/Slices/ProcessManagementSlices/ChamberManagementSlice';
import { useDispatch, useSelector } from "react-redux";
import { status } from '../../../Redux/Slices/userSlice';

const Remove = (props) => {
  const CHAMBER_LOADING = useSelector(state => state.CHAMBER_MANAGEMENT.AddLoading)
  const GET_CHAMBER_LOADING = useSelector(state => state.CHAMBER_MANAGEMENT.GetChamberLoading)
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const ModalState = useSelector(state => state.CHAMBER_MANAGEMENT.ModalState)
  const dispatch = useDispatch()

  const DELETE = () => {
    dispatch(UpdateChamberParams({ 
      chamberid: props.data.chamberid,
      type: props.data.type === "Chamber" ? 0 : props.data.type === "Inlet" ? 1 : 2,
      key: props.data.data.key,
      value: props.data.data.value,
      index: props.data.data.index,
      inletIndex: props.data.data.inletIndex,
      outletIndex: props.data.data.outletIndex,
      operation: props.data.data.operation,
      UserToken,
      PlantID
    }))
  }

  const DELETE_CIO = () => {
    dispatch(DeleteChamberIO({ 
      chamberid: props.data.chamberid,
      type: props.data.type === "Chamber" ? 0 : props.data.type === "Inlet" ? 1 : 2,
      inletIndex: props.data.data.inletIndex,
      outletIndex: props.data.data.outletIndex,
      UserToken,
      PlantID
    }))
  }

  useEffect(() => {
    if(GET_CHAMBER_LOADING === status.LOADING){
      props.setActiveModal({ ActiveModalIndex: 0 })
    }
  }, [GET_CHAMBER_LOADING, props])

  return (
    <div>
      <ConfirmationPopup
        PrimaryAction={props.data.data.action !== 'delete' ? DELETE : DELETE_CIO}
        SecondaryAction={() => { props.setActiveModal({ ActiveModalIndex: 0 }) }}
        PrimaryButtonText={"Delete"}
        color="PrimaryRed"
        message={
          props.data.data.action !== 'delete' ?
          `Are you sure you want to delete the parameter ${props.data.data.key} : ${props.data.data.value} ?` :
          `Are you sure you want to delete the ${props.data.type}: ${props.data.data.name} ?`
        }
        Loading={CHAMBER_LOADING === status.LOADING ? true : false}
      />
    </div>
  )
}

export default Remove