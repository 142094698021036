import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVER_ADDRESS } from "../../../../Firebase/Variables";
import { showTicker } from "../../TickerSlice";
import { status } from "../../userSlice";
import { GetIndustryBills, approveBill } from "./IndustryBillsSlice";

export const UploadPaymentRecipt = createAsyncThunk('UploadPaymentRecipt', async ({ AuthToken, files, billID, setLoading, PlantID }, { dispatch }) => {

    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var formdata = new FormData();
    formdata.append("payment_reciept", files, files.name);
    formdata.append("billid", billID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };


    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/bill/upload/reciept?plantID=${PlantID}`, requestOptions)
        const data = await response.json()

        console.log(response, data)

        if (response.ok) {
            dispatch(showTicker({
                message: data.message,
                type: 'success'
            }))
            setLoading(false)
            return true
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            setLoading(false)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        setLoading(false)
    }
})


export const GetBillReciptApprovalRequests = createAsyncThunk('GetBillReciptApprovalRequests', async ({ AuthToken, PlantID }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/bill/requests?plantID=${PlantID}`, requestOptions)
        const data = await response.json()

        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            rejectWithValue(response.statusText)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
    }
})


export const ApprovePayment = createAsyncThunk('approvePayment', async ({ billID, industryID, paymentReceiptLink, AuthToken, setLoading, setRequestDetails, PlantID }, { dispatch }) => {

    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/bill/approve/${billID}?plantID=${PlantID}`, requestOptions)
        const data = await response.json()
        if (response.ok) {
            dispatch(showTicker({
                message: "Payment recipt approved successfully, Bill marked PAID",
                type: 'success'
            }))
            setRequestDetails({  // closing the side drawer after approving the request
                isRequestOpen: false,
                RequestData: [],
            })
            dispatch(deleteRequest({ requestID: billID }))
            dispatch(approveBill({
                industryId: industryID,
                billId: billID,
                paymentReceiptLink: paymentReceiptLink
            }))
            setLoading(false) // turn off loading
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            setLoading(false)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        setLoading(false)
    }

})

export const RejectPayment = createAsyncThunk('rejectPayment', async ({ billID, AuthToken, setLoading, setRequestDetails, PlantID }, { dispatch }) => {

    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/bill/reject/${billID}?plantID=${PlantID}`, requestOptions)
        const data = await response.json()
        if (response.ok) {
            dispatch(showTicker({
                message: `Payment recipt rejected successfully`,
                type: 'success'
            }))
            setRequestDetails({  // closing the side drawer after approving the request
                isRequestOpen: false,
                RequestData: [],
            })
            dispatch(deleteRequest({ requestID: billID }))
            setLoading(false)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            setLoading(false)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        setLoading(false)
    }

})

const PaymentSlice = createSlice({
    name: 'paymentSlice',
    initialState: {
        Loading: status.IDLE,
        Requests: []
    },
    reducers: {
        deleteRequest: (state, action) => {
            const { requestID } = action.payload;
            state.Requests.requests = state.Requests.requests.filter(req => req.id !== requestID);
        },

    },
    extraReducers: {
        [GetBillReciptApprovalRequests.pending]: (state) => {
            state.Loading = status.LOADING
        },
        [GetBillReciptApprovalRequests.fulfilled]: (state, action) => {
            state.Loading = status.IDLE
            state.Requests = getSortedItemsByDate(action.payload.requests);
        },
        [GetBillReciptApprovalRequests.rejected]: (state) => {
            state.Loading = status.ERROR
        }
    }
})

export const { deleteRequest } = PaymentSlice.actions;
export default PaymentSlice.reducer;





// Function to convert timestamp string to milliseconds
function getTimestampInMilliseconds(timestamp) {
    return new Date(timestamp).getTime();
}

//function for sorting the notices according to date
const getSortedItemsByDate = (invItems) => {
    const requests = invItems?.slice().sort((a, b) => getTimestampInMilliseconds(b.dateAdded) - getTimestampInMilliseconds(a.dateAdded));
    const req = { requests }
    return req
}