import React from 'react'

export default function NotPermitted() {
  return (
    <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <p className="simpleParagraph">You are not permitted to view this page.</p>
    </div>
  )
}
