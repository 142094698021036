import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  OutlinedInput,
  ListItemText,
  Stack,
  Typography, // Import Stack from @mui/material
} from "@mui/material";
import { AddAlertSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DrawerRoutes } from "../Routes/routes";
import Navigate from "./Navigation";
import Table from "./Table";
import { useDispatch, useSelector } from "react-redux";
import { GetFilteredInstruments } from "../../Redux/Slices/ProcessManagementSlices/ProcessManagementSlice";
import { GetAlertsCount } from "../../Redux/Slices/ProcessManagementSlices/SensorSlice";
import { getToken } from "firebase/messaging";
import { onMessageListener } from "../../Firebase/firebase";
import PropaneIcon from '@mui/icons-material/Propane';

// Reusable Select component with dynamic width
const SelectComponent = ({ label, id, value, onChange, options, width }) => {
  // Calculate dynamic width based on label length

  return (
    <FormControl>
      <InputLabel id={`${id}_label`} style={{ top: "-7px" }}>
        {label}
      </InputLabel>
      <Select
        labelId={`${id}_label`}
        id={id}
        size="small"
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(", ")}
        sx={{ width: width }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox size="small" checked={value.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// Main component for the page
export default function Process() {
  // State variables for different Select components
  const [instrument, setInstrument] = useState([]);
  const [location, setLocation] = useState([]);
  const [type, setType] = useState([]);
  const [fluid, setFluid] = useState([]);
  const [io, setIO] = useState([]);
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const dispatch = useDispatch();
  const Filters = useSelector(state => state.PROCESS_MANAGEMENT.Filters)
  const category = useSelector(state => state.PROCESS_MANAGEMENT.SelectedCategory)
  const AlertsCount = useSelector(state => state.SENSORS.AlertsCount)
  const [fluidName, setFluidName] = useState([])
  const [ioName, setioName] = useState([])
  const [typeName, setTypeName] = useState([])
  const [locationName, setLocationName] = useState([])
  const [instrumentName, setInstrumentName] = useState([])

  // Navigation hook
  const navigate = useNavigate();

  // Handler function for handling changes in Select components
  const handleSelectChange = (setter) => (event) => {
    const {
      target: { value },
    } = event;
    setter(typeof value === "string" ? value.split(",") : value);
  };

  // Function to check if at least one option is selected in any of the Select components
  const isApplyButtonDisabled = () => {
    return (
      instrument.length === 0 &&
      location.length === 0 &&
      type.length === 0 &&
      fluid.length === 0 &&
      io.length === 0
    );
  };

  useEffect(() => {
    dispatch(GetAlertsCount({ UserToken, PlantID }))
  }, [dispatch, UserToken])

  useEffect(() => {
    if (Filters.data) {
      for (let i = 0; i < Filters.data.length; i++) {
        const element = Filters.data[i];

        const filterName = element.filterName
        const filterItems = element.filterItem

        if (filterName === "Fluid") {
          setFluidName(filterItems)
        } else if (filterName === "Location") {
          setLocationName(filterItems)
        } else if (filterName === "Instrument") {
          setInstrumentName(filterItems)
        } else if (filterName === "I/O") {
          setioName(filterItems)
        } else if (filterName === "TypeOfInstorWorkingPrinciple") {
          setTypeName(filterItems)
        }
      }
    }
  }, [Filters])

  return (
    <>
      <Stack direction="column">
        {/* Page header section */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="PAGE_HEADER"
        >
          <Stack>
            <h3>Process Management</h3>
            <Typography variant="body2"><span style={{ color: 'red' }}>Realtime data not available as Public static ip is not provided. </span></Typography>
          </Stack>
          <Stack 
            direction='row'
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}
          >
            <Badge badgeContent={AlertsCount} color='PrimaryRed' max={999} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
              <Button
                variant="contained"
                onClick={() => navigate(DrawerRoutes.SensorAlerts)}
                color="SecondaryBtn"
                startIcon={<AddAlertSharp style={{ width: "17px" }} />}
                disableElevation
              >
                Sensor Alerts
              </Button>
            </Badge>
            <Button
              variant="contained"
              onClick={() => navigate(DrawerRoutes.Chambers)}
              color="SecondaryBtn"
              startIcon={<PropaneIcon style={{ width: "17px" }} />}
              disableElevation
            >
              Chambers
            </Button>
          </Stack>
        </Stack>
        {/* Search and toolbar section */}
        <Stack
          direction="row"
          justifyContent="space-between"
          className="Industry_Detail_Container"
          sx={{ height: "88px" }}
        >
          <TextField
            id="outlined-basic"
            label="Search Item..."
            variant="outlined"
            size="small"
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            className="process_toolbar"
          >
            {/* Render Select components for different options with dynamic width */}
            <SelectComponent
              label="Instrument"
              id="instrument"
              value={instrument}
              onChange={handleSelectChange(setInstrument)}
              options={instrumentName}
              width="150px"
            />
            <SelectComponent
              label="Location"
              id="process"
              value={location}
              onChange={handleSelectChange(setLocation)}
              options={locationName}
              width="120px"
            />
            <SelectComponent
              label="Type"
              id="type"
              value={type}
              onChange={handleSelectChange(setType)}
              options={typeName}
              width="100px"
            />
            <SelectComponent
              label="Fluid"
              id="fluid"
              value={fluid}
              onChange={handleSelectChange(setFluid)}
              options={fluidName}
              width="100px"
            />
            <SelectComponent
              label="IO"
              id="io"
              value={io}
              onChange={handleSelectChange(setIO)}
              options={ioName}
              width="80px"
            />

            <Button
              disableElevation
              variant="contained"
              color="PrimaryBlue"
              disabled={isApplyButtonDisabled()}
              onClick={() => {
                dispatch(
                  GetFilteredInstruments({
                    queries: {
                      instrument: instrument,
                      location: location,
                      tiowp: type,
                      fluid: fluid,
                      io: io,
                    },
                    category: category,
                    UserToken: UserToken,
                    PlantID: PlantID
                  })
                )
              }}
            >
              Apply
            </Button>

            <Button
              disableElevation
              variant="contained"
              color="PrimaryBlue"
              disabled={isApplyButtonDisabled()}
              onClick={() => {
                setInstrument([])
                setLocation([])
                setType([])
                setFluid([])
                setIO([])
                dispatch(
                  GetFilteredInstruments({
                    queries: {
                      instrument: [],
                      location: [],
                      tiowp: [],
                      fluid: [],
                      io: [],
                    },
                    category: category,
                    UserToken: UserToken,
                    PlantID: PlantID
                  })
                )
              }}
            >
              Reset
            </Button>
          </Stack>
        </Stack>
        {/* Body container section */}
        <div className="Industries_Body_Container">
          <Navigate></Navigate>
          <Table></Table>
        </div>
      </Stack>
    </>
  );
}
