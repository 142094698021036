import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
    name: 'drawer',
    initialState: '',
    reducers: {
        setActive(state, action) {
            state = action.payload;
            return state;
        }
    }
})

export const { setActive } = drawerSlice.actions;
export default drawerSlice.reducer;