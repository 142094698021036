import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { status } from "../userSlice";
import { showTicker } from "../TickerSlice";
import { SERVER_ADDRESS } from "../../../Firebase/Variables";

export const FetchUsers = createAsyncThunk('FetchUsers', async ({ AuthToken, PlantID }, { dispatch, rejectWithValue, fulfillWithValue }) => {

    console.log(AuthToken, PlantID);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);
    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders,
    };
    try {
        const response = await fetch(`${SERVER_ADDRESS}/user?plantID=${PlantID}`, requestOptions)
        const data = await response.json()

        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return fulfillWithValue(response.status)
        }

    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error',
        }))
        throw rejectWithValue(error.message)
    }
})

const MyTeamSlice = createSlice({
    name: 'My Team',
    initialState: {
        AllUsers: [],
        Admins: [],
        Loading: status.IDLE
    },
    extraReducers: {
        [FetchUsers.pending]: (state) => {
            state.Loading = status.LOADING
        },
        [FetchUsers.fulfilled]: (state, action) => {
            state.Loading = status.IDLE
            state.AllUsers = action.payload
        },
        [FetchUsers.rejected]: (state) => {
            state.Loading = status.ERROR

        }
    }
})

export default MyTeamSlice.reducer