import React, { useEffect } from "react";
import { useState } from "react";
import { Typography, Autocomplete, TextField, Button, Grid, Chip, } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { status } from "../../../Redux/Slices/userSlice";
import { DataGrid } from "@mui/x-data-grid";
import { CircularLoader, SideDrawer, SomethingWentWrong } from "../../UI Components/Basic";
import { convertGMTtoISTAndFormat } from "../../ReportsComponents/BillsAndPayments/DedicatedIndustryBill";
import NoticeSideDrawerLayout from "../../NoticeNotificationsComponents/NoticeSideDrawerLayout";
import { GetNoticesPlantSide } from "../../../Redux/Slices/Industry/SendNoticeSlice";
import { useOutletContext } from "react-router-dom";

export default function IndustryNotices() {

  const Notices = useSelector(state => state.NOTICES.Notices) // getting notices from the redux store.
  const Loading = useSelector(state => state.NOTICES.Loading) // loading notices
  const User = useSelector(state => state.USER_DETAILS) // for checking the logged in user is an admin or industry. Because if the user is an admin, then we will not show new notice tag to admin , because he sent that notices. no notice is new for admin. Notice is new for only industry.
  const dispatch = useDispatch()
  const AuthToken = useSelector((state) => state.USER_DETAILS.AuthToken); // logged in user auth token
  const PlantID = useSelector((state) => state.USER_DETAILS.PlantID); // logged in user auth token
  const { IndustryData, LoadingData } = useOutletContext()

  //filtering notices accoring to the industry. 
  const [DedicatedNotices, setDedicatedNotices] = useState([])
  useEffect(() => {
    if (Loading !== status.ERROR && Loading !== status.LOADING) {
      let filteredNotices = Notices?.notices?.filter(notice => {
        // Check if the notice has industries
        if (notice.data.industries && notice.data.industries.length > 0) {
          // Check if any industry in the notice matches the target industry ID
          return notice.data.industries.some(industry => industry.id === IndustryData.id || industry.id === "All");
        }
        return false; // Return false if industries array is empty or not found
      });
      setDedicatedNotices(filteredNotices)
    }
  }, [Notices, Loading])

  console.log(DedicatedNotices);

  // fetching notices
  useEffect(() => { dispatch(GetNoticesPlantSide({ AuthToken: AuthToken, PlantID: PlantID })) }, [])

  // coloums of the table of rendering notices
  const columns = [
    { field: "id", headerName: "S No.", type: "number", width: 70 },
    {
      field: "data", headerName: "Title", flex: 1, height: 100,
      renderCell: (params) => (
        <div >
          <Typography variant="body1">{params.value.title}</Typography>
          {/* <Typography variant="caption" color="grey" dangerouslySetInnerHTML={{ __html: params.value.description }} /> */}
        </div>
      )
    },
    {
      field: "creationDate", headerName: "Date", width: 220,
      renderCell: (params) => (<Chip label={params.value.date} size='small' sx={{ fontSize: '12px' }} />)
    }
  ];





  // function for rendering the grid of the notices
  const RenderNotices = () => {
    if (Loading === status.IDLE) {
      if (DedicatedNotices?.length === 0) { return <p className="simpleParagraph absCenter">Notices Not Found</p> }
      if (!!Notices?.notices) {
        return <div>
          {
            Notices?.notices !== undefined && DedicatedNotices !== undefined ?
              <DataGrid
                onRowClick={handleRowClick}
                disableRowSelectionOnClick
                rowHeight={65}
                rows={DedicatedNotices?.map((notice, i) => {
                  return (
                    {
                      id: i + 1,
                      data: notice?.data,
                      creationDate: {
                        date: convertGMTtoISTAndFormat(notice?.data?.creationDate),
                        isNew: notice?.data?.isNew,
                        noticeID: notice?.id
                      },
                    }
                  )
                })}
                columns={columns}
                pageSize={2}
              />
              : ''
          }
        </div>
      }
      else {
        return <SomethingWentWrong />
      }
    }
    else if (Loading === status.LOADING) {
      return <CircularLoader />
    }
    else if (Loading === status.ERROR) {
      return <SomethingWentWrong />
    }
  }



  // function for showing the notice infromation in the side drawer
  const [NoticeDetails, setNoticeDetails] = useState({
    isSideDrawerOpen: false,
    activeNoticeData: []
  })

  // function for rendering the all details of a notice in a side drawer
  function RenderNoticeDetails() {
    return <SideDrawer Open={NoticeDetails.isSideDrawerOpen} handle={() => { setNoticeDetails({ isSideDrawerOpen: false, activeNoticeData: [] }) }}>
      <NoticeSideDrawerLayout data={NoticeDetails.activeNoticeData} />
    </SideDrawer>
  }

  // function  for handling the clicking on a notice. it will set the clicked notice data into the side drawer state to make it render in the side drawer.
  const handleRowClick = (params) => {
    setNoticeDetails({
      isSideDrawerOpen: true,
      activeNoticeData: params.row.data,
    })
  };


  return (
    <div>
      <div
        className="table_Heading_Container"
        style={{ justifyContent: "space-between" }}
      >
        <Typography variant="subtitle2">All Notices</Typography>
        <div className="table_toolbar">
          {/* <Autocomplete
            size="small"
            value={selectedMonth}
            onChange={(event, newValue) => {
              setSelectedMonth(newValue);
              setEnteredData({ ...enteredData, itemType: newValue });
            }}
            id="controllable-states-demo"
            options={months}
            sx={{ width: "200px" }}
            renderInput={(params) => (
              <TextField {...params} label="Select month" />
            )}
          /> */}
          <Button
            variant="contained"
            color="PrimaryBlue"
            startIcon={<SendIcon />}
            disableElevation
            disabled={User.UserRole !== 'admin' && User.UserRole !== 'superadmin' && User.UserAccessLevel !== 1 && User.UserAccessLevel !== 0}
          >
            Send Notices
          </Button>
        </div>
      </div>



      {RenderNotices()}
      {RenderNoticeDetails()}
    </div>
  );
}
