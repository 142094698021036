import { React, useState, useEffect } from 'react'
import { Button, Stack, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { SideDrawer } from '../../UI Components/Basic'
import { AddCircleOutline } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { setModalState, UpdateChamberParams } from "../../../Redux/Slices/ProcessManagementSlices/ChamberManagementSlice"
import { useDispatch, useSelector } from "react-redux";
import Edit from './Edit';
import Remove from './Remove';
import { showTicker } from "../../../Redux/Slices/TickerSlice";
import { status } from "../../../Redux/Slices/userSlice";

const InletOutlet = (props) => {
  const dispatch = useDispatch()
  const [ActiveModal, setActiveModal] = useState({
    ActiveModalIndex: 0,
    chamberid: "",
    type: "",
    data: {}
  })
  const [editChamberName, setEditChamberName] = useState({ edit: false, name: props.data.name })
  const CHAMBER_LOADING = useSelector(state => state.CHAMBER_MANAGEMENT.GetChamberLoading)
  const ADD_CHAMBER_LOADING = useSelector(state => state.CHAMBER_MANAGEMENT.AddLoading)
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)

  const handleEditParam = (type, data) => {
    setActiveModal({
      ActiveModalIndex: data.operation === "add" || data.operation === "update" ? 1 : 2,
      chamberid: props.data.chamberid,
      type: type,
      data: data
    })
    dispatch(setModalState(1))
  }

  useEffect(() => {
    // Update local state to reflect the current name from props
    setEditChamberName({ edit: editChamberName.edit, name: props.data.name });
  }, [editChamberName.edit, props.data.name]);

  useEffect(() => {
    if (CHAMBER_LOADING === status.LOADING) {
      setEditChamberName({ edit: false, name: props.data.name })
      props.handle({ ...props.data, open: false })
    }
  }, [CHAMBER_LOADING, props.data.name])

  return (
    <SideDrawer Open={props.Open}>
      {ActiveModal.ActiveModalIndex === 1 ? <Edit setActiveModal={setActiveModal} data={ActiveModal} drawer={props.handle} /> :
        ActiveModal.ActiveModalIndex === 2 ? <Remove setActiveModal={setActiveModal} data={ActiveModal} drawer={props.handle} /> : ""
      }

      <Tooltip title="Close" onClick={props.handle}>
        <IconButton>
          <CloseIcon />
        </IconButton>
      </Tooltip>

      <div style={{ width: "700px", height: "100vh" }}>
        <div className="PAGE_HEADER" style={{ padding: "20px", paddingTop: "40px" }}>
          {/* Name of the page, will show in left of the header */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Stack sx={{ width: "70%" }}>
              <p style={{ fontWeight: "400" }}>{props.data.type}</p>
              <Stack direction="row" alignItems="center" spacing={1} sx={{ width: "100%" }}>
                {!editChamberName.edit ?
                  <Stack direction='row' alignItems='center' sx={{ width: "100%" }}>
                    <h3>{props.data.name}</h3>
                    <IconButton onClick={() => setEditChamberName({ ...editChamberName, edit: true })}> <EditIcon fontSize='medium' /> </IconButton>
                  </Stack> :
                  <Stack direction='row' alignItems='center' sx={{ width: "100%" }}>
                    <TextField
                      autoFocus={editChamberName.edit}
                      placeholder={`${props.data.type} Name`}
                      variant="standard"
                      sx={{ width: "70%" }}
                      required
                      defaultValue={props.data.name}
                      value={editChamberName.name}
                      onChange={(e) => setEditChamberName({ ...editChamberName, name: e.target.value })}
                      disabled={ADD_CHAMBER_LOADING === status.LOADING}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          console.log(editChamberName.name);
                          if (editChamberName.name === "") {
                            dispatch(showTicker({
                              message: "Chamber Name can't be empty",
                              type: 'error'
                            }))
                            return
                          }
                          dispatch(UpdateChamberParams({
                            chamberid: props.data.chamberid,
                            type: props.data.type === "Inlet" ? 1 : 2,
                            name: editChamberName.name,
                            operation: "name",
                            inletIndex: props.data.type === "Inlet" ? props.data.inletIndex : undefined,
                            outletIndex: props.data.type === "Outlet" ? props.data.outletIndex : undefined,
                            UserToken,
                            PlantID
                          }))
                        }
                      }}
                    />
                    <IconButton
                      onClick={() =>
                        setEditChamberName({ name: props.data.name, edit: false })
                      }
                      disabled={ADD_CHAMBER_LOADING === status.LOADING}
                    >
                      <CloseIcon fontSize='medium' />
                    </IconButton>
                  </Stack>
                }
              </Stack>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutline />}
              disableElevation
              onClick={() => handleEditParam(props.data.type, {
                inletIndex: props.data.type === "Inlet" ? props.data.inletIndex : undefined,
                outletIndex: props.data.type === "Outlet" ? props.data.outletIndex : undefined,
                operation: "add"
              })}
            >
              Add Parameters
            </Button>
          </Stack>
        </div>

        <div style={{ paddingTop: "20px" }} >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: "20px" }}>S.No.</TableCell>
                <TableCell>Parameter</TableCell>
                <TableCell>TagNo</TableCell>
                <TableCell>Reading</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data !== undefined && props.data !== null ?
                Object.entries(props?.data?.params).map(([key, value], index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ paddingLeft: "20px", width: "10%" }}>{index + 1}</TableCell>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value}</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>
                      <Stack
                        direction="row"
                        spacing={1}
                      >
                        <Button
                          variant="contained"
                          color="warning"
                          sx={{ height: "2rem", fontWeight: "700" }}
                          size="small"
                          disableElevation
                          onClick={() => handleEditParam(props.data.type, {
                            index: index,
                            inletIndex: props.data.type === "Inlet" ? props.data.inletIndex : undefined,
                            outletIndex: props.data.type === "Outlet" ? props.data.outletIndex : undefined,
                            key: key,
                            value: value,
                            operation: "update"
                          })}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ height: "2rem", fontWeight: "700" }}
                          size="small"
                          disableElevation
                          onClick={() => handleEditParam(props.data.type, {
                            index: index,
                            inletIndex: props.data.type === "Inlet" ? props.data.inletIndex : undefined,
                            outletIndex: props.data.type === "Outlet" ? props.data.outletIndex : undefined,
                            key: key,
                            value: value,
                            operation: "delete"
                          })}
                        >
                          Delete
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )) : ""
              }
            </TableBody>
          </Table>
        </div>



      </div>
    </SideDrawer>
  )
}

export default InletOutlet