import React from 'react'
import RenderPaymentApprovalRequests from '../../NoticeNotificationsComponents/RenderPaymentApprovalRequests'
import { IconButton, Stack } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

export default function PaymentRequests() {
    const navigate = useNavigate()
    return (
        <div>
            <div className="PAGE_HEADER">
                {/* Name of the page, will show in lwft of the header */}
                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /> </IconButton>
                    <h3>Payment Approval Requests</h3>
                </Stack>
            </div>

            {/* this div will contain all the content of the page */}
            <div className="Industries_Body_Container">
                <RenderPaymentApprovalRequests />
            </div>
        </div>
    )
}
