import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_ADDRESS } from "../../../Firebase/Variables";
import { showTicker } from "../TickerSlice";
import { UpdateOtherDetails } from "../userSlice";
import { GetIndustryByID } from "./GetIndustriesSlice";

export const EditIndustry = createAsyncThunk('EditIndustry', async ({ AuthToken, IndustryID, PlantID, setLoading, data }, { dispatch }) => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);
  
    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/update/${IndustryID}?plantID=${PlantID}`, requestOptions)
        const data = await response.json()
        if (!response.ok) {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
        } else {
            await dispatch(GetIndustryByID({
                AuthToken: AuthToken,
                IndustryID: IndustryID
            })).then((data) => {
                // console.log(data);
                if (data.meta.requestStatus === "fulfilled") {
                    dispatch(UpdateOtherDetails(data.payload?.industries[0]?.data))
                }
            })

            dispatch(showTicker({
                message: `${data.message}`,
                type: 'success'
            }))
        }
        setLoading(false)
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        setLoading(false)
    }
})