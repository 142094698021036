import React from 'react'

export default function PageNotFound() {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <p className="simpleParagraph">Page not found</p>
        </div>
    )
}
