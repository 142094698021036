import { createTheme } from "@mui/material";

export const theme = createTheme({
  // this palette of the colors fix the colors for the elements of the material UI. For giving our own colors to material ui components, we just have to give color="PrimaryBlue" and it will reflect to component. PrimaryBlue is our custom color. Similarly we can define more colors for our personal use
  palette: {
    primary: {
      main: "#0052cc",
      contrastText: "#fff",
    },
    PrimaryBlue: {
      main: "#0052cc",
      contrastText: "#fff",
    },
    PrimaryRed: {
      main: "#DE350B",
      contrastText: "#fff",
    },
    PrimaryText: {
      main: "#404040",
    },
    SecondaryBtn: {
      main: "#fff",
      contrastText: "#42526E",
    },
    GreyBtn: {
      main: "#091E420A",
      contrastText: "#42526E"
    }
  },

  //Fixing the font in our material UI components.
  typography: {
    fontFamily: "Inter, sans-serif",
  },

  //Here we overrides some properties of the MUI components.
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          borderRadius: "8px",
          fontWeight: "600",
          height: "40px",
          boxShadow:'none'
        },
      },
    },

    // toggle button styling for the complete app, with hover and active button styling also.
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          borderRadius: "8px",
          fontWeight: "600",
          padding: "0px 15px",
          height: "40px",
          transition: '0.2s',

          "&.Mui-selected": {
            backgroundColor: '#0052cc',
            color: '#fff'
          },

          "&.Mui-selected:hover": {
            backgroundColor: '#338FEB',
            color: '#fff'
          },
        },
      }
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#F9F9F9",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          padding: "10px 0px",
          cursor: 'pointer',
          minWidth:'80px'
        },
      },
    },
  },
});
