import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { BackButtonIcon } from '../../assets/assets'
import Navigation from './Navigation'
import './Styles/IndustryDetails.css'
import './Styles/Industries.css'
import IconButton from '@mui/material/IconButton'
import { ArrowBack } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { GetIndustryByIDPlantSide } from '../../Redux/Slices/Industry/GetIndustriesSlice'

export default function IndustryDetailWrapper() {

    const navigate = useNavigate()
    const { industryID } = useParams()
    const dispatch = useDispatch()
    const AuthToken = useSelector((state) => state.USER_DETAILS.AuthToken); // logged in user auth token
    const PlantID = useSelector((state) => state.USER_DETAILS.PlantID); // logged in user auth token
    const [LoadingData, setLoadingData] = useState(false)
    const [IndustryData, setIndustryData] = useState({})

    // function for getting data of the industry and saving to the redux store. 
    async function getIndDetails() {
        await dispatch(GetIndustryByIDPlantSide({
            AuthToken: AuthToken,
            IndustryID: industryID,
            setLoading: setLoadingData,
            plantID: PlantID
        })).then((data) => {
            if (typeof (data.payload) === 'object') {
                setIndustryData(data?.payload?.industries[0] !== undefined ? data?.payload?.industries[0] : {})
            }
        })
    }

    useEffect(() => { if (Object.keys(IndustryData).length === 0) { getIndDetails() } }, [])

    return (
        <div>
            {/* <button onClick={getIndDetails}>getdetails</button> */}

            {/* Industry details, to be showed in all panels */}
            <div className="Industry_Detail_Container">

                <IconButton onClick={() => { navigate("/industries") }}>
                    <ArrowBack />
                </IconButton>&nbsp;&nbsp;

                <div className='Industry_Basic_detail_Wrapper'>
                    <h3>{LoadingData ? "Loading..." : IndustryData?.data?.companyName}</h3>
                    <p className='simpleParagraph'>{LoadingData ? "Loading..." : IndustryData?.data?.address}</p>
                </div>
            </div>

            <div className="Industries_Body_Container">
                {/* Navigation bar for naviagte through all details of the industry */}
                <Navigation />
                {/* Corresponding active outlet for the industry details */}
                <Outlet
                    context={{ IndustryData, LoadingData }}
                // IndustryData={IndustryData}
                // LoadingData={LoadingData}
                />

            </div>

        </div>
    )
}