import { Add, Cancel } from "@mui/icons-material";
import { useState } from "react";
import { TextField, Autocomplete, Button, IconButton } from "@mui/material";
import React from "react";
import "./InventoryStyles/AddItem.css";
import { useDispatch, useSelector } from "react-redux";
import { GetInventoryConsumables, GetInventoryNonConsumables } from "../../Redux/Slices/InventorySlices/InventorySlice";
import { LoadingButton } from "@mui/lab";
import { showTicker } from "../../Redux/Slices/TickerSlice";
import { SERVER_ADDRESS } from "../../Firebase/Variables";

export default function AddItem(props) {
  const [value, setValue] = useState();
  const options = ["Consumable", "Non Consumable"];
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const [Loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  // state for saving all user inputed data for adding item
  const [EnteredData, setEnteredData] = useState({
    itemName: '',
    itemCode: '',
    itemType: '',
    itemUnit: ''
  })

  const ValidateDataBeforeAPIcall = () => {
    if (EnteredData.itemName !== '' & EnteredData.itemCode !== '' & EnteredData.itemType !== '' & EnteredData.itemType !== null & EnteredData.itemUnit !== ''
    ) {
      ADD_ITEM()
    }
    else {
      dispatch(showTicker({
        message: "Please fill the required fields",
        type: 'error'
      }))
    }
  }

  const ADD_ITEM = () => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${UserToken}`);

    var raw = JSON.stringify({
      "itemName": EnteredData.itemName,
      "itemCode": EnteredData.itemCode,
      "itemType": EnteredData.itemType,
      "itemUnit": EnteredData.itemUnit
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${SERVER_ADDRESS}/inventory/add?plantID=${PlantID}`, requestOptions)
      .then(response => {
        response.json() // Converting to JSON
        // console.log(response.status);
        // console.log(response.statusText);

        if (response.status === 201) {
          dispatch(showTicker({
            message: 'Item Added Successfully',// iF The response if 200, then its ok, item updated in database.
            type: 'success' // Showing success type ticker
          }))
          if (EnteredData.itemType === 'Consumable') { dispatch(GetInventoryConsumables({ AuthToken: UserToken, PlantID: PlantID })) } // Checking the typw of the item and based on that, we are refetchin the list. 
          else { dispatch(GetInventoryNonConsumables({ AuthToken: UserToken, PlantID: PlantID })) }
        }
        else {
          dispatch(showTicker({
            message: response.statusText,// But if the response is other than 200, there must be sonething wrong
            type: 'error'// Showing error type ticker
          }))
        }
      })
      .then(result => {
        props.setActiveModal(0)
        setLoading(false)
      })
      .catch(error => {
        dispatch(showTicker({
          message: "Please fill the required fields",
          type: 'error'
        }))
        setLoading(false)
      });
  }

  // function for restricting the user to type and number and symbols in the unit feild
  const handleUnit = (event) => {
    const inputValue = event.target.value;
    // Check if the input contains only alphabets
    const onlyAlphabets = /^[a-zA-Z\s]*$/;
    if (!onlyAlphabets.test(inputValue)) {
      // If input contains non-alphabet characters, prevent the change
      event.preventDefault();
    }
    else {
      setEnteredData({ ...EnteredData, itemUnit: event.target.value })
    }
  }

  return (
    <div className="Modal_Black_bg">
      <div className="Modal_base">

        {/* Heading of the modal */}
        <div className="Modal_heading">
          Add New Item
          {/* for closing popup we have to set Active modal = 0 */}
          <IconButton color="PrimaryBlue" onClick={() => { props.setActiveModal(0) }}>
            <Cancel />
          </IconButton>
        </div>

        <div className="Modal_content">
          <div className="Item_info">
            <TextField
              sx={{ width: "49%" }}
              size="small"
              label="Item Name"
              placeholder="Item Name"
              autoFocus={true}
              onChange={(e) => { setEnteredData({ ...EnteredData, itemName: e.target.value }) }}
            />
            <TextField
              sx={{ width: "49%" }}
              size="small"
              label="Item Code"
              placeholder="Item Code"
              onChange={(e) => { setEnteredData({ ...EnteredData, itemCode: e.target.value }) }}
            />
          </div>
          <br />
          <div className="Item_Category">
            <Autocomplete
              size="small"
              value={value}
              onChange={(event, newValue) => {
                setEnteredData({ ...EnteredData, itemType: newValue });
              }}
              id="controllable-states-demo"
              options={options}
              sx={{ width: "49%" }}
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
            />
            <div className="Item_unit">
              <TextField
                label="Unit"
                name="Unit"
                value={EnteredData.itemUnit}
                onChange={handleUnit}
                sx={{ width: "100px" }}
                size="small"
                placeholder="Item Unit"
              />
            </div>
          </div>
          <div className="Item_addbtn">
            <LoadingButton loading={Loading ? true : false} variant="contained" size="large" color='PrimaryBlue' disableElevation startIcon={<Add />} onClick={ValidateDataBeforeAPIcall}>
              Add
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
}
