import React, { useState, useEffect } from "react";
import "./Navigation.css";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetFilters, GetFilteredInstruments, GetCategories, setCategory } from "../../Redux/Slices/ProcessManagementSlices/ProcessManagementSlice";

export default function Navigation() {
  const [selectedButton, setSelectedButton] = useState("");
  const dispatch = useDispatch();

  const Categories = useSelector(state => state.PROCESS_MANAGEMENT.Categories)
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)

  useEffect(() => {
    const getCategories = async () => {
      dispatch(GetCategories({ AuthToken: UserToken, PlantID: PlantID }));
    }
    getCategories()
  }, [dispatch, UserToken])

  useEffect(() => {
    setSelectedButton(Categories?.data ? Categories?.data[0] : "")
    const category = Categories?.data ? Categories?.data[0] : ""
    dispatch(GetFilters({ category, UserToken, PlantID }))
    dispatch(setCategory(category))
    dispatch(GetFilteredInstruments({ category, UserToken, PlantID }))
  }, [Categories, dispatch, UserToken])

  return (
    <div className="Navigation_Wrapper">
      <ToggleButtonGroup value={selectedButton} color="PrimaryBlue">
        {Categories?.data?.map((category) => {
          return (
            <ToggleButton
              key={category}
              sx={{ border: "none", borderRadius: "0" }}
              onClick={() => {
                setSelectedButton(category)
                dispatch(setCategory(category))
                dispatch(GetFilters({ category, UserToken, PlantID }))
                dispatch(GetFilteredInstruments({ category, UserToken, PlantID }))
              }}
              value={category}
            >
              {category}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
    </div>
  );
}
