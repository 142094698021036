import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { GetMasterCopies } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/MasterCopySlice'
import { status } from '../../../../Redux/Slices/userSlice'
import { ConfirmationPopup, SomethingWentWrong, UniversalLoader } from '../../../UI Components/Basic'
import { DrawerRoutes } from '../../../Routes/routes'
import { useState } from 'react'
import { Box } from '@mui/system'
import BillHeader from './BillHeader'
import BillBody from './BillBody'
import { Button, Divider, IconButton, Stack, TextField } from '@mui/material'
import BillFooter from './BillFooter'
import { billGeneratingFor, resetData } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/BillGenerationSlice'
import { ArrowBack } from '@mui/icons-material'

export default function GenerateBill() {
    const { industryID } = useParams() // Getting the industry id for which we are goiing to generate the bill. 
    const dispatch = useDispatch() // For dispatching actions. 
    const AuthToken = useSelector((state) => state.USER_DETAILS.AuthToken); // logged in user auth token
    const PlantID = useSelector((state) => state.USER_DETAILS.PlantID); // logged in user auth token
    const MasterBillsLoading = useSelector((state) => state.BILL_MASTER_COPIES.Loading); // Loading for bills copy fetching
    const MasterCopies = useSelector((state) => state.BILL_MASTER_COPIES.Copies); // Loading for bills copy fetching
    const navigate = useNavigate()  // for navvigating from pages


    // Use a ref to keep track of whether fetch has been initiated
    const isFetchInitiated = useRef(false); // this logic is implemented becuse a probelm is happening, this fetch master copy function i running 2 times and we dont know the reson for that at thaat time. so we build a function which restricts that function to run twice. 

    // check function for checking that master copies is fetched or not and if fetched it contains any master copies or not in the server side. 
    const checkAndFetchMasterCopies = () => {
        if (!isFetchInitiated.current && !MasterCopies?.billMasterCopies) {
            // Set the ref to true to indicate fetch initiation
            isFetchInitiated.current = true;
            dispatch(GetMasterCopies({ AuthToken: AuthToken, PlantID: PlantID }));
        }
    };

    // a use effect function with the dependency mastercopies, when the mastercopies is going to change, that function will run to check the bill is avialble in the dataabase or not. 
    useEffect(() => {

        // checking mastercopies in the redux store. 
        checkAndFetchMasterCopies();

        // storing the id of the industry for which bill is going to generate. this stored id will be used in child components for bill generation. 
        dispatch(billGeneratingFor(industryID))

    }, [MasterCopies]);

    // it is a state for store the state for alert popup which going to pop wheh there is no master copies found in the data database. at that time we tell user to create a new master copy and then try to generate a bill. 
    const [ShowAlertForNoMAsterCopy, setShowAlertForNoMAsterCopy] = useState(false) // State for showing the alert if user not have any master bill copies
    //checking the fetched master bills have any bills or not, if yes then go to generate bill and if not then go to create a aster copy. 
    useEffect(() => {
        if (MasterBillsLoading === status.IDLE && MasterCopies?.billMasterCopies) {
            if (Object.keys(MasterCopies.billMasterCopies).length > 0) {
                return
            } else {
                setShowAlertForNoMAsterCopy(true)
            }
        }
    }, [MasterBillsLoading, MasterCopies]);


    // Use useEffect to perform cleanup when the component unmounts
    useEffect(() => {
        return () => {
            dispatch(resetData())
        };
    }, [dispatch]);

    useEffect(() => {
        // Function to show confirmation popup when the user tries to leave the page
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            return '';
        };
        // Attach the event listener when the component mounts
        window.onbeforeunload = handleBeforeUnload;
        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    return (
        <div>
            {/* showing alerts when user did not have any master copies */}{
                ShowAlertForNoMAsterCopy ? <ConfirmationPopup
                    PrimaryButtonText={'Create'}
                    PrimaryAction={() => { navigate(DrawerRoutes.Reports.GenerateMasterBill) }}
                    SecondaryAction={() => { navigate(DrawerRoutes.Reports.Financial_Reports) }}
                    message={"You don't have any Master bills. Master bills are important for generating bills, Would you like to create a new Master bill?"}
                    color={'warning'}
                /> : ''
            }


            <div className="PAGE_HEADER">
                {/* Name of the page, will show in lwft of the header */}
                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /> </IconButton>
                    <h3>Generating Bill</h3>
                </Stack>
            </div>


            {/* this bill generaation process will contain 3 sections, 
            1 - bill header (billl header will contain all the information about the plant and of the industry for which bill is generating. ) 
            2 - Bill body (billl body will contain all the infomation of the goods, and have all the functionalities for adding a good, including selecting master copies, adding good information, editing goods and deleting the goods. ) 
            3 - Bill footer ( it will contain other informarion of the bill like terms and condns, descriptions, last date and other important info.) */}
            <div className="Industries_Body_Container" style={{ overflowY: 'scroll' }}>
                {MasterBillsLoading === status.IDLE ? <>
                    <BillHeader />
                    <Divider />
                    <BillBody />
                    <Divider />
                    <BillFooter />
                    <br />
                </> :
                    MasterBillsLoading === status.LOADING ? <UniversalLoader /> : // showing loader when master bills is loading. 
                        MasterBillsLoading === status.ERROR ? <SomethingWentWrong /> : '' // Showing error when something is wrong in fetching master bills
                }
            </div>
        </div>
    )
}
