import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { GetChambers, setModalState, setSelectedChamber } from '../../../Redux/Slices/ProcessManagementSlices/ChamberManagementSlice';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import AddChamber from "./AddChamber";
import Swap from "./Swap";
import Remove from "./Remove";
import { status } from "../../../Redux/Slices/userSlice";
import { CircularLoader, SomethingWentWrong } from "../../UI Components/Basic";

const Chamber = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const ChambersList = useSelector(state => state.CHAMBER_MANAGEMENT.Chambers)
  const CHAMBER_LOADING = useSelector(state => state.CHAMBER_MANAGEMENT.GetChamberLoading)
  const [ActiveModal, setActiveModal] = useState({ 
    ActiveModalIndex: 0,
    chamberid: "",
    type: "",
    data: {}
  })

  const handleAddChamber = () => {
    dispatch(setModalState(1))
    setActiveModal({
      ActiveModalIndex: 1,
      type: "Chamber",
      data: {}
    })
  }

  const handleEditChamber = (id, data) => {
    dispatch(setSelectedChamber({
      id: id,
      data: data
    }))
    navigate(`/chambers/${id}`)
  }

  const handleDeleteChamber = (chamberid, name) => {
    setActiveModal({
      ActiveModalIndex: 3,
      chamberid: chamberid,
      type: "Chamber",
      data: {
        action: "delete",
        name: name
      }
    })
  }

  const handleSwapChamber = () => {
    setActiveModal({
      ActiveModalIndex: 2,
      chamberid: "",
      type: "Chamber",
      data: {}
    })
  }

  useEffect(() => {
    dispatch(GetChambers({ UserToken, PlantID }))
  }, [PlantID, UserToken, dispatch])

  return (
    <div>
      {ActiveModal.ActiveModalIndex === 1 ? <AddChamber setActiveModal={setActiveModal} data={ActiveModal} /> :
        ActiveModal.ActiveModalIndex === 2 ? <Swap setActiveModal={setActiveModal} data={ActiveModal} /> : 
        ActiveModal.ActiveModalIndex === 3 ? <Remove setActiveModal={setActiveModal} data={ActiveModal} /> : ""
      }

      <div className="PAGE_HEADER">
        <Stack 
          direction="row" 
          justifyContent="space-between" 
          alignItems="center"
          sx={{ width: '100%' }}
        >
          {/* Name of the page, will show in left of the header */}
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /> </IconButton>
            <h3>Chambers</h3>
          </Stack>

          <Stack 
            direction="row"
            spacing={1}
          >
            <Button
              variant="contained"
              type="button"
              color="SecondaryBtn"
              startIcon={<SwapHorizontalCircleIcon fontSize="large" style={{ width: "20px" }} />}
              disableElevation
              onClick={handleSwapChamber}
            >
              Swap Positions
            </Button>
            <Button
              variant="contained"
              type="button"
              color="SecondaryBtn"
              startIcon={<AddCircleIcon style={{ width: "20px" }} />}
              disableElevation
              onClick={handleAddChamber}
            >
              Add Chamber
            </Button>
          </Stack>

        </Stack>

      </div>

      <div className="Industries_Body_Container">
        <Table>
          {/* Table Header */}
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingLeft: "20px", width: "120px" }}>Position</TableCell>
              <TableCell sx={{ width: "50rem" }}>Chamber</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          {CHAMBER_LOADING === status.IDLE ? 
            <TableBody>
              {
                ChambersList.chamber.map((chamber, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ paddingLeft: "35px" }}>{chamber.data.position}</TableCell>
                    <TableCell>{chamber.data.chamberName}</TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}> 
                        <Button 
                          variant="contained" 
                          color="warning" 
                          sx={{ height: "2rem", fontWeight: "700"}}
                          size="small" 
                          disableElevation 
                          onClick={() => handleEditChamber(chamber.id, chamber.data)}
                        >
                          Edit
                        </Button>
                        <Button 
                          variant="contained" 
                          color="error" 
                          sx={{ height: "2rem", fontWeight: "700"}}
                          size="small" 
                          disableElevation 
                          onClick={() => handleDeleteChamber(chamber.id, chamber.data.chamberName)}
                        >
                          Delete
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))

              }

            </TableBody> : CHAMBER_LOADING === status.LOADING ?  <CircularLoader /> 
            : CHAMBER_LOADING === status.ERROR ? <SomethingWentWrong /> : ''
          }
        </Table>
      </div>

    </div>
  )
}

export default Chamber