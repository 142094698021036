// Currently avialable permissions on the plant p0
export const PlantPermission = {
    All: "All",
    IndustryRead: "Industry-Read",
    IndustryWrite: "Industry-Write",
    NoticeRead: "Notices-Read",
    NoticeWrite: "Notices-Write",
    ProcessRead: "Process-Read",
    ProcessWrite: "Process-Write",
    ProcessRead: "Reports-Read",
    ProcessWrite: "Reports-Write",
    InventoryRead: "Inventory-Read",
    InventoryWrite: "Inventory-Write",
    BillRead: "Bill-Read",
    BillWrite: "Bill-Write",
    SludgeRead: "Sludge-Read",
    SludeWrite: "Sludge-Write",
    DepartmentRead: "Department-Read",
    DepartmentWrite: "Department-Write"
}