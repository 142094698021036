import React from 'react'
import { ErrorTicker, ProceedButton } from './AuthUIComponents'
import OtpInput from 'react-otp-input';
import { useState } from 'react';
import { MailVerificationIllustration, VysionLogo_Login } from '../assets/assets';

export default function OTPverification() {
    const [otp, setOtp] = useState(''); // for storing entered otp
    const [error, setError] = useState(''); // Error 
    const [Loading, setLoading] = useState(false) //Loading


    const a = () => {
        // console.log("eheyeyeeyeyeye");
    }


    return (
        <>
            <div className="Grid1">
                {error && <ErrorTicker message={error} reset={setError} />}
                <div className="Login" id="login">
                    <img className="Logologin" src={MailVerificationIllustration} />
                    <p className="Message">A six digit OTP is sent to your registered phone number. Please enter OTP below.</p>
                    <div className='OTPWrapper'>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span>&nbsp;</span>}
                            renderInput={(props) => <input {...props} />}
                            shouldAutoFocus={true}
                        />
                    </div>

                    <ProceedButton
                        targetFunction={a}
                        title={"Login"}
                        loading={Loading} />
                </div>
            </div>
        </>
    )
}
