// this file is a dedicated component or page which renders/show all previous bills and Payments of a industry passed in this function 
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DeleteBill } from '../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/IndustryBillsSlice'
import { Box, Button, Chip, Divider, IconButton, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Delete, Download, MoreVert, Payment, Send } from '@mui/icons-material'
import { ConfirmationPopup } from '../../UI Components/Basic'
import LoadingButton from '@mui/lab/LoadingButton';
import { downloadBill, setDownloadBillID } from '../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/BillGenerationSlice'
import { DrawerRoutes } from '../../Routes/routes'
import { showTicker } from '../../../Redux/Slices/TickerSlice'
import { GetIndName } from '../../../Redux/Slices/Industry/GetIndustriesSlice'
import { NOTICE_PERMISSIONS } from '../../../Roles/Roles'
import { convertGMTtoISTAndFormat } from './DedicatedIndustryBill'

export function BillSideDetailsPlantSide(props) {
    const [MoreMenu, setMoreMenu] = useState(false)
    const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)
    const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [DeleteCNFpopup, setDeleteCNFpopup] = useState(false)
    const [DeletingLoading, setDeletingLoading] = useState(false)
    const DownloadBillID = useSelector(state => state.BILL_GENERATION.downloadBillID)
    const UserRole = useSelector(state => state.USER_DETAILS.UserRole)
    const accessLevel = useSelector(state => state.USER_DETAILS.UserAccessLevel)
    const departments = useSelector(state => state.USER_DETAILS.UserDetails?.departmentAccess)
    const NoticePermissions = NOTICE_PERMISSIONS(UserRole, accessLevel, departments)
    const [IndustryName, setIndustryName] = useState(props.IndustryName)
    const [loadingName, setLoadingName] = useState(false)


    // function for getting the industry name from the industry id. because we need the industry name in the downlaod bill. 
    async function GetIndustryName() {
        await dispatch(GetIndName({
            AuthToken: AuthToken,
            IndustryID: props.industryID,
            setLoading: setLoadingName,
            PlantID: PlantID
        })).then((data) => { setIndustryName(data?.payload?.industry?.data?.name) })
    }

    useEffect(() => { if (IndustryName === "" || IndustryName === undefined || IndustryName === null) { GetIndustryName() } }, []) // fetch the name of the Industry

    // function  for handling the downloading of the bill 
    const downloadHandler = (billid) => {
        if (!IndustryName) {
            // If IndustryName is not defined, fetch it first and then initiate the download
            setLoadingName(true); // Set loading state while fetching the industry name
            dispatch(GetIndName({
                AuthToken: AuthToken,
                IndustryID: props.industryID,
                setLoading: setLoadingName,
                PlantID: PlantID
            })).then((data) => {
                // Once the industry name is fetched, initiate the download
                setIndustryName(data?.payload?.industry?.data?.name)
                dispatch(setDownloadBillID(billid));
                dispatch(downloadBill({ industryid: props.industryID, industryName: data?.payload?.industry?.data?.name, billid: billid, AuthToken: AuthToken, PlantID: PlantID }));
            }).catch((error) => {
                // Handle error if fetching industry name fails
                console.error("Error fetching industry name:", error);
            });
        } else {
            // If IndustryName is already defined, initiate the download directly
            dispatch(setDownloadBillID(billid));
            dispatch(downloadBill({ industryid: props.industryID, industryName: IndustryName, billid: billid, AuthToken: AuthToken, PlantID: PlantID }));
        }
    }

    // function for checking the bill is overdue or not?
    function calculateAmountWithInterest(amount, interestRate, lastDate, isPaid, datePaid) {

        // console.log(amount, interestRate, lastDate, isPaid, datePaid);
        const [day, month, year] = lastDate.split('/');
        const lastDateObj = new Date(`${year}-${month}-${day}`);  // Parse the last date string into a Date object
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (isPaid) {
            const [day, month, year] = datePaid.split('/');
            const datePaidObj = new Date(`${year}-${month}-${day}`);
            const paidBefore = datePaidObj < lastDateObj // checking bill is paid before or after the laste date. 

            if (paidBefore) {
                let newAmount = amount
                let interestAmount = 0
                return { isOverdue: false, paidBefore: true, interestAmount, newAmount };
            }
            else if (!paidBefore) {
                let interestAmount = amount * (interestRate / 100);
                let newAmount = amount + interestAmount;
                return { isOverdue: false, paidBefore: false, interestAmount, newAmount };
            }
        }
        else if (!isPaid) {
            const isBillOverdue = lastDateObj < today;
            if (isBillOverdue) {
                let interestAmount = amount * (interestRate / 100);
                let newAmount = amount + interestAmount;
                return { isOverdue: true, paidBefore: true, interestAmount, newAmount };
            }
            else if (!isBillOverdue) {
                let interestAmount = 0;
                let newAmount = amount + interestAmount;
                return { isOverdue: false, paidBefore: true, interestAmount, newAmount };
            }
        }
    }

    // calculating the interset of the bill if the bill is overdue
    const isOverdue = calculateAmountWithInterest(
        props.billData?.amount,
        props.billData?.requiredFields?.interestRate,
        convertGMTtoISTAndFormat(props.billData?.requiredFields?.lastDate),
        props.billData?.isPaid,
        convertGMTtoISTAndFormat(props.billData?.datePaid)
    )


    // render payment details of the payment. This function is sepcified for the BHIWADI plant because of the HDFC payments integrated to it. 
    const renderPaymentDetails = () => {
        if (props.billData?.paymentData !== undefined && props.billData?.paymentData !== null) {
            return <Stack>
                <Typography variant='body2'>Date of the Payment - {props.billData?.isPaid ? convertGMTtoISTAndFormat(props.billData?.datePaid) : "Not Paid Yet"}</Typography>
                {/* <Typography variant='body2'>Payment Method : &nbsp;
                    {props.billData?.paymentData?.method}&nbsp;
                    {
                        props.billData?.paymentData?.vpa !== null ? `| ${props.billData?.paymentData?.vpa}` :
                            props.billData?.paymentData?.wallet !== null ? `| ${props.billData?.paymentData?.wallet}` :
                                props.billData?.paymentData?.card_id !== null ? `| ${props.billData?.paymentData?.card_id}` : ''

                    }
                </Typography> */}
                <Typography variant='body2'>Order ID : {props.billData?.paymentData?.orderid !== null && props.billData?.paymentData?.orderid !== undefined ? props.billData?.paymentData?.orderid : 'Not available'}</Typography>
                <Typography variant='body2'>Transaction ID : {props.billData?.paymentData?.paymentid !== null && props.billData?.paymentData?.paymentid !== undefined ? props.billData?.paymentData?.paymentid : "Not Available"}</Typography>
            </Stack>
        }
        else {
            return <Stack>
                <Typography variant='body2'>Date of the Payment - {props.billData?.isPaid ? convertGMTtoISTAndFormat(props.billData?.datePaid) : "Not Paid Yet"}</Typography>
                {props.billData?.isPaid ? <Typography variant='body2'>Payment Method : Paid Externally</Typography> : ''}
            </Stack>
        }
    }

    // a small function for showing mm-dd-yyyy format to dd-mm-yyy format. because from database we are getting starting and ending dates in mm-dd-yyyy format
    const flip = (dateString) => {
        const [month, day, year] = dateString.split('/')
        return `${day}/${month}/${year}`
    }

    return <Stack width={800} sx={{ position: 'relative' }}>
        {/* {props.billID} */}
        <Stack p={2} direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction='column'>
                <Typography variant='h6'>{props.billData?.description}</Typography>
                <Typography variant='body2'>Bill Date - {convertGMTtoISTAndFormat(props.billData?.dateCreated)}</Typography>
            </Stack>
            <Chip label={`Quotation No - ${props.billData?.quotationNo}`} color='PrimaryBlue' variant='outlined' />
        </Stack>

        <Divider />

        <Box p={2} sx={{ backgroundColor: props.billData?.isPaid === true ? "#00ff001a" : props.billData?.isPaid === false ? "#ff000014" : 'white', transition: '0.3s' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                {renderPaymentDetails()}
                <Chip label={props.billData?.isPaid ? "Paid" : 'Not Paid'} color={props.billData?.isPaid ? "success" : "PrimaryRed"} />
            </Stack>

            {
                props.billData?.paymentRecieptStatus === 1 && !(props.billData?.isPaid) ? <Typography variant='body2'>Industry Uploaded Payment Receipt. <span className='link' onClick={() => { navigate(DrawerRoutes.Reports.PaymentReceipts) }}>Review it now</span></Typography> : ""
            }
        </Box>

        <Divider />

        <Stack p={2} direction="row" justifyContent="space-between" position="relative">
            <Stack direction="row" spacing={1}>
                <LoadingButton loading={DownloadBillID === props.billID} loadingPosition="start" variant='contained' color='PrimaryBlue' onClick={() => { downloadHandler(props.billID) }} disableElevation startIcon={<Download sx={{ width: '16px' }} />}>Download Bill</LoadingButton>
                {
                    NoticePermissions.isAllowedToSendNotices ? <Button
                        variant='contained'
                        color='PrimaryBlue'
                        onClick={() => { navigate(DrawerRoutes.SendNotice, { state: { noticeTo: props.industryID } }) }}
                        disableElevation
                        startIcon={<Send sx={{ width: '16px' }} />}
                    >Send Notice</Button> : ''
                }
                <Button variant='contained' color='PrimaryBlue' disableElevation disabled={!props.billData?.isPaid} onClick={() => { props.billData?.paymentRecieptLink !== "" ? window.open(props.billData?.paymentRecieptLink, "_blank") : dispatch(showTicker({ message: 'Payment recipt not available', type: 'error' })) }} startIcon={<Payment sx={{ width: '16px' }} />}>Download Payment recipt</Button>
            </Stack>
            <IconButton onClick={() => { setMoreMenu(!MoreMenu) }}>
                <MoreVert />
            </IconButton>


            {
                MoreMenu ? <Stack direction="column" boxShadow="0px 0px 10px #00000021" borderRadius={2} sx={{ position: 'absolute', right: '20px', top: '100%', zIndex: 2, backgroundColor: 'white' }}>
                    <MenuItem
                        onClick={() => { setDeleteCNFpopup(true) }}>
                        <Delete sx={{ width: '16px' }} />&nbsp;&nbsp;Delete Bill
                    </MenuItem>
                </Stack> : ''
            }


            {
                DeleteCNFpopup ? <ConfirmationPopup

                    PrimaryButtonText={"Delete"}
                    PrimaryAction={() => {
                        dispatch(DeleteBill({
                            billID: props.billID,
                            industryID: props.industryID,
                            AuthToken: AuthToken,
                            setDeletingLoading: setDeletingLoading,
                            setDeleteCNFpopup: setDeleteCNFpopup,
                            setBillDetails: props.setBillDetails,
                            PlantID: PlantID
                        }))
                    }}
                    SecondaryAction={() => { setDeleteCNFpopup(false) }}
                    message={"Are you sure you want to delete this bill?"}
                    color={"PrimaryRed"}
                    Loading={DeletingLoading}

                /> : ''
            }

        </Stack>

        <Divider />

        {/* Goods table, All the added goods will list here.  */}
        <Box p={2} zIndex={0} position="relative">
            <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ paddingLeft: '20px', fontSize: '12px' }}>Services</TableCell>
                            <TableCell sx={{ fontSize: '12px' }}>Good Type</TableCell>
                            <TableCell sx={{ fontSize: '12px' }}>Consumption</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody sx={{ position: "relative" }}>
                        {
                            props.billData?.goods !== undefined && props.billData?.goods !== null ?
                                props.billData?.goods.length !== 0 ?
                                    props.billData?.goods.map((good, index) => {
                                        return <TableRow key={index}>
                                            <TableCell sx={{ paddingLeft: '20px', fontSize: '12px' }}>{flip(good.starting)} - {flip(good.ending)}</TableCell>
                                            <TableCell sx={{ fontSize: '12px' }}>{!!good?.type ? good?.type : 'Not Available'}</TableCell>
                                            <TableCell sx={{ fontSize: '12px' }}>{!!good?.qty ? good?.qty : 'Not Available'} meter cube</TableCell>
                                        </TableRow>
                                    })
                                    : ""
                                : ''
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

        <Stack direction="row" spacing={1} sx={{ position: 'fixed', bottom: '30px', right: '30px', zIndex: 1 }}>
            <Box component="section" sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', backgroundColor: 'var(--Tertiary_Blue)', position: "relative", width: 'fit-content' }}>

                <Typography variant='body2'>Bill Amount</Typography>

                {
                    isOverdue?.isOverdue || !isOverdue.paidBefore ?
                        <Typography variant='caption'>
                            ₹{props.billData?.amount.toFixed(2)}
                            &nbsp;+&nbsp;
                            <span style={{ color: "red" }}>
                                ₹{isOverdue?.interestAmount.toFixed(2)} (Late charges)
                            </span>
                        </Typography> : ''
                }

                <Typography variant='h6'>₹{isOverdue?.newAmount.toFixed(2)}</Typography>

            </Box>
            <Box component="section" sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', backgroundColor: 'var(--Tertiary_Blue)', position: "relative", width: 'fit-content' }}>
                <Typography variant='body2'>Interest Rate</Typography>
                <Typography variant='h6'>{props.billData?.requiredFields?.interestRate}%</Typography>
            </Box>
            <Box component="section" sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', backgroundColor: isOverdue.isOverdue ? "#ff000014" : 'var(--Tertiary_Blue)', position: "relative", width: 'fit-content' }}>
                <Typography variant='body2'>Last Date</Typography>
                {isOverdue.isOverdue ? <Typography variant='caption' color="red">Bill is Overdue</Typography> : ''}
                <Typography variant='h6'>{convertGMTtoISTAndFormat(props.billData?.requiredFields?.lastDate)}</Typography>
            </Box>
        </Stack>
    </Stack>
}