import React, { useEffect, useState } from "react";
import { Button, Chip, Dialog, DialogTitle, IconButton, LinearProgress, ListItemIcon, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { AddCircleOutline, AssignmentInd, ModeEdit, MoreVert, PersonRemove, RemoveRedEye } from "@mui/icons-material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import Edit from "./Edit.js"; // Assuming this is the correct path
import AddUser from "./AddUser.js"; // Assuming this is the correct path
import "./Team.css";
import { useDispatch, useSelector } from "react-redux";
import { FetchUsers } from "../../Redux/Slices/MyTeamSlices/GetUsersSlice.js";
import { status } from "../../Redux/Slices/userSlice.js";
import { CircularLoader, SomethingWentWrong } from "../UI Components/Basic.js";
import { TEAMS_PERMISSIONS } from "../../Roles/Roles.js";
import Remove from "./Remove.js";

export default function Team() {
  const dispatch = useDispatch()
  const AllUsers = useSelector((state) => state.TEAM_MANAGEMENT.AllUsers)
  const Loading = useSelector((state) => state.TEAM_MANAGEMENT.Loading)
  const [searchResults, setSearchResults] = useState(null); // For saving filtered results if anyone applies a filter or search key
  const plantID = useSelector(state => state.USER_DETAILS.PlantID);
  const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)
  let searchTimeout; // FOr debouncing purpose


  // this small useffect set data to search results for mapping into the table. this is important because whenever the page loads, if the data in redux is change then the change should reflex in this table also, and direct changes is not reflecting because we are not directly mapping the redux state, we saving the redux state into filtered results or search resutlts and then mapping results in to the table.
  useEffect(() => { setSearchResults(AllUsers.users) }, [AllUsers])

  useEffect(() => {
    if (!(AllUsers?.users) && Loading !== status.LOADING && Loading !== status.ERROR) {
      dispatch(FetchUsers({ AuthToken: AuthToken, PlantID: plantID }))
    }
  }, [])

  const TeamSectionPermissions = TEAMS_PERMISSIONS() // Checking permissions for user by team permission function in roles.js
  const [ActiveModal, setActiveModal] = useState({ // 0 for none, 1for adding users, 2 for editing users, 3 for deleting users, 4 for showing user details
    ActiveModalIndex: 0,
    TargetUser: {
      userID: '',
      name: '',
      postName: '',
      phoneNo: '',
      accessLevel: null,
      departmentAccess: []
    }
  })// For showing modals on the screen. 0 for none, 1 for add user, 2 for edit users

  // function for searching a item in the search bar
  const handleSearch = (searchQuery) => {
    const lowercaseQuery = searchQuery.toLowerCase();// Convert the search query to lowercase for case-insensitive search
    const filteredResults = AllUsers.users.filter( // Filter inventory items based on name or code containing the search query
      item =>
        item.data.name.toLowerCase().includes(lowercaseQuery) ||
        item.data.roleName.toLowerCase().includes(lowercaseQuery) ||
        item.data.mailID.toLowerCase().includes(lowercaseQuery) ||
        item.data.phoneNo.toLowerCase().includes(lowercaseQuery)
    );
    setSearchResults(filteredResults); // Update the state with the search results
  };

  // implementing debouncing in the search bar
  const debouncedSearch = (query) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      handleSearch(query);
    }, 1000);
  };


  const [isMenuOpen, setisMenuOpen] = useState(false) // function for handling the state of the more menu of the user
  const [anchorEl, setAnchorEl] = React.useState(null) // anchor of the menu. 

  // function for opening the more menu. 
  const HandleMenuOpen = (event, data, id) => {
    setisMenuOpen(true)
    setAnchorEl(event.currentTarget);
  }

  // function for closing the more menu 
  const HandleMenuClose = () => {
    setisMenuOpen(false)
    setAnchorEl(null)
  }

  // usestate for storing the state and data of the user profile. 
  const [UserProfile, setUserProfile] = useState({
    isOpen: true,
    userData: [],
    userID: ''
  })


  return (
    <div>
      {/* <button onClick={() => { dispatch(FetchUsers({ AuthToken: AuthToken, PlantID: plantID })) }}>FEtch users</button> */}
      {ActiveModal.ActiveModalIndex === 1 ? <AddUser setActiveModal={setActiveModal} /> :
        ActiveModal.ActiveModalIndex === 2 ? <Edit setActiveModal={setActiveModal} TargetUser={ActiveModal.TargetUser} /> :
          ActiveModal.ActiveModalIndex === 3 ? <Remove setActiveModal={setActiveModal} TargetUser={ActiveModal.TargetUser} /> :
            // ActiveModal.ActiveModalIndex === 4 ? <UserProfileDialogueBox open={ActiveModal.ActiveModalIndex === 4} setActiveModal={setActiveModal} TargetUser={ActiveModal.TargetUser} /> :
            ''}

      {/* Page Header */}
      <div className="PAGE_HEADER">
        <h3>My Team</h3>

        {/* Buttons or other elements on the right side of the page header */}
        {/* <ToggleButtonGroup size="small" color="PrimaryBlue" value={1}>
          <ToggleButton value={1}>My Team</ToggleButton>
          <ToggleButton value={2}>Admins</ToggleButton>
        </ToggleButtonGroup> */}
      </div>

      {/* Body Container */}
      <div className="Industries_Body_Container">
        {/* {Loading===status.LOADING?<LinearProgress color="PrimaryBlue"/>:''} */}
        <div className="table_Heading_Container">
          {/* Search Form */}
          <div className="search_form">
            <TextField
              id="outlined-basic"
              label="Search People"
              variant="outlined"
              size="small"
              onChange={(e) => debouncedSearch(e.target.value)}
            />
          </div>

          {/* Table Toolbar */}
          <div className="table_toolbar">
            <Button
              variant="contained"
              color="PrimaryBlue"
              startIcon={<AddCircleOutline />}
              disableElevation
              disabled={!TeamSectionPermissions.isAllowedToAddUsers} // Checking if has permissions to add user or not
              onClick={() => {
                setActiveModal({
                  ActiveModalIndex: 1
                })
              }} // Setting 1 for opening the Add user modal
            >
              Add Users
            </Button>
          </div>
        </div>

        {/* Table Container */}
        {
          Loading === status.IDLE ? <TableContainer disableElevation>
            <Table>
              {/* Table Header */}
              <TableHead>
                <TableRow>
                  {/* <TableCell >S.No.</TableCell> */}
                  <TableCell sx={{ paddingLeft: "30px", }}>Name</TableCell>
                  <TableCell>Post</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>

              {/* Table Body */}
              <TableBody>
                {
                  searchResults !== null && searchResults !== undefined ?
                    searchResults
                      .filter(user => user.data.accessLevel !== 1)
                      .map((user) => (
                        <TableRow key={user.id}>
                          {/* <TableCell >{searchResults.indexOf(user) + 1}</TableCell> */}
                          <TableCell sx={{ paddingLeft: "30px" }}>{user.data.name}</TableCell>
                          <TableCell>{user.data.postName}</TableCell>
                          <TableCell>{user.data.mailID}</TableCell>
                          <TableCell> {GetDepartmentArray(user.data.departmentAccess)}</TableCell>
                          <TableCell sx={{ position: "relative" }}>
                            <Button
                              size="small"
                              disabled={!TeamSectionPermissions.isAllowedActions}
                              color="PrimaryBlue"
                              onClick={() => {
                                setActiveModal({
                                  ActiveModalIndex: 2,
                                  TargetUser: {
                                    userID: user.id,
                                    name: user.data.name,
                                    phoneNo: user.data.phoneNo,
                                    postName: user.data.postName,
                                    accessLevel: user.data.accessLevel,
                                    departmentAccess: user.data.departmentAccess
                                  }
                                });
                              }}
                            >Edit</Button>
                            <Button
                              size="small"
                              disabled={!TeamSectionPermissions.isAllowedActions}
                              color="PrimaryRed"
                              onClick={() => {
                                setActiveModal({
                                  ActiveModalIndex: 3,
                                  TargetUser: {
                                    userID: user.id,
                                    name: user.data.name
                                  }
                                });
                              }}>Remove</Button>

                            {/* <IconButton
                            onClick={() => {
                              setActiveModal({
                                ActiveModalIndex: 4,
                                TargetUser: {
                                  userID: user.id,
                                  name: user.data.name,
                                  phoneNo: user.data.phoneNo,
                                  postName: user.data.postName,
                                  accessLevel: user.data.accessLevel,
                                  departmentAccess: user.data.departmentAccess
                                }
                              });
                            }}>  <MoreVert fontSize="small" /> </IconButton> */}
                          </TableCell>
                        </TableRow>
                      )) : <p className="simpleParagraph" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>No Items found :-(</p>
                }
              </TableBody>
            </Table>
          </TableContainer>
            : Loading === status.LOADING ? <CircularLoader /> //Showing loading indicator when loading
              : Loading === status.ERROR ? <SomethingWentWrong />
                : ''}
      </div>
      {/* <MoreMenu open={isMenuOpen} anchorEl={anchorEl} close={HandleMenuClose} setUserProfile={setUserProfile} /> */}
    </div>
  );
}


// this function takes an array of access and return the access chip in the list.
function GetDepartmentArray(array) {
  // console.log(array)
  if (array !== undefined && array.length !== 0) {
    const [department, accessType] = array[0].split('-');
    return <div className="morePermissionsParent">
      <Chip
        label={department}
        variant="contained"
        color="PrimaryBlue"
        icon={accessType === 'Read' ? <RemoveRedEye sx={{ width: '14px' }} /> : <ModeEdit sx={{ width: '14px' }} />}
        sx={{ height: "28px", fontSize: '12px', padding: '0px 0px 0px 4px' }} />
      <div className="morePermissions">phohikjbkjbkjohi</div>
      &nbsp;
      {array.length > 1 ?
        <Chip
          label={`+${array.length - 1}`}
          variant="outlined"
          color="PrimaryBlue"
          sx={{ height: "28px" }}
        /> : ''}
    </div>
  }

  else if (array === undefined) {
    return <Chip
      variant="contained"
      sx={{ height: "28px", fontSize: '12px', padding: '0px 0px 0px 4px' }}
      color="PrimaryBlue"
      icon={<ModeEdit sx={{ width: '14px' }} />}
      label="All"
    />
  }

  else if (array.length === 0) {
    return <Chip
      variant="contained"
      sx={{ height: "28px", fontSize: '12px', padding: '0px 0px 0px 4px' }}
      label="No access assigned"
    />
  }
}


function UserProfileDialogueBox(props) {
  return <Dialog
    onClose={() => { props.setActiveModal(null) }}
    open={props.open}
  >
    <DialogTitle>Set backup account</DialogTitle>
    {props.TargetUser.name}
  </Dialog>
}



function MoreMenu(props) {
  const HandleOpenUserProfile = () => {
    // console.log("hey");
  }
  return <Menu
    sx={{ position: "absolute", top: '0' }}
    id="basic-menu"
    open={props.open}
    onClose={props.close}
    anchorEl={props.anchorEl}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
  >
    <MenuItem onClick={HandleOpenUserProfile}><ListItemIcon><PersonRemove fontSize="small" /></ListItemIcon> Suspend User</MenuItem>
    <MenuItem onClick={HandleOpenUserProfile}><ListItemIcon><AssignmentInd fontSize="small" /></ListItemIcon>More Details</MenuItem>
  </Menu >
}