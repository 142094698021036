import './App.css'
import Main_Layout from './Components/Main_Layout';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { AuthenticationRoutes, DrawerRoutes, IndustrySideRoutes, PaymentRoutes } from './Components/Routes/routes';
import UserSelection from './UserAuthentication/UserSelection';
import IndustryLogin from './UserAuthentication/IndustryLogin';
import PlantLogin from './UserAuthentication/PlantLogin';
import OTPverification from './UserAuthentication/OTPverification';
import IndustryRegistrationForm from './UserAuthentication/IndustryRegistrationForm';
import ForgotPassword from './UserAuthentication/ForgotPassword';
import LoginProtectedRoute from './Components/Routes/ProtectedRoutes/LoginProtectedRoute';
import NoInternet from './Components/OtherComponents/NoInternet';
import { ErrorTicker } from './UserAuthentication/AuthUIComponents';
import { useDispatch, useSelector } from 'react-redux';
import PageNotFound from './Components/OtherComponents/PageNotFound';
import UserAccessProtected from './Components/Routes/ProtectedRoutes/UserAccessProtected';
import Industries from './Components/IndustryPageComponents/Industries';
import Dashboard from './Components/DashboardComponents/Dashboard';
import AddIndustry from './Components/IndustryPageComponents/AddIndustry';
import IndustriesRequests from './Components/IndustryPageComponents/IndustriesRequests';
import IndustryDetailWrapper from './Components/IndustryPageComponents/IndustryDetailWrapper';
import AboutIndustry from './Components/IndustryPageComponents/IndustryDetailsComponents/AboutIndustry';
import IndustryEfluentDetails from './Components/IndustryPageComponents/IndustryDetailsComponents/IndustryEffluentDetails';
import IndustryBills from './Components/IndustryPageComponents/IndustryDetailsComponents/IndustryBills';
import IndustryNotices from './Components/IndustryPageComponents/IndustryDetailsComponents/IndustryNotices';
import IndustrySettings from './Components/IndustryPageComponents/IndustryDetailsComponents/IndustrySettings';
import Nonconsumeables from './Components/InventoryComponents/Nonconsumeables';
import Consumables from './Components/InventoryComponents/Consumables';
import NotificationsNotices from './Components/NoticeNotificationsComponents/Notifications';
import SendNotice from './Components/NoticeNotificationsComponents/SendNotice';
import BillsPayments from './Components/ReportsComponents/BillsAndPayments/BillsPayments';
import Team from './Components/TeamManagement/Team';
import DedicatedIndustryBill from './Components/ReportsComponents/BillsAndPayments/DedicatedIndustryBill';
import Process from './Components/ProcessManagement/Process';
import SensorAlerts from './Components/ProcessManagement/SensorAlerts';
import GenerateBill from './Components/ReportsComponents/BillsAndPayments/BillGeneration/GenerateBill';
import GenerateMasterBill from './Components/ReportsComponents/BillsAndPayments/MasterCopies/GenerateMasterBill';
import MasterCopies from './Components/ReportsComponents/BillsAndPayments/MasterCopies/MasterCopies';
import IndustrySideBills from './Components/ReportsComponents/BillsAndPayments/IndustrySideBillsandPayments/IndustrySideBills';
import PlantProfile from './Components/OtherComponents/PlantProfile';
import CCTV from './Components/CCTV/CCTV';
import UnderConstruction from './Components/OtherComponents/UnderConstruction';
import IndustryProfile from './Components/OtherComponents/IndustryProfile';
import Sludge from './Components/SludgeManagement/Sludge';
import PaymentRequests from './Components/ReportsComponents/BillsAndPayments/PaymentRequests';
import PlantSelectionSuperAdmin from './UserAuthentication/PlantSelectionSuperAdmin';
import Chamber from './Components/ProcessManagement/ChamberManagement/Chamber';
import EditChamber from './Components/ProcessManagement/ChamberManagement/EditChamber';
import InventoryReports from './Components/ReportsComponents/InventoryReports/InventoryReports';
import { useEffect } from 'react';
import EditIndustryDetails from './Components/OtherComponents/EditIndustryDetails';
import PaymentSuccess from './Components/OtherComponents/IndustrySideComponents/PaymentSuccess';
import PaymentFailed from './Components/OtherComponents/IndustrySideComponents/PaymentFailed';
import PaymentCancelled from './Components/OtherComponents/IndustrySideComponents/PaymentCancelled';
import InternalServerError from './Components/OtherComponents/IndustrySideComponents/InternalServerError';


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const location = window.location.pathname;
    console.log(location);
    // dispatch(setActive(getCurrentSection()));
  }, [dispatch]);


  return (
    <NoInternet>
      <BrowserRouter>
        <div className="App">
          <Routes>

            <Route path={AuthenticationRoutes.PlantSelectionSuperAdmin} element={<PlantSelectionSuperAdmin />}></Route>
            {/* Main application routes here */}
            <Route path={DrawerRoutes.Dashboard} element={<LoginProtectedRoute> <Main_Layout /></LoginProtectedRoute>}>

              {/* Dashboard route */}
              <Route path={DrawerRoutes.Dashboard} element={<Dashboard />} />
              {/* <Route path={DrawerRoutes.Dashboard} element={<UserAccessProtected child={"Dashboard"}><Dashboard /></UserAccessProtected>} /> */}

              {/* industry section routes */}
              {/* passing child for checking, if user have access to this page or not */}
              <Route path={DrawerRoutes.Industry.Industries} element={<UserAccessProtected child={"Industries"}><Industries /></UserAccessProtected>}></Route>
              <Route path={DrawerRoutes.Add_Industry} element={<UserAccessProtected child={"AddIndustry"}><AddIndustry /></UserAccessProtected>}></Route>
              <Route path={DrawerRoutes.Industry.Industry_requests} element={<UserAccessProtected child={"IndustriesRequests"}><IndustriesRequests /></UserAccessProtected>} />
              <Route path={DrawerRoutes.Industry.Industry_Details} element={<UserAccessProtected child={"IndustryDetailWrapper"}><IndustryDetailWrapper /></UserAccessProtected>}>
                <Route path={DrawerRoutes.Industry.About_Industry} element={<AboutIndustry />} />
                <Route path={DrawerRoutes.Industry.Industry_Effluent_Details} element={<IndustryEfluentDetails />} />
                <Route path={DrawerRoutes.Industry.Industry_Bills} element={<IndustryBills />} />
                <Route path={DrawerRoutes.Industry.Industry_Notices} element={<IndustryNotices />} />
                <Route path={DrawerRoutes.Industry.Industry_Settings} element={<IndustrySettings />} />
              </Route>

              {/* inventory section routes */}
              <Route path={DrawerRoutes.Inventory.consumables} element={<UserAccessProtected child={"Consumables"}><Consumables /></UserAccessProtected>}></Route>
              <Route path={DrawerRoutes.Inventory.nonconsumables} element={<UserAccessProtected child={"Nonconsumeables"}><Nonconsumeables /></UserAccessProtected>}></Route>

              {/* Notices section routes */}
              <Route path={DrawerRoutes.Notification} element={<UserAccessProtected child={"NotificationsNotices"}><NotificationsNotices /></UserAccessProtected>}></Route>
              <Route path={DrawerRoutes.SendNotice} element={<UserAccessProtected child={"SendNotice"}><SendNotice /></UserAccessProtected>}></Route>

              {/* Process management routes */}
              <Route path={DrawerRoutes.Process} element={<UserAccessProtected child={"Process"}><Process /></UserAccessProtected>}></Route>
              <Route path={DrawerRoutes.SensorAlerts} element={<UserAccessProtected child={"SensorAlerts"}><SensorAlerts /></UserAccessProtected>}></Route>
              <Route path={DrawerRoutes.Chambers} element={<UserAccessProtected child={"Chamber"}><Chamber /></UserAccessProtected>}></Route>
              <Route path={DrawerRoutes.EditChamber} element={<UserAccessProtected child={"EditChamber"}><EditChamber /></UserAccessProtected>}></Route>


              {/* report section routes */}
              <Route path={DrawerRoutes.Reports.Financial_Reports} element={<UserAccessProtected child={"BillsPayments"}><BillsPayments /></UserAccessProtected>}></Route>
              <Route path={DrawerRoutes.Reports.Dedicated_industry_bills} element={<UserAccessProtected child={"DedicatedIndustryBill"}><DedicatedIndustryBill /></UserAccessProtected>}></Route>
              <Route path={DrawerRoutes.Reports.GenerateBill} element={<UserAccessProtected child={"GenerateBill"}><GenerateBill /></UserAccessProtected>}></Route>
              <Route path={DrawerRoutes.Reports.MasterBills} element={<UserAccessProtected child={"MasterCopies"}><MasterCopies /></UserAccessProtected>}></Route>
              <Route path={DrawerRoutes.Reports.GenerateMasterBill} element={<UserAccessProtected child={"GenerateMasterBill"}><GenerateMasterBill /></UserAccessProtected>}></Route>
              <Route path={DrawerRoutes.Reports.PaymentReceipts} element={<UserAccessProtected child={"PaymentRequests"}><PaymentRequests /></UserAccessProtected>}></Route>

              {/* team section routes */}
              <Route path={DrawerRoutes.Team} element={<UserAccessProtected child={"Team"}><Team /></UserAccessProtected>}></Route>




              {/* Industry side routes , visible to only industry logged in user */}
              {/* Industry side routes , visible to only industry logged in user */}
              {/* Industry side routes , visible to only industry logged in user */}
              <Route path={IndustrySideRoutes.IndustryBills} element={<UserAccessProtected child={"IndustrySideBills"}><IndustrySideBills /></UserAccessProtected>} />
              <Route path={IndustrySideRoutes.IndustryProfile} element={<UserAccessProtected child={"IndustrySideBills"}><IndustryProfile /></UserAccessProtected>} />
              <Route path={IndustrySideRoutes.EditDetails} element={<UserAccessProtected child={"IndustrySideBills"}><EditIndustryDetails /></UserAccessProtected>} />
              {/* Routes for the payments of the industry. Declaring here because don't want the show it in the mainlayout. we dont want drawer in these pages. */}
              <Route path={PaymentRoutes.PaymentSuccess} element={<UserAccessProtected child={"PaymentPages"}><PaymentSuccess /></UserAccessProtected>} />
              <Route path={PaymentRoutes.PaymentFailed} element={<UserAccessProtected child={"PaymentPages"}><PaymentFailed /></UserAccessProtected>} />
              <Route path={PaymentRoutes.PaymentCancelled} element={<UserAccessProtected child={"PaymentPages"}><PaymentCancelled /></UserAccessProtected>} />
              <Route path={PaymentRoutes.InternalServerError} element={<UserAccessProtected child={"PaymentPages"}><InternalServerError /></UserAccessProtected>} />




              <Route path={DrawerRoutes.PlantProfile} element={<UserAccessProtected child={"PlantProfile"}><PlantProfile /></UserAccessProtected>} />

              <Route path={DrawerRoutes.CCTV} element={<CCTV />} />
              <Route path={DrawerRoutes.Reports.Sludge_Management} element={<UserAccessProtected child={"Sludge"}><UnderConstruction /></UserAccessProtected>} />

              {/* <Route path={DrawerRoutes.Reports.Sludge_Management} element={<UnderConstruction />} /> */}
              <Route path={DrawerRoutes.Reports.Industry_Effluent} element={<UserAccessProtected child="Reports"><UnderConstruction /></UserAccessProtected>} />
              <Route path={DrawerRoutes.Reports.Inventory_Reports} element={<UserAccessProtected child="InventoryReports"><InventoryReports /></UserAccessProtected>} />
              <Route path={DrawerRoutes.Reports.Power_Reports} element={<UserAccessProtected child="Reports"><UnderConstruction /></UserAccessProtected>} />

              {/* route if url match with nothing */}
              <Route path='/*' element={<PageNotFound />} />



            </Route>


            {/* Authentication Routes */}
            <Route path={AuthenticationRoutes.UserSelection} element={<UserSelection />} />
            <Route path={AuthenticationRoutes.PlantLogin} element={<PlantLogin />} />
            <Route path={AuthenticationRoutes.IndustryLogin} element={<IndustryLogin />} />
            <Route path={AuthenticationRoutes.OTPVerification} element={<OTPverification />} />
            <Route path={AuthenticationRoutes.IndustryRegistration} element={<IndustryRegistrationForm />} />
            <Route path={AuthenticationRoutes.ForgotPassword} element={<ForgotPassword />} />


            {/* route if url match with nothing */}
            <Route path='/*' element={<PageNotFound />} />
          </Routes >

          {/* ticker for showing responses. this ticker will show all over this application. */}
          <ErrorTicker />

          {/* restricting for mobile users */}
        </div >
        <p className="restrictForMobileDevices">Not available for Mobile devices. Please open in desktop</p>
      </BrowserRouter >
    </NoInternet >
  );
}

export default App;
