import { ArrowForward } from '@mui/icons-material';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { DrawerRoutes } from '../Components/Routes/routes';

export default function PlantSelectionSuperAdmin() {

  // Initialize the plant state with an empty string or the stored plant ID from local storage
  const storedPlantID = localStorage.getItem('superAdminSelected_PlantID');
  const [plant, setPlant] = useState(storedPlantID !== undefined && storedPlantID !== null ? storedPlantID : "");
  const navigate = useNavigate()
  console.log(plant, storedPlantID);

  // Handle radio button selection and update the plant state and local storage
  const handleProceed = () => {
    localStorage.setItem('superAdminSelected_PlantID', plant);
    navigate(DrawerRoutes.Dashboard)
    navigate(0)
  }


  return (
    <div className="Grid1">
      <div className="Login">
        <Box p={3} width={"100%"}>
          <Stack direction="column" spacing={1}>
            <Box>
              <Typography variant='body2'>Aaao Launde Super admin</Typography>
              <Typography variant='h6'>Plant Select Karo</Typography>
            </Box>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel value="P0" control={<Radio />} onClick={(e) => { setPlant(e.target.value); }} checked={plant === "P0"} label="Test Plant (P0)" />
                <FormControlLabel value="P1" control={<Radio />} onClick={(e) => { setPlant(e.target.value); }} checked={plant === "P1"} label="Bhiwadi Plant (P1)" />
              </RadioGroup>
            </FormControl>

            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant='contained'
                disabled={plant === ""}
                endIcon={<ArrowForward />}
                onClick={handleProceed}
              >Proceed</Button>
            </Stack>
          </Stack>
        </Box>
      </div>
    </div>
  )
}

