import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { debouncedSetInterestRate, debouncedSetLastDate } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/BillGenerationSlice'
import { Receipt } from '@mui/icons-material'
import { CreateBill } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/CreateBillSlice'
import { LoadingButton } from '@mui/lab'
import { useNavigate, useParams } from 'react-router-dom'
import { calculateTotalEffluentDischarge } from './Calculations'

// this is the footer of the bill and this component contains the calulated values of the bill and 2 feilds for last date and intrest rate after the due date.
export default function BillFooter() {
    const billData = useSelector(state => state.BILL_GENERATION)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const BillObject = useSelector(state => state.BILL_GENERATION) // taking Bill object from the redux store.
    const [Loading, setLoading] = useState(false)
    const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)
    const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
    const { industryID } = useParams() // Getting the industry id for which we are goiing to generate the bill. 

    // Convert GMT to IST
    const convertToIST = (date) => {
        return format(date, 'MM/dd/yyyy', { timeZone: 'Asia/Kolkata' });
    };

    return (
        <div>
            <Box component="section" sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', backgroundColor: 'var(--Tertiary_Blue)' }}>

                <Stack direction="row" spacing={1}>
                    <TextField
                        label="Interest Rate (%)"
                        sx={{ width: '50%' }}
                        onChange={(e) => { debouncedSetInterestRate(dispatch, +e.target.value) }}
                        type='number'
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            sx={{ width: '50%' }}
                            disablePast={true}
                            onChange={(value) => { debouncedSetLastDate(dispatch, convertToIST(value)) }}
                            format='dd/MM/yyyy'
                            label="Bill Due Date"
                        />
                    </LocalizationProvider>
                </Stack><br />

                <Stack direction="column" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                    {/* all calculated figures */}
                    <Stack direction="row" flexWrap="wrap" alignItems="flex-start" gap={1}>

                        <Box sx={{ p: 2, border: '1px dashed var(--Primary_Blue)', borderRadius: '12px', width: 'fit-content' }}>
                            <Typography variant='body2'>Grand Total</Typography>
                            <Typography variant='h6'>₹ <b>{billData.amount?.toFixed(2)}</b></Typography>
                        </Box>

                        <Box sx={{ p: 2, border: '1px dashed var(--Primary_Blue)', borderRadius: '12px', width: 'fit-content' }}>
                            <Typography variant='body2'>Total Consumption</Typography>
                            <Stack direction="row" alignItems={"center"} spacing={1}><Typography variant='h6'>{billData.totalConsumption}</Typography><Typography variant='caption'>meter cube</Typography></Stack>
                        </Box>

                        <Box sx={{ p: 2, border: '1px dashed var(--Primary_Blue)', borderRadius: '12px', width: 'fit-content' }}>
                            <Typography variant='body2'>Effluent Discharge</Typography>
                            <Stack direction="row" alignItems={"center"} spacing={1}><Typography variant='h6'>{calculateTotalEffluentDischarge(billData?.goods)}</Typography><Typography variant='caption'>meter cube</Typography></Stack>
                        </Box>

                        <Box sx={{ p: 2, border: '1px dashed var(--Primary_Blue)', borderRadius: '12px', width: 'fit-content' }}>
                            <Typography variant='body2'>Total CGST Amt.</Typography>
                            <Typography variant='h6'>₹ {billData.totalCgst?.toFixed(2)}</Typography>
                        </Box>

                        <Box sx={{ p: 2, border: '1px dashed var(--Primary_Blue)', borderRadius: '12px', width: 'fit-content' }}>
                            <Typography variant='body2'>Total SGST Amt.</Typography>
                            <Typography variant='h6'>₹ {billData.totalSgst?.toFixed(2)}</Typography>
                        </Box>

                    </Stack>

                    <LoadingButton
                        variant='contained'
                        sx={{ padding: '40px 40px' }}
                        startIcon={<Receipt />}
                        loading={Loading}
                        disableElevation
                        onClick={() => { CreateBill(BillObject, AuthToken, industryID, dispatch, setLoading, navigate, PlantID) }} // Creating bill onclicking. Validating feild will done in the same function in the slice.
                    >Generate Bill</LoadingButton>
                </Stack>
            </Box>
        </div>
    )
}
