import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { showTicker } from '../../Redux/Slices/TickerSlice';
import { Box, Button, Chip, Collapse, Divider, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Add, ArrowBack, ArrowDownward, ArrowUpward, Cancel, Check, Clear } from '@mui/icons-material';
import { CircularLoader, SideDrawer } from '../UI Components/Basic';
import { LoadingButton } from '@mui/lab';
import { SERVER_ADDRESS } from '../../Firebase/Variables';
import { useNavigate } from 'react-router-dom';
import { convertGMTtoISTAndFormat } from '../ReportsComponents/BillsAndPayments/DedicatedIndustryBill';

export default function IndustriesRequests() {

    const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken) // token important for api call
    const PlantID = useSelector(state => state.USER_DETAILS.PlantID) // token important for api call
    const [Requests, setRequests] = useState([]) // For storing the requests
    const dispatch = useDispatch() // For dispatching actions
    const navigate = useNavigate()
    const [Loading, setLoading] = useState({
        state: false,
        element: 0 // Instead of creating 3 different state for 3 loaders in 3 buttons, we made a element variable which specify the element in which we have to show the loader. 1 for getting requests, 2 for approve, 3 for reject and 0 for none.
    })
    const [SideDrawerOpen, setSideDrawerOpen] = useState(false) // fornhandling the state of side drawer
    const [SideDrawerData, setSideDrawerData] = useState({ // For storing data to be shown in the side drawer of the industry request. 
        data: [],
        id: ''
    })

    useEffect(() => {
        GetIndustryRequests()
    }, [])


    // function for fetching the requests for the Industry. Function is here because it not be called multiple times so we dont want to store this data in to the redux store. aslo this data will not be user at other places. This component will fetch data on mount averytime.
    const GetIndustryRequests = async () => {
        setLoading({ state: true, element: 1 })
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${AuthToken}`);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${SERVER_ADDRESS}/industry/requests?plantID=${PlantID}`, requestOptions)
            .then((response) => {
                response.json().then((data) => {
                    if (response.ok) {
                        setRequests(data?.industries) //Storing the response in the state we defined
                        setLoading({ state: false, element: 0 }) // Disabling the loader and setting element to 0
                        setSideDrawerOpen(false) // Closing the drawer in case it is opened. Because we have to fetch data immidiately after approving and rejecting the request. so if drawer is opened after fetching data, if will get closed.
                    }
                    else {
                        dispatch(showTicker({
                            message: response.statusText,
                            type: 'error'
                        }))
                    }
                })
            })
            .catch((error) => {
                dispatch(showTicker({
                    message: error.message,
                    type: 'error'
                }))
                setSideDrawerOpen(false)// Closing the drawer in case it is opened. Because we have to fetch data immidiately after approving and rejecting the request. so if drawer is opened after fetching data, if will get closed. In error case also 
            })
    }

    // hitting api endpoint for approving the industry request
    const ApproveIndustry = async (id) => {
        setLoading({
            state: true,
            element: 2
        })
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${AuthToken}`);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${SERVER_ADDRESS}/industry/approve/${id}?plantID=${PlantID}`, requestOptions)
            .then(response => {
                response.json().then((data) => {
                    if (response.ok) {
                        dispatch(showTicker({
                            message: data.message,
                            type: 'success'
                        }))
                        setLoading({
                            state: false,
                            element: 0
                        })
                        setSideDrawerOpen(false)
                        GetIndustryRequests() // Fetching the request again after appcepting the requst, it will refresh the requests list.
                    }
                    else {
                        dispatch(showTicker({
                            message: data.message,
                            type: 'error'
                        }))
                        setLoading({
                            state: false,
                            element: 0
                        })
                    }
                })
            })
            .catch(error => {
                dispatch(showTicker({
                    message: error.message,
                    type: 'error'
                }))
                setLoading({
                    state: false,
                    element: 0
                })
                setSideDrawerOpen(false)
            })
    }

    // hitting api endpoint for rejecting the industry request
    const RejectIndustry = async (id) => {
        setLoading({
            state: true,
            element: 3
        })
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${AuthToken}`);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${SERVER_ADDRESS}/industry/reject/${id}?plantID=${PlantID}`, requestOptions)
            .then(response => {
                response.json().then((data) => {
                    if (response.ok) {
                        dispatch(showTicker({
                            message: "Industry request rejected successfully",
                            type: 'success'
                        }))
                        setLoading({
                            state: false,
                            element: 0
                        })
                        setSideDrawerOpen(false)
                        GetIndustryRequests() // Fetching the request again after appcepting the requst, it will refresh the requests list.
                    }
                    else {
                        dispatch(showTicker({
                            message: data.message,
                            type: 'error'
                        }))
                        setLoading({
                            state: false,
                            element: 0
                        })
                    }
                })
            })
            .catch(error => {
                dispatch(showTicker({
                    message: error.message,
                    type: 'error'
                }))
                setLoading({
                    state: false,
                    element: 0
                })
                setSideDrawerOpen(false)
            })
    }


    // opening the side drawer and setting data into it
    const handleTableRowClick = (data, id) => {
        setSideDrawerOpen(true)
        setSideDrawerData({
            data: data,
            id: id
        })
    }

    return (
        <div>
            <div className="PAGE_HEADER">
                {/* Name of the page, will show in lwft of the header */}
                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /> </IconButton>
                    <h3>Industry Requests</h3>
                </Stack>
            </div>

            {/* this div will contain all the content of the page */}
            <div className="Industries_Body_Container">
                {/* <div className="TOOLS_WRAPPER" style={{ textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}></div> */}
                {/* <button onClick={GetIndustryRequests}> fetch requests</button> */}
                {/* table for listing the request */}
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ paddingLeft: "30px" }}>Company Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Date/Time</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>

                        {
                            Loading.state && Loading.element === 1 ? <CircularLoader /> : <TableBody>
                                {Object.keys(Requests).length !== 0
                                    ? Requests?.map((req) => {
                                        return (
                                            <TableRow key={req.id}>
                                                <TableCell sx={{ paddingLeft: "30px" }} onClick={() => { handleTableRowClick(req.data, req.id) }}>
                                                    {req.data.companyName}
                                                </TableCell>
                                                <TableCell onClick={() => { handleTableRowClick(req.data, req.id) }}>{req.data.email}</TableCell>
                                                <TableCell onClick={() => { handleTableRowClick(req.data, req.id) }}>{req.data.dateAdded}</TableCell>
                                                <TableCell>
                                                    <LoadingButton color="success" loading={Loading.state && Loading.element === 2} onClick={() => { ApproveIndustry(req.id) }}>Accpet</LoadingButton> &nbsp;&nbsp;
                                                    <LoadingButton color="PrimaryRed" loading={Loading.state && Loading.element === 3} onClick={() => { RejectIndustry(req.id) }}>Reject</LoadingButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                    : <p className="simpleParagraph" style={{ position: 'fixed', top: '63%', left: '61%', transform: 'translate(-50%, -50%)' }}>No Requests found</p>}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
            </div >
            <IndustryRequestDetails
                Open={SideDrawerOpen}
                handle={setSideDrawerOpen}
                SideDrawerData={SideDrawerData}
                ApproveIndustry={ApproveIndustry}
                RejectIndustry={RejectIndustry}
                Loading={Loading}
            />
        </div >
    )
}

// function component for side drawer, which contains the all details of the industry. Details of the industry will pass through props.
function IndustryRequestDetails(props) {
    // console.log(props.SideDrawerData.data);


    const MapArray = {
        Phase: props.SideDrawerData?.data?.phase,
        ConsentValidity: props.SideDrawerData?.data?.consentValidity,
        Pincode: props.SideDrawerData?.data?.pincode,
        Address: props.SideDrawerData?.data?.address,
        CompanyName: props.SideDrawerData?.data?.companyName,
        Email: props.SideDrawerData?.data?.email,
        Phone: props.SideDrawerData?.data?.phoneNo,
        TotalEffluentTradeandUtility: props.SideDrawerData?.data?.totalEffluentTradeAndUtility,
        PNumber: props.SideDrawerData?.data?.pno,
        HNtype: props.SideDrawerData?.data?.h_n_type,
        PlantType: props.SideDrawerData?.data?.cetp_stp_etp_type,
        UnitID: props.SideDrawerData?.data?.unitId,
        DomesticEffluent: props.SideDrawerData?.data?.domesticEffluent,
        ICChamberInstalled: props.SideDrawerData?.data?.IC_chamber_install ? "Yes" : "No"
    }

    return <SideDrawer Open={props.Open} handle={props.handle}>
        <div className="bulk_container">
            <div className="bulk_container_title" style={{ background: "white", position: "sticky", top: "0", zIndex: "1", padding: "20px 0px 20px 0px", }}   >
                <Typography sx={{ fontSize: "24px", fontWeight: "700" }} >
                    {props.SideDrawerData.data?.companyName}
                </Typography>
                <p className="simpleParagraph">
                    {props.SideDrawerData.data?.address}
                </p><br />
                {/* Buttons */}
                <Stack direction="row" spacing={1} width={"100%"}>
                    <LoadingButton variant="contained" color="success" disableElevation loading={props.Loading.state && props.Loading.element === 2} startIcon={<Check />} onClick={() => { props.ApproveIndustry(props.SideDrawerData.id) }}>
                        Accept
                    </LoadingButton>
                    <LoadingButton variant="contained" color="PrimaryRed" disableElevation loading={props.Loading.state && props.Loading.element === 3} startIcon={<Clear />} onClick={() => { props.RejectIndustry(props.SideDrawerData.id) }}>
                        Reject
                    </LoadingButton>
                </Stack>
                <br />
                <Divider />
                <Typography variant='caption'>Request Recieved on - {convertGMTtoISTAndFormat(props.SideDrawerData?.data?.dateAdded)}</Typography>
                <Divider />
                <br />

                <Box>
                    <Typography variant="body1" gutterBottom component="div">
                        Industry Details
                    </Typography>
                    <Table size="small" aria-label="purchases">
                        <TableBody>
                            {
                                props.SideDrawerData !== null && props.SideDrawerData !== undefined ?
                                    ['Phase', 'Consent Validity', 'Pincode', 'Address', 'Company Name', 'Email', 'Phone', 'Total Effluent Trade and Utility', 'P Number', 'H/N type', 'Plant Type', 'Unit ID', 'Domestic Effluent', 'IC Chamber Installed?'].map((keyName, i) => {
                                        return <TableRow key={i}>
                                            <TableCell>{keyName}</TableCell>
                                            <TableCell align='right'> {Object.values(MapArray)[i]}</TableCell>
                                        </TableRow>
                                    }) : ''
                            }
                        </TableBody>
                    </Table>
                </Box>


            </div>
        </div>
    </SideDrawer>
}

