import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showTicker } from '../../Redux/Slices/TickerSlice';
import { Box, Button, Card, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import { ArrowOutward, CurrencyRupee, FactoryOutlined, Notifications, Receipt } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { DrawerRoutes, IndustrySideRoutes, PaymentRoutes } from '../Routes/routes';


export default function Dashboard() {

  const navigate = useNavigate()
  const User = useSelector(state => state.USER_DETAILS)
  const isIndustry = User?.UserRole === "industry"

  return (
    <div>
      <br />
      <Box width="100%" height="100%" >
        Dashboard Under Development
        {/* <button onClick={() => { navigate(PaymentRoutes.PaymentSuccess) }}>Success</button> */}
        {/* <button onClick={() => { navigate(PaymentRoutes.PaymentFailed) }}>Fail</button> */}
        {/* <button onClick={() => { navigate(PaymentRoutes.PaymentCancelled) }}>Cancel</button> */}
        {/* <button onClick={() => { navigate(PaymentRoutes.InternalServerError) }}>Internal Server Error</button> */}

        {/*all the cards in the dashboard */}
        <Stack direction="row" justifyContent="flex-start" gap={2}>

          {isIndustry ? <IndustrySideBillDetails
            cardIcon={<Receipt color='PrimaryBlue' sx={{ opacity: 1, width: '15px' }} />}
            title={"Unpaid Bills"}
            data={0}
            buttonText={"Go to Bills & Payments"}
            buttonTarget={() => { navigate(IndustrySideRoutes.IndustryBills) }}
            buttonIcon={<ArrowOutward />}
          /> : ""}

          {isIndustry ? <IndustrySideBillDetails
            cardIcon={<Notifications color='PrimaryBlue' sx={{ opacity: 1, width: '15px' }} />}
            title={"New Notices"}
            data={0}
            buttonText={"Go to Notices"}
            buttonTarget={() => { navigate(DrawerRoutes.Notification) }}
            buttonIcon={<ArrowOutward />}
          /> : ''}

          {!isIndustry ? <IndustriesCard
            cardIcon={<FactoryOutlined color='PrimaryBlue' sx={{ opacity: 1, width: '15px' }} />}
            title={"Total Industries"}
            data={0}
            buttonText={"Go to industries"}
            buttonTarget={() => { navigate(DrawerRoutes.Industry.Industries) }}
            buttonIcon={<ArrowOutward />}
          /> : ""}

          {!isIndustry ? <PaymentsCard
            cardIcon={<CurrencyRupee color='PrimaryBlue' sx={{ opacity: 1, width: '15px' }} />}
            title={"Total Payment Recieved"}
            data={`₹ ${0}`}
            buttonText={"Go to bills"}
            buttonTarget={() => { navigate(DrawerRoutes.Reports.Financial_Reports) }}
            buttonIcon={<ArrowOutward />}
          /> : ""}
        </Stack>

      </Box>
    </div>
  )
}





//declared all the cards seperately in bottom. 
function IndustriesCard(props) {
  return <Box
    p={2}
    sx={{
      height: "fit-content",
      minHeight: "100px",
      backgroundColor: "white",
      borderRadius: '12px',
      minWidth: "160px",
      width: 'fit-content',
      position: 'relative'
    }}>
    <Stack direction="column" spacing={-0.2}>
      {props.cardIcon}
      <Typography variant='caption'>{props.title}</Typography>
      <Typography variant='h5' color={"PrimaryBlue"}><b>{props.data}</b></Typography>
      <br />
      <Button
        startIcon={props.buttonIcon}
        sx={{ height: '30px' }}
        variant='outlined'
        size='small'
        onClick={props.buttonTarget}
      >{props.buttonText}</Button>
    </Stack>
  </Box>
}


function PaymentsCard(props) {
  const [age, setAge] = React.useState('Month');
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return <Box
    p={2}
    sx={{
      height: "fit-content",
      minHeight: "100px",
      backgroundColor: "white",
      borderRadius: '12px',
      minWidth: "320px",
      width: 'fit-content',
      position: 'relative'
    }}>
    <Stack direction="column" spacing={-0.2}>
      {props.cardIcon}
      <Typography variant='caption'>{props.title}</Typography>
      <Typography variant='h5' color={"PrimaryBlue"}><b>{props.data}</b></Typography>
      <br />
      <Button
        startIcon={props.buttonIcon}
        sx={{ height: '30px' }}
        variant='outlined'
        size='small'
        onClick={props.buttonTarget}
      >{props.buttonText}</Button>
    </Stack>

    <FormControl sx={{ width: "120px", height: '28px', position: "absolute", top: "10px", right: "10px" }}>
      <Select
        sx={{ width: "120px", height: '28px', fontSize: '12px', borderRadius: '10px' }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={age}
        onChange={handleChange}
      >
        <MenuItem sx={{ fontSize: '12px' }} value={"Month"}>This Month</MenuItem>
        <MenuItem sx={{ fontSize: '12px' }} value={"Year"}>This Year</MenuItem>
        <MenuItem sx={{ fontSize: '12px' }} value={"Total"}>Total</MenuItem>
      </Select>
    </FormControl>
  </Box>
}



// Card for industry dside, showing how many unpaid bills are remaining
function IndustrySideBillDetails(props) {
  return <Box
    p={2}
    sx={{
      height: "fit-content",
      minHeight: "100px",
      backgroundColor: "white",
      borderRadius: '12px',
      minWidth: "160px",
      width: 'fit-content',
      position: 'relative'
    }}>
    <Stack direction="column" spacing={-0.2}>
      {props.cardIcon}
      <Typography variant='caption'>{props.title}</Typography>
      <Typography variant='h5' color={"PrimaryBlue"}><b>{props.data}</b></Typography>
      <br />
      <Button
        startIcon={props.buttonIcon}
        sx={{ height: '30px' }}
        variant='outlined'
        size='small'
        onClick={props.buttonTarget}
      >{props.buttonText}</Button>
    </Stack>
  </Box>
}