import { createAsyncThunk } from "@reduxjs/toolkit";
import { EmptyFeildValidation } from "../../../../UserAuthentication/AuthFunctions";
import { CheckPercentage } from "../../../../Components/ReportsComponents/BillsAndPayments/BillGeneration/Calculations";
import { showTicker } from "../../TickerSlice";
import { DrawerRoutes } from "../../../../Components/Routes/routes";
import { SERVER_ADDRESS } from "../../../../Firebase/Variables";
import { typeOfGoods } from "../../../../Components/ReportsComponents/BillsAndPayments/BillGeneration/AddGoodFunctions";
import { GetBillsOf } from "./IndustryBillsSlice";

export function CreateBill(BillObject, AuthToken, industryID, dispatch, setLoading, navigate, PlantID) {

    console.log(BillObject, AuthToken, industryID);

    // checking feilds if they are empty or not
    if (EmptyFeildValidation(BillObject.description, "Bill Title", dispatch) && EmptyFeildValidation(BillObject.lastDate, "Due Date", dispatch) && CheckPercentage(BillObject.interestRate, dispatch) && BillObject.goods.length > 0) {

        setLoading(true);

        var raw = JSON.stringify({
            "description": BillObject.description,
            "goods": filterGoodsArray(BillObject.goods),
            "amount": BillObject.amount,
            "interestRate": BillObject.interestRate,
            "lastDate": BillObject.lastDate
        });

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${AuthToken}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${SERVER_ADDRESS}/industry/bill/create/${industryID}?plantID=${PlantID}`, requestOptions)
            .then(response => {
                response.json().then((data) => {
                    console.log(response, data);
                    if (response.ok) {
                        dispatch(showTicker({
                            message: data.message,
                            type: 'success'
                        }))
                        navigate(`/reports/industry-bills/${industryID}`) //after creating the bill, going to the dedicated bills of the industry page to show the creatd bill of the industry
                        setTimeout(() => {
                            dispatch(GetBillsOf({ industryID: industryID, AuthToken: AuthToken, PlantID: PlantID })) // Refetching the bills of the industry to show the updated list of th bills
                        }, 2000);
                    }
                    else if (response.status === 500) { // In case of internal server error
                        dispatch(showTicker({
                            message: `${data.error.message}${data.error}`,
                            type: 'error'
                        }))
                    }
                    else {
                        dispatch(showTicker({
                            message: `${response.statusText} | ${data.message}`,
                            type: 'error'
                        }))
                    }
                })
                setLoading(false);
            })
            .catch((error) => {
                dispatch(showTicker({
                    message: error.message,
                    type: 'error'
                }))
                setLoading(false);
            })
    }

    // handler for if on goods found
    else if (BillObject.goods.length <= 0) {
        dispatch(showTicker({
            message: 'No goods found, Please add at least 1 good.',
            type: 'error'
        }))
    }
}

// we have no usable values in our bill object so we are filtering only usable values here. 
function filterGoodsArray(array) {
    // Map over the goods array and create a new array with filtered values
    const filteredGoods = array.map((item) => ({
        starting: item.type === typeOfGoods.WCG ? item.starting : item.type === typeOfGoods.WFG ? convFlowDuration(item.flowMonth) : '',
        ending: item.type === typeOfGoods.WCG ? item.ending : item.type === typeOfGoods.WFG ? convFlowDuration(item.flowMonth) : "",
        qty: item.type === typeOfGoods.WCG ? item.qty : item.type === typeOfGoods.WFG ? +item.flowRate : 0,
        masterCopyID: item.masterCopyID,
        type: item.type
    }));
    return filteredGoods
}


//function for converting the flow type good's duration to MM/dd/yyyy format for api compatibility
function convFlowDuration(inputDate) {
    const [year, month] = inputDate.split('-');
    const formattedDate = `${month}/01/${year}`;
    return formattedDate;
}