import { React, useState, useEffect } from 'react'
import { TextField, Autocomplete, Stack, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import { showTicker } from "../../../Redux/Slices/TickerSlice";
import { LoadingButton } from "@mui/lab";
import { status } from "../../../Redux/Slices/userSlice";
import { GetTagNos, UpdateChamberParams } from '../../../Redux/Slices/ProcessManagementSlices/ChamberManagementSlice';

const Edit = (props) => {
  const [newParam, setNewParam] = useState({
    key: props.data.data.key || "",
    value: props.data.data.value || "",
  });
  const dispatch = useDispatch();
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const TagNosList = useSelector(state => state.CHAMBER_MANAGEMENT.TagNosList)
  const ModalState = useSelector(state => state.CHAMBER_MANAGEMENT.ModalState)
  const CHAMBER_LOADING = useSelector(state => state.CHAMBER_MANAGEMENT.AddLoading)
  const [autocompleteInputValue, setAutocompleteInputValue] = useState(props.data.data.value || '');

  const handleSave = (inletIndex, outletIndex, operation) => {
    // validate newParam key
    if (newParam.key === "") {
      dispatch(showTicker({
        message: "Key can`t be empty",
        type: 'error'
      }))
      return
    }

    // validate newParam value
    if (newParam.value === "") {
      dispatch(showTicker({
        message: "Value can`t be empty",
        type: 'error'
      }))
      return
    } else if (!TagNosList.data.includes(newParam.value)) {
      dispatch(showTicker({
        message: `TagNo ${newParam.value} not found`,
        type: 'error'
      }))
      return
    }

    // save newParam
    dispatch(UpdateChamberParams({
      chamberid: props.data.chamberid,
      type: props.data.type === "Chamber" ? 0 : props.data.type === "Inlet" ? 1 : 2,
      key: newParam.key,
      value: newParam.value,
      index: props.data.data.index,
      inletIndex: props.data.data.inletIndex,
      outletIndex: props.data.data.outletIndex,
      operation: props.data.data.operation,
      UserToken,
      PlantID
    }))

    if (props.drawer) {
      props.drawer(...props.data, { open: false })
    }
  }

  useEffect(() => {
    dispatch(GetTagNos({ UserToken, PlantID }))
  }, [PlantID, UserToken, dispatch])

  useEffect(() => {
    if (ModalState === 0) {
      props.setActiveModal({ ActiveModalIndex: 0 })
    }
  }, [ModalState, props])

  return (
    <div className="Modal_Black_bg">
      <div className='Modal_base' style={{ border: "none" }}>

        {/* Modal Heading */}
        <div className="Modal_heading" style={{ borderRadius: "10px 10px 0 0" }} >
          {props.data.data.operation === "add" ? "Add" : "Edit"} {props.data.type} Parameter
          <IconButton color="PrimaryBlue" onClick={() => { props.setActiveModal(0) }}>
            <Cancel />
          </IconButton>
        </div>

        <div style={{ padding: "15px", animation: 'fade-up 1s cubic-bezier(0.19, 1, 0.22, 1)' }}>
          <Stack
            direction='row'
            spacing={1}
          >
            <TextField
              sx={{ width: '50%' }}
              size="small"
              placeholder="Parameter Name"
              label="Parameter Name"
              value={newParam.key}
              autoFocus
              onChange={(e) => { setNewParam({ ...newParam, key: e.target.value }) }}
            />

            <Autocomplete
              freeSolo
              disableClearable
              sx={{ width: "50%" }}
              options={TagNosList.data.map((option) => option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select TagNo"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                  sx={{ width: "100%" }}
                  size="small"
                />
              )}
              inputValue={autocompleteInputValue}
              onInputChange={(event, newInputValue) => {
                setAutocompleteInputValue(newInputValue);
                // event.stopPropagation()
              }}
              onChange={(event, newValue) => {
                setNewParam({ ...newParam, value: newValue ? newValue.tagNo || newValue : '' });
                setAutocompleteInputValue(newValue ? newValue.tagNo || newValue : ''); // Resets input field on selection
                // event.stopPropagation()
              }}
              // onFocus={() => props.setAutocompleteFocused(true)}
              // onBlur={() => props.setAutocompleteFocused(false)}
            />

          </Stack>

          {/* Modal Footer */}
          <Stack
            direction="row"
            justifyContent="end"
            sx={{ padding: "15px 0 0 0" }}
          >
            <LoadingButton
              loading={CHAMBER_LOADING === status.LOADING}
              loadingPosition="start"
              variant="contained"
              size="large"
              color="PrimaryBlue"
              disableElevation
              onClick={() => handleSave()}
              sx={{ width: "110px" }}
            >
              Save
            </LoadingButton>
          </Stack>

        </div>

      </div>
    </div>
  )
}

export default Edit