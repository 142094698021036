import { format } from 'date-fns'
import { calculateCgst, calculateSgst } from './Calculations';
import { showTicker } from '../../../../Redux/Slices/TickerSlice';

// THIS FILE IS ONLY FOR IMPORTANT FUNCTIONS RELATED TO THE ADDING GOODS. 
export const typeOfGoods = {
    WCG: 'Water Consumption good',
    WFG: 'Water Flow good'
}

// A initital state for the good. 
export const GoodDatainit = { qty: 0, isYearlyorMonthtly: '', starting: '', ending: '', masterCopyID: '', cgstAmount: 0, sgstAmount: 0, cgstRate: 0, sgstRate: 0, goodPrice: 0, unit: '', HSN_SAC_code: '', }


// function for converting date format to given format
export function FormatTimestamp(timestamp, convFormat) {
    return format(timestamp, convFormat, { timeZone: 'Asia/Kolkata' });
}


// validation of the good data. 
export function ValidateAddingGoods(goodType, GoodData, dispatch) {
    if (goodType === typeOfGoods.WFG) {
        if (GoodData.flowRate !== 0 && GoodData.flowMonth !== "") { return true }
        else {
            dispatch(showTicker({
                message: 'Invalid Data',
                type: 'error'
            }))
            return false
        }
    }
    else if (goodType === typeOfGoods.WCG) {
        if (GoodData.qty > 0 && GoodData.starting !== '' && GoodData.ending !== '') { return true }
        else {
            dispatch(showTicker({
                message: 'Invalid Data, Please check and try again',
                type: 'error'
            }))
            return false
        }
    }
}



// function for getting the master bill from the data, based on the passed selected bill id from the redux store.
export function GetMasterBill(data, id) {
    if (data) {
        return data.find((obj) => obj.id === id);
    }
    else { return }
}



// function for returning the complete ready data to be store. with calculations. 
export function GetFinalGood(masterCopies, billId, GoodData) {

    let MasterBill = GetMasterBill(masterCopies, billId) // getting cooresponding master bill copy from the dedicated function made for this requirement - GetMasterBill
    let typeOfGood = GoodData.type // getting the type of good from the goodData. 

    let completeData = {
        ...GoodData,
        masterCopyID: MasterBill.id,
        goodPrice: parseFloat(MasterBill.data.price), // making sure this is a number.
        cgstRate: parseFloat(MasterBill.data.cgstRate),
        sgstRate: parseFloat(MasterBill.data.sgstRate),
        unit: MasterBill.data.unit,
        HSN_SAC_code: MasterBill.data.HSN_SAC_code,

        //these functions are made in calculate.js file and imported here
        cgstAmount: typeOfGood === typeOfGoods.WCG ? calculateCgst(parseFloat(MasterBill.data.price), GoodData.qty, parseFloat(MasterBill.data.cgstRate)) : typeOfGood === typeOfGoods.WFG ? calculateCgst(parseFloat(MasterBill.data.price), GoodData.flowRate, parseFloat(MasterBill.data.cgstRate)) : 0, // needed (good price, consumption , cgst rate)
        sgstAmount: typeOfGood === typeOfGoods.WCG ? calculateSgst(parseFloat(MasterBill.data.price), GoodData.qty, parseFloat(MasterBill.data.sgstRate)) : typeOfGood === typeOfGoods.WFG ? calculateSgst(parseFloat(MasterBill.data.price), GoodData.flowRate, parseFloat(MasterBill.data.sgstRate)) : 0,
    }
    return completeData
} 