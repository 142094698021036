import { Receipt } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UploadPaymentRecipt } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/PaymentsSlice';
import { LoadingButton } from '@mui/lab';
import { GetSingleBillIndustrySide, updateBillData } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/IndustryBillsSlice';

const UploadReceipt = (props) => {

    const dispatch = useDispatch()
    const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)// logged in user auth token
    const PlantID = useSelector(state => state.USER_DETAILS.PlantID)// logged in user auth token
    const [Loading, setLoading] = useState(false)

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        // Check file type
        if (selectedFile && (selectedFile.type === 'image/png' || selectedFile.type === 'image/jpeg' || selectedFile.type === 'application/pdf')) {
            handleUpload(selectedFile)
        } else {
            alert('Please select a PNG, JPEG, or PDF file.');
        }
    };

    const handleUpload = (i) => {
        if (i) {
            dispatch(UploadPaymentRecipt({
                AuthToken: AuthToken,
                files: i,
                billID: props.billID,
                setLoading: setLoading,
                PlantID: PlantID
            })).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && res.payload !== undefined) {
                    fetchLatestData()
                }
            })
        }
    };


    // function for getting the updated bill data from the server
    const fetchLatestData = () => {
        dispatch(GetSingleBillIndustrySide({
            AuthToken: AuthToken,
            billID: props.billID,
            setLoading: props.setLatestDataLoading,
            PlantID: PlantID
        }))
            .then((res) => {
                // console.log(res);
                if (res.meta.requestStatus === "fulfilled" && res.payload !== undefined) {
                    // setbillData(res.payload?.bill?.data)
                    dispatch(updateBillData({
                        billId: props.billID,
                        newData: res.payload?.bill?.data
                    }))
                }
            })
    }

    return (
        <>
            <input
                type="file"
                id='payment'
                accept=".png,.jpeg,.jpg,.pdf"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />

            {/* <button onClick={fetchLatestData}>gr</button> */}
            <LoadingButton
                variant='contained'
                color='PrimaryBlue'
                disableElevation
                disabled={props.disableProp}
                startIcon={<Receipt sx={{ width: '16px' }} />}
                sx={{ padding: "0px 10px" }}
                loading={Loading}
            >
                <label
                    style={{ width: "100%", height: '100%', display: 'flex', alignItems: 'center' }}
                    htmlFor='payment'>Upload Payment Recipt</label>
            </LoadingButton>

        </>
    );
};

export default UploadReceipt;
