import { Box } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux';
import { removeError, showTicker } from '../../Redux/Slices/TickerSlice';

export default function UnderConstruction() {
    const dispatch = useDispatch()
    return (
        <div>
            <Box width="100%" height="100vh" sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                <img src="https://ik.imagekit.io/hgfugmm0dbi/OCEMS/OBJECTS_8vAButNIZ.svg?updatedAt=1709987438775" alt="" />
            </Box>

{/* 
            <button onClick={() => {
                dispatch(showTicker({
                    message: "Error message",
                    type: "error"
                }));
            }}>error</button>

            <button onClick={() => { dispatch(removeError()) }}>remove</button> */}
        </div>
    )
}
