import { createSlice } from "@reduxjs/toolkit";

const TickerSlice = createSlice({
    name: 'TickerSlice',
    initialState: {
        errorQueue: [] // Queue to store error messages
    },
    reducers: {
        showTicker(state, action) {
            if (state.errorQueue.length <= 2) {
                state.errorQueue.push(action.payload);
            }
            return state
        },
        removeError: (state) => {
            state.errorQueue.splice(0, 1);
        }
    }
})

export const { showTicker, removeError } = TickerSlice.actions;
export default TickerSlice.reducer;