import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { calculateAmount, calculateTotalCgst, calculateTotalConsumption, calculateTotalSgst } from "../../../../Components/ReportsComponents/BillsAndPayments/BillGeneration/Calculations";
import { showTicker } from "../../TickerSlice";
import { SERVER_ADDRESS } from "../../../../Firebase/Variables";

export const downloadBill = createAsyncThunk('bill/download', async ({ industryid, industryName, billid, AuthToken, PlantID }, { dispatch, rejectWithValue }) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    setDownloadBillID(billid);
    try {
        const url = `${SERVER_ADDRESS}/industry/bill/download?industryid=${encodeURIComponent(industryid)}&billid=${encodeURIComponent(billid)}&plantID=${PlantID}`;
        const response = await fetch(url, requestOptions);

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${industryName}_bill_${billid}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            dispatch(showTicker({
                message: response.statusText || "Failed to download bill",
                type: 'error'
            }));
            return rejectWithValue(response.statusText);
        }
    } catch (error) {
        dispatch(showTicker({
            message: error.message || "An error occurred while downloading the bill",
            type: 'error'
        }));
        return rejectWithValue(error.message);
    }
});

const BillGenSlice = createSlice({
    name: 'BillGenerationSlice',
    initialState: {
        description: "",
        goods: [],
        amount: 0,
        interestRate: "",
        lastDate: "",
        totalConsumption: 0,
        totalCgst: 0,
        totalSgst: 0,
        downloadBillID: ""
    },
    reducers: {

        // reducer function for updating the industry id for which the bill is generating. 
        billGeneratingFor: (state, action) => {
            state.industryID = action.payload
        },

        // reducer function for adding new goods to the list 
        addGood: (state, action) => {
            // Add the new good to the goods array
            state.goods.push(action.payload);
            state.amount = calculateAmount(state.goods);
            state.totalConsumption = calculateTotalConsumption(state.goods);
            state.totalCgst = calculateTotalCgst(state.goods);
            state.totalSgst = calculateTotalSgst(state.goods);
            return state
        },

        // reducer function for adding description into the bill
        setDescription: (state, action) => {
            state.description = action.payload;
        },

        // reducer function for editing an existing good into the list. 
        editGood: (state, action) => {
            if (action.payload.index >= 0 && action.payload.index < state.goods.length) {
                state.goods[action.payload.index] = action.payload.readyData // Replacing the old good object with the new updated one

                // Recalculate properties on the basis of the new goods.
                state.amount = calculateAmount(state.goods);
                state.totalConsumption = calculateTotalConsumption(state.goods);
                state.totalCgst = calculateTotalCgst(state.goods);
                state.totalSgst = calculateTotalSgst(state.goods);
                return state
            }
        },

        //reducer function for addding bill last date
        addLastDate: (state, action) => {
            state.lastDate = action.payload
        },

        //reducer function for addding bill interest rate after the last date exceeding
        addInterstRate: (state, action) => {
            state.interestRate = action.payload
        },

        // reducer function for reseting the data inside the redux store when someone leaves page
        resetData: (state) => {
            state.amount = 0
            state.description = ''
            state.goods = []
            state.interestRate = 0
            state.lastDate = ""
            state.totalCgst = 0
            state.totalConsumption = 0
            state.totalSgst = 0
        },

        // reducer function for deleting a good from the list
        deleteGood: (state, action) => {
            const index = action.payload;
            if (index >= 0 && index < state.goods.length) {
                state.goods.splice(index, 1); // Remove the good from the array

                // Recalculate properties after deletion
                state.amount = calculateAmount(state.goods);
                state.totalConsumption = calculateTotalConsumption(state.goods);
                state.totalCgst = calculateTotalCgst(state.goods);
                state.totalSgst = calculateTotalSgst(state.goods);
            }
        },

        setDownloadBillID: (state, action) => {
            state.downloadBillID = action.payload;
        }
    },
    extraReducers: {
        [downloadBill.pending]: (state, action) => {
        },
        [downloadBill.fulfilled]: (state, action) => {
            state.downloadBillID = ""
        },
        [downloadBill.rejected]: (state, action) => {
            state.downloadBillID = ""
        }
    }
})

export const { addGood, setDescription, editGood, addLastDate, addInterstRate, resetData, deleteGood, setDownloadBillID, billGeneratingFor } = BillGenSlice.actions;
export default BillGenSlice.reducer;


// Debouncing function for not redux spam updates

// Create a debounced version of setDescription
let timeoutId;
export const debouncedSetDescription = (dispatch, description) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
        dispatch(setDescription(description));
    }, 2000); // Adjust the debounce delay as needed
};


// Create a debounced version of last date.
let timeoutId2;
export const debouncedSetLastDate = (dispatch, date) => {
    clearTimeout(timeoutId2);

    timeoutId2 = setTimeout(() => {
        dispatch(addLastDate(date));
    }, 500); // Adjust the debounce delay as needed
};


// Create a debounced version of interset rate
let timeoutId3;
export const debouncedSetInterestRate = (dispatch, IntRate) => {
    clearTimeout(timeoutId3);
    timeoutId3 = setTimeout(() => {
        if (IntRate >= 0 && IntRate <= 100 && IntRate !== '') {
            dispatch(addInterstRate(IntRate)); // Value is correct and going to store in redux
        }
        else {
            dispatch(addInterstRate('')) // Making the intrate = '' when someone typed wrong value. We will check for '' during creating bill
            dispatch(showTicker({
                message: 'Interest Rate must be between 0 and 100',
                type: 'error'
            }))
        }
    }, 2000); // Adjust the debounce delay as needed
};