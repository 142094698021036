import React, { useEffect, useRef, useState } from 'react';
import { SearchButtonIcon, SortButtonIcon } from '../../assets/assets';
import './UI.css';
import Button from '@mui/material/Button'
import { LoadingButton } from '@mui/lab';
import { Box, Drawer, Modal } from '@mui/material';

function SearchBar(props) {
    return <div className="search_bar_wrapper">
        <input type="text" className='search_bar' placeholder={props.placeholder}
            value={props.SearchKey} onChange={(e) => { props.setSearchKey(e.target.value) }} style={{ width: props.width }} />
        <img src={SearchButtonIcon} alt="search" className='search_icon' width="20px" height="20px" />
    </div>
}


// A normal loader for api actions and holding user when something is loading
function CircularLoader() {
    return <div className='CircularLoaderWrapper'>
        <svg className='svgLoader' viewBox="25 25 50 50">
            <circle className='circLoader' r="20" cy="50" cx="50"></circle>
        </svg>
    </div>
}


// a popup for confirmation. Shows a popup with a message and 2 buttons primary and secondary. You can pass both actions in props.
// PrimaryAction = for the primary button. Pass your primary action here in this prop.
// PrimaryButtonText - What is the button text for the primary action button. 
// SecondaryAction - your secondsary action. Pass your secondary action here
// Loading - if your primary action is an async opration. pass a loading state here.
// color - color of the primary button, pass here
// message - What will be the message in the popup.
function ConfirmationPopup(props) {
    return <div className="Confirmation_Popup_Wrapper">
        <div className="Confirmation_popup_base">
            <p className="simpleParagraph">{props.message}</p><br />
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Button onClick={props.SecondaryAction} color="SecondaryBtn" variant='contained' sx={{ width: '49%' }} disableElevation> Cancel</Button>
                <LoadingButton
                    onClick={props.PrimaryAction}
                    loading={props.Loading ? true : false}
                    color={props.color}
                    variant='contained'
                    sx={{ width: '49%' }}
                    disableElevation
                >
                    {props.PrimaryButtonText}</LoadingButton>
            </div>
        </div>
    </div>
}

// its a simple global function when called, returns a string of something went wrong. When a api call returns in a error then it will show. It is here if we need to show any illustrations or something in this component.
function SomethingWentWrong() {
    return <div className='SomethingWentWrong'>
        <p className="simpleParagraph">Oops! Something Went Wrong :-(</p>
    </div>
}

// this is a global side drawer function. Wrap your content in this function and your content will show up in a side drawer. Just you need to pass a Open prop for opening and handle prop for closing this drawer.
function SideDrawer(props) {
    const closeRef = useRef(null)
    // const [autocompleteFocused, setAutocompleteFocused] = useState(false);
    // function for closing the drawer onclicking outside of the drawer
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (closeRef.current && !closeRef.current.contains(event.target)) {
                props.handle(false)
            }
        }
        document.addEventListener('click', handleOutsideClick, true);
        return () => { document.removeEventListener('click', handleOutsideClick, true) }
    }, [props.Open]);
    return <Drawer sx={{ zIndex: 1, position: "relative", overflow: 'visible' }} open={props.Open} anchor={"right"}>
        <span ref={closeRef}>
            {props.children}
            {/* {React.Children.map(props.children, child => React.cloneElement(child, { setAutocompleteFocused }))} */}
        </span>
    </Drawer>
}


// this is a universal loader, it is different from the normal one because it appears on complete screeen and restrict user to do anything on the page.
function UniversalLoader() {
    return <div className="UniversalLoaderWrapper">
        <div class="UniversalLoader"></div>
    </div>
}


// global function for showing modals, wrap your elements with this function and a modal will be generated with your content. Just pass open and onclose for handling the popup.
function ShowModal(props) {
    return <Modal
        open={props.open}
        onClose={props.close}
        sx={{ zIndex: 1 }}
    >
        <Box className="ModalBox" style={{ width: props.width }}>
            {props.children}
        </Box>
    </Modal >
}

//EXPORTING ALL UI COMPONENTS
export { UniversalLoader, ShowModal, CircularLoader, ConfirmationPopup, SomethingWentWrong, SideDrawer };
