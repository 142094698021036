import React, { useState, useRef } from "react";
import { CircularLoader, SideDrawer, SomethingWentWrong } from "../UI Components/Basic";
import "./Styles/Industries.css";
import {
  Button, Typography, Card, CardContent, Stack, Chip, IconButton, Tooltip, Switch, Box, Alert, Divider,
} from "@mui/material";
import { Add, Delete, Download, FileOpen, Upload } from "@mui/icons-material";
import { BulkIndustryRegistration, BulkIndustryUploadAPI, bulkIndustryAddResponses } from "../../Redux/Slices/Industry/IndustryRegistrationSlice";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { showTicker } from "../../Redux/Slices/TickerSlice";
import { status } from "../../Redux/Slices/userSlice";


export default function BulkIndustryUpload(props) {

  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const { loading, error, successMessage, errorObj } = useSelector(state => state.BULK_INDUSTRY_UPLOAD);
  const AuthToken = useSelector((state) => state.USER_DETAILS.AuthToken); // logged in user auth token
  const PlantID = useSelector((state) => state.USER_DETAILS.PlantID);


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      dispatch(BulkIndustryUploadAPI({
        AuthToken: AuthToken,
        file: file,
        PlantID: PlantID
      }));
    }
  };




  return (
    <SideDrawer Open={props.Open} handle={props.handle}>
      <Box width={"900px"}>
        <Box sx={{ backgroundColor: "white", position: "sticky", top: 0, left: 0, zIndex: 1, boxShadow: "0px -5px 10px black" }} p={2}>
          <Stack direction="column" spacing={2}>
            <Box>
              <Typography variant="h5">Bulk Upload Industries</Typography>
              <Typography variant="body2">To add industries upload the <b>.csv</b> file of the industries in same format as provided in the download sample. </Typography>
            </Box>
            <Stack direction="row" spacing={1} width={"100%"}>
              <Button
                variant="contained"
                color="PrimaryBlue"
                disableElevation
                onClick={handleUpload}
                startIcon={<Upload />}
                disabled={file === null}
              > Upload file
              </Button>
              <LoadingButton
                variant="contained"
                color="PrimaryBlue"
                disableElevation
                sx={{ zIndex: "0" }}
              >
                <label
                  htmlFor="csv-file"
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    justifyContent: "center",
                  }}
                >
                  <FileOpen sx={{ width: "18px" }} />
                  &nbsp;&nbsp; Select CSV File
                </label>
              </LoadingButton>

              <Button
                variant="contained"
                color="PrimaryBlue"
                startIcon={<Download />}
                disableElevation
                onClick={() => { window.open("https://drive.google.com/uc?export=download&id=1u9wWaOu3RIpZoVD-SJZu0QiNymY5qoPR", "_blank") }}
              >
                Download Sample
              </Button>
            </Stack>
            <Typography variant="body2">File name : {file !== null ? file?.name : "No file uploaded"}</Typography>
          </Stack>
        </Box>


        <div style={{ display: "none" }}>
          <input type="file" onChange={handleFileChange} id="csv-file" />
        </div>

        {/* {error === null || error === bulkIndustryAddResponses.success ? "" : <Box p={2}>
          <Alert severity="error" variant="outlined">Oops! This file is having some issues. Please resolve it and re-upload again.</Alert>
        </Box> } */}

        {
          loading === status.LOADING ? <CircularLoader /> :
            (loading === status.ERROR || loading === status.IDLE) ?

              error === bulkIndustryAddResponses.errorEntries ?
                <Box p={2} m={2} sx={{ backgroundColor: "#ff000014", transition: '0.3s', borderRadius: 2 }}>
                  <Alert severity="error" variant="outlined">{errorObj?.errorRows?.length} Rows have missing information. Please resolve these issues and try to re-upload</Alert>
                  <br />
                  {
                    errorObj.errorRows?.map((entry, index) => {
                      return <Box key={index} p={2} sx={{ backgroundColor: "#ff000014", transition: '0.3s', borderRadius: 2, marginBottom: "7px" }}>
                        {
                          entry?.companyName !== "" ? <Typography variant="body1">{entry?.errors?.length} errors found in Entry with Company Name :<b> {entry?.companyName}</b>. Below are the errors.</Typography> :
                            entry?.address !== "" ? <Typography variant="body1">{entry?.errors?.length} errors found in Entry with Address : <b>{entry?.address}</b>. Below are the errors.</Typography> :
                              <Typography variant="body1">Error in row with no company name and address</Typography>

                        }
                        <br />
                        <MapErrors errors={entry?.errors} />
                      </Box>
                    })
                  }
                </Box>
                :
                error === bulkIndustryAddResponses.invalidFile ?
                  <Box p={2} sx={{ backgroundColor: "#ff000014", transition: '0.3s', borderRadius: 2 }}>
                    <Alert severity="error" variant="outlined">Only .csv file are allowed. Other files are not allowed</Alert>
                  </Box>
                  :
                  error === bulkIndustryAddResponses.emptyFile ?
                    <Box p={2} sx={{ backgroundColor: "#ff000014", transition: '0.3s', borderRadius: 2 }}>
                      <Alert severity="error" variant="outlined">No file Found. Please upload a file.</Alert>
                    </Box>
                    :
                    error === bulkIndustryAddResponses.headerMissing ?
                      <Box p={2} sx={{ backgroundColor: "#ff000014", transition: '0.3s', borderRadius: 2 }}>
                        <Alert severity="error" variant="outlined">Invalid Headers of the file. Some headers are may be missing, extra or not in order. Please use the same file format as provided.</Alert>
                        <br />
                        <Box p={2} sx={{ backgroundColor: "#ff000014", transition: '0.3s', borderRadius: 2 }}>
                          <Typography>Expected Headers</Typography> {
                            errorObj?.expectedHeaders?.map((header, index) => {
                              return <Typography variant="caption" key={index}>{header}, </Typography>
                            })
                          }
                          <br /><br />
                          <Typography>Obtained Headers</Typography> {
                            errorObj?.obtainedHeaders?.map((header, index) => {
                              return <Typography variant="caption" key={index}>{header}, </Typography>
                            })
                          }
                        </Box>
                      </Box>
                      :
                      error === bulkIndustryAddResponses.invalidHeader ?
                        <Box p={2} sx={{ backgroundColor: "#ff000014", transition: '0.3s', borderRadius: 2 }}>
                          <Alert severity="error" variant="outlined">Header with name : <b>{errorObj?.headerObtained}</b> is not acceptable. acceptable name is : <b>{errorObj?.headerRequired}</b></Alert>
                        </Box>
                        :
                        ""
              : ''
        }



        {
          successMessage === "Industries added successfully" ? <Box p={2}>
            <Alert severity="success" variant="filled">All Industries are created successfully and credentials for their login are sent to respective emails</Alert>
          </Box> : ''
        }
      </Box >
    </SideDrawer>
  );
}


// function for mappting the errors in the error list
function MapErrors(props) {
  return <Box>
    {
      props.errors.map((error, index) => {
        return <Typography variant="body2" key={index}>{index + 1}. {error}</Typography>
      })
    }
  </Box>
}



