import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_ADDRESS } from "../../../Firebase/Variables";
import { showTicker } from "../TickerSlice";


export const GetFlowByDate = createAsyncThunk('GetFlowByDate', async ({ IndustryID, AuthToken, Date, setLoading, PlantID }, { dispatch }) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    console.log(IndustryID, AuthToken, Date);

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/flow/?industryid=${IndustryID}&date=${Date}&plantID=${PlantID}`, requestOptions)
        const data = await response.json()

        if (response.ok) {
            if (data.data[0].data.flow !== null && data.data[0].data.flow !== undefined) {
                setLoading(false);
                return data.data[0].data.flow
            }
            else {
                dispatch(showTicker({
                    message: "Flow data not available from the Vision Worldtech Company.",
                    type: "error"
                }));
                setLoading(false);
                return 0
            }
        }
        else {
            console.log(response);
            dispatch(showTicker({
                message: "Flow data not available from the Vision Worldtech Company.",
                type: "error"
            }));
            setLoading(false);
        }
    }
    catch (error) {
        console.log(error);
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }));
        setLoading(false);
    }
})



// function for getting the flow data of the industry via date. 
// export function GetFlowByDate(IndustryID, AuthToken, Date, setLoading, dispatch) {

//     console.log(IndustryID, AuthToken, Date);
//     setLoading(true);
//     var myHeaders = new Headers();
//     myHeaders.append("Authorization", `Bearer ${AuthToken}`);
//     var requestOptions = {
//         method: 'GET',
//         redirect: 'follow'
//     };

//     // Return a Promise
//     return new Promise((resolve, reject) => {

//             .then((response) => {
//         response.json().then((data) => {
//             if (response.ok) {
//                 if (data.data[0].data.flow !== null && data.data[0].data.flow !== undefined) {
//                     resolve(data.data[0].data.flow); // Resolve with flow value
//                 } else {

//                     reject("Flow Not Found"); // Reject with an error message
//                 }
//             } else {
//                 dispatch(showTicker({
//                     message: `${response.statusText} | ${data.message}`,
//                     type: 'error'
//                 }));
//                 reject(`${response.statusText} | ${data.message}`); // Reject with an error message
//             }
//             setLoading(false);
//         });
//     })
//             .catch((error) => {
//                 dispatch(showTicker({
//                     message: error.message,
//                     type: 'error'
//                 }));
//                 setLoading(false);
//                 reject(error.message); // Reject with an error message
//             });
//     });
// }


// function for getting all the flow data at once of a industry.
export const GetAllFlow = createAsyncThunk('GetAllFlow', async ({ IndustryID }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/flow/?industryid=${IndustryID}`, requestOptions)
        const data = await response.json();

        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
    }
})