import React from "react";
import { useState } from "react";
import "./InventoryStyles/EditItem.css";
import { Add, Cancel, Save } from "@mui/icons-material";
import { TextField, Autocomplete, Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { showTicker } from "../../Redux/Slices/TickerSlice";
import { LoadingButton } from "@mui/lab";
import { GetInventoryConsumables, GetInventoryNonConsumables } from "../../Redux/Slices/InventorySlices/InventorySlice";
import { SERVER_ADDRESS } from "../../Firebase/Variables";
const options = ["Consumable", "Non Consumable"];

export default function EditItem(props) {

  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const dispatch = useDispatch()
  const [Loading, setLoading] = useState(false)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const [EditedData, setEditedData] = useState({
    itemType: props.item.itemType
  })

  // function for handle on chnge text feilds
  const HandleTextFeildsChange = (e, UpdateFeild) => {
    const { value } = e.target;

    // Check if the value is empty
    if (value.trim() !== '') {
      // If not empty, add or update the key-value pair
      setEditedData(prevData => ({
        ...prevData,
        [UpdateFeild]: value,
      }));
    } else {
      // If empty, remove the key-value pair
      const { [UpdateFeild]: deletedField, ...rest } = EditedData;
      setEditedData(rest);
    }
  }

  // validating text feild as if no changes are avliable then dont fetch api 
  const handleEditData = () => {
    const Values = Object.values(EditedData)
    // console.log(EditedData);
    if (Values.length === 1) { // this checks if only 1 change is present in the array then it must be consum/noncons dropdown always. so we have to check that dropdown value is new or old, if its new fetch the api otherwise throuw error
      if (EditedData.itemType !== props.item.itemType) {
        UPDATE_EDITED_DATA() //Fetch api
      }
      else {
        dispatch(showTicker({
          message: 'Edit at least 1 detail',
          type: 'error'
        }))
      }
    }
    else if (Values.length > 1) {
      UPDATE_EDITED_DATA()
    }
    else {
      dispatch(showTicker({
        message: 'Edit at least 1 detail',
        type: 'error'
      }))
    }
  }

  // fetch api function
  const UPDATE_EDITED_DATA = () => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${UserToken}`);

    var raw = JSON.stringify({
      "itemid": props.item.itemId, // Gettting from consumables.js
      "updates": EditedData
    });

    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${SERVER_ADDRESS}/inventory/update?plantID=${PlantID}`, requestOptions)
      .then(response => {
        if (response.status === 200) {
          dispatch(showTicker({
            message: "Item updated successfully",
            type: 'success'
          }))
          setLoading(false) // Remove loader
          props.setActiveModal(0) // CLosing the MOdal
          if (props.item.itemType === 'Consumable') { dispatch(GetInventoryConsumables({ AuthToken: UserToken, PlantID: PlantID })) } // Checking the typw of the item and based on that, we are refetchin the list. 
          else { dispatch(GetInventoryNonConsumables({ AuthToken: UserToken, PlantID: PlantID })) }
        }
        else {
          dispatch(showTicker({
            message: response.statusText,
            type: 'error'
          }))
          setLoading(false)// Remove loader
          props.setActiveModal(0)// CLosing the MOdal
        }
      })
      .then(result => {}
        // console.log(result)
        )
      .catch(error => {
        dispatch(showTicker({
          message: error.message,
          type: 'error'
        }))
        setLoading(false)// Remove loader
        props.setActiveModal(0)// CLosing the MOdal
      });
  }

  return (
    <div className="Modal_Black_bg">
      <div className="Modal_base">
        <div className="Modal_heading">
          Edit Item
          <IconButton color="PrimaryBlue" onClick={() => { props.setActiveModal(0) }}>
            <Cancel />
          </IconButton>
        </div>
        <div className="Modal_content">
          <div className="Item_info">
            <TextField
              sx={{ width: 265 }}
              size="small"
              label={props.item.itemName}
              placeholder="Enter New Name"
              onChange={(e) => { HandleTextFeildsChange(e, 'itemName') }}
            />
            <TextField
              sx={{ width: 265 }}
              size="small"
              label={props.item.itemCode}
              placeholder="Enter New Code"
              onChange={(e) => { HandleTextFeildsChange(e, 'itemCode') }}
            />
          </div><br />
          <div className="Item_Category">
            <Autocomplete
              size="small"
              onChange={(event, newValue) => {
                setEditedData({ ...EditedData, itemType: newValue })
              }}
              id="controllable-states-demo"
              options={options}
              sx={{ width: 265 }}
              renderInput={(params) => (
                <TextField {...params} label={props.item.itemType} />
              )}
            />
            <div className="Item_unit">
              <TextField
                size="small"
                placeholder="New Unit"
                label={props.item.itemUnit}
                style={{ width: "100px" }}
                onChange={(e) => { HandleTextFeildsChange(e, 'itemUnit') }}
              />
            </div>
          </div>
          <div className="Item_addbtn">
            <LoadingButton variant="contained" size="large" loading={Loading ? true : false} disableElevation onClick={handleEditData} startIcon={<Save />}>
              Save
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
}
