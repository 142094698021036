import React, { useEffect } from "react";
import { useState } from "react";
import { Typography, Button, TableHead, Autocomplete, TextField, TableContainer, Table, TableBody, TableCell, TableRow, Chip, Stack, } from "@mui/material";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Download, Receipt } from "@mui/icons-material";
import { GetBillsOf } from "../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/IndustryBillsSlice";
import { status } from "../../../Redux/Slices/userSlice";
import { CircularLoader, SideDrawer, SomethingWentWrong } from "../../UI Components/Basic";
import { DataGrid } from "@mui/x-data-grid";
import { BillDetailsLayout, convertGMTtoISTAndFormat } from "../../ReportsComponents/BillsAndPayments/DedicatedIndustryBill";
import { LoadingButton } from "@mui/lab";
import { downloadBill, setDownloadBillID } from "../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/BillGenerationSlice";
import { GetNoticesPlantSide } from "../../../Redux/Slices/Industry/SendNoticeSlice";
import { BillSideDetailsPlantSide } from "../../ReportsComponents/BillsAndPayments/BillSideDetailsPlantSide";

export default function IndustryBills() {

  const dispatch = useDispatch()
  const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const industries = useSelector(state => state.BILLS.industries)
  const Loading = useSelector(state => state.BILLS.loading)
  const DownloadBillID = useSelector(state => state.BILL_GENERATION.downloadBillID)
  const navigate = useNavigate()
  const { industryID } = useParams()
  const { IndustryData, LoadingData } = useOutletContext() // GETTING industry data from industry wrapper. we need ind name in downloading bill. 


  // coloums of the table
  const columns = [
    { field: "id", headerName: "S No.", type: "number", width: 70 },
    { field: "billDesc", headerName: "Bill", width: 200 },
    { field: "date", headerName: "Date", width: 180 },
    { field: "quotationNo", headerName: "Q. No.", width: 140 },
    { field: "amount", headerName: "Amount", width: 100 },
    {
      field: "status", headerName: "Status", width: 100,
      // checking value and based on that value, rendering the chip of paid and not paid
      renderCell: (params) => (
        params.value ? <Chip label="Paid" size='small' color='success' sx={{ fontSize: '12px' }} />
          : <Chip label="Not Paid" size='small' color='PrimaryRed' sx={{ fontSize: '12px' }} />
      )
    },
    {
      field: "actions", headerName: "Actions", sortable: false, width: 400,
      // rendering action buttons
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <LoadingButton
            loading={DownloadBillID === params.row.actions.id}
            loadingPosition="start"
            variant='contained'
            sx={{ height: '32px' }}
            startIcon={<Download sx={{ width: '16px' }} />}
            disableElevation
            onClick={() => { downloadHandler(params.row.actions.id) }}
          >Download Bill</LoadingButton>
          {/* {console.log(params.value)} */}
          <Button
            variant='outlined'
            sx={{ height: '32px' }}
            startIcon={<Receipt sx={{ width: '16px' }} />}
            onClick={() => {
              setBillDetails({
                isSideDrawerOpen: true,
                activeBillId: params.value.id,
                activeBillData: params.value.data
              })
            }}
            disableElevation
          >View Bill</Button>
        </Stack>
      )
    },
  ];

  // function for handing the payments
  const downloadHandler = (billid) => {
    dispatch(setDownloadBillID(billid))
    dispatch(downloadBill({ industryid: industryID, industryName: IndustryData?.data?.companyName, billid: billid, AuthToken: AuthToken, PlantID: PlantID }))
  }

  // Function to convert timestamp string to milliseconds
  function getTimestampInMilliseconds(timestamp) {
    return new Date(timestamp).getTime();
  }
  //function for sorting the bills according to date
  const getSortedBills = (bills) => {
    const newbills = bills?.slice().sort((a, b) => getTimestampInMilliseconds(b.data.dateCreated) - getTimestampInMilliseconds(a.data.dateCreated));
    return newbills
  }

  // fetching the bills of the industry via industry id. Checking also if the bills are already fetched, then do not fetch again. 
  useEffect(() => {
    // Check if bills for the current industry are already fetched

    // If not fetched, dispatch an action to fetch bills for this industry
    if (!(industryID in industries)) {
      dispatch(GetBillsOf({
        industryID: industryID,
        AuthToken: AuthToken,
        PlantID: PlantID
      }))
    }
  }, []);


  // function  for handling the clicking on a bill. it will set the clicked bill data into the side drawer state to make it render in the side drawer.
  const handleCellClick = (params) => {
    // console.log(params);
    if (params.field === "billDesc" || params.field === "date" || params.field === 'quotationNo' || params.field === "amount") {
      setBillDetails({ // Setting the bills details in to the side drawer state for showing it into the sidedrawer
        isSideDrawerOpen: true,
        activeBillId: params.row.actions.id,
        activeBillData: params.row.actions.data
      })
    }
  }


  // Define a function to render bills or a message based on the loading and error state
  const renderBills = () => {
    // Check if bills for the current industry are being loaded
    if (Loading === status.LOADING) {
      return <CircularLoader />; // Show loader while loading
    } else if (Loading === status.ERROR) {
      return <SomethingWentWrong />; // Show error message if loading failed
    } else {
      // Render bills if they exist
      return (
        <div>
          {
            industries[industryID]?.bills !== undefined ?
              <DataGrid
                onCellClick={handleCellClick}
                rowSelection={false}
                rows={getSortedBills(industries[industryID]?.bills)?.map((bill, i) => {
                  return (
                    {
                      id: i + 1,
                      billDesc: bill.data.description,
                      date: convertGMTtoISTAndFormat(bill.data.dateCreated),
                      quotationNo: bill.data.quotationNo,
                      amount: bill.data.amount,
                      status: bill.data.isPaid,
                      actions: bill
                    }
                  )
                })}
                columns={columns}
                pageSize={2}
              />
              : <SomethingWentWrong />
          }

        </div>
      );

    }
  };



  // function for showing the all bill infromation in the side drawer
  const [BillDetails, setBillDetails] = useState({
    isSideDrawerOpen: false,
    activeBillId: '',
    activeBillData: []
  })
  function BillDetailsDrawer() {
    return <SideDrawer Open={BillDetails.isSideDrawerOpen} handle={setBillDetails}>
      <BillSideDetailsPlantSide
        billID={BillDetails.activeBillId}
        industryID={industryID} // important for deleting the bill
        billData={BillDetails.activeBillData}
        setBillDetails={setBillDetails} // passing here because we need to re pass it in the delete bill slice for closing the drawer after deleting the bill 
      />
    </SideDrawer>
  }


  return (
    <div>
      <div
        className="table_Heading_Container"
        style={{ justifyContent: "space-between" }}
      >
        <Typography variant="subtitle2">All bills</Typography>
        <div className="table_toolbar">
          {/* <Autocomplete
            size="small"
            value={selectedMonth}
            onChange={(event, newValue) => {
              setSelectedMonth(newValue);
              setEnteredData({ ...enteredData, itemType: newValue });
            }}
            id="controllable-states-demo"
            options={months}
            sx={{ width: "200px" }}
            renderInput={(params) => (
              <TextField {...params} label="Select month" />
            )}
          /> */}
          <Button
            variant="contained"
            color="PrimaryBlue"
            startIcon={<Receipt sx={{ width: '16px' }} />}
            onClick={() => { navigate(`/reports/generate-bill/${industryID}`) }}
            disableElevation
          >Generate Bill</Button>
        </div>
      </div>
      {renderBills()}

      {/* side drawer for rendering the complete details of the bills */}
      {BillDetailsDrawer()}
    </div>
  );
}
