import React, { useEffect, useState } from "react";
import { Add, ArrowBack, ArrowForward, Cancel, Save } from "@mui/icons-material";
import { TextField, Button, IconButton, Stack, Chip, Divider, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import "./Team.css";
import { useDispatch, useSelector } from "react-redux";
import { showTicker } from "../../Redux/Slices/TickerSlice";
import { LoadingButton } from "@mui/lab";
import { FetchUsers } from "../../Redux/Slices/MyTeamSlices/GetUsersSlice";
import { SERVER_ADDRESS } from "../../Firebase/Variables";

export default function EditItem(props) {
  const [EditedData, setEditedData] = useState([])
  const dispatch = useDispatch()
  const [isBasicDetailsOk, setisBasicDetailsOk] = useState(false)
  const [departmentAccess, setDepartmentAccess] = useState(props.TargetUser.departmentAccess)

  // function for handle on chnge text feilds, handle empty feilds 
  //this function add a key value pair if user type in any feild and if user remove text ffrom feild it delete that key value pair.
  const HandleTextFeildsChange = (e, UpdateFeild) => {
    const { value } = e.target;
    // Check if the value is empty
    if (value.trim() !== '') {
      // If not empty, add or update the key-value pair
      setEditedData(prevData => ({
        ...prevData,
        [UpdateFeild]: value,
      }));
    } else {
      // If empty, remove the key-value pair
      const { [UpdateFeild]: deletedField, ...rest } = EditedData;
      setEditedData(rest);
    }
  }

  // this jsx shows the first step of editing the user , it will show some basic details to edit like name phone and post, and if isBasicDetailsok become true the next step for updting permissions will appear
  return (
    // Main Modal Container
    <div className="Modal_Black_bg">
      <div className="Modal_base">
        <div className="Modal_heading">
          {isBasicDetailsOk ? 'Add & Change Permissions' : 'Edit User'}
          <IconButton color="PrimaryBlue" onClick={() => { props.setActiveModal(0) }}> <Cancel /> </IconButton>
        </div>
        {
          !isBasicDetailsOk ? <div>
            <div className="Modal_content_edit">
              <div className="Item_info">
                <TextField fullWidth size="small" label={props.TargetUser.name} value={EditedData?.name} placeholder="New Name" onChange={(e) => { HandleTextFeildsChange(e, 'name') }} />
              </div>
              <div className="Item_info">
                <TextField sx={{ width: '49%' }} size="small" label={props.TargetUser.postName} value={EditedData?.postName} placeholder="New Post" onChange={(e) => { HandleTextFeildsChange(e, 'postName') }} />
                <TextField sx={{ width: '49%' }} size="small" type="number" label={props.TargetUser.phoneNo} value={EditedData?.phoneNo} placeholder="New Phone No." onChange={(e) => { HandleTextFeildsChange(e, 'phoneNo') }} />
              </div>
              <div className="Item_addbtn">
                <Button variant="contained" size="large" color="PrimaryBlue" endIcon={<ArrowForward />} disableElevation onClick={() => { setisBasicDetailsOk(true) }}>Proceed </Button>
              </div>
            </div>
          </div> :
            <ChangingDepartmentPermission
              departmentAccess={departmentAccess} // FOr displaying the permissions in permission page.
              setDepartmentAccess={setDepartmentAccess} // For adding or removing permission. Simply for changing the user permissions
              setisBasicDetailsOk={setisBasicDetailsOk} //For handling the page wether it is on baisc detials or changing permisssion page.
              TargetUser={props.TargetUser} // FOrwarding the Target user for checking some values like access levels
              EditedData={EditedData} //Need this in api call because it stores all basic data to be update
              setActiveModal={props.setActiveModal}
            />
        }
      </div>
    </div>
  );
}



// there was a problem happening with using addusers.js departmentAccess component is that componet is manipulaing the data passing from Addusers.js and we have different data here, means that function is hardcoded for the Adding users permission. SO we have to create a new similar function for changing the permission for existing user. this make code big but clear. 
function ChangingDepartmentPermission(props) {
  const [selectedDepartment, setSelectedDepartment] = useState(''); // For storing the selected department
  const [selectedAccess, setSelectedAccess] = useState(''); // For storing the selected access
  const [Loading, setLoading] = useState(false)
  const dispatch = useDispatch() // For dispatching actions
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const plantID = useSelector(state => state.USER_DETAILS.PlantID)


  useEffect(() => { if (props.Category === 'Admin') { props.setDepartmentAccess([]) } }, [props.Category])

  // this function is the main function here, it handels all the operations from department and access selelction. 
  const handleAddAccess = () => {
    if (selectedDepartment && selectedAccess) {
      // if someone adds ony other access to user and array includes the ALL permission, then remove it for adding the other permsisions. beacuse both can not be present in that array 
      if (props.departmentAccess.includes('All')) {
        const index = props.departmentAccess.indexOf('All')
        if (index > -1) {
          props.departmentAccess.splice(index, 1);
        }
      }

      // Check if the newAccess already exists in the array
      const newAccess = `${selectedDepartment}-${selectedAccess}`;
      if (!props.departmentAccess.includes(newAccess)) {
        // If selectedAccess is 'write', remove 'read' if present and add 'write'
        if (selectedAccess === 'Write') {
          const readPermission = `${selectedDepartment}-Read`;
          props.setDepartmentAccess((prevAccess) =>
            prevAccess
              .filter((access) => !access.startsWith(`${selectedDepartment}-`))
              .concat(newAccess)
          );
        } else if (selectedAccess === 'Read') {
          // If selectedAccess is 'read', remove 'write' if present and add 'read'
          const writePermission = `${selectedDepartment}-Write`;
          props.setDepartmentAccess((prevAccess) =>
            prevAccess
              .filter((access) => !access.startsWith(`${selectedDepartment}-`))
              .concat(newAccess)
          );
        } else {
          props.setDepartmentAccess((prevAccess) => [...prevAccess, newAccess]);
        }
      } else {
        // Handle case when the permission already exists
        dispatch(showTicker({
          message: 'Access already exists',
          type: 'error'
        }))
        // You can show a message or perform other actions as needed
      }
      // Clear selected values after adding
      setSelectedDepartment('');
      setSelectedAccess('');
    }
    else if (selectedDepartment === "All") {
      props.setDepartmentAccess(['All']);
    }
    else {
      dispatch(showTicker({
        message: 'Please select Department and Access',
        type: 'error'
      }))
    }
  };


  // delete access chip and permission from departmentAccess array
  const handleDeleteAccess = (index) => {
    const updatedAccess = [...props.departmentAccess];
    updatedAccess.splice(index, 1);
    props.setDepartmentAccess(updatedAccess);
  };

  // cant show read and write in the page. so this function make paragraph based on given access.
  const EnhanceParagraph = (access) => {
    const [department, accessType] = access.split('-');
    let actionDescription = '';
    if (access === 'All') {
      return `The user can access all the Departments`;
    }
    else {
      if (accessType === 'Read') {
        actionDescription = 'View';
      } else if (accessType === 'Write') {
        actionDescription = 'View and Change';
      }
      return `This User can ${actionDescription} the data in ${department} Section.`;
    }
  }

  // this function i made because i want to check the paermission that user have currently and permissions that we have given him is same or not, if same there is no updation in departmentAccess array. 
  function arraysAreEqual(array1, array2) {
    if (array1.length !== array2.length) { return false; }
    const copyOfArray2 = array2.slice();
    for (const element of array1) {
      const indexInArray2 = copyOfArray2.indexOf(element);
      if (indexInArray2 === -1) { return false; }
      copyOfArray2.splice(indexInArray2, 1);
    }
    return true;
  }

  // this function checks that user made a changes or not. if not we are not going to fetch api .
  const FinalValidationBeforeFetchingAPI = () => {
    // checking if the props.departmentAcess array is undefined, that means the uer we are editing is An Admin, so we do not need to check permission array are equl or not. 
    if (props.departmentAccess !== undefined) {
      //Checking the passed array and new permission array are equal or not, if equal that means not changes are made. also checking the user made any basic details or not? 
      if (!arraysAreEqual(props.TargetUser.departmentAccess, props.departmentAccess) || props.EditedData.length !== 0) {
        FetchEditUserAPI(
          props.TargetUser.userID, // For appending in the api endpoint
          plantID,
          props.departmentAccess, // new permissions array 
          props.EditedData, // basic details array if user change something.
          UserToken, // usertoken
          dispatch, //For showing ticker
          setLoading, // for loading obiously
          props.setActiveModal // For closing modal
        )
      }
      else {
        dispatch(showTicker({
          message: 'No new changes detected',
          type: 'error'
        }))
      }
    }
    else {
      // here we know that user being edited is admin , so we check basic details only because admin and permission hehe.. you know that right?
      if (props.EditedData.length !== 0 && Object.keys(props.EditedData).length) {
        FetchEditUserAPI(
          props.TargetUser.userID, // For appending in the api endpoint
          plantID,
          props.departmentAccess, // new permissions array 
          props.EditedData, // basic details array if user change something.
          UserToken, // usertoken
          dispatch, //For showing ticker
          setLoading, // for loading obiously
          props.setActiveModal // For closing modal
        )
      }
      else {
        dispatch(showTicker({
          message: 'No new changes detected',
          type: 'error'
        }))
      }
    }
  }

  return (
    <div className="DepartmentAccessContainer" style={{ animation: 'fade-up 1s cubic-bezier(0.19, 1, 0.22, 1)' }}>
      <div className="Item_addbtn" style={{ padding: '20px' }}>
        <Button
          variant="contained"
          size="large"
          color="SecondaryBtn"
          disableElevation
          startIcon={<ArrowBack />}
          onClick={() => { props.setisBasicDetailsOk(false) }}
        >Back</Button>
        &nbsp;
        {/* Main button for fetching api  */}
        <LoadingButton
          variant="contained"
          size="large"
          color="PrimaryBlue"
          disableElevation
          startIcon={<Save />}
          loading={Loading}
          disabled={props.departmentAccess?.length === 0 && props.TargetUser.accessLevel !== 1 ? true : false}
          onClick={() => { FinalValidationBeforeFetchingAPI() }}
        >Save</LoadingButton>
      </div>

      <div className="UserAccess">
        <p className="simpleParagraph">User Permissions</p>
        <br />
        {
          // Check if user is admin he then he dont have any deparmentAccess Array , so we dont have to show any permissions. so we show permissions if any user is not admin 
          props.TargetUser.accessLevel === 1 ? <p className="simpleParagraph"><b>Admin</b> user have permissions to <b>View</b> and <b>Change</b> data in all deparments</p> : <Stack direction="column" spacing={1}>
            {props.departmentAccess?.map((access, index) => (
              <div key={index}>
                <Chip label={EnhanceParagraph(access)} variant="outlined" onDelete={() => handleDeleteAccess(index)} />
              </div>
            ))}
          </Stack>
        }

      </div>

      <Divider />
      <div className="AddUserAccess">
        <FormControl sx={{ width: '36%' }} size="small">
          <InputLabel id="demo-simple-select-label">Select Department</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedDepartment}
            label="Age"
            onChange={(e) => { setSelectedDepartment(e.target.value) }}
            disabled={props.TargetUser.accessLevel === 1} // disabled because admin dont need any permissions
          >
            {/* <MenuItem value={'All'}>All</MenuItem> */}
            <MenuItem value={'Industry'}>Industry</MenuItem>
            {/* <MenuItem value={'Notices'}>Notices</MenuItem> */}
            <MenuItem value={'Bill'}>Bills</MenuItem>
            {/* <MenuItem value={'Sludge'}>Sludge</MenuItem> */}
            {/* <MenuItem value={'Process'}>Process</MenuItem> */}
            {/* <MenuItem value={'Reports'}>Reports</MenuItem> */}
            <MenuItem value={'Inventory'}>Inventory</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: '36%' }} size="small">
          <InputLabel id="demo-simple-select-label">Select Access</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedAccess}
            label="Age"
            onChange={(e) => { setSelectedAccess(e.target.value) }}
            disabled={selectedDepartment === "All" || props.TargetUser.accessLevel === 1} //disabled if selected department is All and if someone editing a admin
          >
            {/* <MenuItem value={'Read'}>View Only</MenuItem> */}
            <MenuItem value={'Write'}>View and Change</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          color="PrimaryBlue"
          onClick={handleAddAccess}
          startIcon={<Add />}
          disabled={props.TargetUser.accessLevel === 1} // Button disabled if someone editing an admin
        >
          Add Access
        </Button>
      </div>
    </div>
  );
};





// function for fetching api for updating the user
function FetchEditUserAPI(uid, PlantID, departmentAccess, EditedData, UserToken, dispatch, setLoading, setActiveModal) {
  setLoading(true)
  // creating a new body object which formatted same as api requirement.
  const newBody = {
    updateData: {
      ...EditedData,
      departmentAccess: departmentAccess
    }
  }
  console.log(newBody);
  const strg = JSON.stringify(newBody)
  console.log(strg);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${UserToken}`);


  var requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: strg,
    redirect: 'follow'
  };

  fetch(`${SERVER_ADDRESS}/user/update/${uid}?plantID=${PlantID}`, requestOptions)
    .then(response => {
      response.json().then((data) => {
        if (response.ok) {
          dispatch(showTicker({
            message: data.message,
            type: 'success'
          }))
          setActiveModal(0) //Closing the edit user modal 
          setLoading(false) // setting loading to false
          dispatch(FetchUsers({ AuthToken: UserToken, PlantID: PlantID })) // refetching the users for showing changes
        }
        else {
          dispatch(showTicker({
            message: data.message,
            type: 'error'
          }))
          setLoading(false)
          console.log(data.message);
        }
      })
    })
    .catch(error => {
      dispatch(showTicker({
        message: error.message,
        type: 'error'
      }))
      setLoading(false)
    });
}