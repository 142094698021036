import React from 'react'
import { useSelector } from 'react-redux'
import { status } from '../../../Redux/Slices/userSlice'
import { DRAWER_PERMISSIONS } from '../../../Roles/Roles'
import { UniversalLoader } from '../../UI Components/Basic'
import PageNotFound from '../../OtherComponents/PageNotFound'
import NotPermitted from '../../OtherComponents/NotPermitted'
import IndustrySideBills from '../../ReportsComponents/BillsAndPayments/IndustrySideBillsandPayments/IndustrySideBills'
import DedicatedIndustryBill from '../../ReportsComponents/BillsAndPayments/DedicatedIndustryBill'
import NotificationsNotices from '../../NoticeNotificationsComponents/Notifications'
import Consumables from '../../InventoryComponents/Consumables'
import { useNavigate } from 'react-router-dom'
import { DrawerRoutes } from '../routes'

export default function UserAccessProtected({ children, child }) {

    const Loading = useSelector(state => state.USER_DETAILS.Loading) // For checking if the user detials are loading or not.
    const user = useSelector(state => state.USER_DETAILS) // Details of the user
    const DrawerPermissions = DRAWER_PERMISSIONS(user.UserRole, user.UserAccessLevel, user.UserDetails?.departmentAccess) // Getting permission for the drawer via the dedicated function in the roles.js file. 
    const navigate = useNavigate()

    // console.log(child); // Checking which child is currently active.


    if (Loading === status.IDLE) { // Waiting for user details to be fetched from server.

        // Basically we made a function "DRAWER_PERMISSIONS" for assigning permissions based on the user role, user level and department access. That permission we are using here to restrict user for going to that page which is not assigned to him. If that function returns false for a specific department then pages related to that department will not showed to user hence it will be redirected to the NotPermitted page. And if returns true then user will redirected to child component.

        // we passed a child prop while rendering the component for checking the permissions of that component. by this child prop we get to know on which page user is trying to go. so before redirecting him to that page we check if he has permission to view that page or not. 

        // all the pages related to the industry section. Permitted here, on basis of Drawerpermission allowed them or not. 
        if (child === 'Industries' || child === 'AddIndustry' || child === 'IndustryDetailWrapper' || child === 'IndustriesRequests') {
            if (user.UserRole === "industry") { return <IndustrySideBills /> }
            if (DrawerPermissions.isAllowedIndustrySection) { return children }
            else if (DrawerPermissions.isAllowedBills) { navigate(DrawerRoutes.Reports.Financial_Reports) }
            else if (DrawerPermissions.isAllowedNotices) { navigate(DrawerRoutes.Notification) }
            else if (DrawerPermissions.isAllowedInventory) { navigate(DrawerRoutes.Inventory.consumables) }
            else { return <NotPermitted /> }
        }

        // all the pages related to the inventory section. 
        else if (child === 'Consumables' || child === 'Nonconsumeables') {
            if (DrawerPermissions.isAllowedInventory) { return children }
            else { return <NotPermitted /> }
        }

        // all the pages related to the Notifications section. 
        else if (child === 'NotificationsNotices' || child === 'SendNotice') {
            if (DrawerPermissions.isAllowedNotices) { return children }
            else { return <NotPermitted /> }
        }

        // all the pages related to the Process management section. 
        else if (child === 'Process' || child === 'SensorAlerts' || child === 'Chamber' || child === "EditChamber") {
            if (DrawerPermissions.isAllowedProcess) { return children }
            else { return <NotPermitted /> }
        }

        else if (child === 'BillsPayments' || child === 'DedicatedIndustryBill' || child === 'GenerateBill' || child === 'MasterCopies' || child === 'GenerateMasterBill' || child === "PaymentRequests") {
            if (DrawerPermissions.isAllowedBills) { return children }
            else { return <NotPermitted /> }
        }

        else if (child === "Sludge") {
            if (DrawerPermissions.isAllowedSludge) { return children }
            else { return <NotPermitted /> }
        }

        else if (child === "Reports" || child === "InventoryReports") {
            if (DrawerPermissions.isAllowedReports) { return children }
            else { return <NotPermitted /> }
        }

        // all the pages related to the Team section. 
        else if (child === 'Team') {
            if (DrawerPermissions.isAllowedTeam) { return children }
            else { return <NotPermitted /> }
        }

        else if (child === 'PlantProfile') {
            if (DrawerPermissions.isAllowedPlantProfile) { return children }
            else { return <NotPermitted /> }
        }





        // industry side component user permissions here
        else if (child === "IndustrySideBills") {
            if (DrawerPermissions.isAllowedIndustrySideBillsSection) { return children }
            else { return <NotPermitted /> }
        }

        else if (child === "PaymentPages") {
            if (DrawerPermissions.isAllowedPaymentResults) { return children }
            else { return <NotPermitted /> }
        }

        else {
            return <PageNotFound />
        }
    }

    // return this when details of the user is fetching from the server
    else {
        return <UniversalLoader />
    }
}
