import React, { useEffect, useRef, useState } from 'react'
import { ErrorTicker, ProceedButton } from './AuthUIComponents'
import { MailVerificationIllustration, VysionLogo_Login } from '../assets/assets'
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthVariable } from '../Firebase/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { AuthenticationRoutes } from '../Components/Routes/routes';
import { useDispatch } from 'react-redux';
import { showTicker } from '../Redux/Slices/TickerSlice';

export default function ForgotPassword() {
    const [Level, setLevel] = useState(0) // Level for different steps of password recovery process

    return (
        <>
            <div className="Grid1">
                {
                    Level === 0 ? <EnterEmailAddress setLevel={setLevel} /> //Showing this for entering email address of user
                        : Level === 1 ? <MailSentConfirmation setLevel={setLevel} /> //showing this for showing the email for resetting password is sent to this email address
                            : <EnterEmailAddress setLevel={setLevel} /> //Showing First level if this logic broke
                }
            </div>

        </>
    )
}


// function for taking email from user for recovering his password
function EnterEmailAddress(props) {
    const [Loading, setLoading] = useState(false) //Loading
    const userNameRef = useRef(""); //Refrence for username feild
    const dispatch = useDispatch()

    // validating  empty feilds 
    const VALIDATE_FEILDS = () => {
        if (userNameRef.current.value === '') {
            dispatch(showTicker({
                message: 'Please fill all the required fields',
                type: 'error'
            }))
            userNameRef.current.focus();
            return
        }
        else {
            if (checkUsername()) { SendPasswordResetEmail() }
            else {
                dispatch(showTicker({
                    message: 'Invalid Email',
                    type: 'error'
                }))
            }
        }
    }

    // validating proper email format
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const checkUsername = () => {
        if (regex.test(userNameRef.current.value)) {
            userNameRef.current.style.outline = "1px solid green"
            return true
        }
        else {
            userNameRef.current.style.outline = "1px solid red"
            return false
        }
    }
    const SendPasswordResetEmail = () => {
        setLoading(true)
        sendPasswordResetEmail(FirebaseAuthVariable, userNameRef.current.value)
            .then(() => {
                setLoading(false) //Setting loading false
                props.setLevel(1) // if email sent, setting level 1 for showing email sent confirmation.
                dispatch(showTicker({
                    message: 'Mail sent to your email address',
                    type: 'success'
                }))
            })
            .catch((error) => {
                const errorMessage = error.message;
                dispatch(showTicker({
                    message: errorMessage,
                    type: 'error'
                }))
                setLoading(false)
            });
    }

    return <div className="Login" id="login">
        <br />
        {/* <img className="Logologin" src={VysionLogo_Login} /> */}
        <p className="Message">Enter your registered email address in order to recover your password</p>
        <input className="UserName" // Taking Email from user
            onChange={checkUsername}
            ref={userNameRef}
            placeholder="Your E-mail address" />
        <ProceedButton
            targetFunction={VALIDATE_FEILDS}
            title={"Proceed"} loading={Loading} />
    </div>
}




// function for showing that the email for resetting password is sent to your registered email.
function MailSentConfirmation() {
    const navigate = useNavigate()
    return <div className="Login" id="login">
        <img className="Logologin" src={MailVerificationIllustration} />
        <p className="Message">Mail for resetting password is sent to your email address. Open your inbox and click on the link for resetting your password and login again.</p>
        <br />
        <ProceedButton title={"Login"} targetFunction={() => { navigate(AuthenticationRoutes.UserSelection) }} />
    </div>
}
