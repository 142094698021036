import React, { useState } from 'react'
import { ConfirmationPopup } from '../UI Components/Basic'
import { useDispatch, useSelector } from 'react-redux'
import { showTicker } from '../../Redux/Slices/TickerSlice'
import { FetchUsers } from '../../Redux/Slices/MyTeamSlices/GetUsersSlice'
import { SERVER_ADDRESS } from '../../Firebase/Variables'

export default function Remove(props) {
    const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
    const dispatch = useDispatch()
    const [Loading, setLoading] = useState(false)
    const PlantID = useSelector(state => state.USER_DETAILS.PlantID)


    // API call for deleting an item
    const DELETE = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${UserToken}`);
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${SERVER_ADDRESS}/user/delete/${props.TargetUser.userID}?plantID=${PlantID}`, requestOptions)
            .then(response => {
                response.json().then((data) => {
                    if (response.ok) {
                        dispatch(showTicker({
                            message: `Successfully deleted the User ${props.TargetUser.name}`,
                            type: 'success'
                        }))
                    }
                    else {
                        dispatch(showTicker({
                            message: data.message,
                            type: "error"
                        }))
                    }
                })
                setLoading(false) // Setting loading to false
                props.setActiveModal(0) // Closing confiremation pop for deleing user
                dispatch(FetchUsers({ AuthToken: UserToken, PlantID: PlantID })) // refetching users for showing changes in the page
            })
            .catch(error => {
                dispatch(showTicker({
                    message: error.message,
                    type: "error"
                }))
                setLoading(false)
            });
    }
    return <ConfirmationPopup
        PrimaryAction={DELETE}
        SecondaryAction={() => { props.setActiveModal(0) }}
        PrimaryButtonText={"Delete"}
        color="PrimaryRed"
        message={`Deleting user will completely remove his/her all data and permissions. Are you sure you want to delete the user ${props.TargetUser.name} ?`}
        Loading={Loading}
    />
}
