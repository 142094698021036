import { createSlice } from "@reduxjs/toolkit";

const navSlice = createSlice({
    name: 'navigationSlice',
    initialState: null,
    reducers: {
        setActiveIndustry(state, action) {
            state = action.payload;
            return state;
        }
    }
})

export const { setActiveIndustry } = navSlice.actions;
export default navSlice.reducer;