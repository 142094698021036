import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showTicker } from "../TickerSlice";
import { DrawerRoutes } from "../../../Components/Routes/routes";
import { SERVER_ADDRESS } from "../../../Firebase/Variables";
import { status } from "../userSlice";

export const SendNotices = createAsyncThunk('SendNotices', async ({ AuthToken, files, RecipientsIDs, title, content, navigate, setSendingLoading, PlantID }, { dispatch }) => {

    setSendingLoading(true)
    let isAll = RecipientsIDs.includes("All")

    // fixing endpoint on basis of isAll is true or not. 
    const endpoint = isAll ? `${SERVER_ADDRESS}/industry/notice/create?sendtoall=true&plantID=${PlantID}` : `${SERVER_ADDRESS}/industry/notice/create?plantID=${PlantID}`
    console.log(RecipientsIDs, isAll, endpoint);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var formdata = new FormData();
    // appending files in to the formdata
    for (let i = 0; i < files.length; i++) {
        formdata.append('notices', files[i], files[i].name)
    }
    formdata.append("description", content);
    formdata.append("title", title);
    if(!isAll){formdata.append("industries", JSON.stringify(RecipientsIDs));}


    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(endpoint, requestOptions)
        const data = await response.json();

        if (response.ok) {
            dispatch(showTicker({
                message: data.message,
                type: 'success'
            }))
            navigate(DrawerRoutes.Notification)
            setSendingLoading(false)
        }
        else {
            console.log(response, data);
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            setSendingLoading(false)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        setSendingLoading(false)
    }
})



// function getting all the notice till now.
export const GetNoticesPlantSide = createAsyncThunk('GetNoticesPlantSide', async ({ AuthToken, PlantID }, { dispatch, fulfillWithValue, rejectWithValue }) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/notice?plantID=${PlantID}`, requestOptions)
        const data = await response.json();

        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
    }
})


// deleting the notices on plant side. 
export const DeleteNotice = createAsyncThunk('DeleteNotice', async ({ AuthToken, NoticeID, PlantID, setLoading, setNoticeDetails, setisDelete }, { dispatch }) => {

    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/notice/delete/${NoticeID}?plantID=${PlantID}`, requestOptions)
        const data = await response.json();

        if (response.ok) {
            dispatch(showTicker({
                message: data.message,
                type: 'success'
            }))
            // some actions after successfully deleting the notice
            setLoading(false)
            dispatch(deleteNoticeRedux(NoticeID))
            setNoticeDetails({
                isSideDrawerOpen: false,
                activeNoticeData: [],
                activeNoticeID: ""
            })
            setisDelete(false)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            setLoading(false)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        setLoading(false)
    }
})


const NoticeSlice = createSlice({
    name: 'NoticeSlice',
    initialState: {
        Loading: status.IDLE,
        Notices: [], // populate when current user is a plant user
        DedicatedNotices: [] // Only for the industry side user. 
    },
    reducers: {
        deleteNoticeRedux(state, action) {
            const noticeID = action.payload;
            state.Notices.notices = state.Notices.notices.filter(notice => notice.id !== noticeID);
        }
    },
    extraReducers: {
        [GetNoticesPlantSide.pending]: (state) => {
            state.Loading = status.LOADING
        },
        [GetNoticesPlantSide.fulfilled]: (state, action) => {
            state.Loading = status.IDLE
            state.Notices = getSortedNotices(action.payload.notices)

        },
        [GetNoticesPlantSide.rejected]: (state) => {
            state.Loading = status.ERROR
        },
    }
})

export const { deleteNoticeRedux } = NoticeSlice.actions;
export default NoticeSlice.reducer;



// Function to convert timestamp string to milliseconds
function getTimestampInMilliseconds(timestamp) {
    return new Date(timestamp).getTime();
}

//function for sorting the notices according to date
const getSortedNotices = (bills) => {
    const notices = bills?.slice().sort((a, b) => getTimestampInMilliseconds(b.data.creationDate) - getTimestampInMilliseconds(a.data.creationDate));
    const newNotices = { notices }
    return newNotices
}