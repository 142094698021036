import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { VysionLogo_Login } from "../assets/assets";
import { useRef } from "react";
import { ErrorTicker, ProceedButton } from "./AuthUIComponents";
// import { LoginEndPoint } from "../API_Management/AuthenticationAPIs";
import { AuthenticationRoutes, DrawerRoutes } from "../Components/Routes/routes";
import './AuthStyles/Login.css'
import { signInWithEmailAndPassword } from "@firebase/auth";
import { FirebaseAuthVariable } from "../Firebase/firebase";
import { useDispatch, useSelector } from "react-redux";
import { Already_Loggedin_User } from "./PlantLogin";
import { CheckCurrentUser } from "../Redux/Slices/userSlice";
import { showTicker } from "../Redux/Slices/TickerSlice";
import { Stack, Typography } from "@mui/material";

const IndustryLogin = () => {

  const navigate = useNavigate(); // For navigation on other routes
  const userNameRef = useRef(""); //Refrence for username feild
  const passwordRef = useRef(""); // Refrence for password feild
  const [Loading, setLoading] = useState(false) //Loading
  const UID = useSelector(state => state.USER_DETAILS.UID); //Getting uid from redux store for checking any user is logged in or not
  const dispatch = useDispatch()

  // dispatching this action because on login page we have to check if user is logged in or not, if any user is already logged in then it will show, alredy logged in user 
  useEffect(() => {
    dispatch(CheckCurrentUser())
  }, [])

  // validating  empty feilds 
  const VALIDATE_FEILDS = () => {
    if (userNameRef.current.value === '') {
      dispatch(showTicker({
        message: 'Please fill all the required fields',
        type: 'error'
      }))
      userNameRef.current.focus();
      return
    }
    if (passwordRef.current.value === '') {
      dispatch(showTicker({
        message: 'Please fill all the required fields',
        type: 'error'
      }))
      passwordRef.current.focus();
      return
    }
    else {
      if (checkUsername()) { LOGGIN_IN(userNameRef.current.value, passwordRef.current.value) }
      else {
        dispatch(showTicker({
          message: 'Invalid username or password',
          type: 'error'
        }))
      }
    }
  }

  // validating proper email
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const checkUsername = () => {
    if (regex.test(userNameRef.current.value)) {
      userNameRef.current.style.outline = "1px solid green"
      return true
    }
    else {
      userNameRef.current.style.outline = "1px solid red"
      return false
    }
  }

  // LOGGING IN THE USER BY TAKING USER EMAIL AND PASSWORD. 
  const LOGGIN_IN = (email, password) => {
    setLoading(true)
    signInWithEmailAndPassword(FirebaseAuthVariable, email, password)
      .then(() => {
        dispatch(showTicker({
          message: 'Login successful',
          type: 'success'
        }))
        navigate(DrawerRoutes.Dashboard, { replace: true })
        setLoading(false)
      })
      .catch((error) => {
        // console.log(error);
        if (error.code === "auth/invalid-credential") {
          dispatch(showTicker({
            message: 'Invalid credentials',
            type: 'error'
          }))
        }
        setLoading(false)
      })
  }

  return (
    <>
      <div className="Grid1">
        {
          UID === null ? <div className="Login" id="login">
            {/* <img className="Logologin" src={VysionLogo_Login} />
            <p className="Message">Welcome to the STP monitoring dashboard
              designed and developed by Vysion Technology Pvt. Ltd.</p> */}
            <br />
            <Stack width="89%">
              <Typography variant='h6'>Login</Typography>
              <Typography variant='caption'>Enter your registered email and password</Typography>
            </Stack>
            <input className="UserName"
              onChange={checkUsername}
              ref={userNameRef}
              placeholder="Industry E-mail address" />
            <input className="UserName"
              ref={passwordRef}
              placeholder='Industry password'
              type='password' />

            {/* forgot password link */}
            <p className="Message" style={{ marginTop: "7px", textAlign: "right", textDecoration: "underline 1px solid lightgrey", cursor: "pointer" }} onClick={() => { navigate(AuthenticationRoutes.ForgotPassword) }}>Forgot Password?</p>

            <ProceedButton targetFunction={VALIDATE_FEILDS} title={"Login"} loading={Loading} />

            <div className="SignupRoute">Not added your Industry yet?&nbsp;<a variant="text" className="link" onClick={() => navigate(AuthenticationRoutes.IndustryRegistration)}>Add now</a></div>
          </div> : <Already_Loggedin_User />
        }
      </div>
    </>
  );
}
export default IndustryLogin;