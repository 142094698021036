import { combineReducers, configureStore } from "@reduxjs/toolkit";
import drawerReducer from './Slices/drawerSlice.js'
import navReducer from './Slices/NavSlice.js'
import userReducer from './Slices/userSlice.js'
import InventorySlice from "./Slices/InventorySlices/InventorySlice.js";
import TickerSlice from "./Slices/TickerSlice.js";
import GetUsersSlice from "./Slices/MyTeamSlices/GetUsersSlice.js";
import GetIndustriesSlice from "./Slices/Industry/GetIndustriesSlice.js";
import MasterCopySlice from "./Slices/ReportsSlices/BillsAndPaymentSlices/MasterCopySlice.js";
import BillGenerationSlice from "./Slices/ReportsSlices/BillsAndPaymentSlices/BillGenerationSlice.js";
import PlantDetailsSlice from "./Slices/PlantDetailsSlice.js";
import IndustryBillsSlice from "./Slices/ReportsSlices/BillsAndPaymentSlices/IndustryBillsSlice.js";
import ProcessManagementSlice from "./Slices/ProcessManagementSlices/ProcessManagementSlice.js";
import SensorSlice from "./Slices/ProcessManagementSlices/SensorSlice.js";
import SendNoticeSlice from "./Slices/Industry/SendNoticeSlice.js";
import PaymentsSlice from "./Slices/ReportsSlices/BillsAndPaymentSlices/PaymentsSlice.js";
import IndustryRegistrationSlice from "./Slices/Industry/IndustryRegistrationSlice.js";
import ChamberManagementSlice from './Slices/ProcessManagementSlices/ChamberManagementSlice.js'
import GetInventoryReportsSlice from "./Slices/ReportsSlices/InventoryReportsSlices/GetInventoryReportsSlice.js";
import DashboardDataSlice from "./Slices/DashboardSlices/DashboardDataSlice.js";

const rootReducer = combineReducers({
    ACTIVE_SECTION: drawerReducer,
    ACTIVE_INDUSTRY: navReducer,
    USER_DETAILS: userReducer,
    PLANT_DETAILS: PlantDetailsSlice,
    DASHBOARD_DATA: DashboardDataSlice,
    INVENTORY_ITEMS: InventorySlice,
    NOTICES: SendNoticeSlice,
    TICKER_DATA: TickerSlice,
    TEAM_MANAGEMENT: GetUsersSlice,
    INDUSTRIES: GetIndustriesSlice,
    BILLS: IndustryBillsSlice,
    BILL_MASTER_COPIES: MasterCopySlice,
    BILL_GENERATION: BillGenerationSlice,
    PROCESS_MANAGEMENT: ProcessManagementSlice,
    CHAMBER_MANAGEMENT: ChamberManagementSlice,
    SENSORS: SensorSlice,
    PAYMENT_APPROVAL_REQUESTS: PaymentsSlice,
    BULK_INDUSTRY_UPLOAD: IndustryRegistrationSlice,
    INVENTORY_REPORTS: GetInventoryReportsSlice
})


// Reset root reducer state to initial state on logout
const resettableReducer = (state, action) => {
    if (action.type === 'user/logout') {
        state = undefined; // Reset the state to its initial values
    }
    return rootReducer(state, action);
};

export const store = configureStore({
    reducer: resettableReducer,
    devTools: false // comment this on development version and un comment it on production.
})  