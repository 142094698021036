import React, { useEffect } from 'react'
import { status } from '../../Redux/Slices/userSlice';
import { CircularLoader, SideDrawer, SomethingWentWrong } from '../UI Components/Basic';
import { DataGrid } from '@mui/x-data-grid';
import { convertGMTtoISTAndFormat } from '../ReportsComponents/BillsAndPayments/DedicatedIndustryBill';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Box, Button, Chip, Divider, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Check, Clear, Download, Payment, Send, Visibility } from '@mui/icons-material';
import { downloadBill, setDownloadBillID } from '../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/BillGenerationSlice';
import { useNavigate } from 'react-router-dom';
import { DrawerRoutes } from '../Routes/routes';
import { ApprovePayment, GetBillReciptApprovalRequests, RejectPayment } from '../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/PaymentsSlice';
import { GetIndName } from '../../Redux/Slices/Industry/GetIndustriesSlice';
import { NOTICE_PERMISSIONS } from '../../Roles/Roles';
import { approveBill } from '../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/IndustryBillsSlice';

export default function RenderPaymentApprovalRequests() {

    const dispatch = useDispatch()
    const reqLoading = useSelector(state => state.PAYMENT_APPROVAL_REQUESTS.Loading)
    const Requests = useSelector(state => state.PAYMENT_APPROVAL_REQUESTS.Requests)
    const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)// logged in user auth token
    const User = useSelector(state => state.USER_DETAILS)


    useEffect(() => {
        dispatch(GetBillReciptApprovalRequests({ AuthToken: AuthToken, PlantID: User.PlantID }))
    }, [])


    const [Loading, setLoading] = useState(false)
    const [RequestDetails, setRequestDetails] = useState({
        isRequestOpen: false,
        RequestData: []
    })

    // function  for handling the clicking on a notice. it will set the clicked notice data into the side drawer state to make it render in the side drawer.
    const handleRowClick = (params) => {
        // handleOpenNotice(params.row.creationDate.noticeID)
        // console.log(params.row);
        setRequestDetails({
            isRequestOpen: true,
            RequestData: params.row,
        })
    };


    // console.log(RequestDetails);
    // function for rendering the all details of a notice in a side drawer
    function RenderRequestDetails() {
        return <SideDrawer Open={RequestDetails.isRequestOpen} handle={() => { setRequestDetails({ isRequestOpen: false, RequestData: [] }) }}>
            <RequestDetailsLayout
                billData={RequestDetails.RequestData?.data}
                billID={RequestDetails.RequestData?.id}
                industryID={RequestDetails.RequestData?.industryID}
                setRequestDetails={setRequestDetails} // for closing the side drawer after processing request
                IndustryName={industryNames[RequestDetails.RequestData?.industryID]}
            />
        </SideDrawer>
    }


    //columns for rendering the table of the requestss 
    const RequestColumns = [
        { field: "industry", headerName: "Industry", width: 300 },
        { field: "date", headerName: "Bill Date", width: 130 },
        { field: "quotationNo", headerName: "Quotation", width: 150 },
        { field: "amount", headerName: "Amount (₹)", width: 120 },
        { field: "reqDate", headerName: "Request Date", width: 120 },
    ];


    // function for rendering the requests of the payment approval requests
    const renderRequests = () => {
        // Check if bills for the current industry are being loaded
        if (reqLoading === status.LOADING) {
            return <CircularLoader />; // Show loader while loading
        } else if (reqLoading === status.ERROR) {
            return <SomethingWentWrong />; // Show error message if loading failed
        } else {
            // Render bills if they exist
            return (
                <div>
                    {
                        !!Requests?.requests ?
                            <DataGrid
                                disableRowSelectionOnClick
                                onCellClick={handleRowClick}
                                sx={{ paddingLeft: '10px' }}
                                rows={Requests?.requests?.map((bill, i) => {
                                    return (
                                        {
                                            id: bill.id,
                                            industryID: bill.industryid,
                                            industry: !Loading ? industryNames[bill.industryid] : "Loading...",
                                            date: convertGMTtoISTAndFormat(bill.data.dateCreated),
                                            quotationNo: bill.data.quotationNo,
                                            amount: `₹ ${bill.data.amount.toFixed(2)}`,
                                            reqDate: convertGMTtoISTAndFormat(bill?.dateAdded),
                                            data: bill.data
                                        }
                                    )
                                })}
                                columns={RequestColumns}
                                pageSize={2}
                            />
                            : <SomethingWentWrong />
                    }
                </div>
            );

        }
    };


    // fetching names of the industry seperately as calling it inside map function will cause unlimited fetches. 
    const [industryNames, setIndustryNames] = useState({});
    // console.log(industryNames);
    useEffect(() => {
        fetchIndustryNames();
    }, [reqLoading, Requests]);

    // console.log(industryNames);

    const fetchIndustryNames = async () => {
        let names = {};
        if (Requests?.requests === undefined) { return }
        for (const request of Requests?.requests) {
            // console.log(request.industryid);
            const data = await dispatch(GetIndName({
                AuthToken: AuthToken,
                IndustryID: request.industryid,
                setLoading: setLoading,
                PlantID: User?.PlantID
            }))
            names = { ...names, [request.industryid]: data?.payload?.industry?.data?.name }
            // console.log(data);
        }
        setIndustryNames(names)
    };


    return (
        <div>
            {renderRequests()}
            {RenderRequestDetails()}
        </div>
    )
}






// function for details in the sidedrawer. 
export function RequestDetailsLayout(props) {
    // const [MoreMenu, setMoreMenu] = useState(false)
    const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)
    const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const DownloadBillID = useSelector(state => state.BILL_GENERATION.downloadBillID)
    const [ApproveLoading, setApproveLoading] = useState(false)
    const [RejectLoading, setRejectLoading] = useState(false)
    const NoticePermissions = NOTICE_PERMISSIONS()


    // function for handing the payments
    const downloadHandler = (billid) => {
        dispatch(setDownloadBillID(billid))
        dispatch(downloadBill({ industryid: props.industryID, industryName: props.IndustryName, billid: billid, AuthToken: AuthToken }))
    }

    // function for checking the bill is overdue or not?
    function calculateAmountWithInterest(amount, interestRate, lastDate) {
        // Parse the last date string into a Date object
        const [day, month, year] = lastDate.split('/');
        const lastDateObj = new Date(`${year}-${month}-${day}`);
        // Get today's date
        const today = new Date();
        // Set time to midnight for comparison
        today.setHours(0, 0, 0, 0);
        // Check if the bill is overdue
        const isOverdue = lastDateObj < today;
        // If the bill is overdue, calculate the new amount with interest
        let newAmount = amount;
        let interestAmount = 0;
        if (isOverdue) {
            // Calculate interest amount
            interestAmount = amount * (interestRate / 100);
            // Calculate new amount
            newAmount = amount + interestAmount;
        }
        // Return the new amount and whether the bill is overdue
        if (props.billData?.isPaid) {
            return { isOverdue: false, interestAmount, newAmount };
        }
        else {
            return { isOverdue, interestAmount, newAmount };
        }
    }

    const isOverdue = calculateAmountWithInterest(props.billData?.amount, props.billData?.requiredFields?.interestRate, convertGMTtoISTAndFormat(props.billData?.requiredFields?.lastDate))


    return <Stack width={800} sx={{ position: 'relative' }}>
        {/* {props.billID} */}
        <Stack p={2} direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction='column'>
                <Typography variant='h6'>{props.billData?.description}</Typography>
                <Typography variant='body2'>Bill Date - {convertGMTtoISTAndFormat(props.billData?.dateCreated)}</Typography>
            </Stack>
            <Chip label={`Quotation No - ${props.billData?.quotationNo}`} color='PrimaryBlue' variant='outlined' />
        </Stack>

        <Divider />

        <Stack p={2} direction="row" justifyContent="space-between" position="relative">
            <Stack direction="row" spacing={1}>

                <LoadingButton variant="contained" color="success" disableElevation startIcon={<Check />} loading={ApproveLoading} onClick={() => {
                    dispatch(ApprovePayment({
                        billID: props.billID,
                        industryID: props.industryID,
                        paymentReceiptLink: props.billData?.paymentRecieptLink,
                        AuthToken: AuthToken,
                        setLoading: setApproveLoading,
                        setRequestDetails: props.setRequestDetails,
                        PlantID: PlantID
                    }))
                }}>
                    Accept
                </LoadingButton>
                <LoadingButton variant="contained" color="PrimaryRed" disableElevation startIcon={<Clear />} loading={RejectLoading} onClick={() => {
                    dispatch(RejectPayment({
                        billID: props.billID,
                        AuthToken: AuthToken,
                        setLoading: setRejectLoading,
                        setRequestDetails: props.setRequestDetails,
                        PlantID: PlantID
                    }))
                }}>
                    Reject
                </LoadingButton>

                <Button variant='outlined' color='PrimaryBlue' onClick={() => { window.open(props.billData?.paymentRecieptLink, '_blank') }} disableElevation startIcon={<Visibility sx={{ width: '16px' }} />}>View recipt</Button>
                <LoadingButton loading={DownloadBillID === props.billID} loadingPosition="start" variant='outlined' color='PrimaryBlue' onClick={() => { downloadHandler(props.billID) }} disableElevation startIcon={<Download sx={{ width: '16px' }} />}>Download Bill</LoadingButton>

                {NoticePermissions.isAllowedToSendNotices ? <Button variant='outlined' color='PrimaryBlue' onClick={() => { navigate(DrawerRoutes.SendNotice, { state: { noticeTo: props.industryID } }) }} disableElevation startIcon={<Send sx={{ width: '16px' }} />}>Send Notice</Button>
                    : ''}
            </Stack>
        </Stack>

        {/* <Divider /> */}

        {/* Goods table, All the added goods will list here.  */}
        <Box p={2} zIndex={0} position="relative">
            <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ paddingLeft: '20px', fontSize: '12px' }}>Services</TableCell>
                            <TableCell sx={{ fontSize: '12px' }}>Good Type</TableCell>
                            <TableCell sx={{ fontSize: '12px' }}>Consumption</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody sx={{ position: "relative" }}>
                        {
                            props.billData?.goods !== undefined && props.billData?.goods !== null ?
                                props.billData?.goods.length !== 0 ?
                                    props.billData?.goods.map((good, index) => {
                                        return <TableRow key={index}>
                                            <TableCell sx={{ paddingLeft: '20px', fontSize: '12px' }}>{good.starting}-{good.ending}</TableCell>
                                            <TableCell sx={{ fontSize: '12px' }}>{!!good?.type ? good?.type : 'Not Available'}</TableCell>
                                            <TableCell sx={{ fontSize: '12px' }}>{!!good?.qty ? good?.qty : 'Not Available'} meter cube</TableCell>
                                        </TableRow>
                                    })
                                    : ""
                                : ''
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

        <Stack direction="row" spacing={1} sx={{ position: 'fixed', bottom: '30px', right: '30px', zIndex: 1 }}>
            <Box component="section" sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', backgroundColor: 'var(--Tertiary_Blue)', position: "relative", width: 'fit-content' }}>
                <Typography variant='body2'>Bill Amount</Typography>
                <Typography variant='h6'>₹ {props.billData?.amount?.toFixed(2)}<span style={{ color: "red" }}>{isOverdue.isOverdue ? ` + ₹ ${isOverdue?.interestAmount?.toFixed(2)}` : ''}</span></Typography>
                {isOverdue.isOverdue ? <Typography variant='h6'> = ₹ {isOverdue?.newAmount?.toFixed(2)}</Typography> : ''}
            </Box>
            <Box component="section" sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', backgroundColor: 'var(--Tertiary_Blue)', position: "relative", width: 'fit-content' }}>
                <Typography variant='body2'>Interest Rate</Typography>
                <Typography variant='h6'>{props.billData?.requiredFields?.interestRate}%</Typography>
            </Box>
            <Box component="section" sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', backgroundColor: isOverdue.isOverdue ? "#ff000014" : 'var(--Tertiary_Blue)', position: "relative", width: 'fit-content' }}>
                {isOverdue.isOverdue ? <Typography variant='caption' color="red">Bill is Overdue</Typography> : ''}
                <Typography variant='body2'>Last Date</Typography>
                <Typography variant='h6'>{convertGMTtoISTAndFormat(props.billData?.requiredFields?.lastDate)}</Typography>
            </Box>
        </Stack>
    </Stack>
}