import { AddCircleOutline, ArrowBack, Factory, Upload } from '@mui/icons-material'
import { Badge, Button, Divider, IconButton, Radio, Stack } from '@mui/material'
import React, { useState } from 'react'
import { Dropdown, InputRow } from '../../UserAuthentication/IndustryRegistrationForm'
import { EmailValidation, EmptyFeildValidation, PhaseValidation, PhoneNumberValidation, PinCodeValidation } from '../../UserAuthentication/AuthFunctions'
import { AddIndustryPlantSide, SingleIndustryRegistration } from '../../Redux/Slices/Industry/IndustryRegistrationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import BulkIndustryUpload from './BulkIndustryUpload'
import { useNavigate } from 'react-router-dom'
import { DrawerRoutes } from '../Routes/routes'

export default function AddIndustry() {
  const [ChamberInstall, setChamberInstall] = useState(true) // State for saving chamber information, (boolean
  const navigate = useNavigate()
  const [Loading, setLoading] = useState(false)
  const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken) // token important for api call
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const dispatch = useDispatch();
  const [SideDrawerOpen, setSideDrawerOpen] = useState(false)
  const [formed, setFormed] = useState({
    companyName: '',
    address: '',
    pinCode: '',
    email: '',
    phone: '',
    plantType: '',
    icChamberInstall: ChamberInstall,
    consentValidity: '',
    domesticEffluent: '',
    hnType: '',
    apiID: '',
    phase: null,
    plantID: PlantID,
    pNumber: '',
    totalEffluentTradeAndUtility: '',
    unitId: '',
    remark: ''
  });


  const FormValidation = () => {
    if ( // Feild validations
      EmptyFeildValidation(formed.companyName, 'Company Name', dispatch) &&
      EmptyFeildValidation(formed.address, 'Address', dispatch) &&
      PinCodeValidation(formed.pinCode, dispatch) &&
      EmailValidation(formed.email, dispatch) &&
      PhoneNumberValidation(formed.phone, dispatch)
    ) {
      const isFromPlant = true// creating a variable for letting that function know that this request is coming from plant side or industry self registration time. 
      // Fetching registration api 
      dispatch(AddIndustryPlantSide({
        IndustryDetails: formed,
        AuthToken: AuthToken,
        PlantID: PlantID,
        setLoading: setLoading
      }))
    }
  }

  return (
    <div>
      {/* this is a universal page header and will be included in every page. */}
      <div className="PAGE_HEADER">
        {/* Name of the page, will show in lwft of the header */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /> </IconButton>
          <h3>Add an Industry</h3>
        </Stack>
        <div>
          <Badge badgeContent={0} color='PrimaryRed' anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <Button color="SecondaryBtn" variant="contained" onClick={() => { navigate(DrawerRoutes.Industry.Industry_requests) }} size='medium' startIcon={<Factory sx={{ width: '18px' }} />} disableElevation>Industry Requests</Button>
          </Badge>
          &nbsp;&nbsp;
          <Button color="SecondaryBtn" variant="contained" onClick={() => { setSideDrawerOpen(true) }} size='medium' startIcon={<Upload />} disableElevation> Bulk Upload </Button>
        </div>
      </div>

      {/* this div will contain all the content of the page */}
      <div className="Industries_Body_Container">
        <div className="TOOLS_WRAPPER" style={{ textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p className="simpleParagraph">Please enter all the details of Industry you want to add here. Make sure ...</p>
          <LoadingButton
            color="PrimaryBlue"
            variant="contained"
            loading={Loading}
            onClick={FormValidation}
            size='medium'
            startIcon={<AddCircleOutline />}
            disableElevation
          > Add this Industry </LoadingButton>
        </div>

        <div style={{ width: '100%', padding: '0px 30px' }}>
          <InputRow Label={"Company Name"} type={"text"} placeHolder={"Company Name"} action={(e) => setFormed({ ...formed, companyName: e.target.value })} />
          <InputRow Label={"Address"} type={"text"} placeHolder={"Address"} action={(e) => setFormed({ ...formed, address: e.target.value })} />
          <InputRow Label={"Pin Code"} type={"text"} placeHolder={"Pin Code"} action={(e) => setFormed({ ...formed, pinCode: e.target.value })} />
          <InputRow Label={"E-mail"} type={"text"} placeHolder={"E-mail address"} action={(e) => setFormed({ ...formed, email: e.target.value })} />
          <InputRow Label={"Phone Number"} type={"number"} placeHolder={"Phone Number"} action={(e) => setFormed({ ...formed, phone: e.target.value })} />
          <br />
          <Divider />
          <br />
          <InputRow Label={"Plant type"} type={"text"} placeHolder={"CETP/ETP/STP"} action={(e) => setFormed({ ...formed, plantType: e.target.value })} />
          <div className="Row">
            <div className="Label">{`IC Chamber Installed?`}</div>
            <div style={{ display: "flex", alignItems: "center", width: "320px", justifyContent: "space-between" }}>
              <Radio checked={ChamberInstall === true} onClick={() => { setChamberInstall(true) }} />
              <div className="Label">{`Installed`}</div>
              <Radio checked={ChamberInstall === false} onClick={() => { setChamberInstall(false) }} />
              <div className="Label">{`Not Installed`}</div>
            </div>
          </div>
          <InputRow Label={"Consent Validity"} type={"text"} placeHolder={"Consent Validity"} action={(e) => setFormed({ ...formed, consentValidity: e.target.value })} />
          <InputRow Label={"Domestic Effluent"} type={"text"} placeHolder={"Domestic Effluent"} action={(e) => setFormed({ ...formed, domesticEffluent: e.target.value })} />
          <InputRow Label={"H/N Type"} type={"text"} placeHolder={"H/N Type"} action={(e) => setFormed({ ...formed, hnType: e.target.value })} />
          <InputRow Label={"Phase"} type={"number"} placeHolder={"Phase"} action={(e) => setFormed({ ...formed, phase: +e.target.value })} />
          {/* <div className="Row">
            <div className="Label">{"Select Plant"}</div>
            <Dropdown formed={formed} setFormed={setFormed} />
          </div> */}
          <InputRow Label={"P. No."} type={"text"} placeHolder={"P. No."} action={(e) => setFormed({ ...formed, pNumber: e.target.value })} />
          <InputRow Label={"Api ID"} type={"text"} placeHolder={"Api ID"} action={(e) => setFormed({ ...formed, apiID: e.target.value })} />
          <InputRow Label={"Total Effluent Trade and Utility"} type={"text"} placeHolder={"Total Effluent Trade and Utility"} action={(e) => setFormed({ ...formed, totalEffluentTradeAndUtility: e.target.value })} />
          <InputRow Label={"Unit ID"} type={"text"} placeHolder={"Unit ID"} action={(e) => setFormed({ ...formed, unitId: e.target.value })} />
          <InputRow Label={"Remark"} type={"text"} placeHolder={"Remark (Optional)"} action={(e) => setFormed({ ...formed, remark: e.target.value })} />
          <br />
          <br />
          <br />
        </div>
      </div>

      {/* seperate pge for bulk industry upload */}
      <BulkIndustryUpload Open={SideDrawerOpen} handle={setSideDrawerOpen} />
    </div>
  )
}
