// this file is a dedicated component or page which renders/show all previous bills and Payments of a industry passed in this function 
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { GetBillsOf } from '../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/IndustryBillsSlice'
import { Button, Chip, IconButton, Stack } from '@mui/material'
import { ArrowBack, Download, Receipt, Send } from '@mui/icons-material'
import { DataGrid } from '@mui/x-data-grid'
import { status } from '../../../Redux/Slices/userSlice'
import { CircularLoader, SideDrawer, SomethingWentWrong, UniversalLoader } from '../../UI Components/Basic'
import LoadingButton from '@mui/lab/LoadingButton';
import { downloadBill, setDownloadBillID } from '../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/BillGenerationSlice'
import { DrawerRoutes } from '../../Routes/routes'
import { GetIndName } from '../../../Redux/Slices/Industry/GetIndustriesSlice'
import { NOTICE_PERMISSIONS } from '../../../Roles/Roles'
import { BillSideDetailsPlantSide } from './BillSideDetailsPlantSide'


export default function DedicatedIndustryBill() {
    const { industryID } = useParams()
    const dispatch = useDispatch()
    const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)
    const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
    const industries = useSelector(state => state.BILLS.industries)
    const Loading = useSelector(state => state.BILLS.loading)
    const navigate = useNavigate()
    const DownloadBillID = useSelector(state => state.BILL_GENERATION.downloadBillID)
    const UserDetails = useSelector(state => state.USER_DETAILS.UserDetails)
    const UserRole = useSelector(state => state.USER_DETAILS.UserRole)
    const accessLevel = useSelector(state => state.USER_DETAILS.UserAccessLevel)
    const departments = useSelector(state => state.USER_DETAILS.UserDetails?.departmentAccess)
    const NoticePermissions = NOTICE_PERMISSIONS(UserRole, accessLevel, departments)
    const [IndustryName, setIndustryName] = useState("")
    const [loadingName, setLoadingName] = useState(false)


    // function for getting the industry name from the industry id. because we need the industry name in the downlaod bill. 
    async function GetIndustryName() {
        await dispatch(GetIndName({
            AuthToken: AuthToken,
            IndustryID: industryID,
            setLoading: setLoadingName,
            PlantID: PlantID
        })).then((data) => { setIndustryName(data?.payload?.industry?.data?.name) })
    }

    useEffect(() => { if (IndustryName === "") { GetIndustryName() } }, []) // fetch the name of the Industry

    // function  for handling the downloading of the bill 
    const downloadHandler = (billid) => {
        if (!IndustryName) {
            // If IndustryName is not defined, fetch it first and then initiate the download
            setLoadingName(true); // Set loading state while fetching the industry name
            dispatch(GetIndName({
                AuthToken: AuthToken,
                IndustryID: industryID,
                setLoading: setLoadingName,
                PlantID: PlantID
            })).then((data) => {
                // Once the industry name is fetched, initiate the download
                setIndustryName(data?.payload?.industry?.data?.name)
                dispatch(setDownloadBillID(billid));
                dispatch(downloadBill({ industryid: industryID, industryName: data?.payload?.industry?.data?.name, billid: billid, AuthToken: AuthToken, PlantID: PlantID }));
            }).catch((error) => {
                // Handle error if fetching industry name fails
                console.error("Error fetching industry name:", error);
            });
        } else {
            // If IndustryName is already defined, initiate the download directly
            dispatch(setDownloadBillID(billid));
            dispatch(downloadBill({ industryid: industryID, industryName: IndustryName, billid: billid, AuthToken: AuthToken, PlantID: PlantID }));
        }
    }


    // coloums of the table
    const columns = [
        { field: "id", headerName: "S No.", type: "number", width: 70, sortable: false },
        { field: "billDesc", headerName: "Bill", width: 200, sortable: false },
        { field: "date", headerName: "Date (dd/mm/yyyy)", width: 170, sortable: false },
        { field: "quotationNo", headerName: "Q. No.", width: 140, sortable: false },
        { field: "amount", headerName: "Amount (₹)", width: 100, sortable: false },
        {
            field: "status", headerName: "Status", width: 100,
            // checking value and based on that value, rendering the chip of paid and not paid
            renderCell: (params) => (
                params.value ? <Chip label="Paid" size='small' color='success' sx={{ fontSize: '12px' }} />
                    : <Chip label="Not Paid" size='small' color='PrimaryRed' sx={{ fontSize: '12px' }} />
            )
        },
        {
            field: "actions", headerName: "Actions", sortable: false, width: 400,
            // rendering action buttons
            renderCell: (params) => (
                <Stack direction="row" spacing={1}>
                    <LoadingButton
                        loading={DownloadBillID === params.row.actions.id}
                        loadingPosition="start"
                        variant='contained'
                        sx={{ height: '32px' }}
                        startIcon={<Download sx={{ width: '16px' }} />}
                        disableElevation
                        onClick={() => { downloadHandler(params.row.actions.id) }}
                    >Download Bill</LoadingButton>
                </Stack>
            )
        },
    ];

    // function  for handling the clicking on a bill. it will set the clicked bill data into the side drawer state to make it render in the side drawer.
    const handleCellClick = (params) => {
        // console.log(params);
        if (params.field === "billDesc" || params.field === "date" || params.field === 'quotationNo' || params.field === "amount") {
            setBillDetails({ // Setting the bills details in to the side drawer state for showing it into the sidedrawer
                isSideDrawerOpen: true,
                activeBillId: params.row.actions.id,
                activeBillData: params.row.actions.data
            })
        }
    }


    // fetching the bills of the industry via industry id. Checking also if the bills are already fetched, then do not fetch again. 
    useEffect(() => {
        // Check if bills for the current industry are already fetched

        // If not fetched, dispatch an action to fetch bills for this industry
        if (!(industryID in industries)) {
            dispatch(GetBillsOf({
                industryID: industryID,
                AuthToken: AuthToken,
                PlantID: PlantID
            }))
        }
    }, []);

    // Function to convert timestamp string to milliseconds
    function getTimestampInMilliseconds(timestamp) {
        return new Date(timestamp).getTime();
    }

    //function for sorting the bills according to date
    const getSortedBills = (bills) => {
        const newbills = bills?.slice().sort((a, b) => getTimestampInMilliseconds(b.data.dateCreated) - getTimestampInMilliseconds(a.data.dateCreated));
        return newbills
    }



    // Define a function to render bills or a message based on the loading and error state
    const renderBills = () => {
        // Check if bills for the current industry are being loaded
        if (Loading === status.LOADING) {
            return <CircularLoader />; // Show loader while loading
        } else if (Loading === status.ERROR) {
            return <SomethingWentWrong />; // Show error message if loading failed
        } else {
            // Render bills if they exist
            return (
                <div>

                    {
                        industries[industryID]?.bills !== undefined ?
                            industries[industryID]?.bills?.length !== 0 ?
                                <DataGrid
                                    sx={{ height: 'calc(100vh - 130px)' }}
                                    onCellClick={handleCellClick}
                                    rowSelection={false}
                                    rows={getSortedBills(industries[industryID]?.bills).map((bill, i) => {
                                        return (
                                            {
                                                id: i + 1,
                                                billDesc: bill.data.description,
                                                date: convertGMTtoISTAndFormat(bill.data.dateCreated),
                                                quotationNo: bill.data.quotationNo,
                                                amount: bill.data.amount.toFixed(2),
                                                status: bill.data.isPaid,
                                                actions: bill
                                            }
                                        )
                                    })}
                                    columns={columns}
                                    pageSize={2}
                                /> : <p className="simpleParagraph absCenter">Bills not found</p>
                            : <SomethingWentWrong />
                    }

                </div>
            );

        }
    };


    // function for showing the all bill infromation in the side drawer
    const [BillDetails, setBillDetails] = useState({
        isSideDrawerOpen: false,
        activeBillId: '',
        activeBillData: []
    })
    function BillDetailsDrawer() {
        return <SideDrawer Open={BillDetails.isSideDrawerOpen} handle={setBillDetails}>
            <BillSideDetailsPlantSide
                billID={BillDetails.activeBillId}
                industryID={industryID} // important for deleting the bill
                billData={BillDetails.activeBillData}
                IndustryName={IndustryName}
                setBillDetails={setBillDetails} // passing here because we need to re pass it in the delete bill slice for closing the drawer after deleting the bill 
            />
        </SideDrawer>
    }

    // from the functional Component, returning the main jsx where all the bills of the dedicated industry will render
    return (
        <div>
            {loadingName ? <UniversalLoader /> : ''}
            <div className="PAGE_HEADER">
                {/* <button onClick={GetIndustryName}>getname</button> */}
                {/* Name of the page, will show in lwft of the header */}
                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton onClick={() => { navigate(DrawerRoutes.Reports.Financial_Reports) }}> <ArrowBack fontSize='small' /> </IconButton>
                    <h3>Industry Bills {IndustryName !== undefined ? `/ ${IndustryName.slice(0, 20)}` : ''}</h3>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button variant='contained' startIcon={<Receipt />} onClick={() => { navigate(`/reports/generate-bill/${industryID}`) }} disableElevation>Generate Bill</Button>
                    {
                        NoticePermissions.isAllowedToSendNotices ? <Button
                            variant='contained'
                            startIcon={<Send fontSize='small' />}
                            onClick={() => { navigate(DrawerRoutes.SendNotice, { state: { noticeTo: industryID } }) }}
                            disableElevation
                        >Send Notice</Button> : ''
                    }
                </Stack>
            </div>


            {/* table of the bills */}
            <div className="Industries_Body_Container">
                {/* rendering the bills */}
                {renderBills()}
                <br />
                <br />
            </div>

            {/* side drawer for rendering the complete details of the bills */}
            {BillDetailsDrawer()}
        </div>
    )
}



// function for convert and format the date into dd/MM/yyyy
export function convertGMTtoISTAndFormat(dateString) {
    // this function check if the format is of mm/dd/yyyy format then return it in format of dd/mm/yyyy and if it is in timestamp format then return inthe same dd/mm/yyyy format
    // Check if the date string is in mm/dd/yyyy format tehn convert it to dd/mm/yyyy format
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
        const [month, day, year] = dateString.split('/');
        const lastDateObj = `${day}/${month}/${year}`
        return lastDateObj;
    }

    // Convert GMT to IST
    const dateGMT = new Date(dateString);
    const day = dateGMT.getDate()
    const month = dateGMT.getMonth() + 1
    const year = dateGMT.getFullYear();
    return `${day}/${month}/${year}`;
}