import React from "react";
import { useLocation } from "react-router-dom";
import "./Addressbar.css";

import {
  DashboardOutlined,
  FactoryOutlined,
  NotificationsActiveOutlined,
  AccountTreeOutlined,
  LocalPoliceOutlined,
  InventoryOutlined,
  AssignmentOutlined,
  VideocamOutlined,
  Groups2Outlined,
} from "@mui/icons-material";
import { Stack } from "@mui/material";

const getIconForRoute = (route) => {
  switch (
    route?.toLowerCase()
    // ... (unchanged)
  ) {
  }
};

const capitalizeAfterSlash = (path) => {
  const parts = path.split("/");
  const formattedPath = parts
    .map((part, index) => {
      if (index === 0) {
        return (
          <span key={index} style={{ display: "flex", alignItems: "center" }}>
            {getIconForRoute(parts[0])}
            <span style={{ marginLeft: "4px" }}>{part.replace(/-/g, " ")}</span>
          </span>
        );
      } else if (part.toLowerCase() === "cctv") {
        return ` / ${part.toUpperCase()} `;
      } else if (index > 0 && part.toLowerCase() === "your-page-id") {
        // Exclude specific page ID from breadcrumb
        return "";
      } else {
        const formattedPart = part
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return ` / ${formattedPart} `;
      }
    })
    .reduce((acc, curr) => {
      return React.cloneElement(acc, {}, acc.props.children, curr);
    });

  return formattedPath;
};
const AddressBar = () => {
  const currentRoute = useLocation();
  const routeParts = currentRoute.pathname
    .split("/")
    .filter((part) => part !== "");

  const routeIcon =
    routeParts.length > 0 ? (
      getIconForRoute(routeParts[0])
    ) : (
      <DashboardOutlined sx={{ width: "15px" }} />
    );

  const formattedPathname = capitalizeAfterSlash(currentRoute.pathname);

  return (
    <Stack direction="row" alignContent="center">
      {" "}
      {formattedPathname}
    </Stack>
  );
};

export default AddressBar;
