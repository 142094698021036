import React from "react";
import "./InventoryStyles/UpdateQuantity.css";
import { Add, ArrowBack, Cancel, Remove } from "@mui/icons-material";
import { Button, IconButton, TextField } from "@mui/material";
import { useState } from "react";
import { ErrorTicker } from "../../UserAuthentication/AuthUIComponents";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { showTicker } from "../../Redux/Slices/TickerSlice";
import { GetInventoryConsumables, GetInventoryNonConsumables } from "../../Redux/Slices/InventorySlices/InventorySlice";
import { SERVER_ADDRESS } from "../../Firebase/Variables";

// this function is the wrapper for the update quantity modal. Here content of the modal will change dynamically depending on user selection.
export default function UpdateQuantity(props) {
  // This usestate will hold the state of our this Modal. If the value of the state is 0, it will be on add remove selection state, 1 for addition and 2 for removal.
  const [Selection, setSelection] = useState(0);
  return (
    <div className="Modal_Black_bg">
      <div className="Modal_base">
        <div className="Modal_heading">
          Update Quantity
          <IconButton color="PrimaryBlue" onClick={() => { props.setActiveModal(0) }}>
            <Cancel />
          </IconButton>
        </div>
        <div>
          {Selection === 0 ? <Add_Remove_Choice setSelection={setSelection} />
            : Selection === 1 ? <AddQuantity setSelection={setSelection} item={props.item} setActiveModal={props.setActiveModal} />
              : Selection === 2 ? <DeleteQuantity setSelection={setSelection} item={props.item} setActiveModal={props.setActiveModal} />
                : <Add_Remove_Choice setSelection={setSelection} />
          }
        </div>
      </div>
    </div>
  );
}

// this function holds the choices that user can make for adding or deletion of a item.
function Add_Remove_Choice(props) {
  return (
    <div className="Modal_content">
      <Button
        variant="contained"
        color="PrimaryBlue"
        sx={{ width: "50%", height: '60px' }}
        startIcon={<Add />}
        onClick={() => props.setSelection(1)}
        disableElevation
      >
        Add Quantity
      </Button>
      &nbsp;
      <Button
        variant="contained"
        color="PrimaryBlue"
        sx={{ width: "49%", height: '60px' }}
        startIcon={<Remove />}
        onClick={() => props.setSelection(2)}
        disableElevation
      >
        Remove Quantity
      </Button>
    </div>
  );
}

// this function render when user select add quantity and it will hold all the feature for Quantity addition.
function AddQuantity(props) {

  const [EnteredQuantity, setEnteredQuantity] = useState(0) // state for saing the entered quantity by user
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken) // Token imp for api authorization
  const dispatch = useDispatch() //For dispatching actions for ticker
  const [Loading, setLoading] = useState(false) // For handling loading
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)

  // function for adding the entered quantity in to the database.
  const ADD_QUANTITY = () => {
    // validating the text feild
    if (EnteredQuantity > 0 && EnteredQuantity !== null && EnteredQuantity !== '') {
      setLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${UserToken}`);

      var raw = JSON.stringify({
        "itemid": props.item.itemId, // Getting these details from Consumables.js by prop drilling method
        "itemUnit": props.item.itemUnit,
        "itemQty": +EnteredQuantity
      });

      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      // fetching API
      fetch(`${SERVER_ADDRESS}/inventory/restock?plantID=${PlantID}`, requestOptions)
        .then(response => {
          response.json()//Converting the response in to JSON

          //Checking if the response is 200 or not. If not there is something wrong
          if (response.status === 200) {
            dispatch(showTicker({
              message: 'Quantity Added Successfully',// iF The response if 200, then its ok, item updated in database.
              type: 'success' // Showing success type ticker
            }))
            if (props.item.itemType === 'Consumable') { dispatch(GetInventoryConsumables({ AuthToken: UserToken, PlantID: PlantID })) } // Checking the typw of the item and based on that, we are refetchin the list. 
            else { dispatch(GetInventoryNonConsumables({ AuthToken: UserToken, PlantID: PlantID })) }
          }
          else {
            dispatch(showTicker({
              message: 'Something went wrong',// But if the response is other than 200, there must be sonething wrong
              type: 'error'// Showing error type ticker
            }))
          }
        })
        .then(result => {
          setLoading(false) // Setting loading to false
          props.setActiveModal(0) // Closing the Whole modal after qudating qunatity in database.
        })
        .catch(error => {
          dispatch(showTicker({
            message: error.message,// If api not fetched show this, 
            type: 'error' // Type error ticker
          }))
          setLoading(false)
        });
    }
    else {
      dispatch(showTicker({
        message: 'Please enter a valid quantity',
        type: 'error'
      }))
    }
  }

  return (
    <div className="Modal_content">
      <div className="model_add">
        <div className=" Item_quanity" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          Quantity Available{" "}
          <span
            style={{
              backgroundColor: "#00997A",
              padding: "10px 15px",
              color: "#fff",
              borderRadius: "8px",
              marginLeft: "20px",
              fontWeight: 600,
              fontSize: "14px"
            }}

          >
            {props.item.itemQuantityAvailable} {props.item.itemUnit}
          </span>
        </div>
        <div className="Add_options">
          <TextField
            size="large"
            label="Enter Quanity"
            placeholder="Enter quanity to add"
            fullWidth
            autoFocus
            type="number"
            onChange={(e) => { setEnteredQuantity(e.target.value) }}
          />
        </div>
        <div className="add_btns">
          <Button variant="secondary" onClick={() => props.setSelection(0)} startIcon={<ArrowBack />} disableElevation >Back</Button>
          <LoadingButton loading={Loading ? true : false} variant="contained" disableElevation color="PrimaryBlue" style={{ marginLeft: "10px" }} startIcon={<Add />} onClick={ADD_QUANTITY}>Add</LoadingButton>
        </div>
      </div>
    </div>
  );
}




//______________________________________________________________________________________________________________
// this function render when user select remove quantity and it will hold all the feature for Quantity deletion.
// this function render when user select remove quantity and it will hold all the feature for Quantity deletion.
// this function render when user select remove quantity and it will hold all the feature for Quantity deletion.
// this function render when user select remove quantity and it will hold all the feature for Quantity deletion.
function DeleteQuantity(props) {

  const [EnteredQuantity, setEnteredQuantity] = useState(0) // state for saing the entered quantity by user
  const [UsageLocation, setUsageLocation] = useState("")
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken) // Token imp for api authorization
  const dispatch = useDispatch() //For dispatching actions for ticker
  const [Loading, setLoading] = useState(false) // For handling loading
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)

  // function for adding the entered quantity in to the database.
  const REMOVE_QUANTITY = () => {
    // validating the text feild
    if (EnteredQuantity > 0 && EnteredQuantity !== null && EnteredQuantity !== '' && UsageLocation !== "" && UsageLocation !== null) {
      setLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${UserToken}`);

      var raw = JSON.stringify({
        "itemid": props.item.itemId, // Getting these details from Consumables.js by prop drilling method
        "usageUnit": props.item.itemUnit,
        "usageQty": +EnteredQuantity,
        "usageLocation": UsageLocation
      });

      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      // fetching API
      fetch(`${SERVER_ADDRESS}/inventory/use?plantID=${PlantID}`, requestOptions)
        .then(response => {
          response.json() //Converting the response in to JSON

          //Checking if the response is 200 or not. If not there is something wrong
          if (response.status === 200) {
            dispatch(showTicker({
              message: 'Quantity Removed Successfully', // iF The response if 200, then its ok, item updated in database.
              type: 'success' // Showing success type ticker
            }))
            if (props.item.itemType === 'Consumable') { dispatch(GetInventoryConsumables({ AuthToken: UserToken, PlantID: PlantID })) } // Checking the typw of the item and based on that, we are refetchin the list. 
            else { dispatch(GetInventoryNonConsumables({ AuthToken: UserToken, PlantID: PlantID })) }
          }
          else {
            dispatch(showTicker({
              message: 'Something went wrong', // But if the response is other than 200, there must be sonething wrong
              type: 'error' // Showing error type ticker
            }))
          }
        })
        .then(result => {
          setLoading(false) // Setting loading to false
          props.setActiveModal(0) // Closing the Whole modal after qudating qunatity in database.
        })
        .catch(error => {
          dispatch(showTicker({
            message: error.message, // If api not fetched show this, 
            type: 'error' // Type error ticker
          }))
          setLoading(false)
        });
    }
    else {
      dispatch(showTicker({
        message: 'Please enter all details', //
        type: 'error'
      }))
    }
  }

  return (
    <div className="Modal_content">
      <div className="model_add">
        <div className=" Item_quanity" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          Quantity Available{" "}
          <span
            style={{
              backgroundColor: "#00997A",
              padding: "10px 15px",
              color: "#fff",
              borderRadius: "8px",
              marginLeft: "20px",
              fontWeight: 600,
              fontSize: "14px"
            }}
          >
            {props.item.itemQuantityAvailable} {props.item.itemUnit}
          </span>
        </div>
        <div className="Add_options">
          <TextField
            size="large"
            label="Enter Quanity"
            placeholder="Enter quanity to add"
            fullWidth
            autoFocus
            type="number"
            onChange={(e) => { setEnteredQuantity(e.target.value) }}
          />
          <br /><br />
          <TextField
            size="medium"
            label="Location"
            placeholder="Where this item is using?"
            fullWidth
            autoFocus
            type="text"
            onChange={(e) => { setUsageLocation(e.target.value) }}
          />
        </div>
        <div className="add_btns">
          <Button variant="secondary" onClick={() => props.setSelection(0)} startIcon={<ArrowBack />} disableElevation >Back</Button>
          <LoadingButton loading={Loading ? true : false} variant="contained" disableElevation color="PrimaryBlue" style={{ marginLeft: "10px" }} startIcon={<Remove />} onClick={REMOVE_QUANTITY}>Remove</LoadingButton>
        </div>
      </div>
    </div>
  );
}
