import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetConsumableReports } from '../../../Redux/Slices/ReportsSlices/InventoryReportsSlices/GetInventoryReportsSlice'
import { status } from '../../../Redux/Slices/userSlice'
import { CircularLoader, SomethingWentWrong } from '../../UI Components/Basic'
import { DataGrid } from '@mui/x-data-grid'
import { convertGMTtoISTAndFormat } from '../BillsAndPayments/DedicatedIndustryBill'
import { Chip, ToggleButton, ToggleButtonGroup } from '@mui/material'
import ConsumableReports from './ConsumableReports'
import NonConsumableReports from './NonConsumableReports'

export default function InventoryReports() {
 


    const [activeSection, setactiveSection] = useState(1)

    return (
        <div>
            <div className="PAGE_HEADER">
                {/* Name of the page, will show in lwft of the header */}
                <h3>Inventory Reports</h3>

                <ToggleButtonGroup size="small" color="PrimaryBlue" value={activeSection}>
                    <ToggleButton value={1} onClick={()=>{setactiveSection(1)}}>Consumable</ToggleButton>
                    <ToggleButton value={2} onClick={()=>{setactiveSection(2)}}> Non-Consumable</ToggleButton>
                </ToggleButtonGroup>
            </div>

            {/* this div will contain all the content of the page */}
            <div className="Industries_Body_Container">
                {activeSection === 1 ? <ConsumableReports/> : activeSection === 2?  <NonConsumableReports/> : ''}
            </div>
        </div>
    )
}
