import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showTicker } from "../TickerSlice";
import { status } from "../userSlice";
import { SERVER_ADDRESS } from "../../../Firebase/Variables";

export const GetFilters = createAsyncThunk('GetFilters', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const { category, UserToken, PlantID } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        if (category === undefined || category === "") {
            // Return a Promise that never resolves
            return new Promise(() => { });
        }
        const response = await fetch(`${SERVER_ADDRESS}/plant/instrument/filters?category=${category}&plantID=${PlantID}`, requestOptions)

        if (response.ok) {
            const data = await response.json()
            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }
    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

export const GetCategories = createAsyncThunk('GetCategories', async ({ AuthToken, PlantID }, { dispatch, rejectWithValue, fulfillWithValue }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${SERVER_ADDRESS}/plant/instrument?plantID=${PlantID}`, requestOptions)

        if (response.ok) {
            const data = await response.json()
            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }
    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

export const GetFilteredInstruments = createAsyncThunk('GetFilteredInstruments', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const { queries, category, UserToken, PlantID } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    if (category === undefined || category === "") {
        // Return a Promise that never resolves
        return new Promise(() => { });
    }
    let query
    if (queries) {
        Object.keys(queries).forEach(key => {
            let temp

            if (queries[key].length > 0) {
                temp = queries[key].map(element => `${key}=${element}`).join("&");
            }

            if (query) {
                query = `${query}&${temp}`
            } else {
                query = temp
            }
        })
    }

    try {
        let response
        if (!query) {
            response = await fetch(`${SERVER_ADDRESS}/plant/instrument/?category=${category}&plantID=${PlantID}`, requestOptions)
        } else {
            response = await fetch(`${SERVER_ADDRESS}/plant/instrument/?category=${category}&${query}&plantID=${PlantID}`, requestOptions)
        }

        if (response.ok) {
            const data = await response.json()
            return fulfillWithValue(data)
        } else {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.statusText)
        }
    } catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})

const processManagementSlice = createSlice({
    name: 'process_management',
    initialState: {
        Filters: {
            data: []
        },
        Categories: {
            data: []
        },
        FilteredInstruments: {
            count: 0,
            data: []
        },
        SelectedCategory: "",
        Loading: status.IDLE
    },
    reducers: {
        setCategory: (state, action) => {
            state.SelectedCategory = action.payload
        }
    },
    extraReducers: {
        // Extra reducers for handling fetching the filters of instruments
        [GetFilters.pending]: (state, action) => {
            state.Loading = status.LOADING
        },
        [GetFilters.fulfilled]: (state, action) => {
            state.Filters = action.payload
            state.Loading = status.IDLE
        },
        [GetFilters.rejected]: (state, action) => {
            state.Loading = status.ERROR
        },

        // Extra reducers for handling fetching the categories of instruments
        [GetCategories.pending]: (state, action) => {
            state.Loading = status.LOADING
        },
        [GetCategories.fulfilled]: (state, action) => {
            state.Categories = action.payload
            state.Loading = status.IDLE
        },
        [GetCategories.rejected]: (state, action) => {
            state.Loading = status.ERROR
        },

        // Extra reducers for GetFilteredInstruments
        [GetFilteredInstruments.pending]: (state, action) => {
            state.Loading = status.LOADING
        },
        [GetFilteredInstruments.fulfilled]: (state, action) => {
            state.FilteredInstruments = action.payload
            state.Loading = status.IDLE
        },
        [GetFilteredInstruments.rejected]: (state, action) => {
            state.Loading = status.ERROR
        }

    }
})

export const { setCategory } = processManagementSlice.actions

export default processManagementSlice.reducer