import { showTicker } from "../../../../Redux/Slices/TickerSlice"
import { typeOfGoods } from "./AddGoodFunctions"

// this file will contain all the formulas for calculating the bill related informations.
export function calculateCgst(price, qty, cgstRate) {
    let amount = price * qty
    let cgst = (amount * cgstRate) / 100
    return cgst
}

export function calculateSgst(price, qty, sgstRate) {
    let amount = price * qty
    let sgst = (amount * sgstRate) / 100
    return sgst
}

export function calculateGoodAmount(cgst, sgst, amount) {
    return cgst + sgst + amount
}

// function for calculating the total amount for the bill. it takes the array of the all goods added in to the bill and calculate the total amount which is the sum of cgst, sgst and good amount.
export const calculateAmount = (goods) => {
    return goods.reduce((total, good) => {
        // Check the type of good
        if (good.type === typeOfGoods.WCG) {
            return total + good.cgstAmount + good.sgstAmount + (good.qty * good.goodPrice);
        } else if (good.type === typeOfGoods.WFG) {
            return total + good.cgstAmount + good.sgstAmount + (good.flowRate * good.goodPrice);
        }
    }, 0);
};

export const calculateTotalConsumption = (goods) => {
    return goods.reduce((total, good) => total + good.qty, 0);
};

export const calculateTotalEffluentDischarge = (goods) => {
    return goods.reduce((total, good) => total + good.flowRate, 0);
};

export const calculateTotalCgst = (goods) => {
    return goods.reduce((total, good) => total + good.cgstAmount, 0);
};

export const calculateTotalSgst = (goods) => {
    return goods.reduce((total, good) => total + good.sgstAmount, 0);
};



// function for checking the percentage of the interest rate. function only used during the creation of bill api fetching.
export const CheckPercentage = (value, dispatch) => {
    if (value !== '' && value >= 0 && value <= 100) {
        return true
    } else {
        dispatch(showTicker({
            message: 'Interest Rate Invalid, Please check and try again',
            type: 'error'
        }))
        return false;
    }
};