// This slice is for updating the user details in the redux store. Taking uid, token and basic userdetails in form of payload, this slice will update the information in the redux store
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FirebaseAuthVariable } from "../../Firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";

export const CheckCurrentUser = createAsyncThunk('CheckCurrentUser', async () => {
    return new Promise((resolve) => {
        onAuthStateChanged(FirebaseAuthVariable, (currentUser) => {
            resolve({
                id: currentUser !== null ? currentUser?.uid : null,
                token: currentUser !== null ? currentUser?.accessToken : null,
                email: currentUser !== null ? currentUser?.email : null
            });
        });
    });
})

// A loading state is also declare for showing loader during we are checking if any user logged in or not
export const status = Object.freeze({
    IDLE: 'idle',
    LOADING: 'loading',
    ERROR: 'error'
})

const userSlice = createSlice({
    name: 'userAuthSlice',
    initialState: {
        Loading: status.LOADING,
        UID: null,
        AuthToken: null,
        UserRole: null, //PlantSide or IndustrySide
        UserAccessLevel: null,
        UserEmail: null,
        DocID: null,
        PlantID: null,
        UserDetails: []
    },
    reducers: {
        toggleLoading(state, action) {
            state.Loading = action.payload
            return state
        },
        UpdateUserDetails(state, action) {
            state.UID = action.payload.UID
            state.AuthToken = action.payload.AuthToken
            state.UserRole = action.payload.UserRole
            state.UserAccessLevel = action.payload.UserAccessLevel
            state.UserEmail = action.payload.UserEmail
            state.DocID = action.payload.DocID
            state.PlantID = action.payload.PlantID
            return state
        },
        UpdateOtherDetails(state, action) {
            state.UserDetails = action.payload
            return state
        },
        UpdatePlantIDincaseofSuperAdmin(state, action) {
            state.PlantID = action.payload
        }
    },
    extraReducers: {
        [CheckCurrentUser.pending]: (state) => {
            state.Loading = status.LOADING
        },
        [CheckCurrentUser.fulfilled]: (state, action) => {
            state.Loading = status.IDLE
            state.UID = action.payload.id
            state.AuthToken = action.payload.token
            state.UserEmail = action.payload.email
        },
        [CheckCurrentUser.rejected]: (state) => {
            state.Loading = status.ERROR
        }
    }
})

export const { toggleLoading, UpdateUserDetails, UpdateOtherDetails, UpdatePlantIDincaseofSuperAdmin } = userSlice.actions;
export default userSlice.reducer;