import { useSelector } from "react-redux"
import { status } from "../Redux/Slices/userSlice"
import { PlantPermission } from "./PlantPermissions"

// this function returns permissions for drawerClasses, which person can accesss which section. IMP ---- BY DRAWER ONLY NOT FROM ROUTES
export function DRAWER_PERMISSIONS(userType, accessLevel, departmentAccess) {
    const Loading = useSelector(state => state.USER_DETAILS.Loading)
    if (Loading === status.LOADING) { return }
    // Initialize default permissions
    let permissions = {
        isAllowedDashboard: true,
        isAllowedIndustrySection: false,
        isAllowedNotices: true,
        isAllowedBills: false,
        isAllowedSludge: false,
        isAllowedProcess: false,
        isAllowedPcb: false,
        isAllowedInventory: false,
        isAllowedReports: false,
        isAllowedCCTV: false,
        isAllowedTeam: false,
        isAllowedPlantProfile: false, // default

        isAllowedIndustrySideBillsSection: true, // this permission is for industry side users, it is true when someone logged in from industry side and false for plant side user.
        isAllowedPaymentResults: true // this permission is for showing the results of the payment 
        // Add more permissions as needed
    };
    // Hardcode permissions for industry users. if the user is from industry side then return the permisssions as it is. 
    if (userType === 'industry') { return permissions; }

    // Check if "All" is present in departmentAccess
    const hasAllAccess = departmentAccess?.includes('All');

    // Set permissions based on access level
    switch (accessLevel) {
        case 0: // Super Admin
            permissions = {
                isAllowedDashboard: true,
                isAllowedIndustrySection: true,
                isAllowedNotices: true,
                isAllowedProcess: true,
                isAllowedPcb: true,
                isAllowedBills: true,
                isAllowedSludge: true,
                isAllowedInventory: true,
                isAllowedReports: true,
                isAllowedCCTV: true,
                isAllowedTeam: true,
                isAllowedPlantProfile: true,
                isAllowedIndustrySideBillsSection: false, //restricting the plant user to access the industry side component
                isAllowedPaymentResults: false
            };
            break;
        case 1: // Admin
            permissions = {
                isAllowedDashboard: true,
                isAllowedIndustrySection: true,
                isAllowedNotices: true,
                isAllowedProcess: true,
                isAllowedPcb: true,
                isAllowedBills: true,
                isAllowedSludge: true,
                isAllowedInventory: true,
                isAllowedReports: true,
                isAllowedCCTV: true,
                isAllowedTeam: true,
                isAllowedPlantProfile: true,
                isAllowedIndustrySideBillsSection: false, //restricting the plant user to access the industry side component
                isAllowedPaymentResults: false
            };
            break;
        case 2: // Officer
            permissions.isAllowedDashboard = true
            permissions.isAllowedInventory = hasAllAccess ? true : [PlantPermission.InventoryRead, PlantPermission.InventoryWrite].some(i => departmentAccess?.includes(i))
            permissions.isAllowedIndustrySection = hasAllAccess ? true : [PlantPermission.IndustryRead, PlantPermission.IndustryWrite].some(i => departmentAccess?.includes(i))
            permissions.isAllowedNotices = hasAllAccess ? true : [PlantPermission.NoticeRead, PlantPermission.NoticeWrite].some(i => departmentAccess?.includes(i))
            permissions.isAllowedProcess = hasAllAccess ? true : [PlantPermission.ProcessRead, PlantPermission.ProcessWrite].some(i => departmentAccess?.includes(i))
            permissions.isAllowedBills = hasAllAccess ? true : [PlantPermission.BillRead, PlantPermission.BillWrite].some(i => departmentAccess?.includes(i))
            permissions.isAllowedSludge = hasAllAccess ? true : [PlantPermission.SludgeRead, PlantPermission.SludeWrite].some(i => departmentAccess?.includes(i))
            permissions.isAllowedPcb = false
            permissions.isAllowedReports = false
            permissions.isAllowedCCTV = false
            permissions.isAllowedTeam = true
            permissions.isAllowedPlantProfile = true
            permissions.isAllowedIndustrySideBillsSection = false//restricting the plant user to access the industry side component
            permissions.isAllowedPaymentResults = false
            break;
        case 3: // Operator
            permissions.isAllowedDashboard = true
            permissions.isAllowedInventory = hasAllAccess ? true : [PlantPermission.InventoryRead, PlantPermission.InventoryWrite].some(i => departmentAccess?.includes(i))
            permissions.isAllowedIndustrySection = hasAllAccess ? true : [PlantPermission.IndustryRead, PlantPermission.IndustryWrite].some(i => departmentAccess?.includes(i))
            permissions.isAllowedNotices = hasAllAccess ? true : [PlantPermission.NoticeRead, PlantPermission.NoticeWrite].some(i => departmentAccess?.includes(i))
            permissions.isAllowedProcess = hasAllAccess ? true : [PlantPermission.ProcessRead, PlantPermission.ProcessWrite].some(i => departmentAccess?.includes(i))
            permissions.isAllowedBills = hasAllAccess ? true : [PlantPermission.BillRead, PlantPermission.BillWrite].some(i => departmentAccess?.includes(i))
            permissions.isAllowedSludge = hasAllAccess ? true : [PlantPermission.SludgeRead, PlantPermission.SludeWrite].some(i => departmentAccess?.includes(i))
            permissions.isAllowedPcb = false
            permissions.isAllowedReports = false
            permissions.isAllowedCCTV = false
            permissions.isAllowedTeam = true
            permissions.isAllowedPlantProfile = true
            permissions.isAllowedIndustrySideBillsSection = false //restricting the plant user to access the industry side component
            permissions.isAllowedPaymentResults = false
            break;
        default:
            break;
    }

    return permissions;
}



// THIS FUNCTION RETURN PERMISSIONS FOR TEAM SECTION INTERNALLY, based on access level.
export const TEAMS_PERMISSIONS = () => {
    const accessLevel = useSelector(state => state.USER_DETAILS.UserAccessLevel)
    const permissions = {
        isAllowedToAddUsers: accessLevel === 0 ? true : accessLevel === 1 ? true : false,
        isAllowedActions: accessLevel === 0 || accessLevel === 1 ? true : false
    }
    return permissions
}


// THIS FUNCTION RETURN PERMISSIONS FOR NOTICE SECTION INTERNALLY, based on access level, role and depatment access.
export const NOTICE_PERMISSIONS = (UserRole, accessLevel, departments) => {
    // const UserRole = useSelector(state => state.USER_DETAILS.UserRole)
    // const accessLevel = useSelector(state => state.USER_DETAILS.UserAccessLevel)
    // const departments = useSelector(state => state.USER_DETAILS.UserDetails.departmentAccess)

    const check = () => {
        if (UserRole === "industry") { return false } // returning false for the industry, Industry cant send notices
        else if (UserRole !== 'industry') { // if not a industry, he must be from plant side. checking the access levels of the user if he is an admin or super admin then giving permissions true
            if (accessLevel === 0 || accessLevel === 1) { return true } // if not super admin or admin, checking the user have access for notices section. checking departmentAccess array of the user for this. 
            else {
                if (departments?.includes(PlantPermission.NoticeWrite)) { return true } // if found giving permission to go to the notice section.
                else { return false } // otherwise restricting user to go on the page. 
            }
        }
        else { return false }
    }
    const permissions = {
        isAllowedToSendNotices: check() // checking the permissions and sending permissions.
    }
    return permissions
}



export const BILLS_PAYMENTS_PERMISSIONS = () => {
    const UserRole = useSelector(state => state.USER_DETAILS.UserRole)
    const accessLevel = useSelector(state => state.USER_DETAILS.UserAccessLevel)
    const departments = useSelector(state => state.USER_DETAILS.UserDetails.departmentAccess)

    const permissions = {
        isAllowedGenerateBills: false,
        isAllowedGenerateMasterBills: false,
    }

    const checkPermission = () => {
        if (UserRole === "industry") { return false } // returning false for the industry, Industry cant generate bills
        else if (UserRole !== 'industry') { // if not a industry, he must be from plant side. checking the access levels of the user if he is an admin or super admin then giving permissions true
            if (accessLevel === 0 || accessLevel === 1) { return true } // if not super admin or admin, checking the user have access for bills section. checking departmentAccess array of the user for this. 
            else {
                if (departments?.includes(PlantPermission.BillWrite)) { return true } // if found giving permission to go to the notice section.
                else { return false } // otherwise restricting user to go on the page. 
            }
        }
        else { return false }
    }
    return permissions
}