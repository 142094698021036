import { showTicker } from "../Redux/Slices/TickerSlice";

// functions for validting the industry registration  form is heree
export function EmptyFeildValidation(feild, feildName, dispatch) {
    if (feild !== '' && feild !== undefined && feild !== null) {
        return true
    }
    else {
        dispatch(showTicker({
            message: `${feildName} must not be empty`,
            type: 'error'
        }))
    }
}

export function PhoneNumberValidation(feild, dispatch) {
    if (feild.length < 13 && feild.length >= 10) {
        return true
    }
    else {
        dispatch(showTicker({
            message: `Phone number is not valid`,
            type: 'error'
        }))
    }
}
const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // For Email Validation
export function EmailValidation(feild, dispatch) {
    if (regex.test(feild)) {
        return true
    }
    else {
        dispatch(showTicker({
            message: 'Email Address is not valid',
            type: 'error'
        }))
    }
}
export function PinCodeValidation(feild, dispatch) {
    if (feild.length === 6) {
        return true
    }
    else {
        dispatch(showTicker({
            message: 'Invalid Pincode',
            type: 'error'
        }))
    }
}
export function PhaseValidation(feild, dispatch) {
    if (feild >= 0 && feild !== null) {
        return true
    }
    else {
        dispatch(showTicker({
            message: 'Plant phase is not valid',
            type: 'error'
        }))
    }
}

export function EmptyArrayValidation(feild, fieldName, dispatch) {
    if (feild.length !== 0) {
        return true
    }
    else {
        dispatch(showTicker({
            message: `${fieldName} must not be empty`,
            type: 'error'
        }))
    }
}