import { ArrowBack, Edit } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { status } from '../../Redux/Slices/userSlice'
import { IndustrySideRoutes } from '../Routes/routes'
import { CircularLoader, SomethingWentWrong } from '../UI Components/Basic'

export default function IndustryProfile() {

  const navigate = useNavigate()
  const IndustryDetails = useSelector(state => state.USER_DETAILS?.UserDetails)
  const IndustryDetailsLoading = useSelector(state => state.USER_DETAILS.Loading)

  return (
    <div>
      <div className="PAGE_HEADER">
        {/* Name of the page, will show in lwft of the header */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /> </IconButton>
          <h3>Industry Profile</h3>
        </Stack>

      </div>
      {/* table of the bills */}
      <div className="Industries_Body_Container">
        <Box p={2}>
          {
            IndustryDetailsLoading === status.IDLE ? <Box>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant='h6'>{IndustryDetails?.companyName}</Typography>
                <Button variant='contained' startIcon={<Edit sx={{ width: '14px' }} />} onClick={() => { navigate(IndustrySideRoutes.EditDetails) }} disableElevation>Edit Details</Button>
              </Stack>
              <br />
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Industry Address</TableCell>
                      <TableCell>{IndustryDetails?.address}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Industry Pincode</TableCell>
                      <TableCell>{IndustryDetails?.pincode}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Phone</TableCell>
                      <TableCell>{IndustryDetails?.phoneNo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>E-mail</TableCell>
                      <TableCell>{IndustryDetails?.email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Date added</TableCell>
                      <TableCell>{IndustryDetails?.dateAdded}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Phase</TableCell>
                      <TableCell>{IndustryDetails?.phase}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Consent Validity</TableCell>
                      <TableCell>{IndustryDetails?.consentValidity}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total Effluent trade and utility</TableCell>
                      <TableCell>{IndustryDetails?.totalEffluentTradeAndUtility}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>P. Number</TableCell>
                      <TableCell>{IndustryDetails?.pno}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>H/N Type</TableCell>
                      <TableCell>{IndustryDetails?.h_n_type}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Plant Type</TableCell>
                      <TableCell>{IndustryDetails?.cetp_stp_etp_type}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Unit ID</TableCell>
                      <TableCell>{IndustryDetails?.unitId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Domestic Effluent</TableCell>
                      <TableCell>{IndustryDetails?.domesticEffluent}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>IC Chamber Installed</TableCell>
                      <TableCell>{IndustryDetails?.IC_chamber_install === 'yes' ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br /><br />

            </Box>
              : IndustryDetailsLoading === status.LOADING ? <CircularLoader />
                : IndustryDetailsLoading === status.ERROR ? <SomethingWentWrong />
                  : ''
          }
        </Box>
      </div>
    </div>
  )
} 
