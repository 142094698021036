import React, { useState } from 'react'
import './Styles/Navigation.css'
import { useNavigate, useParams } from 'react-router-dom'

import { DrawerRoutes } from '../Routes/routes'
import { useDispatch, useSelector } from 'react-redux'
import { setActive } from '../../Redux/Slices/drawerSlice'
import { AboutIcon, BillsPaymentsIcon, EffluentDetailsIcon, NoticesIcon, SettingsIcon } from '../../assets/assets'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

export default function Navigation() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { industryID } = useParams()

    const OnClickLink = (dispAddress) => {
        setActiveSection(dispAddress) // Changing section value
        navigate(`/industries/industry/${dispAddress}/${industryID}`); // NAvigating to corresponding section
        dispatch(setActive(`/industries/industry/${dispAddress}/${industryID}`)) // Changing address into redux store
    }

    const [a, b, c, d, e, f] = window.location.href.split('/')
    console.log(a, b, c, d, e, f);
    const [ActiveSection, setActiveSection] = useState(f) // For storing the current section of the page

    return (
        <div className='Navigation_Wrapper' style={{ position: "sticky", top: 0, backgroundColor: 'white', zIndex: 1 }}>
            <ToggleButtonGroup color="PrimaryBlue" value={ActiveSection} >
                <ToggleButton sx={{ border: 'none', borderRadius: '0' }} onClick={() => { OnClickLink("about-industry") }} value={"about-industry"}>About</ToggleButton>
                <ToggleButton sx={{ border: 'none', borderRadius: '0' }} onClick={() => { OnClickLink("industry-effluent") }} value={"industry-effluent"}>Effluent Details</ToggleButton>
                <ToggleButton sx={{ border: 'none', borderRadius: '0' }} onClick={() => { OnClickLink("industry-bills") }} value={"industry-bills"}>Bills & Payments</ToggleButton>
                <ToggleButton sx={{ border: 'none', borderRadius: '0' }} onClick={() => { OnClickLink("industry-notice") }} value={"industry-notice"}>Notices</ToggleButton>
                <ToggleButton sx={{ border: 'none', borderRadius: '0' }} onClick={() => { OnClickLink("industry-settings") }} value={"industry-settings"}>Settings</ToggleButton>
            </ToggleButtonGroup>
        </div>
    )   
}
