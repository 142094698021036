import React from "react";
import { useState } from "react";
import { Add, ArrowForward, Cancel } from "@mui/icons-material";
import { TextField, Button, Autocomplete, Divider, Chip, Stack, IconButton } from "@mui/material";
import "./Team.css";
import "./TeamStyles/AddUser.css";
import { useDispatch, useSelector } from "react-redux";
import DepartmentAccessComponent from "./TeamExtraFunctions";
import { EmailValidation, EmptyFeildValidation, PhoneNumberValidation } from "../../UserAuthentication/AuthFunctions";


export default function AddUser(props) {

  const dispatch = useDispatch()
  const AccessLevel = useSelector((state) => state.USER_DETAILS.UserAccessLevel) // Getting logged in user access level from redux store
  const options = getRolesByAccessLevel(AccessLevel); // Creating access roles options array from access level, to show allowed access to add users to logged in user
  const [value, setValue] = useState(); // For storing the new user post selected by user, for selecting which api is to be fetch. 
  const [departmentAccess, setDepartmentAccess] = useState([]); // For storing the new user permissions assigned to him.
  const [ApiBodyArray, setApiBodyArray] = useState({  // Api required feilds
    name: '',
    email: '',
    phoneNo: '',
    postName: '',
  })
  const [ProgressLevel, setProgressLevel] = useState(0) // For showing 2 forms, 0 for entering basic details of the new adding user and 1 for showing the peremission page where user can assign permissions to new user.

  //function for getting options array dynamiallly by access level
  function getRolesByAccessLevel(accessLevel) {
    let roles = [];
    if (accessLevel === 1) {
      roles = ['Officer'];
    } else if (accessLevel === 2) {
      roles = [];
    } else if (accessLevel === 0) {
      roles = ['Admin', 'Officer', 'Operator'];
    } else {
      roles = []
    }
    return roles;
  }


  const ForwardingToNextStep = () => {
    if (
      EmptyFeildValidation(ApiBodyArray.name, "Name", dispatch) &&
      EmailValidation(ApiBodyArray.email, dispatch) &&
      PhoneNumberValidation(ApiBodyArray.phoneNo, dispatch) &&
      EmptyFeildValidation(ApiBodyArray.postName, "Post Name", dispatch) &&
      EmptyFeildValidation(value, "Categroy", dispatch)
    ) {
      setProgressLevel(1)
    }
  }


  return (
    // Main Modal Container
    <div className="Modal_Black_bg">
      <div className="Modal_base">
        {/* Modal Heading */}
        <div className="Modal_heading">
          {ProgressLevel === 0 ? "Add User" : <div>
            Give Permissions to User
            <p className="simpleParagraph">You can add Permissions by Selecting Department and selecting Access.</p>
          </div>}
          <IconButton color="PrimaryBlue" onClick={() => { props.setActiveModal(0) }}>
            <Cancel />
          </IconButton>
        </div>

        {
          ProgressLevel === 0 ? < div className="Modal_content_edit" style={{ animation: 'fade-up 1s cubic-bezier(0.19, 1, 0.22, 1)' }}>
            {/* User Information Inputs */}
            <div className="Item_info">
              {/* TextField for new name */}
              <TextField
                sx={{ width: '49%' }}
                size="small"
                placeholder="Name"
                label="Name"
                value={ApiBodyArray.name}
                autoFocus
                onChange={(e) => { setApiBodyArray({ ...ApiBodyArray, name: e.target.value }) }}
              />

              <TextField
                sx={{ width: '49%' }}
                size="small"
                label="Email"
                placeholder="Email address"
                value={ApiBodyArray.email}
                onChange={(e) => { setApiBodyArray({ ...ApiBodyArray, email: e.target.value }) }}
              />

            </div>

            <div className="Item_info">
              <TextField
                sx={{ width: '33%' }}
                size="small"
                type="number"
                label="Phone"
                placeholder="Phone Number"
                value={ApiBodyArray.phoneNo}
                onChange={(e) => { setApiBodyArray({ ...ApiBodyArray, phoneNo: e.target.value }) }}
              />

              {/* TextField for new name */}
              <TextField
                sx={{ width: '32%' }}
                size="small"
                label="Post"
                placeholder="User post"
                value={ApiBodyArray.postName}
                onChange={(e) => { setApiBodyArray({ ...ApiBodyArray, postName: e.target.value }) }}
              />
              <Autocomplete
                size="small"
                value={value}
                onChange={(event, newValue) => { setValue(newValue); }}
                id="controllable-states-demo"
                options={options}
                sx={{ width: '32%' }}
                renderInput={(params) => (<TextField {...params} label="Category" />)}
              />
            </div>


            {/* Save Button */}
            <div className="Item_addbtn">
              <Button
                variant="contained"
                size="large"
                color="PrimaryBlue"
                disableElevation
                endIcon={<ArrowForward />}
                onClick={ForwardingToNextStep}
              >
                Next
              </Button>
            </div>
          </div> : <DepartmentAccessComponent
            departmentAccess={departmentAccess} // Sending this for printing and checking the array in next component
            setDepartmentAccess={setDepartmentAccess} // Sending this for manipulating the array in next component
            setProgressLevel={setProgressLevel} //For navigating back in Progress
            ApiBodyArray={ApiBodyArray} // Sending for further processing in "TeamExtraFunctions.js"
            Category={value} // For Handling which api is goiing to be fetched
            setActiveModal={props.setActiveModal} //For Closing the modal
          />
        }
      </div>
    </div >
  );
}

