import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActive } from "../../Redux/Slices/drawerSlice";
import { setActiveIndustry } from "../../Redux/Slices/NavSlice";
import { Button, Chip, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import { GetIndustries } from "../../Redux/Slices/Industry/GetIndustriesSlice";
import { DrawerRoutes } from "../Routes/routes";
import { status } from "../../Redux/Slices/userSlice";
import { CircularLoader, SomethingWentWrong } from "../UI Components/Basic";

export default function Industries() {

  const AuthToken = useSelector((state) => state.USER_DETAILS.AuthToken); // logged in user auth token
  const PlantID = useSelector((state) => state.USER_DETAILS.PlantID)
  const [Active_IndustryID, setActive_IndustryID] = useState(null);  // For getting which industry is clicked
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const IndustryData = useSelector((state) => state.INDUSTRIES.Industries); //Getting fetched industries
  const Loading = useSelector(state => state.INDUSTRIES.Loading) // Loadin gduring the industry loading
  let searchTimeout; // FOr debouncing purpose

  // Navigate to the industry section if id of the Active industry change
  useEffect(() => {
    if (Active_IndustryID !== null) {
      Navigate(`/industries/industry/about-industry/${Active_IndustryID}`);
      dispatch(setActive(`/industries/industry/about-industry/${Active_IndustryID}`));
      dispatch(setActiveIndustry(Active_IndustryID));
    }

    if (!IndustryData?.industries && Loading !== status.ERROR && Loading !== status.LOADING) { dispatch(GetIndustries({ AuthToken: AuthToken, PlantID: PlantID })) } // fetching industry data if not available. 
  }, [Active_IndustryID]);


  const ShowFlowData = (data) => {
    // // console.log(data);
    if (!!data?.latestFlowData && !!data?.latestFlowData !== undefined) {
      if (data?.latestFlowData?.flow === null) {
        return <Chip label={data?.latestFlowData?.message} size="small" />
      }
      else if (data?.latestFlowData?.flow !== null) {
        return <Tooltip title={`Flow for the month - ${data?.latestFlowData?.month}`}><Chip label={data?.latestFlowData?.flow?.toFixed(2)} size="small" /></Tooltip>

      }
      else {
        return "Not available"
      }
    }
    else { return <Chip label="Not available" size="small" /> }
  }

  const [searchResults, setSearchResults] = useState(IndustryData?.industries); // For saving filtered results if anyone applies a filter or search key
  // this small useffect set data to search results for mapping into the table. this is important because whenever the page loads, if the data in redux is change then the change should reflex in this table also, and direct changes is not reflecting because we are not directly mapping the redux state, we saving the redux state into filtered results or search resutlts and then mapping results in to the table.
  useEffect(() => { setSearchResults(IndustryData?.industries) }, [IndustryData])
  // function for searching the indstries with searchbar.
  const handleSearch = (searchQuery) => {
    const lowercaseQuery = searchQuery.toLowerCase();// Convert the search query to lowercase for case-insensitive search
    const filteredResults = IndustryData?.industries?.filter( // Filter inventory items based on name or code containing the search query
      ind =>
        ind?.data?.companyName?.toLowerCase().includes(lowercaseQuery) ||
        ind?.data?.address?.toLowerCase().includes(lowercaseQuery) ||
        ind?.data?.email?.toLowerCase().includes(lowercaseQuery)
    );
    setSearchResults(filteredResults); // Update the state with the search results
  };

  // implementing debouncing in the search bar
  const debouncedSearch = (query) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      handleSearch(query);
    }, 1000);
  };



  return (
    <div>
      {/* <button onClick={() => { dispatch(GetIndustries(AuthToken)); }}> Getindustries</button> */}
      {/* this is a universal page header and will be included in every page. */}
      <div className="PAGE_HEADER">
        {/* Name of the page, will show in lwft of the header */}
        <Stack>
          <h3>Industries</h3>
          <Typography variant="body2"><span style={{ color: 'red' }}>Complete flow data is unavailable from <b>Vision Worldtech Company</b>.</span></Typography>
        </Stack>
        {/* create any buttons or other things here. it will show on right side of the page header */}
      </div>

      {/* this div will contain all the content of the page */}
      <div className="Industries_Body_Container">
        {/* this tool wrapper may be included in every page. it will contain all tools related to content of the page like searchbar, filters etc. */}
        <div
          className="TOOLS_WRAPPER"
          style={{
            display: "flex",
            flexDirection: "Row",
            justifyContent: "space-between",
          }}
        >
          {/* searchbar for searching the industries, entered inpupt will be stored in "text" state */}
          <div className="search_form">
            <TextField
              id="outlined-basic"
              label="Search any industry"
              variant="outlined"
              size="small"
              onChange={(e) => debouncedSearch(e.target.value)}
            />
          </div>

          <Button
            onClick={() => {
              Navigate(DrawerRoutes.Add_Industry);
            }}
            color="PrimaryBlue"
            variant="contained"
            size="medium"
            startIcon={<AddCircleOutline />}
            disableElevation
          >
            Add Industry
          </Button>
        </div>

        <div className="INDUSTRY_TABLE_CONTAINER">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: "0px 30px", width: "30%" }}>Industries</TableCell>
                  <TableCell>Flow (meter cube)</TableCell>
                  <TableCell>Ph</TableCell>
                  <TableCell>BOD</TableCell>
                  <TableCell>COD</TableCell>
                  <TableCell>TDS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  Loading === status.IDLE ?
                    IndustryData.industries !== undefined && searchResults !== undefined
                      ? searchResults.map((ind) => {
                        return (
                          <TableRow
                            key={ind.id}
                            onClick={() => { Navigate(`/industries/industry/about-industry/${ind.id}`) }}
                          >
                            <TableCell sx={{ padding: "10px 30px", width: "30%" }}>
                              <Stack>
                                <Typography variant="body2">{ind.data.companyName}</Typography>
                                <Typography variant="caption">{ind.data.address}</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>{ShowFlowData(ind.data)}</TableCell>
                            <TableCell><Chip label="Not Available" size="small" /></TableCell>
                            <TableCell><Chip label="Not Available" size="small" /></TableCell>
                            <TableCell><Chip label="Not Available" size="small" /></TableCell>
                            <TableCell><Chip label="Not Available" size="small" /></TableCell>
                          </TableRow>
                        );
                      })
                      : ""
                    : Loading === status.LOADING ? <CircularLoader />
                      : Loading === status.ERROR ? <SomethingWentWrong /> : ""
                }
              </TableBody>
            </Table>
          </TableContainer>
          <br /><br /><br />
        </div>
      </div>
    </div>
  );
}
