import React, { useEffect, useState } from "react";
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack,
} from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { AddCircleOutline, Delete, Edit, Upgrade } from "@mui/icons-material";
import "./InventoryStyles/Consumables.css";
import UpdateQuantity from "./UpdateQuantity";
import AddItem from "./AddItem";
import EditItem from "./EditItem";
import { useDispatch, useSelector } from "react-redux";
import { GetInventoryConsumables, GetInventoryNonConsumables } from "../../Redux/Slices/InventorySlices/InventorySlice.js";
import { CircularLoader, ConfirmationPopup, SomethingWentWrong } from "../UI Components/Basic.js";
import { useNavigate } from "react-router-dom";
import { DrawerRoutes } from "../Routes/routes.js";
import { showTicker } from "../../Redux/Slices/TickerSlice.js";
import { status } from "../../Redux/Slices/userSlice.js";
import { SERVER_ADDRESS } from "../../Firebase/Variables.js";
import { FormatTimestamp } from "../ReportsComponents/BillsAndPayments/BillGeneration/AddGoodFunctions.js";
import { convertGMTtoISTAndFormat } from "../ReportsComponents/BillsAndPayments/DedicatedIndustryBill.js";

export default function Consumables() {

  // all defined variables
  const dispatch = useDispatch(); //for deispatching actions
  const navigate = useNavigate(); // for navigation 
  const UserToken = useSelector((state) => state.USER_DETAILS.AuthToken);
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const INVENTORY_CONSUMABLES = useSelector((state) => state.INVENTORY_ITEMS.Consumables);
  const INVENTORY_Loading = useSelector((state) => state.INVENTORY_ITEMS.Loading);
  const [searchResults, setSearchResults] = useState(INVENTORY_CONSUMABLES.items); // For saving filtered results if anyone applies a filter or search key
  let searchTimeout; // FOr debouncing purpose

  const isEmpty = INVENTORY_CONSUMABLES?.items?.length <= 0

  // Fetching the inventory items 
  useEffect(() => { if (!INVENTORY_CONSUMABLES?.items && INVENTORY_Loading !== status.LOADING && INVENTORY_Loading !== status.ERROR) { dispatch(GetInventoryConsumables({ AuthToken: UserToken, PlantID: PlantID })) } }, [])

  // this small useffect set data to search results for mapping into the table. this is important because whenever the page loads, if the data in redux is change then the change should reflex in this table also, and direct changes is not reflecting because we are not directly mapping the redux state, we saving the redux state into filtered results or search resutlts and then mapping results in to the table.
  useEffect(() => { setSearchResults(INVENTORY_CONSUMABLES.items) }, [INVENTORY_CONSUMABLES])

  // function for searching a item in the search bar
  const handleSearch = (searchQuery) => {
    const lowercaseQuery = searchQuery.toLowerCase();// Convert the search query to lowercase for case-insensitive search
    const filteredResults = INVENTORY_CONSUMABLES.items.filter( // Filter inventory items based on name or code containing the search query
      item =>
        item.data.itemName.toLowerCase().includes(lowercaseQuery) ||
        item.data.itemCode.toLowerCase().includes(lowercaseQuery)
    );
    setSearchResults(filteredResults); // Update the state with the search results
  };

  // implementing debouncing in the search bar
  const debouncedSearch = (query) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      handleSearch(query);
    }, 1000);
  };

  // A use state for handling opening and closing of All modals like add, update, edit and delete. A number will be assigned to every modal and if the modal number matched with state value the corresponding modal will appear on the screen. The values for modals are as follows - ( 0 - None, 1 - Add Item, 2 - Update Item, 3 - Edit Item, 4 - Delete Item). And target item for the element for which we have targeting.
  const [ActiveModal, setActiveModal] = useState({
    ActiveModalIndex: 0, // This state opens corresponding modal, Initialised with 0 so no modal will apear on screen
    TargetItem: { // and this, passes data into that modal 
      itemId: '',
      itemName: '',
      itemCode: '',
      itemQuantityAvailable: '',
      itemUnit: '',
      itemType: '',
    }
  })

  const handleCheckUsage = () => {
    navigate(DrawerRoutes.Reports.Inventory_Reports)
  };

  return (
    <>
      {/* Dynamically rendering the modals as Active Modal changes the corresponding modal will appear on screen */}
      {ActiveModal.ActiveModalIndex === 0 ? ''
        : ActiveModal.ActiveModalIndex === 1 ? <AddItem setActiveModal={setActiveModal} />
          : ActiveModal.ActiveModalIndex === 2 ? <UpdateQuantity setActiveModal={setActiveModal} item={ActiveModal.TargetItem} />
            : ActiveModal.ActiveModalIndex === 3 ? <EditItem setActiveModal={setActiveModal} item={ActiveModal.TargetItem} />
              : ActiveModal.ActiveModalIndex === 4 ? <DeleteItem setActiveModal={setActiveModal} item={ActiveModal.TargetItem} /> : ''}

      {/* <button onClick={() => { dispatch(GetInventoryConsumables(UserToken)) }}>fetch items</button> */}


      {/* this is a universal page header and will be included in every page. */}
      <div className="PAGE_HEADER">
        {/* Name of the page, will show in lwft of the header */}
        <h3>Inventory / Consumables</h3>

        {/* create any buttons or other things here. it will show on right side of the page header */}
        <ToggleButtonGroup size="small" color="PrimaryBlue" value={"1"}>
          <ToggleButton value="1">Consumable</ToggleButton>
          <ToggleButton onClick={() => { navigate(DrawerRoutes.Inventory.nonconsumables) }} value="2" > Non-Consumable</ToggleButton>
        </ToggleButtonGroup>
      </div>

      {/* this div will contain all the content of the page */}
      <div className="Industries_Body_Container">
        <div className="table_Heading_Container">
          <div className="search_form">
            <TextField
              id="outlined-basic"
              label="Search Item..."
              variant="outlined"
              size="small"
              onChange={(e) => debouncedSearch(e.target.value)}
            />
          </div>
          <div className="table_toolbar">
            <Button
              onClick={handleCheckUsage}

              disableElevation
            >Check Usage</Button>
            <Button onClick={() => {
              setActiveModal({
                ActiveModalIndex: 1,
                TargetItemId: null  // Null because we dont need any item to pass for adding new items
              })
            }} variant="contained" color="PrimaryBlue" startIcon={<AddCircleOutline />} disableElevation>Add Item</Button>
          </div>
        </div>

        {/* TAble data containerF */}

        {
          INVENTORY_Loading === status.IDLE ? <div>
            {
              !(isEmpty) ? <TableContainer disableElevation>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="10%" sx={{ paddingLeft: "30px", }}> S.No.</TableCell>
                      <TableCell >Name</TableCell>
                      <TableCell >Item Code</TableCell>
                      <TableCell >Quantity</TableCell>
                      <TableCell sx={{ width: '120px' }}>Created</TableCell>
                      <TableCell >Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchResults?.map((item) => {
                      return <TableRow key={item.id}>
                        <TableCell sx={{ paddingLeft: "30px" }}>{searchResults.indexOf(item) + 1}</TableCell>
                        <TableCell>{item.data.itemName}</TableCell>
                        <TableCell>{item.data.itemCode}</TableCell>
                        <TableCell sx={{ width: '140px' }}><b>{item.data.itemQuantityAvailable} {item.data.itemUnit}</b></TableCell>
                        <TableCell>{convertGMTtoISTAndFormat(item.data.dateCreated)}</TableCell>

                        <TableCell id="action_toolbar">
                          <Stack direction="row" spacing={1}>
                            <Button color="PrimaryBlue" variant="contained" startIcon={<Upgrade sx={{ width: '16px' }} />} sx={{ height: '32px' }} onClick={() => {
                              setActiveModal({
                                ActiveModalIndex: 2,
                                TargetItem: {
                                  itemId: item.id,
                                  itemName: item.data.itemName,
                                  itemCode: item.data.itemCode,
                                  itemQuantityAvailable: item.data.itemQuantityAvailable,
                                  itemUnit: item.data.itemUnit,
                                  itemType: item.data.itemType
                                }
                              })
                            }}>Update Quantity</Button>
                            <Button color="warning" variant="contained" startIcon={<Edit sx={{ width: '14px' }} />} sx={{ height: '32px' }} onClick={() => {
                              setActiveModal({
                                ActiveModalIndex: 3,
                                TargetItem: {
                                  itemId: item.id,
                                  itemName: item.data.itemName,
                                  itemCode: item.data.itemCode,
                                  itemQuantityAvailable: item.data.itemQuantityAvailable,
                                  itemUnit: item.data.itemUnit,
                                  itemType: item.data.itemType
                                }
                              })
                            }}>Edit</Button>
                            <Button color="PrimaryRed" variant="contained" startIcon={<Delete sx={{ width: '14px' }} />} sx={{ height: '32px' }} onClick={() => {
                              setActiveModal({
                                ActiveModalIndex: 4,
                                TargetItem: {
                                  itemId: item.id,
                                  itemName: item.data.itemName,
                                  itemType: item.data.itemType
                                }
                              })
                            }}>Delete</Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    })}
                  </TableBody>
                </Table>
              </TableContainer> : <p className="absCenter simpleParagraph">No items found</p>
            }
          </div>
            : INVENTORY_Loading === status.LOADING ? < CircularLoader />
              : INVENTORY_Loading === status.ERROR ? <SomethingWentWrong /> : ''}
      </div >
    </>
  );
}


// function for deleting the item. made this function here beacuse dont want to create any seperate file for such a small thing
export function DeleteItem(props) {
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const dispatch = useDispatch()
  const [Loading, setLoading] = useState(false)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)

  // API call for deleting an item
  const DELETE = () => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${UserToken}`);
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(`${SERVER_ADDRESS}/inventory/delete/${props.item.itemId}?plantID=${PlantID}`, requestOptions)
      .then(response => {
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch(showTicker({
              message: `Successfully deleted ${props.item.itemName}`,
              type: 'success'
            }))
          }
          else {
            dispatch(showTicker({
              message: data.message,
              type: "error"
            }))
          }
        })
        setLoading(false)
        props.setActiveModal(0)
        if (props.item.itemType === 'Consumable') { dispatch(GetInventoryConsumables({ AuthToken: UserToken, PlantID: PlantID })) } // Checking the typw of the item and based on that, we are refetchin the list. 
        else { dispatch(GetInventoryNonConsumables({ AuthToken: UserToken, PlantID: PlantID })) }
      })

      .catch(error => {
        dispatch(showTicker({
          message: error.message,
          type: "error"
        }))
        setLoading(false)
      });
  }
  return <ConfirmationPopup
    PrimaryAction={DELETE}
    SecondaryAction={() => { props.setActiveModal(0) }}
    PrimaryButtonText={"Delete"}
    color="PrimaryRed"
    message={`Are you sure you want to delete the item ${props.item.itemName} ?`}
    Loading={Loading}
  />
}