import { Box, Chip, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react'
import { convertGMTtoISTAndFormat } from '../ReportsComponents/BillsAndPayments/DedicatedIndustryBill';
import { useDispatch, useSelector } from 'react-redux';
import { Delete } from '@mui/icons-material';
import { DeleteNotice } from '../../Redux/Slices/Industry/SendNoticeSlice';
import { ConfirmationPopup } from '../UI Components/Basic';

export default function NoticeSideDrawerLayout(props) {
    const UserRole = useSelector(state => state.USER_DETAILS.UserRole) // for checking the logged in user is an admin or industry. Because if the user is an admin, then we will not show new notice tag to admin , because he sent that notices. no notice is new for admin. Notice is new for only industry. 
    const dispatch = useDispatch()
    const [Loading, setLoading] = useState(false)
    const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)// logged in user auth token
    const PlantID = useSelector(state => state.USER_DETAILS.PlantID)// logged in user auth token
    const [isDelete, setisDelete] = useState(false) // for showing popup

    //hitting api for deleting the notice.
    const deleteNotice = () => {
        dispatch(DeleteNotice({
            AuthToken: AuthToken,
            NoticeID: props.id,
            PlantID: PlantID,

            setLoading: setLoading,
            setNoticeDetails: props.setNoticeDetails,
            setisDelete: setisDelete
        }))
    }

    return (
        <Box width={800} p={3} >
            <Stack spacing={1} direction="column">
                <Box position="sticky" top={0} bgcolor="white" p="10px 0px">

                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Chip label={`Notice Date : ${convertGMTtoISTAndFormat(props?.data?.creationDate)}`} sx={{ width: 'fit-content', marginBottom: 1 }} />
                        <Tooltip title="Delete this notice">
                            <IconButton color='PrimaryRed' onClick={() => { setisDelete(true) }}><Delete fontSize='small' /></IconButton>
                        </Tooltip>
                    </Stack>
                    <Typography variant='h6'>{props.data?.title}</Typography><br />
                    <Stack direction="row" gap={1} flexWrap="wrap">
                        {UserRole !== "industry" ? <Typography variant='body2'>Sent to : </Typography> : ''}
                        {
                            UserRole !== 'industry' ? props.data?.industries?.length > 0 && props.data?.industries !== null && props.data?.industries !== undefined ?
                                props.data?.industries?.map((ind) => {
                                    return <Tooltip key={ind.id} title={ind?.data?.address}>
                                        {ind.id === "All" ? <Chip label="Sent to All Industries" size='small' variant='outlined' /> : <Chip label={ind?.data?.name} size='small' variant='outlined' />}
                                    </Tooltip>
                                })
                                : '' : ""
                        }
                    </Stack>
                </Box>

                {/* rendering the content of the notice */}
                <Typography variant='body2' dangerouslySetInnerHTML={{ __html: props.data?.description }} />

                <br />
                <Divider />
                {props.data?.attachments?.length !== 0 ? <Box sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', position: "relative" }}>
                    <Stack direction="row"> {
                        props.data?.attachments?.map((att) => {
                            return <Tooltip key={att.url} title={att.name}>
                                <Box
                                    onClick={() => { window.open(att.url, "_blank") }}
                                    sx={{
                                        p: 1, border: '1px dashed lightblue',
                                        backgroundColor: 'var(--Tertiary_Blue)',
                                        borderRadius: '10px',
                                        width: '100px',
                                        height: '80px',
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Typography variant='body2'>{att.name.slice(0, 9)}..</Typography>
                                </Box>
                            </Tooltip>
                        })}
                    </Stack>
                </Box> : ''}
            </Stack>


            {
                isDelete ? <ConfirmationPopup
                    PrimaryAction={deleteNotice}
                    PrimaryButtonText={"Delete"}
                    SecondaryAction={() => { setisDelete(false) }}
                    message={`Are you sure you want to delete the Notice? Deleting this notice from here will also remove from industry side also. are you sure you want to continue? `}
                    Loading={Loading}
                    color={"PrimaryRed"}
                /> : ''
            }
        </Box>
    )
}

