// this component will contain all the bills of a specific industry in industry side login. 
import React, { useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, Chip, Divider, Stack, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Download, Payment } from '@mui/icons-material'

// import { DownloadBillPDF } from '../BillGeneration/BillToPdf'
import { downloadBill, setDownloadBillID } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/BillGenerationSlice'
import LoadingButton from '@mui/lab/LoadingButton';
import UploadReceipt from './PaymentReciptUpload'
import { paymentHandler } from './PaymentHandle'
import { convertGMTtoISTAndFormat } from '../DedicatedIndustryBill'
import { GetSingleBillIndustrySide, updateBillData } from '../../../../Redux/Slices/ReportsSlices/BillsAndPaymentSlices/IndustryBillsSlice'
import { CircularLoader } from '../../../UI Components/Basic'
import { LOCAL_SERVER_ADDRESS, SERVER_ADDRESS } from '../../../../Firebase/Variables'
import { VysionLogo_Payments } from '../../../../assets/assets'

export default function BillSideDetails(props) {

    const dispatch = useDispatch()
    const plantID = useSelector(state => state.USER_DETAILS.PlantID);
    const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)
    const industryID = useSelector(state => state.USER_DETAILS.DocID);
    const industryDetails = useSelector(state => state.USER_DETAILS.UserDetails);
    const DownloadBillID = useSelector(state => state.BILL_GENERATION.downloadBillID)
    const bills = useSelector(state => state.BILLS.DedicatedBills?.bills)
    const [paymentLoader, setPaymentLoader] = useState(false)

    // selecting the active bill from the redux store. Fetching this from redux store because earlier we are getting this from props. but the problem with props is that the data is not updating from inside of this component. so we just update the data in the redux store and got changes here. We are talking about rerender page after payments recipt upload
    const bill = bills !== undefined && bills !== null && Object.keys(bills).length > 0 ? bills.find((bill) => bill.id === props.billID) : {}
    const billData = bill?.data

    console.log(billData);
    // function for checking the bill is overdue or not?
    function calculateAmountWithInterest(amount, interestRate, lastDate, isPaid, datePaid) {

        const [day, month, year] = lastDate.split('/');
        const lastDateObj = new Date(`${year}-${month}-${day}`);  // Parse the last date string into a Date object
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (isPaid) {
            const [day, month, year] = datePaid.split('/');
            const datePaidObj = new Date(`${year}-${month}-${day}`);
            const paidBefore = datePaidObj < lastDateObj // checking bill is paid before or after the laste date. 

            if (paidBefore) {
                let newAmount = amount
                let interestAmount = 0
                return { isOverdue: false, paidBefore: true, interestAmount, newAmount };
            }
            else if (!paidBefore) {
                let interestAmount = interestRate === 0 ? amount : amount * (interestRate / 100);
                let newAmount = amount + interestAmount;
                return { isOverdue: false, paidBefore: false, interestAmount, newAmount };
            }
        }
        else if (!isPaid) {
            const isBillOverdue = lastDateObj < today;
            if (isBillOverdue) {
                let interestAmount = interestRate === 0 ? amount : amount * (interestRate / 100);
                let newAmount = amount + interestAmount;
                return { isOverdue: true, paidBefore: true, interestAmount, newAmount };
            }
            else if (!isBillOverdue) {
                let interestAmount = 0;
                let newAmount = amount + interestAmount;
                return { isOverdue: false, paidBefore: true, interestAmount, newAmount };
            }
        }
    }

    // checking tis the bill is overdue or not at every instance
    const isOverdue = calculateAmountWithInterest(billData?.amount, billData?.requiredFields?.interestRate, convertGMTtoISTAndFormat(billData?.requiredFields?.lastDate))


    // function for handing the payments
    const downloadHandler = (billid) => {
        dispatch(setDownloadBillID(billid))
        dispatch(downloadBill({ industryid: industryID, billid: billid, AuthToken: AuthToken, PlantID: plantID }))
    }


    // function for rendering the payment details of the bill if paid and not paid. function is specific for the bhiwadi plant. need to change it when deploy for others
    const renderPaymentDetails = () => {
        if (billData?.paymentData !== undefined && billData?.paymentData !== null) {
            return <Stack>
                <Typography variant='body2'>Date of the Payment - {billData?.isPaid ? convertGMTtoISTAndFormat(billData?.datePaid) : "Not Paid Yet"}</Typography>
                {/* <Typography variant='body2'>Payment Method : &nbsp;
                    {billData?.paymentData?.method}&nbsp;
                    {
                        billData?.paymentData?.vpa !== null ? `| ${billData?.paymentData?.vpa}` :
                            billData?.paymentData?.wallet !== null ? `| ${billData?.paymentData?.wallet}` :
                                billData?.paymentData?.card_id !== null ? `| ${billData?.paymentData?.card_id}` : ''

                    }
                </Typography>*/}
                {billData?.isPaid ?
                    <>
                        <Typography variant='body2'>Order ID : {billData?.paymentData?.orderid ? billData.paymentData.orderid : 'Not available'}</Typography>
                        <Typography variant='body2'>Transaction ID : {billData?.paymentData?.paymentid !== null && billData?.paymentData?.paymentid !== undefined ? billData?.paymentData?.paymentid : 'Not available'}</Typography>
                    </>
                    : ""}

            </Stack>
        }
        else {
            return <Stack>
                <Typography variant='body2'>Date of the Payment - {billData?.isPaid ? convertGMTtoISTAndFormat(billData?.datePaid) : "Not Paid Yet"}</Typography>
                {billData?.isPaid ? <Typography variant='body2'>Payment Method : Paid Externally</Typography> : ''}
            </Stack>
        }
    }

    // usestate for holding all the state of the bill, including pay button, upoad recipt button and stepper steps.
    const [BillState, setBillState] = useState({
        isApproved: 0,
        showStepper: false,
        activeStep: 1,
        payBillButtonEnabled: true,
        uploadReciptButtonEnabled: true
    })

    // steps of the stepper
    const steps = ['Payment Receipt Uploaded', 'Receipt in review', BillState.isApproved === 1 ? "Receipt Approved" : BillState.isApproved === 2 ? "Receipt Rejected" : "Action"];

    // this function manage the complete buttons state on basis of bill data. 
    const HandleBillStates = () => {
        if (billData?.isPaid) {
            if (billData?.paymentData === undefined) { // paid from receipt
                if (billData?.paymentRecieptStatus === 2) {
                    setBillState({
                        showStepper: true, activeStep: 3, payBillButtonEnabled: false, uploadReciptButtonEnabled: false, isApproved: 1
                    })
                }
                else if (billData?.paymentRecieptStatus === 3) {
                    setBillState({
                        showStepper: true, activeStep: 3, payBillButtonEnabled: true, uploadReciptButtonEnabled: true, isApproved: 2
                    })
                }
                else {
                    setBillState({
                        isApproved: 0, showStepper: false, activeStep: 1, payBillButtonEnabled: true, uploadReciptButtonEnabled: true
                    })
                }
            }
            else if (billData?.paymentData !== undefined && billData?.paymentData !== null) { // paid from our platform
                setBillState({
                    isApproved: 0, showStepper: false, activeStep: 1, payBillButtonEnabled: false, uploadReciptButtonEnabled: false
                })
            }
        }
        else if (billData?.isPaid === false) {
            if (billData?.paymentRecieptStatus === 0) {
                setBillState({
                    isApproved: 0, showStepper: false, activeStep: 0, payBillButtonEnabled: true, uploadReciptButtonEnabled: true
                })
            }
            else if (billData?.paymentRecieptStatus === 1) {
                setBillState({
                    isApproved: 0, showStepper: true, activeStep: 1, payBillButtonEnabled: false, uploadReciptButtonEnabled: false
                })
            }
            else if (billData?.paymentRecieptStatus === 2) {
                setBillState({
                    isApproved: 1, showStepper: true, activeStep: 3, payBillButtonEnabled: false, uploadReciptButtonEnabled: false
                })
            }
            else if (billData?.paymentRecieptStatus === 3) {
                setBillState({
                    isApproved: 2, showStepper: true, activeStep: 3, payBillButtonEnabled: true, uploadReciptButtonEnabled: true
                })
            }
            else {
                setBillState({
                    isApproved: 0, showStepper: false, activeStep: 1, payBillButtonEnabled: true, uploadReciptButtonEnabled: true
                })
            }
        }
    }



    const [LatestDataLoading, setLatestDataLoading] = useState(false)

    // handles the steps of the steppers, running this function on page load. 
    useEffect(() => {
        // console.log("handling the bill states");
        HandleBillStates()
    }, [LatestDataLoading, bills, bill, billData])



    const onPayment = async (event) => {
        event.preventDefault();

        setPaymentLoader(true)
        const notes = {
            plantId: plantID,
            industryId: industryID,
            billId: props.billID
        }
        const amount = isOverdue?.isOverdue || !isOverdue.paidBefore ? (billData?.amount + isOverdue?.interestAmount).toFixed(2) * 100 : (billData?.amount).toFixed(2) * 100

        const response = await fetch(`${SERVER_ADDRESS}/public/payments/order`, {
            method: "POST",
            body: JSON.stringify({
                amount: amount,
                currency: "INR",
                receipt: billData?.quotationNo,
                notes,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const order = await response.json();

        if (order.id) {
            document.querySelector('input[name="order_id"]').value = order.id;
            document.querySelector('input[name="amount"]').value = amount;
            document.querySelector('input[name="cancel_url"]').value = `https://ocems.techvysion.com/payment-cancelled?amount=${amount / 100}&orderid=${order.id}`;
            document.querySelector('input[name="callback_url"]').value = `${SERVER_ADDRESS}/public/payments/verify?amount=${amount}&orderid=${order.id}`;

            setPaymentLoader(false)

            document.getElementById('paymentForm').submit();
        }

    }

    return <Stack width={800} sx={{ position: 'relative' }}>
        {
            LatestDataLoading ? <CircularLoader /> : <Box>
                {/* <button onClick={fetchLatestData}>get latest bill data</button> */}

                {/* {props.billID} */}
                <Stack p={2} direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction='column'>
                        <Typography variant='h6'>{billData?.description}</Typography>
                        <Typography variant='body2'>Bill Date - {convertGMTtoISTAndFormat(billData?.dateCreated)}</Typography>
                    </Stack>
                    <Chip label={`Quotation No - ${billData?.quotationNo}`} color='PrimaryBlue' variant='outlined' />
                </Stack>

                <Divider />

                <Box p={2} sx={{
                    backgroundColor:
                        billData?.isPaid === true ? "#00ff001a"
                            : (billData?.isPaid === false && (BillState.activeStep === 0 || BillState.activeStep === 3)) ? "#ff000014"
                                : (BillState.activeStep === 1 && billData?.isPaid === false) ? "#ffd34838"
                                    : 'white',
                    transition: '0.3s'
                }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        {renderPaymentDetails()}
                        <Chip label={
                            billData?.isPaid === true ? "Paid"
                                :
                                (billData?.isPaid === false && (BillState.activeStep === 0 || BillState.activeStep === 3)) ? "Not Paid"
                                    : (BillState.activeStep === 1 && billData?.isPaid === false) ? "In Review"
                                        : 'Not Paid'
                        }
                            color={
                                billData?.isPaid ? "success"
                                    : (billData?.isPaid === false && (BillState.activeStep === 0 || BillState.activeStep === 3)) ? "PrimaryRed"
                                        : (BillState.activeStep === 1 && billData?.isPaid === false) ? "warning"
                                            : "PrimaryRed"
                            } />
                    </Stack>

                    {/* payment steps if user paid exterally */}
                    {
                        billData?.paymentRecieptStatus !== 0 && !(billData?.isPaid) ? <Box>
                            <Typography variant='body2'>Payment Receipt status</Typography>
                            <br />
                            <Box sx={{ width: '90%' }}>
                                <Stepper activeStep={BillState.activeStep}>
                                    {steps.map((label) => {
                                        return (<Step key={label}><StepLabel>{label}</StepLabel></Step>);
                                    })}
                                </Stepper>
                            </Box>
                        </Box> : ""
                    }
                </Box>

                <Divider />

                <Stack p={2} direction="row" justifyContent="space-between" position="relative">
                    <Stack direction="row" spacing={1}>

                        {/* button for downloading the bill */}
                        <LoadingButton
                            loading={DownloadBillID === props.billID}
                            loadingPosition="start"
                            variant='contained'
                            color='PrimaryBlue'
                            onClick={() => { downloadHandler(props.billID) }}
                            disableElevation
                            startIcon={<Download sx={{ width: '16px' }} />}
                        >Download Bill</LoadingButton>


                        {/* this componet includes upload recipt button and all logic for uploading receipt */}
                        <UploadReceipt
                            billID={props.billID} // IMportatnt for uploading recipt api
                            disableProp={!BillState.uploadReciptButtonEnabled} // conditions for disabling the button. 
                            setLatestDataLoading={setLatestDataLoading}
                        />


                        {/* Button for pay bill */}

                        <form id='paymentForm' method="POST" action="https://api.razorpay.com/v1/checkout/embedded" >
                            <input type="hidden" name="key_id" value={process.env.REACT_APP_RZP_TES_KEY} />
                            <input type="hidden" name="amount" value='' />
                            <input type="hidden" name="order_id" value='' />
                            <input type="hidden" name="name" value="Vysion Technologies" />
                            <input type="hidden" name="image" value={VysionLogo_Payments} />
                            <input type="hidden" name="prefill[name]" value={industryDetails.companyName} />
                            <input type="hidden" name="prefill[contact]" value={industryDetails.phoneNo} />
                            <input type="hidden" name="prefill[email]" value={industryDetails.email} />
                            <input type="hidden" name="notes[shipping address]" value={industryDetails.address} />
                            <input type="hidden" name="callback_url" value={`${SERVER_ADDRESS}/public/payments/verify`} />
                            <input type="hidden" name="cancel_url" value='' />

                            <LoadingButton
                                loading={paymentLoader}
                                loadingPosition='start'
                                type='submit'
                                variant='contained'
                                color='PrimaryBlue'
                                onClick={(e) => onPayment(e)}
                                disableElevation
                                disabled={billData?.isPaid || !BillState.payBillButtonEnabled}
                                startIcon={<Payment sx={{ width: '16px' }} />}
                            >Pay Bill</LoadingButton>
                        </form>

                    </Stack>
                </Stack>

                <Divider />

                {/* Goods table, All the added goods will list here.  */}
                <Box p={2} zIndex={0} position="relative">
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ paddingLeft: '20px', fontSize: '12px' }}>Services</TableCell>
                                    <TableCell sx={{ fontSize: '12px' }}>Good Type</TableCell>
                                    <TableCell sx={{ fontSize: '12px' }}>Consumption</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody sx={{ position: "relative" }}>
                                {
                                    billData?.goods !== undefined && billData?.goods !== null ?
                                        billData?.goods.length !== 0 ?
                                            billData?.goods.map((good, index) => {
                                                return <TableRow key={index}>
                                                    <TableCell sx={{ paddingLeft: '20px', fontSize: '12px' }}>{good.starting}-{good.ending}</TableCell>
                                                    <TableCell sx={{ fontSize: '12px' }}>{good?.type}</TableCell>
                                                    <TableCell sx={{ fontSize: '12px' }}><b>{good?.qty}</b> m3</TableCell>
                                                </TableRow>
                                            })
                                            : ""
                                        : ''
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                </Box>

                {/* showing the bill amount here */}
                <Stack direction="row" spacing={1} sx={{ position: 'fixed', bottom: '30px', right: '30px', zIndex: 1, backgroundColor: 'white' }}>
                    <Box component="section" sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', zIndex: 1, backgroundColor: 'var(--Tertiary_Blue)', position: "relative", width: 'fit-content' }}>
                        <Typography variant='body2'>Bill Amount</Typography>
                        {
                            isOverdue?.isOverdue || !isOverdue.paidBefore ?
                                <Typography variant='caption'>
                                    ₹{billData?.amount.toFixed(2)}
                                    &nbsp;+&nbsp;
                                    <span style={{ color: "red" }}>
                                        ₹{isOverdue?.interestAmount.toFixed(2)}
                                    </span>
                                </Typography> : ''
                        }
                        <Typography variant='h6'>₹{isOverdue?.newAmount.toFixed(2)}</Typography>
                    </Box>

                    {/* interest rate box */}
                    <Box component="section" sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', zIndex: 1, backgroundColor: 'var(--Tertiary_Blue)', position: "relative", width: 'fit-content' }}>
                        <Typography variant='body2'>Interest Rate</Typography>
                        <Typography variant='h6'>{billData?.requiredFields?.interestRate}%</Typography>
                    </Box>

                    {/* box for the last date of the bill  */}
                    <Box component="section" sx={{ p: 2, border: '1px dashed lightgrey', margin: '20px', borderRadius: '12px', zIndex: 1, backgroundColor: isOverdue.isOverdue ? "#ffebeb" : 'var(--Tertiary_Blue)', position: "relative", width: 'fit-content' }}>
                        {isOverdue.isOverdue ? <Typography variant='caption' color="red">Bill is Overdue</Typography> : ''}
                        <Typography variant='body2'>Last Date</Typography>
                        <Typography variant='h6'>{convertGMTtoISTAndFormat(billData?.requiredFields?.lastDate)}</Typography>
                    </Box>
                </Stack>
            </Box>
        }
    </Stack>

}