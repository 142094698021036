import { Box } from '@mui/material'
import React from 'react'

export default function CCTV() {
    return (
        <div>
            <div className="PAGE_HEADER">
                {/* Name of the page, will show on the left of the header */}
                <h3>CCTV Footage</h3>

                {/* create any buttons or other things here. it will show on the right side of the page header */}
            
            </div>

            {/* this div will contain all the content of the page */}
            <div className="Industries_Body_Container">
                <Box p={3}>
                    CCTV link not provided
                </Box>
            </div>
        </div>
    )
}