import './Drawer.css'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActive } from "../../Redux/Slices/drawerSlice";
import { DrawerRoutes, IndustrySideRoutes } from "../Routes/routes";

import Link_Container_Dropdown from "./Link_Container_Dropdown";
import UserDetailsDrawer from "./UserDetailsDrawer";
import { CCTVIcon, CPCBIcon, DashboardIcon, IndustryIcon, InventoryIcon, MyTeamIcon, NotificationIcon, ProcessIcon, ReportsIcon } from "../../assets/assets";
import { DRAWER_PERMISSIONS } from "../../Roles/Roles";
import { status } from "../../Redux/Slices/userSlice";
import { AccountTree, AccountTreeOutlined, AssignmentOutlined, Dashboard, DashboardOutlined, Factory, FactoryOutlined, Groups2Outlined, Inventory, InventoryOutlined, LocalPoliceOutlined, Logout, ManageHistoryOutlined, NotificationsActive, NotificationsActiveOutlined, Policy, ReceiptLongRounded, ReceiptOutlined, Report, Settings, SolarPowerOutlined, VideocamOutlined, WaterDropOutlined } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { SomethingWentWrong, UniversalLoader } from '../UI Components/Basic';
import { signOut } from 'firebase/auth';
import { FirebaseAuthVariable } from '../../Firebase/firebase';
import { showTicker } from '../../Redux/Slices/TickerSlice';
import { AuthenticationRoutes } from '../Routes/routes';
import { ConfirmationPopup } from '../UI Components/Basic';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';



export default function Drawer() {

    const user = useSelector(state => state.USER_DETAILS)
    const Loading = useSelector(state => state.USER_DETAILS.Loading)
    const DrawerPermissions = DRAWER_PERMISSIONS(user.UserRole, user.UserAccessLevel, user.UserDetails?.departmentAccess)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setActive(getCurrentSection()))
    }, [])



    return (
        Loading === status.IDLE ? <div className="Drawer_wrapper">
            <div className="Drawer_User_Details">
                <UserDetailsDrawer />
            </div>
            <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.452)' }} />

            {DrawerPermissions.isAllowedDashboard ? <Link_Container_Box icon={<DashboardOutlined fontSize='small' />} route={DrawerRoutes.Dashboard} Name={"Dashboard"} /> : ''}
            {DrawerPermissions?.isAllowedIndustrySection ? <Link_Container_Box icon={<FactoryOutlined fontSize='small' />} route={DrawerRoutes.Industry.Industries} Name={"Industries"} /> : ' '}
            {DrawerPermissions.isAllowedBills ? <Link_Container_Box icon={<ReceiptLongRounded fontSize='small' />} route={DrawerRoutes.Reports.Financial_Reports} Name={"Bills & Payments"} /> : ''}
            {DrawerPermissions.isAllowedNotices ? <Link_Container_Box icon={<NotificationsActiveOutlined fontSize='small' />} route={DrawerRoutes.Notification} Name={"Notifications/Notice"} /> : ''}
            {DrawerPermissions.isAllowedInventory ? <Link_Container_Box icon={<InventoryOutlined fontSize='small' />} route={DrawerRoutes.Inventory.consumables} Name={"Inventory"} /> : ''}
            {DrawerPermissions.isAllowedSludge ? <Link_Container_Box icon={<ManageHistoryOutlined fontSize='small' />} route={DrawerRoutes.Reports.Sludge_Management} Name={"Sludge Managements"} /> : ''}
            {DrawerPermissions.isAllowedProcess ? <Link_Container_Box icon={<AccountTreeOutlined fontSize='small' />} route={DrawerRoutes.Process} Name={"Process Management"} /> : ''}

            {
                DrawerPermissions.isAllowedReports ? <Link_Container_Dropdown
                    SelectorName={"Reports"}
                    SelectorIcon={<AssignmentOutlined fontSize='small' />}
                    selections={
                        [
                            {
                                icon: <WaterDropOutlined fontSize='small' />,
                                route: DrawerRoutes.Reports.Industry_Effluent,
                                Name: "Industry Effluent",
                            },
                            {
                                icon: <InventoryOutlined fontSize='small' />,
                                route: DrawerRoutes.Reports.Inventory_Reports,
                                Name: "Inventory",
                            },
                            {
                                icon: <SolarPowerOutlined fontSize='small' />,
                                route: DrawerRoutes.Reports.Power_Reports,
                                Name: "Power",
                            },
                        ]
                    }
                /> : ''
            }


            {/* {DrawerPermissions.isAllowedPcb ? <Link_Container_Box icon={<LocalPoliceOutlined fontSize='small' />} route={DrawerRoutes.CPCB} Name={"CPCB/PPCB"} /> : ''} */}

            {DrawerPermissions.isAllowedCCTV ? <Link_Container_Box icon={<VideocamOutlined fontSize='small' />} route={DrawerRoutes.CCTV} Name={"CCTV Footage"} /> : ''}
            {DrawerPermissions.isAllowedTeam ? <Link_Container_Box icon={<Groups2Outlined fontSize='small' />} route={DrawerRoutes.Team} Name={"My Team"} /> : ''}
            {DrawerPermissions.isAllowedIndustrySideBillsSection ? <Link_Container_Box icon={<ReceiptOutlined fontSize='small' />} route={IndustrySideRoutes.IndustryBills} Name={"Bills & Payments"} /> : ''}



            {
                <Link_Container_Dropdown
                    SelectorName={"Settings"}
                    SelectorIcon={<Settings fontSize='small' />}
                    selections={
                        [
                            {
                                icon: <Policy fontSize='small' />,
                                route: DrawerRoutes.Reports.Industry_Effluent,
                                Name: "Privacy policy",
                                onclickAction: "open-link",
                                link: "https://docs.google.com/document/d/1Z6mlvDcRIdmHWf9zDIRYp8fG1MWvS6BaY5TlhZjEtXg/edit?usp=sharing"
                            },
                            {
                                icon: <Logout fontSize='small' />,
                                route: "none",
                                Name: "Logout",
                                onclickAction: "logout",
                            },
                        ]
                    }
                />
            }
        </div> : Loading === status.LOADING ? <UniversalLoader /> :
            Loading === status.ERROR ? <SomethingWentWrong /> : ''
    )
}

export function Link_Container_Box(props) {
    const dispatch = useDispatch();
    const Active = useSelector(st => st.ACTIVE_SECTION)
    const [Loading, setLoading] = useState(false)
    const [isDelete, setisDelete] = useState(false)
    const navigate = useNavigate()

    // handling the action of the button on basis of what type of action it is. 
    const OnclickAction = (action) => {
        if (action === "logout") {
            setisDelete(true)
        }
        else if (action === "open-link") {
            window.open(props.link, "_blank")
        }
    }

    console.log(Active);
    console.log(props.route);


    // function for logging out from the current acount
    const LOGGIN_OUT = () => {
        setLoading(true)
        signOut(FirebaseAuthVariable).then((res) => {
            setLoading(false)
            dispatch({ type: 'user/logout' }); // resetting the redux store for the new user
            localStorage.setItem('superAdminSelected_PlantID', null); // setting the superAdminSelected_PlantID to becuase if that loggedout user was a superadmin, then this var must be plant id of that plant which superadmin is watching. so after logging out of the superadmin we have to reset it for no errors or bugs.
            dispatch(showTicker({
                message: 'Logged Out Successfully',
                type: 'success'
            }))
            navigate(AuthenticationRoutes.UserSelection) //NAvigating to the login page
        }).catch((err) => {
            setLoading(false)
            dispatch(showTicker({
                message: err.message,
                type: 'error'
            }))
        })
    }


    return <div>
        {
            props.action === undefined ? <Link to={props.route} style={{ textDecoration: 'none' }}>
                <div
                    className={Active === props.route ? "Link_Container_Box active" : "Link_Container_Box"}
                    onClick={() => {
                        dispatch(setActive(getCurrentSection()))
                        OnclickAction(props.action)
                    }}>
                    {props.icon}&nbsp;&nbsp;&nbsp;&nbsp;{props.Name}
                </div>
            </Link> :
                <div style={{ textDecoration: 'none' }}>
                    <div
                        className={Active === props.route ? "Link_Container_Box active" : "Link_Container_Box"}
                        onClick={() => {
                            dispatch(setActive(getCurrentSection()))
                            OnclickAction(props.action)
                        }}>
                        {props.icon}&nbsp;&nbsp;&nbsp;&nbsp;{props.Name}
                    </div>
                </div>
        }


        {isDelete ? <ConfirmationPopup
            PrimaryAction={LOGGIN_OUT}
            PrimaryButtonText={"Logout"}
            SecondaryAction={() => { setisDelete(false) }}
            message={`Are you sure you want to Logout`}
            Loading={Loading}
        /> : ''}
    </div>
}



export const getCurrentSection = () => {
    const currentPath = window.location.pathname;

    // Check if the current path matches any of the routes in DrawerRoutes
    for (const section in DrawerRoutes) {
        if (typeof DrawerRoutes[section] === 'string' && DrawerRoutes[section] === currentPath) {
            // If the current path matches a top-level route, return the section
            return section;
        } else if (typeof DrawerRoutes[section] === 'object') {
            // If the section contains nested routes, check if the current path matches any nested route
            for (const nestedRoute in DrawerRoutes[section]) {
                if (DrawerRoutes[section][nestedRoute] === currentPath) {
                    return section;
                }
            }
        }
    }

    // If no match found, return null
    return null;
};