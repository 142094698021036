import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { status } from "../../Redux/Slices/userSlice";
import { CircularLoader, SomethingWentWrong } from "../UI Components/Basic";

const columns = [
  {
    field: "id",
    headerName: "S No.",
    type: "number",
    width: 100,
  },
  { field: "location", headerName: "Location", width: 250 },
  { field: "tagNo", headerName: "Tag No", width: 100 },
  { field: "type", headerName: "Type", width: 170 },
  { field: "fluid", headerName: "Fluid", width: 130 },
  {
    field: "readings", // Corrected the field name here
    headerName: "Readings",
    width: 250,
    sortable: false,
  },
];

export default function TableData() {
  const FilteredInstruments = useSelector(state => state.PROCESS_MANAGEMENT.FilteredInstruments)
  const FILTER_LOADING = useSelector(state => state.PROCESS_MANAGEMENT.Loading)

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {FILTER_LOADING === status.IDLE ?
        <DataGrid
          rows={FilteredInstruments?.data?.map((instrument, i) => {
            return (
              {
                id: i+1,
                location: instrument.Location,
                tagNo: instrument.TagNo,
                type: instrument.TypeOfInstorWorkingPrinciple ? instrument.TypeOfInstorWorkingPrinciple : "-",
                fluid: instrument.Fluid ? instrument.Fluid : "-",
                readings: "-"
              }
            )
          })}
          columns={columns}
          pageSize={5} 
        /> : FILTER_LOADING === status.LOADING ? < CircularLoader />
        : FILTER_LOADING === status.ERROR ? <SomethingWentWrong /> : ''
      }
    </div>
  );
}
