import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { status } from "./userSlice";
import { showTicker } from "./TickerSlice";
import { SERVER_ADDRESS } from "../../Firebase/Variables";

export const GetPlantDetails = createAsyncThunk('Getplantdetails', async ({ AuthToken, plantID }, { dispatch, rejectWithValue, fulfillWithValue }) => {

    console.log(AuthToken, plantID);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${SERVER_ADDRESS}/public/plant?plantid=${plantID}`, requestOptions)
        if (!response.ok) {
            dispatch(showTicker({
                message: response.statusText,
                type: 'error'
            }))
            return rejectWithValue(response.status)
        }
        const data = await response.json();
        return fulfillWithValue(data)
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        throw rejectWithValue(error.message)
    }
})


const PlantDetailsSlice = createSlice({
    name: 'Plant Details',
    initialState: {
        Loading: status.LOADING,
        Details: []
    },
    extraReducers: {
        [GetPlantDetails.pending]: (state) => {
            state.Loading = status.LOADING;
        },
        [GetPlantDetails.fulfilled]: (state, action) => {
            state.Details = action.payload;
            state.Loading = status.IDLE;
        },
        [GetPlantDetails.rejected]: (state) => {
            state.Loading = status.ERROR
        }
    }
})

export default PlantDetailsSlice.reducer;
