import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, Autocomplete, TextField } from "@mui/material";
import "./Style.css";
import { useOutletContext } from "react-router-dom";

const IndustryEfluentDetails = () => {
  // State for Autocomplete value
  // const [selectedMonth, setSelectedMonth] = useState(null);
  const { IndustryData, LoadingData } = useOutletContext()
  // console.log(IndustryData);

  // // State for storing entered data
  // const [enteredData, setEnteredData] = useState({
  //   itemType: "", // Initialize with an empty string or any default value
  // });

  // Array of month names for Autocomplete options
  // const months = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];

  //function for returning the value of flow available IN THE INDUSTRY DETAILS. 
  const ShowFlowData = () => {
    if (!!IndustryData?.data?.latestFlowData && !!IndustryData?.data?.latestFlowData !== undefined) {
      if (IndustryData?.data?.latestFlowData?.flow === null) {
        return <Typography variant="h6">{IndustryData?.data?.latestFlowData?.message}</Typography>
      }
      else if (IndustryData?.data?.latestFlowData?.flow !== null) {
        return <Typography variant="h4">{IndustryData?.data?.latestFlowData?.flow?.toFixed(2)}<Typography variant="body2">meter cube</Typography></Typography>
      }
      else {
        return "NA"
      }
    }
    else { return "NA" }
  }

  return (
    <div>
      <br />
      <Typography variant="body2"><span style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Complete flow data is unavailable from <b>Vision Worldtech Company</b>.</span></Typography>

      {/* Cards section */}
      <div className="card_container">
        {/* Card 1 */}
        <Card
          variant="outlined"
          style={{ padding: "20px", backgroundColor: "#F7F9FB", width: "100%" }}
          disableElevation
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Inlet Quantity
            </Typography>
            {ShowFlowData()}
          </CardContent>
        </Card>

        {/* Card 2 - Card 5 (pH, BOD, COD, TDS) */}
        {[...Array(4)].map((_, index) => (
          <Card
            key={index}
            variant="outlined"
            style={{
              padding: "20px",
              backgroundColor: "#F7F9FB",
              minWidth: "150px",
            }}
            disableElevation
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {/* Placeholder for dynamic card titles */}
                {index === 1
                  ? "pH"
                  : index === 2
                    ? "BOD"
                    : index === 3
                      ? "COD"
                      : "TDS"}
              </Typography>
              <Typography variant="h4">NA</Typography>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Water consumption details section */}
      {/* <div
        className="table_Heading_Container"
        style={{ justifyContent: "space-between" }}
      >
        <Typography variant="subtitle2">Water Consumption Details</Typography>
        <div className="table_toolbar">
          <Button disableElevation>Check Effluent Report</Button>
          <Button variant="contained" color="PrimaryBlue" disableElevation>
            Send Notice
          </Button>
        </div>
      </div> */}

      {/* Autocomplete for selecting months */}
      {/* <div
        className="table_Heading_Container"
        style={{ justifyContent: "space-between", border: "none" }}
      >
        <Autocomplete
          size="small"
          value={selectedMonth}
          onChange={(event, newValue) => {
            setSelectedMonth(newValue);
            setEnteredData({ ...enteredData, itemType: newValue });
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ width: "200px" }}
          renderInput={(params) => (
            <TextField {...params} label="Select month" />
          )}
        />
        <div className="table_toolbar">
          <Button disableElevation>Check Consumption Reports</Button>
        </div>
      </div> */}

      {/* Additional card section */}
      {/* <div className="card_container"> */}
      {/* Card 1 */}
      {/* <Card
          variant="outlined"
          style={{
            padding: "20px",
            backgroundColor: "#F7F9FB",
            minWidth: "300px",
          }}
          disableElevation
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              This month's
            </Typography>
            <Typography variant="h4">25,00,000</Typography>
          </CardContent>
        </Card> */}

      {/* Card 2 */}
      {/* <Card
          variant="outlined"
          style={{ padding: "20px", backgroundColor: "#F7F9FB", width: "100%" }}
          disableElevation
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Bill
            </Typography>
            <Typography variant="h4">25,00,000</Typography>
          </CardContent>
        </Card>
      </div> */}
    </div>
  );
};

export default IndustryEfluentDetails;
