import { Box, Divider, Paper, Stack, Switch, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteIndustryRedux } from '../../../Redux/Slices/Industry/GetIndustriesSlice'
import { Delete } from '@mui/icons-material'
import { ConfirmationPopup } from '../../UI Components/Basic'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { DeleteIndustry } from '../../../Redux/Slices/Industry/DeleteIndustrySlice'

export default function IndustrySettings() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [Loading, setLoading] = useState(false) // for deleting loafing
    const [isDelete, setisDelete] = useState(false) // for showing popup
    const { IndustryData, LoadingData } = useOutletContext()
    const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)
    const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
    console.log(IndustryData);

    const HandleDelete = () => {
        dispatch(DeleteIndustry({
            AuthToken: AuthToken,
            IndustryID: IndustryData?.id,
            PlantID: PlantID,
            setLoading: setLoading,
            setisDelete: setisDelete,
            navigate: navigate
        }))
    }

    return (
        <>
            <Box p={2}>
                <Paper elevation={1}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" height="60px" p={2}>
                        <Typography>Suspend Industry</Typography>
                        <Switch disabled />
                    </Stack>
                </Paper>
                <Divider />
                <Paper elevation={1}>
                    <Stack direction="row" mt={1} alignItems="center" justifyContent="flex-start" height="60px" p={2} onClick={() => { setisDelete(true) }}>
                        <Delete fontSize='small' />&nbsp;&nbsp;&nbsp;
                        <Typography>Delete Industry</Typography>
                    </Stack>
                </Paper>
            </Box>

            {
                isDelete ? <ConfirmationPopup
                    PrimaryAction={HandleDelete}
                    PrimaryButtonText={"Delete"}
                    SecondaryAction={() => { setisDelete(false) }}
                    message={`Are you sure you want to delete the ${IndustryData?.data?.companyName} permanently? Permanently deleting this industry will remove all of its details, bills, notices and other things. Are you sure you want to continue?`}
                    Loading={Loading}
                    color={"PrimaryRed"}
                /> : ''
            }
        </>
    )
}
