import { React, useEffect, useState } from "react";
import { Cancel } from "@mui/icons-material";
import { TextField, Button, Autocomplete, Stack, IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddChamberData, GetTagNos, AddIO } from '../../../Redux/Slices/ProcessManagementSlices/ChamberManagementSlice';
import { showTicker } from "../../../Redux/Slices/TickerSlice";
import { LoadingButton } from "@mui/lab";
import { status } from "../../../Redux/Slices/userSlice";

const AddChamber = (props) => {
  const dispatch = useDispatch();
  const UserToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const TagNosList = useSelector(state => state.CHAMBER_MANAGEMENT.TagNosList)
  const [ApiBodyArray, setApiBodyArray] = useState({  // Api required feilds
    name: '',
    params: []
  })
  const [newParam, setNewParam] = useState({key: "", value: ""})
  const CHAMBER_LOADING = useSelector(state => state.CHAMBER_MANAGEMENT.AddLoading)
  const GET_CHAMBER_LOADING = useSelector(state => state.CHAMBER_MANAGEMENT.GetChamberLoading)
  const [autocompleteKeyInput, setAutocompleteInput] = useState('');

  const handleParamAddition = () => {
    // validate newParam key
    if(newParam.key === ""){
      dispatch(showTicker({
        message: "Key can`t be empty",
        type: 'error'
      }))
      return
    }
    
    // validate newParam value
    if(newParam.value === ""){
      dispatch(showTicker({
        message: "Value can`t be empty",
        type: 'error'
      }))
      return
    } else if(!TagNosList.data.includes(newParam.value)){
      dispatch(showTicker({
        message: `TagNo ${newParam.value} not found`,
        type: 'error'
      }))
      return
    }

    // add newParam to params
    setApiBodyArray({
      ...ApiBodyArray,
      params: [...ApiBodyArray.params, newParam]
    })

    // clear newParam
    setNewParam({key: "", value: ""})

    // clear the autocomplete field
    setAutocompleteInput('');
  }

  const handleParamDelete = (index) => {
    // remove param from params
    setApiBodyArray({
      ...ApiBodyArray,
      params: ApiBodyArray.params.filter((param, i) => i !== index)
    })
  }

  const handleAddChamber = () => {
    // validate name
    if(ApiBodyArray.name === ""){
      dispatch(showTicker({
        message: `${props.data.type} Name can't be empty`,
        type: 'error'
      }))
      return
    }

    // dispatch AddChamber
    if(props.data.type === "Chamber"){
      dispatch(AddChamberData({ chamber: ApiBodyArray, UserToken, PlantID }))
    } else {
      dispatch(AddIO({
        io: ApiBodyArray,
        chamberid: props.data.chamberid,
        type: props.data.type === "Inlet" ? 1 : 2,
        UserToken,
        PlantID,
      }))
    }
  }

  useEffect(() => {
    dispatch(GetTagNos({ UserToken, PlantID }))
  }, [PlantID, UserToken, dispatch])

  useEffect(() => {
    if(GET_CHAMBER_LOADING === status.LOADING){
      props.setActiveModal({ ActiveModalIndex: 0 })
    }
  }, [GET_CHAMBER_LOADING, props])

  return (
    <div className="Modal_Black_bg">
      <div className='Modal_base' style={{ border: "none" }}>

        {/* Modal Heading */}
        <div className="Modal_heading" style={{ borderRadius: "10px 10px 0 0" }} >
          Add {props.data.type}
          <IconButton color="PrimaryBlue" onClick={() => { props.setActiveModal(0) }}>
            <Cancel />
          </IconButton>
        </div>

        {/* Modal Body */}
        <div style={{ animation: 'fade-up 1s cubic-bezier(0.19, 1, 0.22, 1)' }}>
          <div style={{ padding: '15px' }}>
            <Stack spacing={1}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                placeholder={`${props.data.type} Name`}
                label={`${props.data.type} Name`}
                value={ApiBodyArray.name}
                autoFocus
                onChange={(e) => { setApiBodyArray({ ...ApiBodyArray, name: e.target.value }) }}
              />
              <Stack direction="row" spacing={1}>
                <TextField
                  sx={{ width: '40%' }}
                  size="small"
                  placeholder="Parameter Name"
                  label="Parameter Name"
                  value={newParam.key}
                  autoFocus
                  onChange={(e) => { setNewParam({ ...newParam, key: e.target.value }) }}
                  disabled={ApiBodyArray.name.length === 0}
                />

                <Autocomplete
                  freeSolo
                  disableClearable
                  sx={{ width: "40%" }}
                  options={TagNosList.data.map((option) => option)}
                  disabled={ApiBodyArray.name.length === 0}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select TagNo"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  )}
                  inputValue={autocompleteKeyInput} 
                  onInputChange={(event, newValue) => {
                    setAutocompleteInput(newValue);
                    setNewParam({ ...newParam, value: newValue });
                  }}
                />

                <Button
                  variant="outlined"
                  size="large"
                  sx={{ width: "20%", fontSize: 'small' }}
                  startIcon={<AddCircleIcon style={{ width: "20px" }} />}
                  onClick={handleParamAddition}
                  disableElevation
                  disabled={newParam.key === "" || newParam.value === ""}
                >
                  Add
                </Button>

              </Stack>

              <TableContainer sx={{ maxHeight: 300 }}>
                <Table sx={{ '& .MuiTableCell-root': { textAlign: 'center', fontWeight: "500", fontSize: "medium" } }}>
                  <TableBody>
                    {
                      ApiBodyArray.params.map((param, index) => (
                        <TableRow key={index}>
                          <TableCell>{param.key}</TableCell>
                          <TableCell>{param.value}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="error"
                              sx={{ height: "2rem", fontWeight: "700" }}
                              size="small"
                              disableElevation
                              onClick={() => handleParamDelete(index)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>

              </TableContainer>

            </Stack> 

          </div>
            
          {/* Modal Footer */}
          <Stack
            direction="row" 
            justifyContent="end" 
            sx={{ padding: "15px" }}
          >
            <LoadingButton
              loading={CHAMBER_LOADING === status.LOADING}
              loadingPosition="start"
              variant="contained"
              size="large"
              color="PrimaryBlue"
              disableElevation
              onClick={handleAddChamber}
              disabled={ApiBodyArray.name.length === 0}
              sx={{ width: "170px" }}
            >
              Add {props.data.type}
            </LoadingButton>

          </Stack>
        </div>
      </div>
    </div>
  )
}

export default AddChamber