import React, { useEffect } from 'react'
import Drawer, { getCurrentSection } from './Drawer Components/Drawer'
import './Main_Layout.css'
import '../App.css'
import AddressBar from './AddressBar/AddressBar'
import { Outlet } from "react-router-dom";
import { FirebaseAuthVariable } from '../Firebase/firebase'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateOtherDetails, UpdatePlantIDincaseofSuperAdmin, UpdateUserDetails, status, toggleLoading } from '../Redux/Slices/userSlice'
import { getToken } from "firebase/messaging";
import { messaging, onMessageListener } from "../Firebase/firebase";
import { SERVER_ADDRESS } from '../Firebase/Variables'
import { GetIndustryByID } from '../Redux/Slices/Industry/GetIndustriesSlice'
import { GetPlantDetails } from '../Redux/Slices/PlantDetailsSlice'
import { showTicker } from '../Redux/Slices/TickerSlice'
import { setActive } from '../Redux/Slices/drawerSlice'


// this component contains complete dashboard including drawer, addressbar. THis is the parent for our all data in the application.
export default function Main_Layout({ child }) {

    const dispatch = useDispatch() // For dispatching actions
    const PlantDetails = useSelector(state => state.PLANT_DETAILS.Details?.plant)
    const PlantDetailsLoading = useSelector(state => state.PLANT_DETAILS.Loading)
    const UserDetails = useSelector(state => state.USER_DETAILS)
    const storedPlantID = localStorage.getItem('superAdminSelected_PlantID'); // this is for checking that if the superadmin is already selected a plant, then it is not important to ask him for a plant selection.


    // getting plant details. only when the data is not avilable in the redux store or loading is idle. 
    const fetchPlantDetails = (AuthToken, PlantID) => {
        if (AuthToken !== undefined && PlantID !== undefined) {
            if (!!PlantDetails === false && PlantDetailsLoading !== status.ERROR) {
                dispatch(GetPlantDetails({
                    AuthToken: AuthToken,
                    plantID: PlantID?.toUpperCase()
                }))
            }
        }
        else {
            dispatch(showTicker({
                message: 'Something went wrong. Please refresh the page',
                type: 'error'
            }))
        }
    }

    // function for getting data of the industry and saving to the redux store. 
    async function getIndDetails(id, token) {
        await dispatch(GetIndustryByID({
            AuthToken: token,
            IndustryID: id
        })).then((data) => {
            // console.log(data);
            if (data.meta.requestStatus === "fulfilled") {
                dispatch(UpdateOtherDetails(data.payload?.industries[0]?.data))
            }
        })
    }


    // function for updating the fcm token of the user 
    const UpdateFCMToken = async (UserToken, fcm_token) => {
        var raw = JSON.stringify({
            "fcm_token": fcm_token
        });

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${UserToken}`);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(`${SERVER_ADDRESS}/user/update/fcm_token`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                //  console.log(result);
            })
            .catch(error => {
                // console.log(error);
            });
    }

    async function requestPermission(UserToken) {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            // Generate Token
            const token = await getToken(messaging, {
                vapidKey: "BD1SuWXr6UILGc36nLV7JozZ8zec8X0j7Y_vpjjOxO1esI2pO595fVUobk044VtmXMGKEMuIK5bpFO7poH3eYvI",
            });
            // Send this token  to server 
            await UpdateFCMToken(UserToken, token)

        } else if (permission === "denied") {
            alert("Please allow notifications");
        }
    }


    // Function for checking the Details of the user, From where user belongs to. This function is here in MainLayout.js because main layout is our dashboard. When the dashboard render it must knows the user and according to user details it renders information
    const CheckUserClaims = async () => {
        //checking user claims here and updating them to the redux store. Based on user claims, dashboard will reder accordingly. ;
        FirebaseAuthVariable.currentUser.getIdTokenResult()
            .then((idTokenResult) => {
                //Storing the user level and role in the redux store..these details are the details which you get for any of the user. other details are user specific that are stored in the userDetails array in the redux. 
                dispatch(UpdateUserDetails({
                    UID: idTokenResult.claims.user_id,
                    AuthToken: idTokenResult.token,
                    UserRole: idTokenResult.claims.role, //PlantSide or IndustrySide
                    UserAccessLevel: idTokenResult.claims.accessLevel,
                    UserEmail: idTokenResult.claims.email,
                    DocID: idTokenResult.claims.industryid,
                    PlantID: idTokenResult.claims.plantID,
                }))


                requestPermission(idTokenResult.token) // Requesting permission from the user for notification access.
                // console.log(idTokenResult);

                // console.log(idTokenResult);
                // fetching user other details based on user role. this is becuase endpoints for fetching detail s of users in plant and indusrty are different.
                // condition if the logged in user is from plant side, then fetch the get user endpoint for getting the user details. 
                if (idTokenResult.claims.role !== 'industry') {
                    var myHeaders = new Headers();
                    myHeaders.append("Authorization", `Bearer ${idTokenResult.token}`);

                    var requestOptions = {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow'
                    };
                    fetch(`${SERVER_ADDRESS}/user/${idTokenResult.claims.user_id}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {

                            //checking if the user is a superadmin, because in case of superadmin we dont get plant id in claims. in case of superadmin er stored plant id in local and storing plant id from local to redux. 
                            if (idTokenResult.claims.accessLevel === 0) {
                                dispatch(UpdatePlantIDincaseofSuperAdmin(storedPlantID))
                                fetchPlantDetails(idTokenResult.token, storedPlantID) // storing the plant details in the redux store
                            } // fetching the plantid from the local storage and sotring itto the redux for handling all the operations. in case of super admin. 
                            else {
                                fetchPlantDetails(idTokenResult.token, idTokenResult?.claims?.plantID) // storing the plant details in the redux store
                            }

                            dispatch(UpdateOtherDetails(result.user)) // updating the user details in the redux store
                            dispatch(toggleLoading(status.IDLE)) // closing the loading 

                        })
                        .catch(error => {
                            // console.log(error);
                            dispatch(toggleLoading(status.IDLE))
                        });
                }
                //condittion for if the user is from the industry side, then fetch the industry side endpoint for fetching details of the industry. 
                else if (idTokenResult.claims.role === 'industry') {
                    getIndDetails(idTokenResult.claims.industryid, idTokenResult.token)
                    dispatch(toggleLoading(status.IDLE))
                }
            })


            // in case of any errors, do not update data and make all the feilds to its initial state. 
            .catch((error) => {
                // setting user to null if any error will come
                dispatch(UpdateUserDetails({
                    UID: null,
                    AuthToken: null,
                    UserRole: null,
                    UserAccessLevel: null,
                    UserEmail: null,
                    DocID: null,
                    PlantID: null
                }))
                dispatch(toggleLoading(status.IDLE))
            });
    }

    // Important functions to be run for user validation 
    useEffect(() => {

        // CheckuserCLaims important function for the validation of the user, It check the user is a plant side user or industry side user, if plant side what is the access level of the user and other important things like userRole, access to that user. Hence this function is important for loading of the application, without this function application will not load. 
        CheckUserClaims()

        //some other functions here
    }, [])

    onMessageListener()
        .then((payload) => {
            // Check if the Notification API is available
            if (Notification.permission === 'granted') {
                // Display a notification
                const notificationTitle = payload.notification.title;
                const notificationOptions = {
                    body: payload.notification.body,
                    icon: payload.notification.image,
                };

                new Notification(notificationTitle, notificationOptions);
            }
        })
        .catch((err) => console.log('failed: ', err));


    return (
        <>
            <div className="Main_Layout_Wrapper">
                <div className="Drawer_Container">
                    <Drawer />
                </div>
                <div style={{ backgroundColor: "#F7F9FB" }}>
                    {/* showing address for every page here */}
                    <div className="Address_Bar_Container">
                        <AddressBar />
                    </div>

                    {/* outlet for pages */}
                    <div className="Content_Body">
                        <Outlet />
                    </div>
                </div>
            </div >
        </>
    )
}
