import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { status } from '../../Redux/Slices/userSlice';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { Cameraswitch } from '@mui/icons-material';
import { AuthenticationRoutes, DrawerRoutes, IndustrySideRoutes } from '../Routes/routes';
import { useNavigate } from 'react-router-dom';

export default function UserDetailsDrawer() {
  const UserRole = useSelector(state => state.USER_DETAILS.UserRole)
  const UserEmail = useSelector(state => state.USER_DETAILS.UserEmail)
  const PlantName = useSelector(state => state.PLANT_DETAILS.Details?.plant?.plantName)
  const PlantDetailsLoading = useSelector(state => state.PLANT_DETAILS.Loading)
  const User = useSelector(state => state.USER_DETAILS)
  const [Loading, setLoading] = useState(false)
  const [isDelete, setisDelete] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // setting name of the user
  const [Name, setName] = useState("Loading")
  useEffect(() => {
    const temp = () => {
      if (User.Loading === status.IDLE && PlantDetailsLoading === status.IDLE && User.UserRole !== "industry") {
        setName(PlantName)
      }
      else if (User.Loading === status.IDLE && User.UserDetails !== undefined && User.UserRole === "industry") {
        setName(User?.UserDetails?.companyName)
      }

      else if (User.Loading === status.ERROR || PlantDetailsLoading === status.ERROR || User.UserDetails === undefined) {
        setName("Error")
      }
      else {
        setName("Loading")
      }
    }
    temp()
  }, [PlantDetailsLoading, User])


  const HandleProfileClick = () => {
    if (UserRole === "industry") {
      navigate(IndustrySideRoutes.IndustryProfile)
    }
    else {
      navigate(DrawerRoutes.PlantProfile)
    }
  }

  const UserChipColor = () => {
    let comp = UserRole?.toLowerCase()?.replace(/\s/g, "");
    if (comp === "superadmin") { return "PrimaryRed" }
    else if (comp === "admin") { return "warning" }
    else if (comp === "officer" || comp === "operator") { return "PrimaryBlue" }
    else if (comp === "industry") { return "success" }
    else { return "PrimaryBlue" }
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <Stack direction="column" width="100%" justifyContent="space-between" spacing={1}>

          <Chip label={UserRole?.toUpperCase()} variant='contained' size='small' color={UserChipColor()} sx={{ width: 'fit-content' }} />

          <Stack direction="column" spacing={-0.5} onClick={HandleProfileClick} sx={{ cursor: 'pointer' }}>
            <Typography variant='body1'>
              {`${Name?.slice(0, 20)}...`}
            </Typography>
            <Typography variant='caption'>{UserEmail}</Typography>
          </Stack>

          {
            User?.UserAccessLevel === 0 ? <Button
              variant='contained'
              color='SecondaryBtn'
              size='small'
              sx={{ width: 'fit-content', padding: '10px 20px' }}
              disableElevation
              startIcon={<Cameraswitch />}
              onClick={() => { navigate(AuthenticationRoutes.PlantSelectionSuperAdmin) }}
            >Switch Plants</Button> : ''
          }

        </Stack>
      </div>
    </>
  )
}
