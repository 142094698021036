import { Typography, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Divider } from "@mui/material";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { convertGMTtoISTAndFormat } from "../../ReportsComponents/BillsAndPayments/DedicatedIndustryBill";

export default function AboutIndustry() {
  const { IndustryData, LoadingData } = useOutletContext()
  return (
    <div style={{ padding: "20px", position: "relative", }}>

      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "transparent" }} >
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="subtitle1">Company Details</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Industry name</TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.companyName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pin Code </TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.pincode !== "" ? IndustryData?.data?.pincode : "Not available"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>E-mail Address</TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone Number</TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.phoneNo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Industry Type</TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.cetp_stp_etp_type !== "" ? IndustryData?.data?.cetp_stp_etp_type?.toUpperCase() : 'Not available'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Industry Added on </TableCell>
              <TableCell>{LoadingData ? "Loading..." : convertGMTtoISTAndFormat(IndustryData?.data?.dateAdded)}</TableCell>
            </TableRow>

            <Divider />

            <TableRow>
              <TableCell>Consent Validity </TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.consentValidity !== "" ? IndustryData?.data?.consentValidity : 'Not available'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>IC Chamber Installed ? </TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.IC_chamber_install ? "Installed" : 'Not Installed'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>H/N Type </TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.h_n_type !== "" ? IndustryData?.data?.h_n_type : 'Not available'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phase </TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.phase !== "" ? IndustryData?.data?.phase : 'Not available'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>P. Number</TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.pno !== "" ? IndustryData?.data?.pno : 'Not available'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Effluent Trade and Utility</TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.totalEffluentTradeAndUtility !== "" ? IndustryData?.data?.totalEffluentTradeAndUtility : 'Not available'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Unit ID</TableCell>
              <TableCell>{LoadingData ? "Loading..." : IndustryData?.data?.unitId !== "" ? IndustryData?.data?.unitId : 'Not available'}</TableCell>
            </TableRow>

            <br /><br /><br /><br />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
