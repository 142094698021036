import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showTicker } from "../../TickerSlice";
import userSlice, { status } from "../../userSlice";
import { SERVER_ADDRESS } from "../../../../Firebase/Variables";

export const GetBillsOf = createAsyncThunk('GetBillsOf', async ({ industryID, AuthToken, PlantID }, { dispatch, fulfillWithValue, rejectWithValue }) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    // console.log(industryID, AuthToken);

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/bill/get/?industryid=${industryID}&plantID=${PlantID}`, requestOptions)
        const data = await response.json()

        console.log(response, data)

        if (response.ok) {
            return fulfillWithValue({ industryID: industryID, bills: data })
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(response.status)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        return rejectWithValue(error.message)
    }
})



export const GetIndustryBills = createAsyncThunk('GetIndustryBills', async ({ AuthToken, PlantID }, { dispatch, fulfillWithValue, rejectWithValue }) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/bill/get?plantID=${PlantID}`, requestOptions)
        const data = await response.json()

        console.log(response, data)

        if (response.ok) {
            return fulfillWithValue(data)
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            return rejectWithValue(response.status)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        return rejectWithValue(error.message)
    }

})


export const DeleteBill = createAsyncThunk('DeleteBill', async ({ billID, industryID, AuthToken, setDeletingLoading, setDeleteCNFpopup, setBillDetails, PlantID }, { dispatch }) => {
    setDeletingLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(`${SERVER_ADDRESS}/industry/bill/delete/${industryID}/${billID}?plantID=${PlantID}`, requestOptions)
        .then((response) => {
            response.json().then((data) => {
                console.log(response, data);
                if (response.ok) {
                    dispatch(showTicker({
                        message: data.message,
                        type: 'success',
                    }))
                }
                else {
                    dispatch(showTicker({
                        message: `${response.statusText} | ${data.message}`,
                        type: 'error'
                    }))
                }
            })

            setDeletingLoading(false)
            setDeleteCNFpopup(false)
            setBillDetails({
                isSideDrawerOpen: false,
                activeBillId: '',
                activeBillData: []
            })
            dispatch(deleteBill({ industryId: industryID, billId: billID })) // deleting bill from the list for instantly remove the bill from the list. 
        })
        .catch((error) => {
            dispatch(showTicker({
                message: error.message,
                type: 'error'
            }))
            setDeletingLoading(false)
        })
})


// function for get one bill of a industry by industry id and bill id
export const GetSingleBillIndustrySide = createAsyncThunk('GetSingleBillPlantSide', async ({ AuthToken, billID, setLoading, PlantID }, { dispatch }) => {

    console.log(AuthToken, billID);

    // setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AuthToken}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${SERVER_ADDRESS}/industry/bill/get/?bill=${billID}&plantID=${PlantID}`, requestOptions)
        const data = await response.json()

        if (response.ok) {
            console.log(data);
            // setLoading(false)
            return data
        }
        else {
            dispatch(showTicker({
                message: `${response.statusText} | ${data.message}`,
                type: 'error'
            }))
            // setLoading(false)
        }
    }
    catch (error) {
        dispatch(showTicker({
            message: error.message,
            type: 'error'
        }))
        // setLoading(false)
    }
})



const industrySlice = createSlice({
    name: 'industry',
    initialState: {
        loading: status.IDLE,
        industries: {}, // Object to store bills for each industry. this wil contain bills fo all the indsutries id wise. 
        DedicatedBills: {} // this object will contain bills of a single industry, this will be used at industry side onlyy. 
    },
    reducers: {
        deleteBill: (state, action) => {
            const { industryId, billId } = action.payload;
            console.log(industryId, billId);

            // Check if the industryId exists in the industries object
            if (state.industries[industryId]) {
                state.industries[industryId].bills = state.industries[industryId].bills.filter(bill => bill.id !== billId);
            }
        },

        updateBillData: (state, action) => {
            const { billId, newData } = action.payload;

            // Find the index of the bill in the array
            const billIndex = state.DedicatedBills.bills.findIndex(bill => bill.id === billId);
            state.DedicatedBills.bills[billIndex].data = newData;
        },

        approveBill(state, action) {
            const { industryId, billId, paymentReceiptLink } = action.payload;
            // Check if the industry exists
            if (state.industries[industryId]) {
                // Use immer's produce function to safely update the state immutably
                const billIndex = state.industries[industryId].bills.findIndex(bill => bill.id === billId);

                if (billIndex !== -1) {
                    state.industries[industryId].bills[billIndex].data.isPaid = true
                    state.industries[industryId].bills[billIndex].data.paymentRecieptStatus = 2
                    state.industries[industryId].bills[billIndex].data.paymentRecieptLink = paymentReceiptLink
                }

            }
        }
    },
    extraReducers: {
        [GetBillsOf.pending]: (state) => {
            state.loading = status.LOADING;
        },
        [GetBillsOf.fulfilled]: (state, action) => {
            let industryId = action.payload.industryID
            let bills = action.payload.bills
            // Append bills to existing industry data or create a new entry if not exists


            if (!(industryId in state.industries)) {
                state.industries[industryId] = bills
            }
            // state.industries[industryId] = state.industries[industryId] ? [...state.industries[industryId], ...bills] : bills;
            state.loading = status.IDLE;
        },
        [GetBillsOf.rejected]: (state, action) => {
            state.loading = status.ERROR;
            // Handle error if needed
        },


        // extra reducers for handling the seperate function for handling fetcthing of the bills of a specific industry
        [GetIndustryBills.pending]: (state) => {
            state.loading = status.LOADING;
        },
        [GetIndustryBills.fulfilled]: (state, action) => {
            state.loading = status.IDLE;
            state.DedicatedBills = action.payload
        },
        [GetIndustryBills.rejected]: (state) => {
            state.loading = status.ERROR
        },
    }
});

export const { deleteBill, updateBillData, approveBill } = industrySlice.actions;
export default industrySlice.reducer;



