import { ArrowBack, Save } from '@mui/icons-material'
import { IconButton, Stack, Divider, Radio } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { InputRow } from '../../UserAuthentication/IndustryRegistrationForm'
import { LoadingButton } from '@mui/lab'
import { EmptyFeildValidation, PinCodeValidation } from '../../UserAuthentication/AuthFunctions'
import { EditIndustry } from '../../Redux/Slices/Industry/EditIndustrySlice'

export default function EditIndustryDetails() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const AuthToken = useSelector(state => state.USER_DETAILS.AuthToken)
  const PlantID = useSelector(state => state.USER_DETAILS.PlantID)
  const IndustryID = useSelector(state => state.USER_DETAILS.DocID)
  const IndustryDetails = useSelector(state => state.USER_DETAILS?.UserDetails)
  const [Loading, setLoading] = useState(false)

  const [IndustryDetailsInit, setIndustryDetailsInit] = useState({
    // only these details we let industry to edit
    address: IndustryDetails?.address,
    pincode: IndustryDetails?.pincode,
    IC_chamber_install: IndustryDetails?.IC_chamber_install,
    cetp_stp_etp_type: IndustryDetails?.cetp_stp_etp_type,
    consentValidity: IndustryDetails?.consentValidity,
    domesticEffluent: IndustryDetails?.domesticEffluent,
    h_n_type: IndustryDetails?.h_n_type,
    phase: IndustryDetails?.phase,
    pno: IndustryDetails?.pno,
    remark: IndustryDetails?.remark,
    totalEffluentTradeAndUtility: IndustryDetails?.totalEffluentTradeAndUtility,
    unitId: IndustryDetails?.unitId,
    longitude: IndustryDetails?.longitude,
    latitude: IndustryDetails?.latitude,
    apiID: IndustryDetails?.apiID
  })

  const submit = () => {
    if ( 
      EmptyFeildValidation(IndustryDetailsInit.address, 'Address', dispatch) &&
      PinCodeValidation(IndustryDetailsInit.pincode, dispatch) 
    ) {
      dispatch(EditIndustry({
        AuthToken: AuthToken,
        IndustryID: IndustryID,
        PlantID: PlantID,
        setLoading: setLoading,
        data: IndustryDetailsInit
      }))
    }
  }

  const disabled = () => {
    if( IndustryDetails.address !== IndustryDetailsInit.address ||
        IndustryDetails.pincode !== IndustryDetailsInit.pincode ||
        IndustryDetails.IC_chamber_install !== IndustryDetailsInit.IC_chamber_install ||
        IndustryDetails.cetp_stp_etp_type !== IndustryDetailsInit.cetp_stp_etp_type ||
        IndustryDetails.consentValidity !== IndustryDetailsInit.consentValidity ||
        IndustryDetails.domesticEffluent !== IndustryDetailsInit.domesticEffluent ||
        IndustryDetails.h_n_type !== IndustryDetailsInit.h_n_type ||
        IndustryDetails.phase !== IndustryDetailsInit.phase ||
        IndustryDetails.pno !== IndustryDetailsInit.pno ||
        IndustryDetails.remark !== IndustryDetailsInit.remark ||
        IndustryDetails.totalEffluentTradeAndUtility !== IndustryDetailsInit.totalEffluentTradeAndUtility ||
        IndustryDetails.unitId !== IndustryDetailsInit.unitId ||
        IndustryDetails.longitude !== IndustryDetailsInit.longitude ||
        IndustryDetails.latitude !== IndustryDetailsInit.latitude ||
        IndustryDetails.apiID !== IndustryDetailsInit.apiID
    ) {
      return false
    } else {
      return true
    }
  }

  return (
    <div>
      <div className="PAGE_HEADER">
        {/* Name of the page, will show in lwft of the header */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={() => { navigate(-1) }}> <ArrowBack fontSize='small' /></IconButton>
          <h3>Edit Industry Details</h3>
        </Stack>
      </div>
      {/* table of the bills */}
      <div className="Industries_Body_Container">
        <div className="TOOLS_WRAPPER" style={{ textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p className="simpleParagraph">Please enter all the details of Industry you want to add here. Make sure ...</p>
          <LoadingButton
            color="PrimaryBlue"
            variant="contained"
            loading={Loading}
            onClick={submit}
            size='medium'
            startIcon={<Save />}
            disabled={disabled()}
            disableElevation
          > Save Details </LoadingButton>
        </div>

        <div style={{ width: '100%', padding: '0px 30px' }}>
          <InputRow Label={"Company Name"} type={"text"} value={IndustryDetails.companyName} disabled={true} placeHolder={"Company Name"} />
          <InputRow Label={"Address"} type={"text"} value={IndustryDetailsInit.address} placeHolder={"Address"} action={(e) => setIndustryDetailsInit({ ...IndustryDetailsInit, address: e.target.value })} />
          <InputRow Label={"Pin Code"} type={"text"} value={IndustryDetailsInit.pincode} placeHolder={"Pin Code"} action={(e) => setIndustryDetailsInit({ ...IndustryDetailsInit, pincode: e.target.value })} />
          <InputRow Label={"E-mail"} type={"text"} value={IndustryDetails.email} disabled={true}  placeHolder={"E-mail address"} />
          <InputRow Label={"Phone Number"} type={"number"} value={IndustryDetails.phoneNo} disabled={true} placeHolder={"Phone Number"} />
          <br />
          <Divider />
          <br />
          <InputRow Label={"Plant type"} type={"text"} value={IndustryDetailsInit.cetp_stp_etp_type} placeHolder={"CETP/ETP/STP"} action={(e) => setIndustryDetailsInit({ ...IndustryDetailsInit, cetp_stp_etp_type: e.target.value })} />
          <div className="Row">
            <div className="Label">{`IC Chamber Installed?`}</div>
            <div style={{ display: "flex", alignItems: "center", width: "320px", justifyContent: "space-between" }}>
              <Radio checked={IndustryDetailsInit.IC_chamber_install === 'yes'} onClick={() => { setIndustryDetailsInit({...IndustryDetailsInit, IC_chamber_install: 'yes' }) }} />
              <div className="Label">{`Installed`}</div>
              <Radio checked={IndustryDetailsInit.IC_chamber_install === 'no'} onClick={() => { setIndustryDetailsInit({...IndustryDetailsInit, IC_chamber_install: 'no' }) }} />
              <div className="Label">{`Not Installed`}</div>
            </div>
          </div>
          <InputRow Label={"Consent Validity"} type={"text"} value={IndustryDetailsInit.consentValidity} placeHolder={"Consent Validity"} action={(e) => setIndustryDetailsInit({ ...IndustryDetailsInit, consentValidity: e.target.value })} />
          <InputRow Label={"Domestic Effluent"} type={"text"} value={IndustryDetailsInit.domesticEffluent} placeHolder={"Domestic Effluent"} action={(e) => setIndustryDetailsInit({ ...IndustryDetailsInit, domesticEffluent: e.target.value })} />
          <InputRow Label={"H/N Type"} type={"text"} value={IndustryDetailsInit.h_n_type} placeHolder={"H/N Type"} action={(e) => setIndustryDetailsInit({ ...IndustryDetailsInit, h_n_type: e.target.value })} />
          <InputRow Label={"Phase"} type={"number"} value={IndustryDetailsInit.phase} placeHolder={"Phase"} action={(e) => setIndustryDetailsInit({ ...IndustryDetailsInit, phase: e.target.value })} />

          <InputRow Label={"P. No."} type={"text"} value={IndustryDetailsInit.pno} placeHolder={"P. No."} action={(e) => setIndustryDetailsInit({ ...IndustryDetailsInit, pno: e.target.value })} />
          <InputRow Label={"Api ID"} type={"text"} value={IndustryDetailsInit.apiID} placeHolder={"Api ID"} action={(e) => setIndustryDetailsInit({ ...IndustryDetailsInit, apiID: parseInt(e.target.value) })} />
          <InputRow Label={"Total Effluent Trade and Utility"} value={IndustryDetailsInit.totalEffluentTradeAndUtility} type={"text"} placeHolder={"Total Effluent Trade and Utility"} action={(e) => setIndustryDetailsInit({ ...IndustryDetailsInit, totalEffluentTradeAndUtility: e.target.value })} />
          <InputRow Label={"Unit ID"} type={"text"} value={IndustryDetailsInit.unitId} placeHolder={"Unit ID"} action={(e) => setIndustryDetailsInit({ ...IndustryDetailsInit, unitId: e.target.value })} />
          <InputRow Label={"Remark"} type={"text"} value={IndustryDetailsInit.remark} placeHolder={"Remark (Optional)"} action={(e) => setIndustryDetailsInit({ ...IndustryDetailsInit, remark: e.target.value })} />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  )
}